.world_layers_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
}
.world_layer {

}
