import { loadSound, playSound } from '../basicSounds'
import { ButtonProvider } from './Button'

void loadSound('button_click.mp3')

const __ButtonAppProvider_COMPONENT = ({ children }) => {
  return <ButtonProvider onClick={() => {
    void playSound('button_click.mp3')
  }}>{children}</ButtonProvider>
}
;__ButtonAppProvider_COMPONENT.displayName = 'ButtonAppProvider';export default __ButtonAppProvider_COMPONENT;