# Made in Blockbench 4.9.4
mtllib materials.mtl

o body
v 0.09375 0.25 0.0625
v 0.09375 0.25 -0.0625
v 0.09375 0 0.0625
v 0.09375 0 -0.0625
v -0.09375 0.25 -0.0625
v -0.09375 0.25 0.0625
v -0.09375 0 -0.0625
v -0.09375 0 0.0625
vt 0.0625 0.625
vt 0.15625 0.625
vt 0.15625 0.5
vt 0.0625 0.5
vt 0 0.625
vt 0.0625 0.625
vt 0.0625 0.5
vt 0 0.5
vt 0.21875 0.625
vt 0.3125 0.625
vt 0.3125 0.5
vt 0.21875 0.5
vt 0.15625 0.625
vt 0.21875 0.625
vt 0.21875 0.5
vt 0.15625 0.5
vt 0.15625 0.625
vt 0.0625 0.625
vt 0.0625 0.6875
vt 0.15625 0.6875
vt 0.25 0.6875
vt 0.15625 0.6875
vt 0.15625 0.625
vt 0.25 0.625
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_f88b8145-cdbb-8011-03d2-81deb45b4e43
f 4/4/1 7/3/1 5/2/1 2/1/1
f 3/8/2 4/7/2 2/6/2 1/5/2
f 8/12/3 3/11/3 1/10/3 6/9/3
f 7/16/4 8/15/4 6/14/4 5/13/4
f 6/20/5 1/19/5 2/18/5 5/17/5
f 7/24/6 4/23/6 3/22/6 8/21/6
o body
v 0.08125000000000004 0.17500000000000004 0.050000000000000044
v 0.08125000000000004 0.17500000000000004 -0.04999999999999999
v 0.08125000000000004 -0.11249999999999999 0.050000000000000044
v 0.08125000000000004 -0.11249999999999999 -0.04999999999999999
v -0.08124999999999999 0.17500000000000004 -0.04999999999999999
v -0.08124999999999999 0.17500000000000004 0.050000000000000044
v -0.08124999999999999 -0.11249999999999999 -0.04999999999999999
v -0.08124999999999999 -0.11249999999999999 0.050000000000000044
vt 0.0625 0.4375
vt 0.15625 0.4375
vt 0.15625 0.28125
vt 0.0625 0.28125
vt 0 0.4375
vt 0.0625 0.4375
vt 0.0625 0.28125
vt 0 0.28125
vt 0.21875 0.4375
vt 0.3125 0.4375
vt 0.3125 0.28125
vt 0.21875 0.28125
vt 0.15625 0.4375
vt 0.21875 0.4375
vt 0.21875 0.28125
vt 0.15625 0.28125
vt 0.15625 0.4375
vt 0.0625 0.4375
vt 0.0625 0.5
vt 0.15625 0.5
vt 0.25 0.5
vt 0.15625 0.5
vt 0.15625 0.4375
vt 0.25 0.4375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_f88b8145-cdbb-8011-03d2-81deb45b4e43
f 12/28/7 15/27/7 13/26/7 10/25/7
f 11/32/8 12/31/8 10/30/8 9/29/8
f 16/36/9 11/35/9 9/34/9 14/33/9
f 15/40/10 16/39/10 14/38/10 13/37/10
f 14/44/11 9/43/11 10/42/11 13/41/11
f 15/48/12 12/47/12 11/46/12 16/45/12
o head
v 0.15625 0.5625 0.15625
v 0.15625 0.5625 -0.15625
v 0.15625 0.25 0.15625
v 0.15625 0.25 -0.15625
v -0.15625 0.5625 -0.15625
v -0.15625 0.5625 0.15625
v -0.15625 0.25 -0.15625
v -0.15625 0.25 0.15625
vt 0.15625 0.84375
vt 0.3125 0.84375
vt 0.3125 0.6875
vt 0.15625 0.6875
vt 0 0.84375
vt 0.15625 0.84375
vt 0.15625 0.6875
vt 0 0.6875
vt 0.46875 0.84375
vt 0.625 0.84375
vt 0.625 0.6875
vt 0.46875 0.6875
vt 0.3125 0.84375
vt 0.46875 0.84375
vt 0.46875 0.6875
vt 0.3125 0.6875
vt 0.3125 0.84375
vt 0.15625 0.84375
vt 0.15625 1
vt 0.3125 1
vt 0.46875 1
vt 0.3125 1
vt 0.3125 0.84375
vt 0.46875 0.84375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_f88b8145-cdbb-8011-03d2-81deb45b4e43
f 20/52/13 23/51/13 21/50/13 18/49/13
f 19/56/14 20/55/14 18/54/14 17/53/14
f 24/60/15 19/59/15 17/58/15 22/57/15
f 23/64/16 24/63/16 22/62/16 21/61/16
f 22/68/17 17/67/17 18/66/17 21/65/17
f 23/72/18 20/71/18 19/70/18 24/69/18
o rightArm
v 0.18125000000000002 0.259375 0.05625000000000002
v 0.18125000000000002 0.259375 -0.05625000000000002
v 0.18125000000000002 0.021874999999999978 0.05625000000000002
v 0.18125000000000002 0.021874999999999978 -0.05625000000000002
v 0.06874999999999998 0.259375 -0.05625000000000002
v 0.06874999999999998 0.259375 0.05625000000000002
v 0.06874999999999998 0.021874999999999978 -0.05625000000000002
v 0.06874999999999998 0.021874999999999978 0.05625000000000002
vt 0.78125 0.9375
vt 0.84375 0.9375
vt 0.84375 0.8125
vt 0.78125 0.8125
vt 0.71875 0.9375
vt 0.78125 0.9375
vt 0.78125 0.8125
vt 0.71875 0.8125
vt 0.90625 0.9375
vt 0.96875 0.9375
vt 0.96875 0.8125
vt 0.90625 0.8125
vt 0.84375 0.9375
vt 0.90625 0.9375
vt 0.90625 0.8125
vt 0.84375 0.8125
vt 0.84375 0.9375
vt 0.78125 0.9375
vt 0.78125 1
vt 0.84375 1
vt 0.90625 1
vt 0.84375 1
vt 0.84375 0.9375
vt 0.90625 0.9375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_f88b8145-cdbb-8011-03d2-81deb45b4e43
f 28/76/19 31/75/19 29/74/19 26/73/19
f 27/80/20 28/79/20 26/78/20 25/77/20
f 32/84/21 27/83/21 25/82/21 30/81/21
f 31/88/22 32/87/22 30/86/22 29/85/22
f 30/92/23 25/91/23 26/90/23 29/89/23
f 31/96/24 28/95/24 27/94/24 32/93/24
o leftArm
v -0.06874999999999998 0.259375 0.05625000000000002
v -0.06874999999999998 0.259375 -0.05625000000000002
v -0.06874999999999998 0.021874999999999978 0.05625000000000002
v -0.06874999999999998 0.021874999999999978 -0.05625000000000002
v -0.18125000000000002 0.259375 -0.05625000000000002
v -0.18125000000000002 0.259375 0.05625000000000002
v -0.18125000000000002 0.021874999999999978 -0.05625000000000002
v -0.18125000000000002 0.021874999999999978 0.05625000000000002
vt 0.78125 0.75
vt 0.84375 0.75
vt 0.84375 0.625
vt 0.78125 0.625
vt 0.71875 0.75
vt 0.78125 0.75
vt 0.78125 0.625
vt 0.71875 0.625
vt 0.90625 0.75
vt 0.96875 0.75
vt 0.96875 0.625
vt 0.90625 0.625
vt 0.84375 0.75
vt 0.90625 0.75
vt 0.90625 0.625
vt 0.84375 0.625
vt 0.84375 0.75
vt 0.78125 0.75
vt 0.78125 0.8125
vt 0.84375 0.8125
vt 0.90625 0.8125
vt 0.84375 0.8125
vt 0.84375 0.75
vt 0.90625 0.75
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_f88b8145-cdbb-8011-03d2-81deb45b4e43
f 36/100/25 39/99/25 37/98/25 34/97/25
f 35/104/26 36/103/26 34/102/26 33/101/26
f 40/108/27 35/107/27 33/106/27 38/105/27
f 39/112/28 40/111/28 38/110/28 37/109/28
f 38/116/29 33/115/29 34/114/29 37/113/29
f 39/120/30 36/119/30 35/118/30 40/117/30
o leftWing
v -0.03125 0.1875 0.0625
v -0.03125 0.1875 0.0625
v -0.03125 -0.125 0.0625
v -0.03125 -0.125 0.0625
v -0.53125 0.1875 0.0625
v -0.53125 0.1875 0.0625
v -0.53125 -0.125 0.0625
v -0.53125 -0.125 0.0625
vt 0.75 0.3125
vt 0.5 0.3125
vt 0.5 0.15625
vt 0.75 0.15625
vt 0.75 0.3125
vt 0.75 0.3125
vt 0.75 0.15625
vt 0.75 0.15625
vt 1 0.3125
vt 0.75 0.3125
vt 0.75 0.15625
vt 1 0.15625
vt 0.5 0.3125
vt 0.5 0.3125
vt 0.5 0.15625
vt 0.5 0.15625
vt 0.5 0.3125
vt 0.75 0.3125
vt 0.75 0.3125
vt 0.5 0.3125
vt 0.75 0.3125
vt 1 0.3125
vt 1 0.3125
vt 0.75 0.3125
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_f88b8145-cdbb-8011-03d2-81deb45b4e43
f 44/124/31 47/123/31 45/122/31 42/121/31
f 43/128/32 44/127/32 42/126/32 41/125/32
f 48/132/33 43/131/33 41/130/33 46/129/33
f 47/136/34 48/135/34 46/134/34 45/133/34
f 46/140/35 41/139/35 42/138/35 45/137/35
f 47/144/36 44/143/36 43/142/36 48/141/36
o rightWing
v 0.53125 0.1875 0.0625
v 0.53125 0.1875 0.0625
v 0.53125 -0.125 0.0625
v 0.53125 -0.125 0.0625
v 0.03125 0.1875 0.0625
v 0.03125 0.1875 0.0625
v 0.03125 -0.125 0.0625
v 0.03125 -0.125 0.0625
vt 0.5 0.3125
vt 0.75 0.3125
vt 0.75 0.15625
vt 0.5 0.15625
vt 0.5 0.3125
vt 0.5 0.3125
vt 0.5 0.15625
vt 0.5 0.15625
vt 0.75 0.3125
vt 1 0.3125
vt 1 0.15625
vt 0.75 0.15625
vt 0.75 0.3125
vt 0.75 0.3125
vt 0.75 0.15625
vt 0.75 0.15625
vt 0.75 0.3125
vt 0.5 0.3125
vt 0.5 0.3125
vt 0.75 0.3125
vt 1 0.3125
vt 0.75 0.3125
vt 0.75 0.3125
vt 1 0.3125
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_f88b8145-cdbb-8011-03d2-81deb45b4e43
f 52/148/37 55/147/37 53/146/37 50/145/37
f 51/152/38 52/151/38 50/150/38 49/149/38
f 56/156/39 51/155/39 49/154/39 54/153/39
f 55/160/40 56/159/40 54/158/40 53/157/40
f 54/164/41 49/163/41 50/162/41 53/161/41
f 55/168/42 52/167/42 51/166/42 56/165/42