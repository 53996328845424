.xp-bar-bg {
  z-index: -1;
  position: fixed;
  left: 50%;
  bottom: calc(var(--safe-area-inset-bottom) + 25px);
  transform: translate(-50%);
  width: 182px;
  height: 5px;
  background-image: url('minecraft-assets/minecraft-assets/data/1.16.4/gui/icons.png');
  background-size: 256px;
  background-position-y: -64px;
}

.xp-bar {
  width: 182px;
  height: 5px;
  background-image: url('minecraft-assets/minecraft-assets/data/1.17.1/gui/icons.png');
  background-size: 256px;
  background-position-y: -69px;
}


.xp-label {
  position: fixed;
  top: -8px;
  left: 50%;
  transform: translate(-50%);
  font-size: 10px;
  font-family: minecraft, mojangles, monospace;
  color: rgb(30, 250, 30);
  text-shadow: 0px -1px #000, 0px 1px #000, 1px 0px #000, -1px 0px #000;
  z-index: 10;
}
