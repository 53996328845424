.mobile-top-btns {
  display: none;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  gap: 0 5px;
  z-index: -1;
}

.pause-btn,
.chat-btn {
  --scale: 1.3;
  border: none;
  outline: 0.5px solid white;
  width: calc(14px * var(--scale));
  height: calc(14px * var(--scale));
  background-image: url('extra-textures/gui.png');
  background-size: calc(256px * var(--scale));
  background-position-x: calc(var(--scale) * -202px);
  background-position-y: calc(var(--scale) * -66px);
}

.chat-btn {
  background-position-y: calc(var(--scale) * -84px);
}
.debug-btn {
  background: #9c8c86;
  font-size: 8px;
  /* todo make other buttons centered */
  /* margin-right: 5px; */
  color: white;
  font-family: minecraft, mojangles, monospace;
  padding: 4px 6px;
  outline: 0.5px solid white;
}
