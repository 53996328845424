# Made in Blockbench 4.9.4
mtllib materials.mtl

o body
v 0.21875 0.25 0.3125
v 0.21875 0.25 -0.25
v 0.21875 0.0625 0.3125
v 0.21875 0.0625 -0.25
v -0.21875 0.25 -0.25
v -0.21875 0.25 0.3125
v -0.21875 0.0625 -0.25
v -0.21875 0.0625 0.3125
vt 0.25 0.7916666666666666
vt 0.3958333333333333 0.7916666666666666
vt 0.3958333333333333 0.7291666666666667
vt 0.25 0.7291666666666667
vt 0.0625 0.7916666666666666
vt 0.25 0.7916666666666666
vt 0.25 0.7291666666666667
vt 0.0625 0.7291666666666667
vt 0.5833333333333334 0.7916666666666666
vt 0.7291666666666666 0.7916666666666666
vt 0.7291666666666666 0.7291666666666667
vt 0.5833333333333334 0.7291666666666667
vt 0.3958333333333333 0.7916666666666666
vt 0.5833333333333334 0.7916666666666666
vt 0.5833333333333334 0.7291666666666667
vt 0.3958333333333333 0.7291666666666667
vt 0.3958333333333333 0.7916666666666666
vt 0.25 0.7916666666666666
vt 0.25 0.9791666666666666
vt 0.3958333333333333 0.9791666666666666
vt 0.5416666666666666 0.9791666666666666
vt 0.3958333333333333 0.9791666666666666
vt 0.3958333333333333 0.7916666666666666
vt 0.5416666666666666 0.7916666666666666
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_4288dcd0-d35c-c2c4-0fee-38c374635ee1
f 4/4/1 7/3/1 5/2/1 2/1/1
f 3/8/2 4/7/2 2/6/2 1/5/2
f 8/12/3 3/11/3 1/10/3 6/9/3
f 7/16/4 8/15/4 6/14/4 5/13/4
f 6/20/5 1/19/5 2/18/5 5/17/5
f 7/24/6 4/23/6 3/22/6 8/21/6
o body
v 0.21875 0.1875 0.3125
v 0.21875 0.1875 -0.25
v 0.21875 0.1875 0.3125
v 0.21875 0.1875 -0.25
v -0.21875 0.1875 -0.25
v -0.21875 0.1875 0.3125
v -0.21875 0.1875 -0.25
v -0.21875 0.1875 0.3125
vt 0.6666666666666666 0.35416666666666663
vt 0.8125 0.35416666666666663
vt 0.8125 0.35416666666666663
vt 0.6666666666666666 0.35416666666666663
vt 0.4791666666666667 0.35416666666666663
vt 0.6666666666666666 0.35416666666666663
vt 0.6666666666666666 0.35416666666666663
vt 0.4791666666666667 0.35416666666666663
vt 1 0.35416666666666663
vt 1.1458333333333333 0.35416666666666663
vt 1.1458333333333333 0.35416666666666663
vt 1 0.35416666666666663
vt 0.8125 0.35416666666666663
vt 1 0.35416666666666663
vt 1 0.35416666666666663
vt 0.8125 0.35416666666666663
vt 0.8125 0.35416666666666663
vt 0.6666666666666666 0.35416666666666663
vt 0.6666666666666666 0.5416666666666667
vt 0.8125 0.5416666666666667
vt 0.9583333333333334 0.5416666666666667
vt 0.8125 0.5416666666666667
vt 0.8125 0.35416666666666663
vt 0.9583333333333334 0.35416666666666663
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_4288dcd0-d35c-c2c4-0fee-38c374635ee1
f 12/28/7 15/27/7 13/26/7 10/25/7
f 11/32/8 12/31/8 10/30/8 9/29/8
f 16/36/9 11/35/9 9/34/9 14/33/9
f 15/40/10 16/39/10 14/38/10 13/37/10
f 14/44/11 9/43/11 10/42/11 13/41/11
f 15/48/12 12/47/12 11/46/12 16/45/12
o head
v 0.21875 0.3125 0.3125
v 0.21875 0.3125 -0.25
v 0.21875 0.3125 0.3125
v 0.21875 0.3125 -0.25
v -0.21875 0.3125 -0.25
v -0.21875 0.3125 0.3125
v -0.21875 0.3125 -0.25
v -0.21875 0.3125 0.3125
vt 0.6666666666666666 0.5416666666666667
vt 0.8125 0.5416666666666667
vt 0.8125 0.5416666666666667
vt 0.6666666666666666 0.5416666666666667
vt 0.4791666666666667 0.5416666666666667
vt 0.6666666666666666 0.5416666666666667
vt 0.6666666666666666 0.5416666666666667
vt 0.4791666666666667 0.5416666666666667
vt 1 0.5416666666666667
vt 1.1458333333333333 0.5416666666666667
vt 1.1458333333333333 0.5416666666666667
vt 1 0.5416666666666667
vt 0.8125 0.5416666666666667
vt 1 0.5416666666666667
vt 1 0.5416666666666667
vt 0.8125 0.5416666666666667
vt 0.8125 0.5416666666666667
vt 0.6666666666666666 0.5416666666666667
vt 0.6666666666666666 0.7291666666666667
vt 0.8125 0.7291666666666667
vt 0.9583333333333334 0.7291666666666667
vt 0.8125 0.7291666666666667
vt 0.8125 0.5416666666666667
vt 0.9583333333333334 0.5416666666666667
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_4288dcd0-d35c-c2c4-0fee-38c374635ee1
f 20/52/13 23/51/13 21/50/13 18/49/13
f 19/56/14 20/55/14 18/54/14 17/53/14
f 24/60/15 19/59/15 17/58/15 22/57/15
f 23/64/16 24/63/16 22/62/16 21/61/16
f 22/68/17 17/67/17 18/66/17 21/65/17
f 23/72/18 20/71/18 19/70/18 24/69/18
o head
v 0.21875 0.375 0.3125
v 0.21875 0.375 -0.25
v 0.21875 0.1875 0.3125
v 0.21875 0.1875 -0.25
v -0.21875 0.375 -0.25
v -0.21875 0.375 0.3125
v -0.21875 0.1875 -0.25
v -0.21875 0.1875 0.3125
vt 0.1875 0.5416666666666667
vt 0.3333333333333333 0.5416666666666667
vt 0.3333333333333333 0.47916666666666663
vt 0.1875 0.47916666666666663
vt 0 0.5416666666666667
vt 0.1875 0.5416666666666667
vt 0.1875 0.47916666666666663
vt 0 0.47916666666666663
vt 0.5208333333333334 0.5416666666666667
vt 0.6666666666666666 0.5416666666666667
vt 0.6666666666666666 0.47916666666666663
vt 0.5208333333333334 0.47916666666666663
vt 0.3333333333333333 0.5416666666666667
vt 0.5208333333333334 0.5416666666666667
vt 0.5208333333333334 0.47916666666666663
vt 0.3333333333333333 0.47916666666666663
vt 0.3333333333333333 0.5416666666666667
vt 0.1875 0.5416666666666667
vt 0.1875 0.7291666666666667
vt 0.3333333333333333 0.7291666666666667
vt 0.4791666666666667 0.7291666666666667
vt 0.3333333333333333 0.7291666666666667
vt 0.3333333333333333 0.5416666666666667
vt 0.4791666666666667 0.5416666666666667
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_4288dcd0-d35c-c2c4-0fee-38c374635ee1
f 28/76/19 31/75/19 29/74/19 26/73/19
f 27/80/20 28/79/20 26/78/20 25/77/20
f 32/84/21 27/83/21 25/82/21 30/81/21
f 31/88/22 32/87/22 30/86/22 29/85/22
f 30/92/23 25/91/23 26/90/23 29/89/23
f 31/96/24 28/95/24 27/94/24 32/93/24
o right_eye
v 0.21875 0.5 0
v 0.21875 0.5 -0.1875
v 0.21875 0.375 0
v 0.21875 0.375 -0.1875
v 0.03125 0.5 -0.1875
v 0.03125 0.5 0
v 0.03125 0.375 -0.1875
v 0.03125 0.375 0
vt 0.0625 0.9375
vt 0.125 0.9375
vt 0.125 0.8958333333333334
vt 0.0625 0.8958333333333334
vt 0 0.9375
vt 0.0625 0.9375
vt 0.0625 0.8958333333333334
vt 0 0.8958333333333334
vt 0.1875 0.9375
vt 0.25 0.9375
vt 0.25 0.8958333333333334
vt 0.1875 0.8958333333333334
vt 0.125 0.9375
vt 0.1875 0.9375
vt 0.1875 0.8958333333333334
vt 0.125 0.8958333333333334
vt 0.125 0.9375
vt 0.0625 0.9375
vt 0.0625 1
vt 0.125 1
vt 0.1875 1
vt 0.125 1
vt 0.125 0.9375
vt 0.1875 0.9375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_4288dcd0-d35c-c2c4-0fee-38c374635ee1
f 36/100/25 39/99/25 37/98/25 34/97/25
f 35/104/26 36/103/26 34/102/26 33/101/26
f 40/108/27 35/107/27 33/106/27 38/105/27
f 39/112/28 40/111/28 38/110/28 37/109/28
f 38/116/29 33/115/29 34/114/29 37/113/29
f 39/120/30 36/119/30 35/118/30 40/117/30
o left_eye
v -0.03125 0.5 0
v -0.03125 0.5 -0.1875
v -0.03125 0.375 0
v -0.03125 0.375 -0.1875
v -0.21875 0.5 -0.1875
v -0.21875 0.5 0
v -0.21875 0.375 -0.1875
v -0.21875 0.375 0
vt 0.0625 0.8333333333333334
vt 0.125 0.8333333333333334
vt 0.125 0.7916666666666666
vt 0.0625 0.7916666666666666
vt 0 0.8333333333333334
vt 0.0625 0.8333333333333334
vt 0.0625 0.7916666666666666
vt 0 0.7916666666666666
vt 0.1875 0.8333333333333334
vt 0.25 0.8333333333333334
vt 0.25 0.7916666666666666
vt 0.1875 0.7916666666666666
vt 0.125 0.8333333333333334
vt 0.1875 0.8333333333333334
vt 0.1875 0.7916666666666666
vt 0.125 0.7916666666666666
vt 0.125 0.8333333333333334
vt 0.0625 0.8333333333333334
vt 0.0625 0.8958333333333334
vt 0.125 0.8958333333333334
vt 0.1875 0.8958333333333334
vt 0.125 0.8958333333333334
vt 0.125 0.8333333333333334
vt 0.1875 0.8333333333333334
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_4288dcd0-d35c-c2c4-0fee-38c374635ee1
f 44/124/31 47/123/31 45/122/31 42/121/31
f 43/128/32 44/127/32 42/126/32 41/125/32
f 48/132/33 43/131/33 41/130/33 46/129/33
f 47/136/34 48/135/34 46/134/34 45/133/34
f 46/140/35 41/139/35 42/138/35 45/137/35
f 47/144/36 44/143/36 43/142/36 48/141/36
o croaking_body
v 0.21250000000000002 0.1875 -0.0625
v 0.21250000000000002 0.1875 -0.2375
v 0.21250000000000002 0.07499999999999996 -0.0625
v 0.21250000000000002 0.07499999999999996 -0.2375
v -0.21250000000000002 0.1875 -0.2375
v -0.21250000000000002 0.1875 -0.0625
v -0.21250000000000002 0.07499999999999996 -0.2375
v -0.21250000000000002 0.07499999999999996 -0.0625
vt 0.6041666666666666 0.8333333333333334
vt 0.75 0.8333333333333334
vt 0.75 0.7916666666666666
vt 0.6041666666666666 0.7916666666666666
vt 0.5416666666666666 0.8333333333333334
vt 0.6041666666666666 0.8333333333333334
vt 0.6041666666666666 0.7916666666666666
vt 0.5416666666666666 0.7916666666666666
vt 0.8125 0.8333333333333334
vt 0.9583333333333334 0.8333333333333334
vt 0.9583333333333334 0.7916666666666666
vt 0.8125 0.7916666666666666
vt 0.75 0.8333333333333334
vt 0.8125 0.8333333333333334
vt 0.8125 0.7916666666666666
vt 0.75 0.7916666666666666
vt 0.75 0.8333333333333334
vt 0.6041666666666666 0.8333333333333334
vt 0.6041666666666666 0.8958333333333334
vt 0.75 0.8958333333333334
vt 0.8958333333333334 0.8958333333333334
vt 0.75 0.8958333333333334
vt 0.75 0.8333333333333334
vt 0.8958333333333334 0.8333333333333334
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_4288dcd0-d35c-c2c4-0fee-38c374635ee1
f 52/148/37 55/147/37 53/146/37 50/145/37
f 51/152/38 52/151/38 50/150/38 49/149/38
f 56/156/39 51/155/39 49/154/39 54/153/39
f 55/160/40 56/159/40 54/158/40 53/157/40
f 54/164/41 49/163/41 50/162/41 53/161/41
f 55/168/42 52/167/42 51/166/42 56/165/42
o tongue
v 0.125 0.19374999999999998 0.30625
v 0.125 0.19374999999999998 -0.13124999999999998
v 0.125 0.19374999999999998 0.30625
v 0.125 0.19374999999999998 -0.13124999999999998
v -0.125 0.19374999999999998 -0.13124999999999998
v -0.125 0.19374999999999998 0.30625
v -0.125 0.19374999999999998 -0.13124999999999998
v -0.125 0.19374999999999998 0.30625
vt 0.5 0.5833333333333333
vt 0.5833333333333334 0.5833333333333333
vt 0.5833333333333334 0.5833333333333333
vt 0.5 0.5833333333333333
vt 0.3541666666666667 0.5833333333333333
vt 0.5 0.5833333333333333
vt 0.5 0.5833333333333333
vt 0.3541666666666667 0.5833333333333333
vt 0.7291666666666666 0.5833333333333333
vt 0.8125 0.5833333333333333
vt 0.8125 0.5833333333333333
vt 0.7291666666666666 0.5833333333333333
vt 0.5833333333333334 0.5833333333333333
vt 0.7291666666666666 0.5833333333333333
vt 0.7291666666666666 0.5833333333333333
vt 0.5833333333333334 0.5833333333333333
vt 0.5833333333333334 0.5833333333333333
vt 0.5 0.5833333333333333
vt 0.5 0.7291666666666667
vt 0.5833333333333334 0.7291666666666667
vt 0.6666666666666666 0.7291666666666667
vt 0.5833333333333334 0.7291666666666667
vt 0.5833333333333334 0.5833333333333333
vt 0.6666666666666666 0.5833333333333333
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_4288dcd0-d35c-c2c4-0fee-38c374635ee1
f 60/172/43 63/171/43 61/170/43 58/169/43
f 59/176/44 60/175/44 58/174/44 57/173/44
f 64/180/45 59/179/45 57/178/45 62/177/45
f 63/184/46 64/183/46 62/182/46 61/181/46
f 62/188/47 57/187/47 58/186/47 61/185/47
f 63/192/48 60/191/48 59/190/48 64/189/48
o left_arm
v -0.1875 0.1875 -0.03125
v -0.1875 0.1875 -0.21875
v -0.1875 0 -0.03125
v -0.1875 0 -0.21875
v -0.3125 0.1875 -0.21875
v -0.3125 0.1875 -0.03125
v -0.3125 0 -0.21875
v -0.3125 0 -0.03125
vt 0.0625 0.27083333333333337
vt 0.10416666666666667 0.27083333333333337
vt 0.10416666666666667 0.20833333333333337
vt 0.0625 0.20833333333333337
vt 0 0.27083333333333337
vt 0.0625 0.27083333333333337
vt 0.0625 0.20833333333333337
vt 0 0.20833333333333337
vt 0.16666666666666666 0.27083333333333337
vt 0.20833333333333334 0.27083333333333337
vt 0.20833333333333334 0.20833333333333337
vt 0.16666666666666666 0.20833333333333337
vt 0.10416666666666667 0.27083333333333337
vt 0.16666666666666666 0.27083333333333337
vt 0.16666666666666666 0.20833333333333337
vt 0.10416666666666667 0.20833333333333337
vt 0.10416666666666667 0.27083333333333337
vt 0.0625 0.27083333333333337
vt 0.0625 0.33333333333333337
vt 0.10416666666666667 0.33333333333333337
vt 0.14583333333333334 0.33333333333333337
vt 0.10416666666666667 0.33333333333333337
vt 0.10416666666666667 0.27083333333333337
vt 0.14583333333333334 0.27083333333333337
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_4288dcd0-d35c-c2c4-0fee-38c374635ee1
f 68/196/49 71/195/49 69/194/49 66/193/49
f 67/200/50 68/199/50 66/198/50 65/197/50
f 72/204/51 67/203/51 65/202/51 70/201/51
f 71/208/52 72/207/52 70/206/52 69/205/52
f 70/212/53 65/211/53 66/210/53 69/209/53
f 71/216/54 68/215/54 67/214/54 72/213/54
o left_arm
v 0 -0.0006249999999999867 0.03125
v 0 -0.0006249999999999867 -0.46875
v 0 -0.0006249999999999867 0.03125
v 0 -0.0006249999999999867 -0.46875
v -0.5 -0.0006249999999999867 -0.46875
v -0.5 -0.0006249999999999867 0.03125
v -0.5 -0.0006249999999999867 -0.46875
v -0.5 -0.0006249999999999867 0.03125
vt 0.5416666666666666 0
vt 0.7083333333333334 0
vt 0.7083333333333334 0
vt 0.5416666666666666 0
vt 0.375 0
vt 0.5416666666666666 0
vt 0.5416666666666666 0
vt 0.375 0
vt 0.875 0
vt 1.0416666666666667 0
vt 1.0416666666666667 0
vt 0.875 0
vt 0.7083333333333334 0
vt 0.875 0
vt 0.875 0
vt 0.7083333333333334 0
vt 0.7083333333333334 0
vt 0.5416666666666666 0
vt 0.5416666666666666 0.16666666666666663
vt 0.7083333333333334 0.16666666666666663
vt 0.875 0.16666666666666663
vt 0.7083333333333334 0.16666666666666663
vt 0.7083333333333334 0
vt 0.875 0
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_4288dcd0-d35c-c2c4-0fee-38c374635ee1
f 76/220/55 79/219/55 77/218/55 74/217/55
f 75/224/56 76/223/56 74/222/56 73/221/56
f 80/228/57 75/227/57 73/226/57 78/225/57
f 79/232/58 80/231/58 78/230/58 77/229/58
f 78/236/59 73/235/59 74/234/59 77/233/59
f 79/240/60 76/239/60 75/238/60 80/237/60
o right_arm
v 0.3125 0.1875 -0.03125
v 0.3125 0.1875 -0.21875
v 0.3125 0 -0.03125
v 0.3125 0 -0.21875
v 0.1875 0.1875 -0.21875
v 0.1875 0.1875 -0.03125
v 0.1875 0 -0.21875
v 0.1875 0 -0.03125
vt 0.0625 0.14583333333333337
vt 0.10416666666666667 0.14583333333333337
vt 0.10416666666666667 0.08333333333333337
vt 0.0625 0.08333333333333337
vt 0 0.14583333333333337
vt 0.0625 0.14583333333333337
vt 0.0625 0.08333333333333337
vt 0 0.08333333333333337
vt 0.16666666666666666 0.14583333333333337
vt 0.20833333333333334 0.14583333333333337
vt 0.20833333333333334 0.08333333333333337
vt 0.16666666666666666 0.08333333333333337
vt 0.10416666666666667 0.14583333333333337
vt 0.16666666666666666 0.14583333333333337
vt 0.16666666666666666 0.08333333333333337
vt 0.10416666666666667 0.08333333333333337
vt 0.10416666666666667 0.14583333333333337
vt 0.0625 0.14583333333333337
vt 0.0625 0.20833333333333337
vt 0.10416666666666667 0.20833333333333337
vt 0.14583333333333334 0.20833333333333337
vt 0.10416666666666667 0.20833333333333337
vt 0.10416666666666667 0.14583333333333337
vt 0.14583333333333334 0.14583333333333337
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_4288dcd0-d35c-c2c4-0fee-38c374635ee1
f 84/244/61 87/243/61 85/242/61 82/241/61
f 83/248/62 84/247/62 82/246/62 81/245/62
f 88/252/63 83/251/63 81/250/63 86/249/63
f 87/256/64 88/255/64 86/254/64 85/253/64
f 86/260/65 81/259/65 82/258/65 85/257/65
f 87/264/66 84/263/66 83/262/66 88/261/66
o right_arm
v 0.5 -0.0006249999999999867 0.03125
v 0.5 -0.0006249999999999867 -0.46875
v 0.5 -0.0006249999999999867 0.03125
v 0.5 -0.0006249999999999867 -0.46875
v 0 -0.0006249999999999867 -0.46875
v 0 -0.0006249999999999867 0.03125
v 0 -0.0006249999999999867 -0.46875
v 0 -0.0006249999999999867 0.03125
vt 0.20833333333333334 0
vt 0.375 0
vt 0.375 0
vt 0.20833333333333334 0
vt 0.041666666666666664 0
vt 0.20833333333333334 0
vt 0.20833333333333334 0
vt 0.041666666666666664 0
vt 0.5416666666666666 0
vt 0.7083333333333334 0
vt 0.7083333333333334 0
vt 0.5416666666666666 0
vt 0.375 0
vt 0.5416666666666666 0
vt 0.5416666666666666 0
vt 0.375 0
vt 0.375 0
vt 0.20833333333333334 0
vt 0.20833333333333334 0.16666666666666663
vt 0.375 0.16666666666666663
vt 0.5416666666666666 0.16666666666666663
vt 0.375 0.16666666666666663
vt 0.375 0
vt 0.5416666666666666 0
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_4288dcd0-d35c-c2c4-0fee-38c374635ee1
f 92/268/67 95/267/67 93/266/67 90/265/67
f 91/272/68 92/271/68 90/270/68 89/269/68
f 96/276/69 91/275/69 89/274/69 94/273/69
f 95/280/70 96/279/70 94/278/70 93/277/70
f 94/284/71 89/283/71 90/282/71 93/281/71
f 95/288/72 92/287/72 91/286/72 96/285/72
o left_leg
v -0.15625 0.1875 0.375
v -0.15625 0.1875 0.125
v -0.15625 0 0.375
v -0.15625 0 0.125
v -0.34375 0.1875 0.125
v -0.34375 0.1875 0.375
v -0.34375 0 0.125
v -0.34375 0 0.375
vt 0.375 0.39583333333333337
vt 0.4375 0.39583333333333337
vt 0.4375 0.33333333333333337
vt 0.375 0.33333333333333337
vt 0.2916666666666667 0.39583333333333337
vt 0.375 0.39583333333333337
vt 0.375 0.33333333333333337
vt 0.2916666666666667 0.33333333333333337
vt 0.5208333333333334 0.39583333333333337
vt 0.5833333333333334 0.39583333333333337
vt 0.5833333333333334 0.33333333333333337
vt 0.5208333333333334 0.33333333333333337
vt 0.4375 0.39583333333333337
vt 0.5208333333333334 0.39583333333333337
vt 0.5208333333333334 0.33333333333333337
vt 0.4375 0.33333333333333337
vt 0.4375 0.39583333333333337
vt 0.375 0.39583333333333337
vt 0.375 0.47916666666666663
vt 0.4375 0.47916666666666663
vt 0.5 0.47916666666666663
vt 0.4375 0.47916666666666663
vt 0.4375 0.39583333333333337
vt 0.5 0.39583333333333337
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_4288dcd0-d35c-c2c4-0fee-38c374635ee1
f 100/292/73 103/291/73 101/290/73 98/289/73
f 99/296/74 100/295/74 98/294/74 97/293/74
f 104/300/75 99/299/75 97/298/75 102/297/75
f 103/304/76 104/303/76 102/302/76 101/301/76
f 102/308/77 97/307/77 98/306/77 101/305/77
f 103/312/78 100/311/78 99/310/78 104/309/78
o left_leg
v -0.09375 -0.0006249999999999867 0.5
v -0.09375 -0.0006249999999999867 0
v -0.09375 -0.0006249999999999867 0.5
v -0.09375 -0.0006249999999999867 0
v -0.59375 -0.0006249999999999867 0
v -0.59375 -0.0006249999999999867 0.5
v -0.59375 -0.0006249999999999867 0
v -0.59375 -0.0006249999999999867 0.5
vt 0.20833333333333334 0.16666666666666663
vt 0.375 0.16666666666666663
vt 0.375 0.16666666666666663
vt 0.20833333333333334 0.16666666666666663
vt 0.041666666666666664 0.16666666666666663
vt 0.20833333333333334 0.16666666666666663
vt 0.20833333333333334 0.16666666666666663
vt 0.041666666666666664 0.16666666666666663
vt 0.5416666666666666 0.16666666666666663
vt 0.7083333333333334 0.16666666666666663
vt 0.7083333333333334 0.16666666666666663
vt 0.5416666666666666 0.16666666666666663
vt 0.375 0.16666666666666663
vt 0.5416666666666666 0.16666666666666663
vt 0.5416666666666666 0.16666666666666663
vt 0.375 0.16666666666666663
vt 0.375 0.16666666666666663
vt 0.20833333333333334 0.16666666666666663
vt 0.20833333333333334 0.33333333333333337
vt 0.375 0.33333333333333337
vt 0.5416666666666666 0.33333333333333337
vt 0.375 0.33333333333333337
vt 0.375 0.16666666666666663
vt 0.5416666666666666 0.16666666666666663
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_4288dcd0-d35c-c2c4-0fee-38c374635ee1
f 108/316/79 111/315/79 109/314/79 106/313/79
f 107/320/80 108/319/80 106/318/80 105/317/80
f 112/324/81 107/323/81 105/322/81 110/321/81
f 111/328/82 112/327/82 110/326/82 109/325/82
f 110/332/83 105/331/83 106/330/83 109/329/83
f 111/336/84 108/335/84 107/334/84 112/333/84
o right_leg
v 0.34375 0.1875 0.375
v 0.34375 0.1875 0.125
v 0.34375 0 0.375
v 0.34375 0 0.125
v 0.15625 0.1875 0.125
v 0.15625 0.1875 0.375
v 0.15625 0 0.125
v 0.15625 0 0.375
vt 0.08333333333333333 0.39583333333333337
vt 0.14583333333333334 0.39583333333333337
vt 0.14583333333333334 0.33333333333333337
vt 0.08333333333333333 0.33333333333333337
vt 0 0.39583333333333337
vt 0.08333333333333333 0.39583333333333337
vt 0.08333333333333333 0.33333333333333337
vt 0 0.33333333333333337
vt 0.22916666666666666 0.39583333333333337
vt 0.2916666666666667 0.39583333333333337
vt 0.2916666666666667 0.33333333333333337
vt 0.22916666666666666 0.33333333333333337
vt 0.14583333333333334 0.39583333333333337
vt 0.22916666666666666 0.39583333333333337
vt 0.22916666666666666 0.33333333333333337
vt 0.14583333333333334 0.33333333333333337
vt 0.14583333333333334 0.39583333333333337
vt 0.08333333333333333 0.39583333333333337
vt 0.08333333333333333 0.47916666666666663
vt 0.14583333333333334 0.47916666666666663
vt 0.20833333333333334 0.47916666666666663
vt 0.14583333333333334 0.47916666666666663
vt 0.14583333333333334 0.39583333333333337
vt 0.20833333333333334 0.39583333333333337
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_4288dcd0-d35c-c2c4-0fee-38c374635ee1
f 116/340/85 119/339/85 117/338/85 114/337/85
f 115/344/86 116/343/86 114/342/86 113/341/86
f 120/348/87 115/347/87 113/346/87 118/345/87
f 119/352/88 120/351/88 118/350/88 117/349/88
f 118/356/89 113/355/89 114/354/89 117/353/89
f 119/360/90 116/359/90 115/358/90 120/357/90
o right_leg
v 0.59375 -0.0006249999999999867 0.5
v 0.59375 -0.0006249999999999867 0
v 0.59375 -0.0006249999999999867 0.5
v 0.59375 -0.0006249999999999867 0
v 0.09375 -0.0006249999999999867 0
v 0.09375 -0.0006249999999999867 0.5
v 0.09375 -0.0006249999999999867 0
v 0.09375 -0.0006249999999999867 0.5
vt 0.5416666666666666 0.16666666666666663
vt 0.7083333333333334 0.16666666666666663
vt 0.7083333333333334 0.16666666666666663
vt 0.5416666666666666 0.16666666666666663
vt 0.375 0.16666666666666663
vt 0.5416666666666666 0.16666666666666663
vt 0.5416666666666666 0.16666666666666663
vt 0.375 0.16666666666666663
vt 0.875 0.16666666666666663
vt 1.0416666666666667 0.16666666666666663
vt 1.0416666666666667 0.16666666666666663
vt 0.875 0.16666666666666663
vt 0.7083333333333334 0.16666666666666663
vt 0.875 0.16666666666666663
vt 0.875 0.16666666666666663
vt 0.7083333333333334 0.16666666666666663
vt 0.7083333333333334 0.16666666666666663
vt 0.5416666666666666 0.16666666666666663
vt 0.5416666666666666 0.33333333333333337
vt 0.7083333333333334 0.33333333333333337
vt 0.875 0.33333333333333337
vt 0.7083333333333334 0.33333333333333337
vt 0.7083333333333334 0.16666666666666663
vt 0.875 0.16666666666666663
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_4288dcd0-d35c-c2c4-0fee-38c374635ee1
f 124/364/91 127/363/91 125/362/91 122/361/91
f 123/368/92 124/367/92 122/366/92 121/365/92
f 128/372/93 123/371/93 121/370/93 126/369/93
f 127/376/94 128/375/94 126/374/94 125/373/94
f 126/380/95 121/379/95 122/378/95 125/377/95
f 127/384/96 124/383/96 123/382/96 128/381/96