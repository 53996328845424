# Made in Blockbench 4.9.4
mtllib materials.mtl

o Body
v 0.25 1.5 0.1875
v 0.25 1.5 -0.1875
v 0.25 0.75 0.1875
v 0.25 0.75 -0.1875
v -0.25 1.5 -0.1875
v -0.25 1.5 0.1875
v -0.25 0.75 -0.1875
v -0.25 0.75 0.1875
vt 0.34375 0.59375
vt 0.46875 0.59375
vt 0.46875 0.40625
vt 0.34375 0.40625
vt 0.25 0.59375
vt 0.34375 0.59375
vt 0.34375 0.40625
vt 0.25 0.40625
vt 0.5625 0.59375
vt 0.6875 0.59375
vt 0.6875 0.40625
vt 0.5625 0.40625
vt 0.46875 0.59375
vt 0.5625 0.59375
vt 0.5625 0.40625
vt 0.46875 0.40625
vt 0.46875 0.59375
vt 0.34375 0.59375
vt 0.34375 0.6875
vt 0.46875 0.6875
vt 0.59375 0.6875
vt 0.46875 0.6875
vt 0.46875 0.59375
vt 0.59375 0.59375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_0e7f3cc8-7af8-21a7-d2f3-04bee5fb4aae
f 4/4/1 7/3/1 5/2/1 2/1/1
f 3/8/2 4/7/2 2/6/2 1/5/2
f 8/12/3 3/11/3 1/10/3 6/9/3
f 7/16/4 8/15/4 6/14/4 5/13/4
f 6/20/5 1/19/5 2/18/5 5/17/5
f 7/24/6 4/23/6 3/22/6 8/21/6
o Body
v 0.28125 1.53125 0.21875
v 0.28125 1.53125 -0.21875
v 0.28125 0.34375 0.21875
v 0.28125 0.34375 -0.21875
v -0.28125 1.53125 -0.21875
v -0.28125 1.53125 0.21875
v -0.28125 0.34375 -0.21875
v -0.28125 0.34375 0.21875
vt 0.09375 0.3125
vt 0.21875 0.3125
vt 0.21875 0.03125
vt 0.09375 0.03125
vt 0 0.3125
vt 0.09375 0.3125
vt 0.09375 0.03125
vt 0 0.03125
vt 0.3125 0.3125
vt 0.4375 0.3125
vt 0.4375 0.03125
vt 0.3125 0.03125
vt 0.21875 0.3125
vt 0.3125 0.3125
vt 0.3125 0.03125
vt 0.21875 0.03125
vt 0.21875 0.3125
vt 0.09375 0.3125
vt 0.09375 0.40625
vt 0.21875 0.40625
vt 0.34375 0.40625
vt 0.21875 0.40625
vt 0.21875 0.3125
vt 0.34375 0.3125
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_0e7f3cc8-7af8-21a7-d2f3-04bee5fb4aae
f 12/28/7 15/27/7 13/26/7 10/25/7
f 11/32/8 12/31/8 10/30/8 9/29/8
f 16/36/9 11/35/9 9/34/9 14/33/9
f 15/40/10 16/39/10 14/38/10 13/37/10
f 14/44/11 9/43/11 10/42/11 13/41/11
f 15/48/12 12/47/12 11/46/12 16/45/12
o head
v 0.25 2.125 0.25
v 0.25 2.125 -0.25
v 0.25 1.5 0.25
v 0.25 1.5 -0.25
v -0.25 2.125 -0.25
v -0.25 2.125 0.25
v -0.25 1.5 -0.25
v -0.25 1.5 0.25
vt 0.125 0.875
vt 0.25 0.875
vt 0.25 0.71875
vt 0.125 0.71875
vt 0 0.875
vt 0.125 0.875
vt 0.125 0.71875
vt 0 0.71875
vt 0.375 0.875
vt 0.5 0.875
vt 0.5 0.71875
vt 0.375 0.71875
vt 0.25 0.875
vt 0.375 0.875
vt 0.375 0.71875
vt 0.25 0.71875
vt 0.25 0.875
vt 0.125 0.875
vt 0.125 1
vt 0.25 1
vt 0.375 1
vt 0.25 1
vt 0.25 0.875
vt 0.375 0.875
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_0e7f3cc8-7af8-21a7-d2f3-04bee5fb4aae
f 20/52/13 23/51/13 21/50/13 18/49/13
f 19/56/14 20/55/14 18/54/14 17/53/14
f 24/60/15 19/59/15 17/58/15 22/57/15
f 23/64/16 24/63/16 22/62/16 21/61/16
f 22/68/17 17/67/17 18/66/17 21/65/17
f 23/72/18 20/71/18 19/70/18 24/69/18
o nose
v 0.0625 1.6875 -0.25
v 0.0625 1.6875 -0.375
v 0.0625 1.4375 -0.25
v 0.0625 1.4375 -0.375
v -0.0625 1.6875 -0.375
v -0.0625 1.6875 -0.25
v -0.0625 1.4375 -0.375
v -0.0625 1.4375 -0.25
vt 0.40625 0.96875
vt 0.4375 0.96875
vt 0.4375 0.90625
vt 0.40625 0.90625
vt 0.375 0.96875
vt 0.40625 0.96875
vt 0.40625 0.90625
vt 0.375 0.90625
vt 0.46875 0.96875
vt 0.5 0.96875
vt 0.5 0.90625
vt 0.46875 0.90625
vt 0.4375 0.96875
vt 0.46875 0.96875
vt 0.46875 0.90625
vt 0.4375 0.90625
vt 0.4375 0.96875
vt 0.40625 0.96875
vt 0.40625 1
vt 0.4375 1
vt 0.46875 1
vt 0.4375 1
vt 0.4375 0.96875
vt 0.46875 0.96875
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_0e7f3cc8-7af8-21a7-d2f3-04bee5fb4aae
f 28/76/19 31/75/19 29/74/19 26/73/19
f 27/80/20 28/79/20 26/78/20 25/77/20
f 32/84/21 27/83/21 25/82/21 30/81/21
f 31/88/22 32/87/22 30/86/22 29/85/22
f 30/92/23 25/91/23 26/90/23 29/89/23
f 31/96/24 28/95/24 27/94/24 32/93/24
o LeftLeg
v 0 0.75 0.125
v 0 0.75 -0.125
v 0 0 0.125
v 0 0 -0.125
v -0.25 0.75 -0.125
v -0.25 0.75 0.125
v -0.25 0 -0.125
v -0.25 0 0.125
vt 0.0625 0.59375
vt 0.125 0.59375
vt 0.125 0.40625
vt 0.0625 0.40625
vt 0 0.59375
vt 0.0625 0.59375
vt 0.0625 0.40625
vt 0 0.40625
vt 0.1875 0.59375
vt 0.25 0.59375
vt 0.25 0.40625
vt 0.1875 0.40625
vt 0.125 0.59375
vt 0.1875 0.59375
vt 0.1875 0.40625
vt 0.125 0.40625
vt 0.125 0.59375
vt 0.0625 0.59375
vt 0.0625 0.65625
vt 0.125 0.65625
vt 0.1875 0.65625
vt 0.125 0.65625
vt 0.125 0.59375
vt 0.1875 0.59375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_0e7f3cc8-7af8-21a7-d2f3-04bee5fb4aae
f 36/100/25 39/99/25 37/98/25 34/97/25
f 35/104/26 36/103/26 34/102/26 33/101/26
f 40/108/27 35/107/27 33/106/27 38/105/27
f 39/112/28 40/111/28 38/110/28 37/109/28
f 38/116/29 33/115/29 34/114/29 37/113/29
f 39/120/30 36/119/30 35/118/30 40/117/30
o RightLeg
v 0.25 0.75 0.125
v 0.25 0.75 -0.125
v 0.25 0 0.125
v 0.25 0 -0.125
v 0 0.75 -0.125
v 0 0.75 0.125
v 0 0 -0.125
v 0 0 0.125
vt 0.125 0.59375
vt 0.0625 0.59375
vt 0.0625 0.40625
vt 0.125 0.40625
vt 0.1875 0.59375
vt 0.125 0.59375
vt 0.125 0.40625
vt 0.1875 0.40625
vt 0.25 0.59375
vt 0.1875 0.59375
vt 0.1875 0.40625
vt 0.25 0.40625
vt 0.0625 0.59375
vt 0 0.59375
vt 0 0.40625
vt 0.0625 0.40625
vt 0.0625 0.59375
vt 0.125 0.59375
vt 0.125 0.65625
vt 0.0625 0.65625
vt 0.125 0.65625
vt 0.1875 0.65625
vt 0.1875 0.59375
vt 0.125 0.59375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_0e7f3cc8-7af8-21a7-d2f3-04bee5fb4aae
f 44/124/31 47/123/31 45/122/31 42/121/31
f 43/128/32 44/127/32 42/126/32 41/125/32
f 48/132/33 43/131/33 41/130/33 46/129/33
f 47/136/34 48/135/34 46/134/34 45/133/34
f 46/140/35 41/139/35 42/138/35 45/137/35
f 47/144/36 44/143/36 43/142/36 48/141/36
o RightArm
v 0.5 1.5 0.125
v 0.5 1.5 -0.125
v 0.5 0.75 0.125
v 0.5 0.75 -0.125
v 0.25 1.5 -0.125
v 0.25 1.5 0.125
v 0.25 0.75 -0.125
v 0.25 0.75 0.125
vt 0.6875 0.21875
vt 0.75 0.21875
vt 0.75 0.03125
vt 0.6875 0.03125
vt 0.625 0.21875
vt 0.6875 0.21875
vt 0.6875 0.03125
vt 0.625 0.03125
vt 0.8125 0.21875
vt 0.875 0.21875
vt 0.875 0.03125
vt 0.8125 0.03125
vt 0.75 0.21875
vt 0.8125 0.21875
vt 0.8125 0.03125
vt 0.75 0.03125
vt 0.75 0.21875
vt 0.6875 0.21875
vt 0.6875 0.28125
vt 0.75 0.28125
vt 0.8125 0.28125
vt 0.75 0.28125
vt 0.75 0.21875
vt 0.8125 0.21875
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_0e7f3cc8-7af8-21a7-d2f3-04bee5fb4aae
f 52/148/37 55/147/37 53/146/37 50/145/37
f 51/152/38 52/151/38 50/150/38 49/149/38
f 56/156/39 51/155/39 49/154/39 54/153/39
f 55/160/40 56/159/40 54/158/40 53/157/40
f 54/164/41 49/163/41 50/162/41 53/161/41
f 55/168/42 52/167/42 51/166/42 56/165/42
o LeftArm
v -0.25 1.5 0.125
v -0.25 1.5 -0.125
v -0.25 0.75 0.125
v -0.25 0.75 -0.125
v -0.5 1.5 -0.125
v -0.5 1.5 0.125
v -0.5 0.75 -0.125
v -0.5 0.75 0.125
vt 0.75 0.21875
vt 0.6875 0.21875
vt 0.6875 0.03125
vt 0.75 0.03125
vt 0.8125 0.21875
vt 0.75 0.21875
vt 0.75 0.03125
vt 0.8125 0.03125
vt 0.875 0.21875
vt 0.8125 0.21875
vt 0.8125 0.03125
vt 0.875 0.03125
vt 0.6875 0.21875
vt 0.625 0.21875
vt 0.625 0.03125
vt 0.6875 0.03125
vt 0.6875 0.21875
vt 0.75 0.21875
vt 0.75 0.28125
vt 0.6875 0.28125
vt 0.75 0.28125
vt 0.8125 0.28125
vt 0.8125 0.21875
vt 0.75 0.21875
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_0e7f3cc8-7af8-21a7-d2f3-04bee5fb4aae
f 60/172/43 63/171/43 61/170/43 58/169/43
f 59/176/44 60/175/44 58/174/44 57/173/44
f 64/180/45 59/179/45 57/178/45 62/177/45
f 63/184/46 64/183/46 62/182/46 61/181/46
f 62/188/47 57/187/47 58/186/47 61/185/47
f 63/192/48 60/191/48 59/190/48 64/189/48