# Made in Blockbench 4.9.4
mtllib materials.mtl

o section_2
v 0.09375 0.1875 0.21875
v 0.09375 0.1875 0.15625
v 0.09375 0 0.21875
v 0.09375 0 0.15625
v -0.09375 0.1875 0.15625
v -0.09375 0.1875 0.21875
v -0.09375 0 0.15625
v -0.09375 0 0.21875
vt 0.015625 0.53125
vt 0.0625 0.53125
vt 0.0625 0.4375
vt 0.015625 0.4375
vt 0 0.53125
vt 0.015625 0.53125
vt 0.015625 0.4375
vt 0 0.4375
vt 0.078125 0.53125
vt 0.125 0.53125
vt 0.125 0.4375
vt 0.078125 0.4375
vt 0.0625 0.53125
vt 0.078125 0.53125
vt 0.078125 0.4375
vt 0.0625 0.4375
vt 0.0625 0.53125
vt 0.015625 0.53125
vt 0.015625 0.5625
vt 0.0625 0.5625
vt 0.109375 0.5625
vt 0.0625 0.5625
vt 0.0625 0.53125
vt 0.109375 0.53125
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_50f00cc5-283b-b301-14d6-d02da334960b
f 4/4/1 7/3/1 5/2/1 2/1/1
f 3/8/2 4/7/2 2/6/2 1/5/2
f 8/12/3 3/11/3 1/10/3 6/9/3
f 7/16/4 8/15/4 6/14/4 5/13/4
f 6/20/5 1/19/5 2/18/5 5/17/5
f 7/24/6 4/23/6 3/22/6 8/21/6
o section_0
v 0.125 0.1875 -0.15000000000000002
v 0.125 0.1875 -0.275
v 0.125 0 -0.15000000000000002
v 0.125 0 -0.275
v -0.125 0.1875 -0.275
v -0.125 0.1875 -0.15000000000000002
v -0.125 0 -0.275
v -0.125 0 -0.15000000000000002
vt 0.03125 0.9375
vt 0.09375 0.9375
vt 0.09375 0.84375
vt 0.03125 0.84375
vt 0 0.9375
vt 0.03125 0.9375
vt 0.03125 0.84375
vt 0 0.84375
vt 0.125 0.9375
vt 0.1875 0.9375
vt 0.1875 0.84375
vt 0.125 0.84375
vt 0.09375 0.9375
vt 0.125 0.9375
vt 0.125 0.84375
vt 0.09375 0.84375
vt 0.09375 0.9375
vt 0.03125 0.9375
vt 0.03125 1
vt 0.09375 1
vt 0.15625 1
vt 0.09375 1
vt 0.09375 0.9375
vt 0.15625 0.9375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_50f00cc5-283b-b301-14d6-d02da334960b
f 12/28/7 15/27/7 13/26/7 10/25/7
f 11/32/8 12/31/8 10/30/8 9/29/8
f 16/36/9 11/35/9 9/34/9 14/33/9
f 15/40/10 16/39/10 14/38/10 13/37/10
f 14/44/11 9/43/11 10/42/11 13/41/11
f 15/48/12 12/47/12 11/46/12 16/45/12
o section_1
v 0.1875 0.25 0.16249999999999998
v 0.1875 0.25 -0.15000000000000002
v 0.1875 0 0.16249999999999998
v 0.1875 0 -0.15000000000000002
v -0.1875 0.25 -0.15000000000000002
v -0.1875 0.25 0.16249999999999998
v -0.1875 0 -0.15000000000000002
v -0.1875 0 0.16249999999999998
vt 0.078125 0.6875
vt 0.171875 0.6875
vt 0.171875 0.5625
vt 0.078125 0.5625
vt 0 0.6875
vt 0.078125 0.6875
vt 0.078125 0.5625
vt 0 0.5625
vt 0.25 0.6875
vt 0.34375 0.6875
vt 0.34375 0.5625
vt 0.25 0.5625
vt 0.171875 0.6875
vt 0.25 0.6875
vt 0.25 0.5625
vt 0.171875 0.5625
vt 0.171875 0.6875
vt 0.078125 0.6875
vt 0.078125 0.84375
vt 0.171875 0.84375
vt 0.265625 0.84375
vt 0.171875 0.84375
vt 0.171875 0.6875
vt 0.265625 0.6875
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_50f00cc5-283b-b301-14d6-d02da334960b
f 20/52/13 23/51/13 21/50/13 18/49/13
f 19/56/14 20/55/14 18/54/14 17/53/14
f 24/60/15 19/59/15 17/58/15 22/57/15
f 23/64/16 24/63/16 22/62/16 21/61/16
f 22/68/17 17/67/17 18/66/17 21/65/17
f 23/72/18 20/71/18 19/70/18 24/69/18
o section_3
v 0.03125 0.125 0.28125
v 0.03125 0.125 0.21875
v 0.03125 0 0.28125
v 0.03125 0 0.21875
v -0.03125 0.125 0.21875
v -0.03125 0.125 0.28125
v -0.03125 0 0.21875
v -0.03125 0 0.28125
vt 0.015625 0.40625
vt 0.03125 0.40625
vt 0.03125 0.34375
vt 0.015625 0.34375
vt 0 0.40625
vt 0.015625 0.40625
vt 0.015625 0.34375
vt 0 0.34375
vt 0.046875 0.40625
vt 0.0625 0.40625
vt 0.0625 0.34375
vt 0.046875 0.34375
vt 0.03125 0.40625
vt 0.046875 0.40625
vt 0.046875 0.34375
vt 0.03125 0.34375
vt 0.03125 0.40625
vt 0.015625 0.40625
vt 0.015625 0.4375
vt 0.03125 0.4375
vt 0.046875 0.4375
vt 0.03125 0.4375
vt 0.03125 0.40625
vt 0.046875 0.40625
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_50f00cc5-283b-b301-14d6-d02da334960b
f 28/76/19 31/75/19 29/74/19 26/73/19
f 27/80/20 28/79/20 26/78/20 25/77/20
f 32/84/21 27/83/21 25/82/21 30/81/21
f 31/88/22 32/87/22 30/86/22 29/85/22
f 30/92/23 25/91/23 26/90/23 29/89/23
f 31/96/24 28/95/24 27/94/24 32/93/24