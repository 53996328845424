# Made in Blockbench 4.9.4
mtllib materials.mtl

o base
v 0.5 0.5 0.5
v 0.5 0.5 -0.5
v 0.5 0 0.5
v 0.5 0 -0.5
v -0.5 0.5 -0.5
v -0.5 0.5 0.5
v -0.5 0 -0.5
v -0.5 0 0.5
vt 0.25 0.3125
vt 0.5 0.3125
vt 0.5 0.1875
vt 0.25 0.1875
vt 0 0.3125
vt 0.25 0.3125
vt 0.25 0.1875
vt 0 0.1875
vt 0.75 0.3125
vt 1 0.3125
vt 1 0.1875
vt 0.75 0.1875
vt 0.5 0.3125
vt 0.75 0.3125
vt 0.75 0.1875
vt 0.5 0.1875
vt 0.5 0.3125
vt 0.25 0.3125
vt 0.25 0.5625
vt 0.5 0.5625
vt 0.75 0.5625
vt 0.5 0.5625
vt 0.5 0.3125
vt 0.75 0.3125
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_1c25eeea-87e5-6114-747e-b8cdb1a56a09
f 4/4/1 7/3/1 5/2/1 2/1/1
f 3/8/2 4/7/2 2/6/2 1/5/2
f 8/12/3 3/11/3 1/10/3 6/9/3
f 7/16/4 8/15/4 6/14/4 5/13/4
f 6/20/5 1/19/5 2/18/5 5/17/5
f 7/24/6 4/23/6 3/22/6 8/21/6
o lid
v 0.5 1.5625 0.5
v 0.5 1.5625 -0.5
v 0.5 0.8125 0.5
v 0.5 0.8125 -0.5
v -0.5 1.5625 -0.5
v -0.5 1.5625 0.5
v -0.5 0.8125 -0.5
v -0.5 0.8125 0.5
vt 0.25 0.75
vt 0.5 0.75
vt 0.5 0.5625
vt 0.25 0.5625
vt 0 0.75
vt 0.25 0.75
vt 0.25 0.5625
vt 0 0.5625
vt 0.75 0.75
vt 1 0.75
vt 1 0.5625
vt 0.75 0.5625
vt 0.5 0.75
vt 0.75 0.75
vt 0.75 0.5625
vt 0.5 0.5625
vt 0.5 0.75
vt 0.25 0.75
vt 0.25 1
vt 0.5 1
vt 0.75 1
vt 0.5 1
vt 0.5 0.75
vt 0.75 0.75
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_1c25eeea-87e5-6114-747e-b8cdb1a56a09
f 12/28/7 15/27/7 13/26/7 10/25/7
f 11/32/8 12/31/8 10/30/8 9/29/8
f 16/36/9 11/35/9 9/34/9 14/33/9
f 15/40/10 16/39/10 14/38/10 13/37/10
f 14/44/11 9/43/11 10/42/11 13/41/11
f 15/48/12 12/47/12 11/46/12 16/45/12
o head
v 0.1875 0.75 0.1875
v 0.1875 0.75 -0.1875
v 0.1875 0.375 0.1875
v 0.1875 0.375 -0.1875
v -0.1875 0.75 -0.1875
v -0.1875 0.75 0.1875
v -0.1875 0.375 -0.1875
v -0.1875 0.375 0.1875
vt 0.09375 0.09375
vt 0.1875 0.09375
vt 0.1875 0
vt 0.09375 0
vt 0 0.09375
vt 0.09375 0.09375
vt 0.09375 0
vt 0 0
vt 0.28125 0.09375
vt 0.375 0.09375
vt 0.375 0
vt 0.28125 0
vt 0.1875 0.09375
vt 0.28125 0.09375
vt 0.28125 0
vt 0.1875 0
vt 0.1875 0.09375
vt 0.09375 0.09375
vt 0.09375 0.1875
vt 0.1875 0.1875
vt 0.28125 0.1875
vt 0.1875 0.1875
vt 0.1875 0.09375
vt 0.28125 0.09375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_1c25eeea-87e5-6114-747e-b8cdb1a56a09
f 20/52/13 23/51/13 21/50/13 18/49/13
f 19/56/14 20/55/14 18/54/14 17/53/14
f 24/60/15 19/59/15 17/58/15 22/57/15
f 23/64/16 24/63/16 22/62/16 21/61/16
f 22/68/17 17/67/17 18/66/17 21/65/17
f 23/72/18 20/71/18 19/70/18 24/69/18