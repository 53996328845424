{
  "armor_stand": {
    "identifier": "minecraft:armor_stand",
    "min_engine_version": "1.8.0",
    "materials": {"default": "armor_stand"},
    "textures": {"default": "textures/entity/armorstand/wood"},
    "animations": {
      "default_pose": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["-this", "-this", "-this"]},
          "head": {"rotation": ["-this", "-this", "-this"]},
          "leftarm": {"rotation": ["-this - 10", "-this", "-this - 10"]},
          "leftleg": {"rotation": ["-this - 1", "-this", "-this - 1"]},
          "rightarm": {"rotation": ["-this - 15", "-this", "-this + 10"]},
          "rightitem": {"rotation": ["-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-this + 1", "-this", "-this + 1"]}
        }
      },
      "no_pose": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["-this", "-this", "-this"]},
          "head": {"rotation": ["-this", "-this", "-this"]},
          "leftarm": {"rotation": ["-this", "-this", "-this"]},
          "leftleg": {"rotation": ["-this", "-this", "-this"]},
          "rightarm": {"rotation": ["-this", "-this", "-this"]},
          "rightitem": {"rotation": ["-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-this", "-this", "-this"]}
        }
      },
      "solemn_pose": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["-this", "-this", "-this + 2"]},
          "head": {"rotation": ["-this + 15", "-this", "-this"]},
          "leftarm": {"rotation": ["-this - 30", "-this + 15", "-this + 15"]},
          "leftleg": {"rotation": ["-this - 1", "-this", "-this - 1"]},
          "rightarm": {"rotation": ["-this - 60", "-this - 20", "-this - 10"]},
          "rightitem": {"rotation": ["-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-this + 1", "-this", "-this + 1"]}
        }
      },
      "athena_pose": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["-this", "-this", "-this + 2"]},
          "head": {"rotation": ["-this - 5", "-this", "-this"]},
          "leftarm": {"rotation": ["-this + 10", "-this", "-this - 5"]},
          "leftleg": {"rotation": ["-this - 3", "-this - 3", "-this - 3"]},
          "rightarm": {"rotation": ["-this - 60", "-this + 20", "-this - 10"]},
          "rightitem": {"rotation": ["-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-this + 3", "-this + 3", "-this + 3"]}
        }
      },
      "brandish_pose": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["-this", "-this", "-this - 2"]},
          "head": {"rotation": ["-this - 15", "-this", "-this"]},
          "leftarm": {"rotation": ["-this + 20", "-this", "-this - 10"]},
          "leftleg": {"rotation": ["-this + 5", "-this - 3", "-this - 3"]},
          "rightarm": {"rotation": ["-this - 110", "-this + 50", "-this"]},
          "rightitem": {"rotation": ["-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-this - 5", "-this + 3", "-this + 3"]}
        }
      },
      "honor_pose": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["-this", "-this", "-this"]},
          "head": {"rotation": ["-this - 15", "-this", "-this"]},
          "leftarm": {"rotation": ["-this - 110", "-this + 35", "-this"]},
          "leftleg": {"rotation": ["-this + 5", "-this - 3", "-this - 3"]},
          "rightarm": {"rotation": ["-this - 110", "-this - 35", "-this"]},
          "rightitem": {"rotation": ["-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-this - 5", "-this + 3", "-this + 3"]}
        }
      },
      "entertain_pose": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["-this", "-this", "-this"]},
          "head": {"rotation": ["-this - 15", "-this", "-this"]},
          "leftarm": {"rotation": ["-this - 110", "-this - 35", "-this"]},
          "leftleg": {"rotation": ["-this + 5", "-this - 3", "-this - 3"]},
          "rightarm": {"rotation": ["-this - 110", "-this + 35", "-this"]},
          "rightitem": {"rotation": ["-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-this - 5", "-this + 3", "-this + 3"]}
        }
      },
      "salute_pose": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["-this", "-this", "-this"]},
          "head": {"rotation": ["-this", "-this", "-this"]},
          "leftarm": {"rotation": ["-this + 10", "-this", "-this - 5"]},
          "leftleg": {"rotation": ["-this - 1", "-this", "-this - 1"]},
          "rightarm": {"rotation": ["-this - 70", "-this - 40", "-this"]},
          "rightitem": {"rotation": ["-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-this + 1", "-this", "-this + 1"]}
        }
      },
      "riposte_pose": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["-this", "-this", "-this"]},
          "head": {"rotation": ["-this + 16", "-this + 20", "-this"]},
          "leftarm": {"rotation": ["-this + 4", "-this + 8", "-this + 237"]},
          "leftleg": {"rotation": ["-this - 14", "-this - 18", "-this - 16"]},
          "rightarm": {"rotation": ["-this + 246", "-this", "-this + 89"]},
          "rightitem": {"rotation": ["-this", "-this + 180", "-this"]},
          "rightleg": {"rotation": ["-this + 8", "-this + 20", "-this + 4"]}
        }
      },
      "zombie_pose": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["-this", "-this", "-this"]},
          "head": {"rotation": ["-this - 10", "-this", "-this - 5"]},
          "leftarm": {"rotation": ["-this - 105", "-this", "-this"]},
          "leftleg": {"rotation": ["-this + 7", "-this", "-this"]},
          "rightarm": {"rotation": ["-this - 100", "-this", "-this"]},
          "rightitem": {"rotation": ["-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-this - 46", "-this", "-this"]}
        }
      },
      "cancan_a_pose": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["-this", "-this + 22", "-this"]},
          "head": {"rotation": ["-this - 5", "-this + 18", "-this"]},
          "leftarm": {"rotation": ["-this + 8", "-this", "-this - 114"]},
          "leftleg": {"rotation": ["-this - 111", "-this + 55", "-this"]},
          "rightarm": {"rotation": ["-this", "-this + 84", "-this + 111"]},
          "rightitem": {"rotation": ["-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-this", "-this + 23", "-this - 13"]}
        }
      },
      "cancan_b_pose": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["-this", "-this - 18", "-this"]},
          "head": {"rotation": ["-this - 10", "-this - 20", "-this"]},
          "leftarm": {"rotation": ["-this", "-this", "-this - 112"]},
          "leftleg": {"rotation": ["-this", "-this", "-this + 13"]},
          "rightarm": {"rotation": ["-this + 8", "-this + 90", "-this + 111"]},
          "rightitem": {"rotation": ["-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-this - 119", "-this - 42", "-this"]}
        }
      },
      "hero_pose": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["-this", "-this + 8", "-this"]},
          "head": {"rotation": ["-this - 4", "-this + 67", "-this"]},
          "leftarm": {"rotation": ["-this + 16", "-this + 32", "-this - 8"]},
          "leftleg": {"rotation": ["-this", "-this - 75", "-this - 8"]},
          "rightarm": {"rotation": ["-this - 99", "-this + 63", "-this"]},
          "rightitem": {"rotation": ["-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-this + 4", "-this + 63", "-this + 8"]}
        }
      },
      "wiggle": {
        "loop": true,
        "bones": {
          "baseplate": {
            "rotation": [
              0,
              "math.sin((variable.armor_stand.hurt_time - query.frame_alpha) * 120) * 3",
              0
            ]
          }
        }
      }
    },
    "scripts": {
      "initialize": [
        "variable.armor_stand.pose_index = 0;",
        "variable.armor_stand.hurt_time = 0;"
      ],
      "animate": ["controller.pose", "controller.wiggling"]
    },
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "baseplate",
            "cubes": [
              {"origin": [-6, 0, -6], "size": [12, 1, 12], "uv": [0, 32]}
            ]
          },
          {"name": "waist", "parent": "baseplate", "pivot": [0, 12, 0]},
          {
            "name": "body",
            "parent": "waist",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-6, 21, -1.5], "size": [12, 3, 3], "uv": [0, 26]},
              {"origin": [-3, 14, -1], "size": [2, 7, 2], "uv": [16, 0]},
              {"origin": [1, 14, -1], "size": [2, 7, 2], "uv": [48, 16]},
              {"origin": [-4, 12, -1], "size": [8, 2, 2], "uv": [0, 48]}
            ]
          },
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-1, 24, -1], "size": [2, 7, 2], "uv": [0, 0]}]
          },
          {
            "name": "hat",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 24, -4], "size": [8, 8, 8], "uv": [32, 0]}
            ]
          },
          {
            "name": "leftarm",
            "parent": "body",
            "mirror": true,
            "pivot": [5, 22, 0],
            "cubes": [
              {"origin": [5, 12, -1], "size": [2, 12, 2], "uv": [32, 16]}
            ]
          },
          {"name": "leftitem", "parent": "leftarm", "pivot": [6, 15, 1]},
          {
            "name": "leftleg",
            "parent": "body",
            "mirror": true,
            "pivot": [1.9, 12, 0],
            "cubes": [
              {"origin": [0.9, 1, -1], "size": [2, 11, 2], "uv": [40, 16]}
            ]
          },
          {
            "name": "rightarm",
            "parent": "body",
            "pivot": [-5, 22, 0],
            "cubes": [
              {"origin": [-7, 12, -1], "size": [2, 12, 2], "uv": [24, 0]}
            ]
          },
          {"name": "rightitem", "parent": "rightarm", "pivot": [-6, 15, 1]},
          {
            "name": "rightleg",
            "parent": "body",
            "pivot": [-1.9, 12, 0],
            "cubes": [
              {"origin": [-2.9, 1, -1], "size": [2, 11, 2], "uv": [8, 0]}
            ]
          }
        ],
        "visible_bounds_width": 1.5,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 64
      }
    },
    "render_controllers": ["controller.render.armor_stand"],
    "enable_attachables": true,
    "animation_controllers": {
      "controller.pose": {
        "initial_state": "default",
        "states": {
          "athena": {
            "animations": ["athena_pose"],
            "blend_transition": 0.1,
            "blend_via_shortest_path": true,
            "transitions": [
              {"default": "variable.armor_stand.pose_index == 0"},
              {"none": "variable.armor_stand.pose_index == 1"},
              {"solemn": "variable.armor_stand.pose_index == 2"},
              {"brandish": "variable.armor_stand.pose_index == 4"},
              {"honor": "variable.armor_stand.pose_index == 5"},
              {"entertain": "variable.armor_stand.pose_index == 6"},
              {"salute": "variable.armor_stand.pose_index == 7"},
              {"riposte": "variable.armor_stand.pose_index == 8"},
              {"zombie": "variable.armor_stand.pose_index == 9"},
              {"cancan_a": "variable.armor_stand.pose_index == 10"},
              {"cancan_b": "variable.armor_stand.pose_index == 11"},
              {"hero": "variable.armor_stand.pose_index == 12"}
            ]
          },
          "brandish": {
            "animations": ["brandish_pose"],
            "blend_transition": 0.1,
            "blend_via_shortest_path": true,
            "transitions": [
              {"default": "variable.armor_stand.pose_index == 0"},
              {"none": "variable.armor_stand.pose_index == 1"},
              {"solemn": "variable.armor_stand.pose_index == 2"},
              {"athena": "variable.armor_stand.pose_index == 3"},
              {"honor": "variable.armor_stand.pose_index == 5"},
              {"entertain": "variable.armor_stand.pose_index == 6"},
              {"salute": "variable.armor_stand.pose_index == 7"},
              {"riposte": "variable.armor_stand.pose_index == 8"},
              {"zombie": "variable.armor_stand.pose_index == 9"},
              {"cancan_a": "variable.armor_stand.pose_index == 10"},
              {"cancan_b": "variable.armor_stand.pose_index == 11"},
              {"hero": "variable.armor_stand.pose_index == 12"}
            ]
          },
          "cancan_a": {
            "animations": ["cancan_a_pose"],
            "blend_transition": 0.1,
            "blend_via_shortest_path": true,
            "transitions": [
              {"default": "variable.armor_stand.pose_index == 0"},
              {"none": "variable.armor_stand.pose_index == 1"},
              {"solemn": "variable.armor_stand.pose_index == 2"},
              {"athena": "variable.armor_stand.pose_index == 3"},
              {"brandish": "variable.armor_stand.pose_index == 4"},
              {"honor": "variable.armor_stand.pose_index == 5"},
              {"entertain": "variable.armor_stand.pose_index == 6"},
              {"salute": "variable.armor_stand.pose_index == 7"},
              {"riposte": "variable.armor_stand.pose_index == 8"},
              {"zombie": "variable.armor_stand.pose_index == 9"},
              {"cancan_b": "variable.armor_stand.pose_index == 11"},
              {"hero": "variable.armor_stand.pose_index == 12"}
            ]
          },
          "cancan_b": {
            "animations": ["cancan_b_pose"],
            "blend_transition": 0.1,
            "blend_via_shortest_path": true,
            "transitions": [
              {"default": "variable.armor_stand.pose_index == 0"},
              {"none": "variable.armor_stand.pose_index == 1"},
              {"solemn": "variable.armor_stand.pose_index == 2"},
              {"athena": "variable.armor_stand.pose_index == 3"},
              {"brandish": "variable.armor_stand.pose_index == 4"},
              {"honor": "variable.armor_stand.pose_index == 5"},
              {"entertain": "variable.armor_stand.pose_index == 6"},
              {"salute": "variable.armor_stand.pose_index == 7"},
              {"riposte": "variable.armor_stand.pose_index == 8"},
              {"zombie": "variable.armor_stand.pose_index == 9"},
              {"cancan_a": "variable.armor_stand.pose_index == 10"},
              {"hero": "variable.armor_stand.pose_index == 12"}
            ]
          },
          "default": {
            "animations": ["default_pose"],
            "blend_transition": 0.1,
            "blend_via_shortest_path": true,
            "transitions": [
              {"none": "variable.armor_stand.pose_index == 1"},
              {"solemn": "variable.armor_stand.pose_index == 2"},
              {"athena": "variable.armor_stand.pose_index == 3"},
              {"brandish": "variable.armor_stand.pose_index == 4"},
              {"honor": "variable.armor_stand.pose_index == 5"},
              {"entertain": "variable.armor_stand.pose_index == 6"},
              {"salute": "variable.armor_stand.pose_index == 7"},
              {"riposte": "variable.armor_stand.pose_index == 8"},
              {"zombie": "variable.armor_stand.pose_index == 9"},
              {"cancan_a": "variable.armor_stand.pose_index == 10"},
              {"cancan_b": "variable.armor_stand.pose_index == 11"},
              {"hero": "variable.armor_stand.pose_index == 12"}
            ]
          },
          "entertain": {
            "animations": ["entertain_pose"],
            "blend_transition": 0.1,
            "blend_via_shortest_path": true,
            "transitions": [
              {"default": "variable.armor_stand.pose_index == 0"},
              {"none": "variable.armor_stand.pose_index == 1"},
              {"solemn": "variable.armor_stand.pose_index == 2"},
              {"athena": "variable.armor_stand.pose_index == 3"},
              {"brandish": "variable.armor_stand.pose_index == 4"},
              {"honor": "variable.armor_stand.pose_index == 5"},
              {"salute": "variable.armor_stand.pose_index == 7"},
              {"riposte": "variable.armor_stand.pose_index == 8"},
              {"zombie": "variable.armor_stand.pose_index == 9"},
              {"cancan_a": "variable.armor_stand.pose_index == 10"},
              {"cancan_b": "variable.armor_stand.pose_index == 11"},
              {"hero": "variable.armor_stand.pose_index == 12"}
            ]
          },
          "hero": {
            "animations": ["hero_pose"],
            "blend_transition": 0.1,
            "blend_via_shortest_path": true,
            "transitions": [
              {"default": "variable.armor_stand.pose_index == 0"},
              {"none": "variable.armor_stand.pose_index == 1"},
              {"solemn": "variable.armor_stand.pose_index == 2"},
              {"athena": "variable.armor_stand.pose_index == 3"},
              {"brandish": "variable.armor_stand.pose_index == 4"},
              {"honor": "variable.armor_stand.pose_index == 5"},
              {"entertain": "variable.armor_stand.pose_index == 6"},
              {"salute": "variable.armor_stand.pose_index == 7"},
              {"riposte": "variable.armor_stand.pose_index == 8"},
              {"zombie": "variable.armor_stand.pose_index == 9"},
              {"cancan_a": "variable.armor_stand.pose_index == 10"},
              {"cancan_b": "variable.armor_stand.pose_index == 11"}
            ]
          },
          "honor": {
            "animations": ["honor_pose"],
            "blend_transition": 0.1,
            "blend_via_shortest_path": true,
            "transitions": [
              {"default": "variable.armor_stand.pose_index == 0"},
              {"none": "variable.armor_stand.pose_index == 1"},
              {"solemn": "variable.armor_stand.pose_index == 2"},
              {"athena": "variable.armor_stand.pose_index == 3"},
              {"brandish": "variable.armor_stand.pose_index == 4"},
              {"entertain": "variable.armor_stand.pose_index == 6"},
              {"salute": "variable.armor_stand.pose_index == 7"},
              {"riposte": "variable.armor_stand.pose_index == 8"},
              {"zombie": "variable.armor_stand.pose_index == 9"},
              {"cancan_a": "variable.armor_stand.pose_index == 10"},
              {"cancan_b": "variable.armor_stand.pose_index == 11"},
              {"hero": "variable.armor_stand.pose_index == 12"}
            ]
          },
          "none": {
            "animations": ["no_pose"],
            "blend_transition": 0.1,
            "blend_via_shortest_path": true,
            "transitions": [
              {"default": "variable.armor_stand.pose_index == 0"},
              {"solemn": "variable.armor_stand.pose_index == 2"},
              {"athena": "variable.armor_stand.pose_index == 3"},
              {"brandish": "variable.armor_stand.pose_index == 4"},
              {"honor": "variable.armor_stand.pose_index == 5"},
              {"entertain": "variable.armor_stand.pose_index == 6"},
              {"salute": "variable.armor_stand.pose_index == 7"},
              {"riposte": "variable.armor_stand.pose_index == 8"},
              {"zombie": "variable.armor_stand.pose_index == 9"},
              {"cancan_a": "variable.armor_stand.pose_index == 10"},
              {"cancan_b": "variable.armor_stand.pose_index == 11"},
              {"hero": "variable.armor_stand.pose_index == 12"}
            ]
          },
          "riposte": {
            "animations": ["riposte_pose"],
            "blend_transition": 0.1,
            "blend_via_shortest_path": true,
            "transitions": [
              {"default": "variable.armor_stand.pose_index == 0"},
              {"none": "variable.armor_stand.pose_index == 1"},
              {"solemn": "variable.armor_stand.pose_index == 2"},
              {"athena": "variable.armor_stand.pose_index == 3"},
              {"brandish": "variable.armor_stand.pose_index == 4"},
              {"honor": "variable.armor_stand.pose_index == 5"},
              {"entertain": "variable.armor_stand.pose_index == 6"},
              {"salute": "variable.armor_stand.pose_index == 7"},
              {"zombie": "variable.armor_stand.pose_index == 9"},
              {"cancan_a": "variable.armor_stand.pose_index == 10"},
              {"cancan_b": "variable.armor_stand.pose_index == 11"},
              {"hero": "variable.armor_stand.pose_index == 12"}
            ]
          },
          "salute": {
            "animations": ["salute_pose"],
            "blend_transition": 0.1,
            "blend_via_shortest_path": true,
            "transitions": [
              {"default": "variable.armor_stand.pose_index == 0"},
              {"none": "variable.armor_stand.pose_index == 1"},
              {"solemn": "variable.armor_stand.pose_index == 2"},
              {"athena": "variable.armor_stand.pose_index == 3"},
              {"brandish": "variable.armor_stand.pose_index == 4"},
              {"honor": "variable.armor_stand.pose_index == 5"},
              {"entertain": "variable.armor_stand.pose_index == 6"},
              {"riposte": "variable.armor_stand.pose_index == 8"},
              {"zombie": "variable.armor_stand.pose_index == 9"},
              {"cancan_a": "variable.armor_stand.pose_index == 10"},
              {"cancan_b": "variable.armor_stand.pose_index == 11"},
              {"hero": "variable.armor_stand.pose_index == 12"}
            ]
          },
          "solemn": {
            "animations": ["solemn_pose"],
            "blend_transition": 0.1,
            "blend_via_shortest_path": true,
            "transitions": [
              {"default": "variable.armor_stand.pose_index == 0"},
              {"none": "variable.armor_stand.pose_index == 1"},
              {"athena": "variable.armor_stand.pose_index == 3"},
              {"brandish": "variable.armor_stand.pose_index == 4"},
              {"honor": "variable.armor_stand.pose_index == 5"},
              {"entertain": "variable.armor_stand.pose_index == 6"},
              {"salute": "variable.armor_stand.pose_index == 7"},
              {"riposte": "variable.armor_stand.pose_index == 8"},
              {"zombie": "variable.armor_stand.pose_index == 9"},
              {"cancan_a": "variable.armor_stand.pose_index == 10"},
              {"cancan_b": "variable.armor_stand.pose_index == 11"},
              {"hero": "variable.armor_stand.pose_index == 12"}
            ]
          },
          "zombie": {
            "animations": ["zombie_pose"],
            "blend_transition": 0.1,
            "blend_via_shortest_path": true,
            "transitions": [
              {"default": "variable.armor_stand.pose_index == 0"},
              {"none": "variable.armor_stand.pose_index == 1"},
              {"solemn": "variable.armor_stand.pose_index == 2"},
              {"athena": "variable.armor_stand.pose_index == 3"},
              {"brandish": "variable.armor_stand.pose_index == 4"},
              {"honor": "variable.armor_stand.pose_index == 5"},
              {"entertain": "variable.armor_stand.pose_index == 6"},
              {"salute": "variable.armor_stand.pose_index == 7"},
              {"riposte": "variable.armor_stand.pose_index == 8"},
              {"cancan_a": "variable.armor_stand.pose_index == 10"},
              {"cancan_b": "variable.armor_stand.pose_index == 11"},
              {"hero": "variable.armor_stand.pose_index == 12"}
            ]
          }
        }
      },
      "controller.wiggling": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [
              {
                "wiggling": "(variable.armor_stand.hurt_time - query.frame_alpha) > 0"
              }
            ]
          },
          "wiggling": {
            "animations": ["wiggle"],
            "transitions": [
              {
                "default": "(variable.armor_stand.hurt_time - query.frame_alpha) <= 0"
              }
            ]
          }
        }
      }
    }
  },
  "arrow": {
    "identifier": "minecraft:arrow",
    "materials": {"default": "arrow"},
    "textures": {"default": "textures/entity/arrow"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 1, 0],
            "cubes": [
              {
                "origin": [0, -2.5, -3],
                "rotation": [0, 0, 45],
                "size": [0, 5, 16],
                "uv": {"east": {"uv": [0, 0]}}
              },
              {
                "origin": [0, -2.5, -3],
                "rotation": [0, 0, -45],
                "size": [0, 5, 16],
                "uv": {"east": {"uv": [0, 0]}}
              },
              {
                "origin": [-2.5, -2.5, 12],
                "rotation": [0, 0, 45],
                "size": [5, 5, 0],
                "uv": {"south": {"uv": [0, 5]}}
              }
            ]
          }
        ],
        "texturewidth": 32,
        "textureheight": 32
      }
    },
    "animations": {
      "move": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              "variable.shake_power - query.target_x_rotation",
              "-query.target_y_rotation",
              0
            ],
            "scale": [0.7, 0.7, 0.9]
          }
        }
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.shake = query.shake_time - query.frame_alpha;",
        "variable.shake_power = variable.shake > 0.0 ? -Math.sin(variable.shake * 200.0) * variable.shake : 0.0;"
      ],
      "animate": ["move"]
    },
    "render_controllers": ["controller.render.arrow"]
  },
  "bat": {
    "identifier": "minecraft:bat",
    "materials": {"default": "bat"},
    "textures": {"default": "textures/entity/bat"},
    "geometry": {
      "default": {
        "visible_bounds_width": 1,
        "visible_bounds_height": 1,
        "visible_bounds_offset": [0, 0.5, 0],
        "bones": [
          {
            "name": "head",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-3, 21, -3], "size": [6, 6, 6], "uv": [0, 0]}]
          },
          {
            "name": "rightEar",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 26, -2], "size": [3, 4, 1], "uv": [24, 0]}
            ],
            "parent": "head"
          },
          {
            "name": "leftEar",
            "mirror": true,
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [1, 26, -2], "size": [3, 4, 1], "uv": [24, 0]}
            ],
            "parent": "head"
          },
          {
            "name": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-3, 8, -3], "size": [6, 12, 6], "uv": [0, 16]},
              {"origin": [-5, -8, 0], "size": [10, 16, 1], "uv": [0, 34]}
            ]
          },
          {
            "name": "rightWing",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-12, 7, 1.5], "size": [10, 16, 1], "uv": [42, 0]}
            ],
            "parent": "body"
          },
          {
            "name": "rightWingTip",
            "pivot": [-12, 23, 1.5],
            "cubes": [
              {"origin": [-20, 10, 1.5], "size": [8, 12, 1], "uv": [24, 16]}
            ],
            "parent": "rightWing"
          },
          {
            "name": "leftWing",
            "mirror": true,
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [2, 7, 1.5], "size": [10, 16, 1], "uv": [42, 0]}
            ],
            "parent": "body"
          },
          {
            "name": "leftWingTip",
            "mirror": true,
            "pivot": [12, 23, 1.5],
            "cubes": [
              {"origin": [12, 10, 1.5], "size": [8, 12, 1], "uv": [24, 16]}
            ],
            "parent": "leftWing"
          }
        ]
      }
    },
    "scripts": {"scale": "0.35"},
    "animations": {
      "resting": {
        "loop": true,
        "bones": {
          "body": {"position": [0, -0.035, 0], "rotation": [180, 0, 0]},
          "head": {
            "position": [0, -0.035, 0],
            "rotation": [
              "query.target_x_rotation",
              "180.0f - query.target_y_rotation",
              180
            ]
          },
          "leftwing": {"position": [3, 0, 3], "rotation": [-9, 72, 0]},
          "leftwingtip": {"rotation": [0, 99, 0]},
          "rightwing": {"position": [-3, 0, 3], "rotation": [-9, -72, 0]},
          "rightwingtip": {"rotation": [0, -99, 0]}
        }
      },
      "flying": {
        "loop": true,
        "bones": {
          "body": {
            "position": [0, "math.cos(query.life_time * 343.774) * 1.6", 0],
            "rotation": [
              "45.0 - math.cos(query.life_time * 1489.6) * 8.59",
              0,
              0
            ]
          },
          "head": {
            "position": [
              "-this",
              "math.cos(query.life_time * 343.774) * 1.6 - this",
              "-this"
            ],
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          },
          "leftwing": {
            "position": ["-this", "-this", "-this"],
            "rotation": [0, "math.cos(query.life_time * 1489.6) * -45.0", 0]
          },
          "leftwingtip": {
            "rotation": [0, "math.cos(query.life_time * 1489.6) * -22.0", 0]
          },
          "rightwing": {
            "position": ["-this", "-this", "-this"],
            "rotation": [0, "math.cos(query.life_time * 1489.6) * 45.0", 0]
          },
          "rightwingtip": {
            "rotation": [0, "math.cos(query.life_time * 1489.6) * 22.0", 0]
          }
        }
      }
    },
    "animation_controllers": {
      "move": {
        "initial_state": "flying",
        "states": {
          "flying": {
            "animations": ["flying"],
            "transitions": [{"resting": "query.is_resting"}]
          },
          "resting": {
            "animations": ["resting"],
            "transitions": [{"flying": "!query.is_resting"}]
          }
        }
      }
    },
    "render_controllers": ["controller.render.bat"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 18}
  },
  "bee": {
    "identifier": "minecraft:bee",
    "materials": {"default": "bee"},
    "textures": {
      "default": "textures/entity/bee/bee",
      "angry": "textures/entity/bee/bee_angry",
      "nectar": "textures/entity/bee/bee_nectar",
      "angry_nectar": "textures/entity/bee/bee_angry_nectar"
    },
    "geometry": {
      "default": {
        "texturewidth": 64,
        "textureheight": 64,
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 1.5,
        "visible_bounds_offset": [0, 0.25, 0],
        "bones": [
          {
            "name": "body",
            "pivot": [0.5, 5, 0],
            "cubes": [
              {"origin": [-3, 2, -5], "size": [7, 7, 10], "uv": [0, 0]},
              {"origin": [2, 7, -8], "size": [1, 2, 3], "uv": [2, 0]},
              {"origin": [-2, 7, -8], "size": [1, 2, 3], "uv": [2, 3]}
            ],
            "locators": {"lead": [0, 4, -1]}
          },
          {
            "name": "stinger",
            "parent": "body",
            "pivot": [0.5, 6, 1],
            "cubes": [{"origin": [0.5, 5, 5], "size": [0, 1, 2], "uv": [26, 7]}]
          },
          {
            "name": "rightwing_bone",
            "parent": "body",
            "pivot": [-1, 9, -3],
            "rotation": [15, -15, 0],
            "cubes": [
              {"origin": [-10, 9, -3], "size": [9, 0, 6], "uv": [0, 18]}
            ]
          },
          {
            "name": "leftwing_bone",
            "parent": "body",
            "pivot": [2, 9, -3],
            "rotation": [15, 15, 0],
            "cubes": [{"origin": [2, 9, -3], "size": [9, 0, 6], "uv": [9, 24]}]
          },
          {
            "name": "leg_front",
            "parent": "body",
            "pivot": [2, 2, -2],
            "cubes": [{"origin": [-3, 0, -2], "size": [7, 2, 0], "uv": [26, 1]}]
          },
          {
            "name": "leg_mid",
            "parent": "body",
            "pivot": [2, 2, 0],
            "cubes": [{"origin": [-3, 0, 0], "size": [7, 2, 0], "uv": [26, 3]}]
          },
          {
            "name": "leg_back",
            "parent": "body",
            "pivot": [2, 2, 2],
            "cubes": [{"origin": [-3, 0, 2], "size": [7, 2, 0], "uv": [26, 5]}]
          }
        ]
      }
    },
    "animations": {
      "flying": {
        "loop": true,
        "animation_length": 2.55,
        "bones": {
          "body": {
            "rotation": {"0.0": [0, 0, 0], "1.30": [5, 0, 0], "2.55": [0, 0, 0]}
          },
          "leftwing_bone": {
            "rotation": {
              "0.0": [0, 0, 35],
              "0.05": [0, 0, 0],
              "0.10": [0, 0, -35],
              "0.15": [0, 0, 0],
              "0.2": [0, 0, 35],
              "0.25": [0, 0, 0],
              "0.30": [0, 0, -35],
              "0.35": [0, 0, 0],
              "0.40": [0, 0, 35],
              "0.45": [0, 0, 0],
              "0.50": [0, 0, -35],
              "0.55": [0, 0, 0],
              "0.60": [0, 0, 35],
              "0.65": [0, 0, 0],
              "0.70": [0, 0, -35],
              "0.75": [0, 0, 0],
              "0.80": [0, 0, 35],
              "0.85": [0, 0, 0],
              "0.90": [0, 0, -35],
              "0.95": [0, 0, 0],
              "1.0": [0, 0, 35],
              "1.05": [0, 0, 0],
              "1.10": [0, 0, -35],
              "1.15": [0, 0, 0],
              "1.20": [0, 0, 35],
              "1.25": [0, 0, 0],
              "1.30": [0, 0, -35],
              "1.35": [0, 0, 0],
              "1.40": [0, 0, 35],
              "1.45": [0, 0, 0],
              "1.50": [0, 0, -35],
              "1.55": [0, 0, 0],
              "1.60": [0, 0, 35],
              "1.65": [0, 0, 0],
              "1.70": [0, 0, -35],
              "1.75": [0, 0, 0],
              "1.80": [0, 0, 35],
              "1.85": [0, 0, 0],
              "1.90": [0, 0, -35],
              "1.95": [0, 0, 0],
              "2.0": [0, 0, 35],
              "2.05": [0, 0, 0],
              "2.10": [0, 0, -35],
              "2.15": [0, 0, 0],
              "2.20": [0, 0, 35],
              "2.25": [0, 0, 0],
              "2.30": [0, 0, -35],
              "2.35": [0, 0, 0],
              "2.40": [0, 0, 35],
              "2.45": [0, 0, 0],
              "2.50": [0, 0, -35],
              "2.55": [0, 0, 0]
            }
          },
          "leg_front": {
            "rotation": {
              "0.0": [15, 0, 0],
              "1.20": [25, 0, 0],
              "2.55": [15, 0, 0]
            }
          },
          "leg_mid": {
            "rotation": {
              "0.0": [15, 0, 0],
              "1.30": [35, 0, 0],
              "2.55": [15, 0, 0]
            }
          },
          "leg_back": {
            "rotation": {
              "0.0": [30, 0, 0],
              "1.60": [45, 0, 0],
              "2.55": [30, 0, 0]
            }
          },
          "rightwing_bone": {
            "rotation": {
              "0.0": [0, 0, -35],
              "0.05": [0, 0, 0],
              "0.10": [0, 0, 35],
              "0.15": [0, 0, 0],
              "0.2": [0, 0, -35],
              "0.25": [0, 0, 0],
              "0.30": [0, 0, 35],
              "0.35": [0, 0, 0],
              "0.40": [0, 0, -35],
              "0.45": [0, 0, 0],
              "0.50": [0, 0, 35],
              "0.55": [0, 0, 0],
              "0.60": [0, 0, -35],
              "0.65": [0, 0, 0],
              "0.70": [0, 0, 35],
              "0.75": [0, 0, 0],
              "0.80": [0, 0, -35],
              "0.85": [0, 0, 0],
              "0.90": [0, 0, 35],
              "0.95": [0, 0, 0],
              "1.0": [0, 0, -35],
              "1.05": [0, 0, 0],
              "1.10": [0, 0, 35],
              "1.15": [0, 0, 0],
              "1.20": [0, 0, -35],
              "1.25": [0, 0, 0],
              "1.30": [0, 0, 35],
              "1.35": [0, 0, 0],
              "1.40": [0, 0, -35],
              "1.45": [0, 0, 0],
              "1.50": [0, 0, 35],
              "1.55": [0, 0, 0],
              "1.60": [0, 0, -35],
              "1.65": [0, 0, 0],
              "1.70": [0, 0, 35],
              "1.75": [0, 0, 0],
              "1.80": [0, 0, -35],
              "1.85": [0, 0, 0],
              "1.90": [0, 0, 35],
              "1.95": [0, 0, 0],
              "2.0": [0, 0, -35],
              "2.05": [0, 0, 0],
              "2.10": [0, 0, 35],
              "2.15": [0, 0, 0],
              "2.20": [0, 0, -35],
              "2.25": [0, 0, 0],
              "2.30": [0, 0, 35],
              "2.35": [0, 0, 0],
              "2.40": [0, 0, -35],
              "2.45": [0, 0, 0],
              "2.50": [0, 0, 35],
              "2.55": [0, 0, 0]
            }
          }
        }
      },
      "bee_sting": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": ["-175 * math.sin(variable.attack_time * 180)", 0, 0]
          }
        }
      },
      "bee_no_stinger": {"loop": true, "bones": {"stinger": {"scale": 0}}},
      "bee_fly_bobbing": {
        "loop": true,
        "bones": {
          "body": {
            "position": [0, "math.cos(query.life_time * 343.774) * 1.6", 0],
            "rotation": [0, "20 * math.cos(query.life_time * 343.774 / 2)", 0]
          }
        }
      }
    },
    "particle_effects": {"nectar_dripping": "minecraft:nectar_drip_particle"},
    "scripts": {
      "animate": [
        "bee_root_controller",
        {"bee_no_stinger": "query.mark_variant == 1"}
      ]
    },
    "render_controllers": ["controller.render.bee"],
    "spawn_egg": {"texture": "egg_bee", "texture_index": 0},
    "animation_controllers": {
      "drip": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"dripping": "query.is_powered"}]},
          "dripping": {
            "particle_effects": [{"effect": "nectar_dripping"}],
            "transitions": [{"default": "!query.is_powered"}]
          }
        }
      },
      "controller_bee_sting": {
        "initial_state": "default",
        "states": {"default": {"animations": ["bee_sting"]}}
      },
      "bee_root_controller": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": [
              "flying",
              "drip",
              "bee_fly_bobbing",
              "controller_bee_sting"
            ]
          }
        }
      }
    }
  },
  "blaze": {
    "identifier": "minecraft:blaze",
    "min_engine_version": "1.8.0",
    "materials": {"body": "blaze_body", "head": "blaze_head"},
    "textures": {"default": "textures/entity/blaze"},
    "geometry": {
      "default": {
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 1, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "upperBodyParts0",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 16, 0], "size": [2, 8, 2], "uv": [0, 16]}]
          },
          {
            "name": "upperBodyParts1",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 16, 0], "size": [2, 8, 2], "uv": [0, 16]}]
          },
          {
            "name": "upperBodyParts2",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 16, 0], "size": [2, 8, 2], "uv": [0, 16]}]
          },
          {
            "name": "upperBodyParts3",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 16, 0], "size": [2, 8, 2], "uv": [0, 16]}]
          },
          {
            "name": "upperBodyParts4",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 16, 0], "size": [2, 8, 2], "uv": [0, 16]}]
          },
          {
            "name": "upperBodyParts5",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 16, 0], "size": [2, 8, 2], "uv": [0, 16]}]
          },
          {
            "name": "upperBodyParts6",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 16, 0], "size": [2, 8, 2], "uv": [0, 16]}]
          },
          {
            "name": "upperBodyParts7",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 16, 0], "size": [2, 8, 2], "uv": [0, 16]}]
          },
          {
            "name": "upperBodyParts8",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 16, 0], "size": [2, 8, 2], "uv": [0, 16]}]
          },
          {
            "name": "upperBodyParts9",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 16, 0], "size": [2, 8, 2], "uv": [0, 16]}]
          },
          {
            "name": "upperBodyParts10",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 16, 0], "size": [2, 8, 2], "uv": [0, 16]}]
          },
          {
            "name": "upperBodyParts11",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 16, 0], "size": [2, 8, 2], "uv": [0, 16]}]
          },
          {
            "name": "head",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-4, 20, -4], "size": [8, 8, 8], "uv": [0, 0]}]
          }
        ]
      }
    },
    "animations": {
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "move": {
        "loop": true,
        "bones": {
          "upperbodyparts0": {
            "position": [
              "math.cos(query.life_time * -360.0        ) * 9.0",
              "2.0 + math.cos((0 * 2 + query.life_time * 20.0) * 14.32)",
              "math.sin(query.life_time * -360.0        ) * 9.0"
            ]
          },
          "upperbodyparts1": {
            "position": [
              "math.cos(query.life_time * -360.0 +  90.0) * 9.0",
              "2.0 + math.cos((1 * 2 + query.life_time * 20.0) * 14.32)",
              "math.sin(query.life_time * -360.0 +  90.0) * 9.0"
            ]
          },
          "upperbodyparts10": {
            "position": [
              "math.cos(query.life_time * -180.0 + 207.0) * 5.0",
              "-11.0 + math.cos((10 * 1.5 + query.life_time * 20.0) * 14.32)",
              "math.sin(query.life_time * -180.0 + 207.0) * 5.0"
            ]
          },
          "upperbodyparts11": {
            "position": [
              "math.cos(query.life_time * -180.0 + 297.0) * 5.0",
              "-11.0 + math.cos((11 * 1.5 + query.life_time * 20.0) * 14.32)",
              "math.sin(query.life_time * -180.0 + 297.0) * 5.0"
            ]
          },
          "upperbodyparts2": {
            "position": [
              "math.cos(query.life_time * -360.0 + 180.0) * 9.0",
              "2.0 + math.cos((2 * 2 + query.life_time * 20.0) * 14.32)",
              "math.sin(query.life_time * -360.0 + 180.0) * 9.0"
            ]
          },
          "upperbodyparts3": {
            "position": [
              "math.cos(query.life_time * -360.0 + 270.0) * 9.0",
              "2.0 + math.cos((3 * 2 + query.life_time * 20.0) * 14.32)",
              "math.sin(query.life_time * -360.0 + 270.0) * 9.0"
            ]
          },
          "upperbodyparts4": {
            "position": [
              "math.cos(query.life_time * 108.0 +  45.0) * 7.0",
              "-2.0 + math.cos((4 * 2 + query.life_time * 20.0) * 14.32)",
              "math.sin(query.life_time * 108.0 +  45.0) * 7.0"
            ]
          },
          "upperbodyparts5": {
            "position": [
              "math.cos(query.life_time * 108.0 + 135.0) * 7.0",
              "-2.0 + math.cos((5 * 2 + query.life_time * 20.0) * 14.32)",
              "math.sin(query.life_time * 108.0 + 135.0) * 7.0"
            ]
          },
          "upperbodyparts6": {
            "position": [
              "math.cos(query.life_time * 108.0 + 225.0) * 7.0",
              "-2.0 + math.cos((6 * 2 + query.life_time * 20.0) * 14.32)",
              "math.sin(query.life_time * 108.0 + 225.0) * 7.0"
            ]
          },
          "upperbodyparts7": {
            "position": [
              "math.cos(query.life_time * 108.0 + 315.0) * 7.0",
              "-2.0 + math.cos((7 * 2 + query.life_time * 20.0) * 14.32)",
              "math.sin(query.life_time * 108.0 + 315.0) * 7.0"
            ]
          },
          "upperbodyparts8": {
            "position": [
              "math.cos(query.life_time * -180.0 +  27.0) * 5.0",
              "-11.0 + math.cos((8 * 1.5 + query.life_time * 20.0) * 14.32)",
              "math.sin(query.life_time * -180.0 +  27.0) * 5.0"
            ]
          },
          "upperbodyparts9": {
            "position": [
              "math.cos(query.life_time * -180.0 + 117.0) * 5.0",
              "-11.0 + math.cos((9 * 1.5 + query.life_time * 20.0) * 14.32)",
              "math.sin(query.life_time * -180.0 + 117.0) * 5.0"
            ]
          }
        }
      }
    },
    "particle_effects": {"charged_flames": "minecraft:mobflame_emitter"},
    "animation_controllers": {
      "move": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move", "look_at_target"]}}
      },
      "flame": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"flaming": "query.is_charged"}]},
          "flaming": {
            "particle_effects": [{"effect": "charged_flames"}],
            "transitions": [{"default": "!query.is_charged"}]
          }
        }
      }
    },
    "render_controllers": ["controller.render.blaze"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 21}
  },
  "boat": {
    "identifier": "minecraft:boat",
    "textures": {
      "default": "textures/entity/boat/acacia",
      "acacia": "textures/entity/boat/acacia",
      "birch": "textures/entity/boat/birch",
      "darkoak": "textures/entity/boat/dark_oak",
      "jungle": "textures/entity/boat/jungle",
      "oak": "textures/entity/boat/oak",
      "spruce": "textures/entity/boat/spruce"
    },
    "geometry": {
      "default": {
        "texturewidth": 128,
        "textureheight": 64,
        "bones": [
          {
            "name": "bottom",
            "pivot": [0, 18, 0],
            "rotation": [90, 0, 0],
            "mirror": true,
            "cubes": [
              {"origin": [-14, 10, 0], "size": [28, 16, 3], "uv": [0, 0]}
            ]
          },
          {
            "name": "front",
            "pivot": [15, 24, 0],
            "rotation": [0, 90, 0],
            "mirror": true,
            "cubes": [
              {"origin": [7, 21, -1], "size": [16, 6, 2], "uv": [0, 27]}
            ]
          },
          {
            "name": "back",
            "pivot": [-15, 24, 0],
            "rotation": [0, -90, 0],
            "mirror": true,
            "cubes": [
              {"origin": [-24, 21, -1], "size": [18, 6, 2], "uv": [0, 19]}
            ]
          },
          {
            "name": "right",
            "pivot": [0, 24, -9],
            "rotation": [0, -180, 0],
            "mirror": true,
            "cubes": [
              {"origin": [-14, 21, -10], "size": [28, 6, 2], "uv": [0, 35]}
            ]
          },
          {
            "name": "left",
            "pivot": [0, 24, 9],
            "mirror": true,
            "cubes": [
              {"origin": [-14, 21, 8], "size": [28, 6, 2], "uv": [0, 43]}
            ]
          }
        ]
      }
    }
  },
  "cat": {
    "identifier": "minecraft:cat",
    "materials": {"default": "cat"},
    "textures": {
      "white": "textures/entity/cat/white",
      "black": "textures/entity/cat/tuxedo",
      "red": "textures/entity/cat/red",
      "siamese": "textures/entity/cat/siamese",
      "british": "textures/entity/cat/british_shorthair",
      "calico": "textures/entity/cat/calico",
      "persian": "textures/entity/cat/persian",
      "ragdoll": "textures/entity/cat/ragdoll",
      "tabby": "textures/entity/cat/tabby",
      "jellie": "textures/entity/cat/jellie",
      "all_black": "textures/entity/cat/all_black",
      "white_tame": "textures/entity/cat/white",
      "black_tame": "textures/entity/cat/tuxedo_tame",
      "red_tame": "textures/entity/cat/red",
      "siamese_tame": "textures/entity/cat/siamese",
      "british_tame": "textures/entity/cat/british_shorthair",
      "calico_tame": "textures/entity/cat/calico",
      "persian_tame": "textures/entity/cat/persian",
      "ragdoll_tame": "textures/entity/cat/ragdoll",
      "tabby_tame": "textures/entity/cat/tabby",
      "all_black_tame": "textures/entity/cat/all_black",
      "jellie_tame": "textures/entity/cat/jellie"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 2.5,
        "visible_bounds_height": 1,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "pivot": [0, 9, -9],
            "locators": {"lead": [0, 9, -9]},
            "cubes": [
              {"origin": [-2.5, 7, -12], "size": [5, 4, 5], "uv": [0, 0]},
              {
                "origin": [-1.5, 7.01562, -13],
                "size": [3, 2, 2],
                "uv": [0, 24]
              },
              {"origin": [-2, 11, -9], "size": [1, 1, 2], "uv": [0, 10]},
              {"origin": [1, 11, -9], "size": [1, 1, 2], "uv": [6, 10]}
            ],
            "name": "head",
            "parent": "body"
          },
          {
            "pivot": [0, 7, 1],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {"origin": [-2, -1, -2], "size": [4, 16, 6], "uv": [20, 0]}
            ],
            "name": "body"
          },
          {
            "pivot": [0, 9, 8],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {"origin": [-0.5, 1, 8], "size": [1, 8, 1], "uv": [0, 15]}
            ],
            "name": "tail1",
            "parent": "body"
          },
          {
            "pivot": [0, 9, 16],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {
                "origin": [-0.5, 1, 16],
                "size": [1, 8, 1],
                "uv": [4, 15],
                "inflate": 0,
                "mirror": false
              }
            ],
            "name": "tail2",
            "parent": "tail1"
          },
          {
            "pivot": [1.1, 6, 7],
            "cubes": [
              {
                "origin": [0.1, 0, 6],
                "size": [2, 6, 2],
                "uv": [8, 13],
                "inflate": 0,
                "mirror": false
              }
            ],
            "name": "backLegL",
            "parent": "body"
          },
          {
            "pivot": [-1.1, 6, 7],
            "cubes": [
              {
                "origin": [-2.1, 0, 6],
                "size": [2, 6, 2],
                "uv": [8, 13],
                "inflate": 0,
                "mirror": false
              }
            ],
            "name": "backLegR",
            "parent": "body"
          },
          {
            "pivot": [1.2, 10, -4],
            "cubes": [
              {
                "origin": [0.2, 0.2, -5],
                "size": [2, 10, 2],
                "uv": [40, 0],
                "inflate": 0,
                "mirror": false
              }
            ],
            "name": "frontLegL",
            "parent": "body"
          },
          {
            "pivot": [-1.2, 10, -4],
            "cubes": [
              {
                "origin": [-2.2, 0.2, -5],
                "size": [2, 10, 2],
                "uv": [40, 0],
                "inflate": 0,
                "mirror": false
              }
            ],
            "name": "frontLegR",
            "parent": "body"
          }
        ]
      }
    },
    "animations": {
      "sneak": {
        "loop": true,
        "bones": {
          "backlegl": {
            "position": [0, 1, 0],
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17) * 57.3 * query.modified_move_speed",
              0,
              0
            ]
          },
          "backlegr": {
            "position": [0, 1, 0],
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17 + 180.0) * 57.3 * query.modified_move_speed",
              0,
              0
            ]
          },
          "body": {"position": [0, -1, 0]},
          "frontlegl": {
            "position": [0, 1, 0],
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17 + 180.0) * 57.3 * query.modified_move_speed",
              0,
              0
            ]
          },
          "frontlegr": {
            "position": [0, 1, 0],
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17) * 57.3 * query.modified_move_speed",
              0,
              0
            ]
          },
          "head": {"position": [0, -1, 0]},
          "tail1": {"position": [0, 1, 0]},
          "tail2": {
            "rotation": [
              "62.0 + math.cos(query.modified_distance_moved * 57.3) * 27.0 * query.modified_move_speed",
              0,
              0
            ]
          }
        }
      },
      "walk": {
        "loop": true,
        "bones": {
          "backlegl": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17) * 57.3 * query.modified_move_speed",
              0,
              0
            ]
          },
          "backlegr": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17 + 180.0) * 57.3 * query.modified_move_speed",
              0,
              0
            ]
          },
          "frontlegl": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17 + 180.0) * 57.3 * query.modified_move_speed",
              0,
              0
            ]
          },
          "frontlegr": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17) * 57.3 * query.modified_move_speed",
              0,
              0
            ]
          },
          "tail1": {"rotation": [-51.57, 0, 0]},
          "tail2": {
            "rotation": [
              "62.0 + math.cos(query.modified_distance_moved * 57.3) * 45.0 * query.modified_move_speed",
              0,
              0
            ]
          }
        }
      },
      "sprint": {
        "loop": true,
        "bones": {
          "backlegl": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17) * 57.3 * query.modified_move_speed",
              0,
              0
            ]
          },
          "backlegr": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17 + 17.19) * 57.3 * query.modified_move_speed",
              0,
              0
            ]
          },
          "frontlegl": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17 + 197.19) * 57.3 * query.modified_move_speed",
              0,
              0
            ]
          },
          "frontlegr": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17 + 180.0) * 57.3 * query.modified_move_speed",
              0,
              0
            ]
          },
          "tail1": {"rotation": [0, 0, 0]},
          "tail2": {
            "rotation": [
              "62.0 + math.cos(query.modified_distance_moved * 57.3) * 18.0 * query.modified_move_speed",
              0,
              0
            ]
          }
        }
      },
      "sit": {
        "loop": true,
        "bones": {
          "backlegl": {"position": [0, 0, 1], "rotation": [-45, 0, 0]},
          "backlegr": {"position": [0, 0, 1], "rotation": [-45, 0, 0]},
          "body": {"position": [0, -1, 0], "rotation": [-45, 0, 0]},
          "frontlegl": {"position": [0, -4.5, -1], "rotation": [42.15, 0, 0]},
          "frontlegr": {"position": [0, -4.5, -1], "rotation": [42.15, 0, 0]},
          "tail1": {"position": [0, -3, 1], "rotation": [45, 0, 0]},
          "tail2": {"position": [0, 0, 0], "rotation": [45, 0, 0]}
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "baby_transform": {"loop": true, "bones": {"head": {"scale": 1.5}}},
      "lie_down": {
        "loop": true,
        "bones": {
          "backlegl": {"position": [0, -0.7, 0], "rotation": [-22.92, 0, 0]},
          "backlegr": {
            "position": [0.1, -1.2, 0],
            "rotation": [28.65, 0, -28.65]
          },
          "body": {
            "position": [
              0,
              "variable.liedownamount * -4.5 + variable.lieonplayer * (4.5 + query.is_baby * 6)",
              0
            ],
            "rotation": [
              0,
              0,
              "math.lerprotate(0.0, 90.0, variable.liedownamount)"
            ]
          },
          "frontlegl": {"position": [0, -1, -1.5], "rotation": [-72.81, 0, 0]},
          "frontlegr": {
            "position": [0.2, -0.9, 0],
            "rotation": [-30, 0, -14.46]
          },
          "head": {
            "position": [-0.1, 0, -0.5],
            "rotation": [
              -10,
              "math.lerprotate(this, 75.81, variable.liedownamount)",
              0
            ]
          },
          "tail1": {
            "position": [0, 0, 0],
            "rotation": [
              "math.lerprotate(this, -33.84, variable.liedownamounttail)",
              0,
              0
            ]
          },
          "tail2": {
            "position": [0, 0, 0],
            "rotation": [
              "math.lerprotate(this, -68.92, variable.liedownamounttail)",
              0,
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "look_at_target": {
        "initial_state": "default",
        "states": {"default": {"animations": ["look_at_target"]}}
      },
      "move": {
        "initial_state": "lie_down",
        "states": {
          "lie_down": {
            "animations": ["lie_down"],
            "transitions": [
              {"sneaking": "variable.state == 0"},
              {"sprinting": "variable.state == 1"},
              {"sitting": "variable.state == 2"},
              {"walking": "variable.state == 3"}
            ]
          },
          "sitting": {
            "animations": ["sit"],
            "transitions": [
              {"sneaking": "variable.state == 0"},
              {"sprinting": "variable.state == 1"},
              {"walking": "variable.state == 3"},
              {"lie_down": "variable.state == 4"}
            ]
          },
          "sneaking": {
            "animations": ["sneak"],
            "transitions": [
              {"sprinting": "variable.state == 1"},
              {"sitting": "variable.state == 2"},
              {"walking": "variable.state == 3"},
              {"lie_down": "variable.state == 4"}
            ]
          },
          "sprinting": {
            "animations": ["sprint"],
            "transitions": [
              {"sneaking": "variable.state == 0"},
              {"sitting": "variable.state == 2"},
              {"walking": "variable.state == 3"},
              {"lie_down": "variable.state == 4"}
            ]
          },
          "walking": {
            "animations": ["walk"],
            "transitions": [
              {"sneaking": "variable.state == 0"},
              {"sprinting": "variable.state == 1"},
              {"sitting": "variable.state == 2"},
              {"lie_down": "variable.state == 4"}
            ]
          }
        }
      },
      "baby": {
        "initial_state": "baby",
        "states": {
          "baby": {"animations": [{"baby_transform": "query.is_baby"}]}
        }
      }
    },
    "render_controllers": ["controller.render.cat"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 53}
  },
  "cave_spider": {
    "identifier": "minecraft:cave_spider",
    "min_engine_version": "1.8.0",
    "materials": {"default": "spider", "invisible": "spider_invisible"},
    "textures": {"default": "textures/entity/spider/cave_spider"},
    "geometry": {
      "default": {
        "visible_bounds_width": 2,
        "visible_bounds_height": 1,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "head",
            "pivot": [0, 9, -3],
            "cubes": [
              {"origin": [-4, 5, -11], "size": [8, 8, 8], "uv": [32, 4]}
            ],
            "parent": "body0"
          },
          {
            "name": "body0",
            "pivot": [0, 9, 0],
            "cubes": [{"origin": [-3, 6, -3], "size": [6, 6, 6], "uv": [0, 0]}]
          },
          {
            "name": "body1",
            "pivot": [0, 9, 9],
            "cubes": [
              {"origin": [-5, 5, 3], "size": [10, 8, 12], "uv": [0, 12]}
            ],
            "parent": "body0"
          },
          {
            "name": "leg0",
            "pivot": [-4, 9, 2],
            "cubes": [
              {"origin": [-19, 8, 1], "size": [16, 2, 2], "uv": [18, 0]}
            ],
            "parent": "body0"
          },
          {
            "name": "leg1",
            "pivot": [4, 9, 2],
            "cubes": [{"origin": [3, 8, 1], "size": [16, 2, 2], "uv": [18, 0]}],
            "parent": "body0"
          },
          {
            "name": "leg2",
            "pivot": [-4, 9, 1],
            "cubes": [
              {"origin": [-19, 8, 0], "size": [16, 2, 2], "uv": [18, 0]}
            ],
            "parent": "body0"
          },
          {
            "name": "leg3",
            "pivot": [4, 9, 1],
            "cubes": [{"origin": [3, 8, 0], "size": [16, 2, 2], "uv": [18, 0]}],
            "parent": "body0"
          },
          {
            "name": "leg4",
            "pivot": [-4, 9, 0],
            "cubes": [
              {"origin": [-19, 8, -1], "size": [16, 2, 2], "uv": [18, 0]}
            ],
            "parent": "body0"
          },
          {
            "name": "leg5",
            "pivot": [4, 9, 0],
            "cubes": [
              {"origin": [3, 8, -1], "size": [16, 2, 2], "uv": [18, 0]}
            ],
            "parent": "body0"
          },
          {
            "name": "leg6",
            "pivot": [-4, 9, -1],
            "cubes": [
              {"origin": [-19, 8, -2], "size": [16, 2, 2], "uv": [18, 0]}
            ],
            "parent": "body0"
          },
          {
            "name": "leg7",
            "pivot": [4, 9, -1],
            "cubes": [
              {"origin": [3, 8, -2], "size": [16, 2, 2], "uv": [18, 0]}
            ],
            "parent": "body0"
          }
        ]
      }
    },
    "scripts": {"scale": "0.7"},
    "animations": {
      "default_leg_pose": {
        "loop": true,
        "bones": {
          "leg0": {"rotation": [0, "45.0 - this", "-45.0 - this"]},
          "leg1": {"rotation": [0, "-45.0 - this", "45.0 - this"]},
          "leg2": {"rotation": [0, "22.5 - this", "-33.3 - this"]},
          "leg3": {"rotation": [0, "-22.5 - this", "33.3 - this"]},
          "leg4": {"rotation": [0, "-22.5 - this", "-33.3 - this"]},
          "leg5": {"rotation": [0, "22.5 - this", "33.3 - this"]},
          "leg6": {"rotation": [0, "-45.0 - this", "-45.0 - this"]},
          "leg7": {"rotation": [0, "45.0 - this", "45.0 - this"]}
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "walk": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": [
              0,
              "-math.abs(math.cos(query.anim_time * 76.34 + 90 * 0) * 22.92)",
              "math.abs(math.sin(query.anim_time * 38.17 + 90 * 0) * 22.92)"
            ]
          },
          "leg1": {
            "rotation": [
              0,
              "math.abs(math.cos(query.anim_time * 76.34 + 90 * 0) * 22.92)",
              "-math.abs(math.sin(query.anim_time * 38.17 + 90 * 0) * 22.92)"
            ]
          },
          "leg2": {
            "rotation": [
              0,
              "-math.abs(math.cos(query.anim_time * 76.34 + 90 * 1) * 22.92)",
              "math.abs(math.sin(query.anim_time * 38.17 + 90 * 1) * 22.92)"
            ]
          },
          "leg3": {
            "rotation": [
              0,
              "math.abs(math.cos(query.anim_time * 76.34 + 90 * 1) * 22.92)",
              "-math.abs(math.sin(query.anim_time * 38.17 + 90 * 1) * 22.92)"
            ]
          },
          "leg4": {
            "rotation": [
              0,
              "-math.abs(math.cos(query.anim_time * 76.34 + 90 * 2) * 22.92)",
              "math.abs(math.sin(query.anim_time * 38.17 + 90 * 2) * 22.92)"
            ]
          },
          "leg5": {
            "rotation": [
              0,
              "math.abs(math.cos(query.anim_time * 76.34 + 90 * 2) * 22.92)",
              "-math.abs(math.sin(query.anim_time * 38.17 + 90 * 2) * 22.92)"
            ]
          },
          "leg6": {
            "rotation": [
              0,
              "-math.abs(math.cos(query.anim_time * 76.34 + 90 * 3) * 22.92)",
              "math.abs(math.sin(query.anim_time * 38.17 + 90 * 3) * 22.92)"
            ]
          },
          "leg7": {
            "rotation": [
              0,
              "math.abs(math.cos(query.anim_time * 76.34 + 90 * 3) * 22.92)",
              "-math.abs(math.sin(query.anim_time * 38.17 + 90 * 3) * 22.92)"
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "move": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": [
              "default_leg_pose",
              {"walk": "query.modified_move_speed"},
              "look_at_target"
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.spider"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 22}
  },
  "chest_minecart": {
    "identifier": "minecraft:chest_minecart",
    "min_engine_version": "1.8.0",
    "materials": {"default": "minecart"},
    "textures": {"default": "textures/entity/minecart"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "bottom",
            "pivot": [0, 6, 0],
            "cubes": [
              {
                "origin": [-10, -6.5, -1],
                "size": [20, 16, 2],
                "rotation": [90, 0, 0],
                "uv": [0, 10]
              }
            ]
          },
          {
            "name": "back",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-17, 2.5, -1],
                "size": [16, 8, 2],
                "rotation": [0, 270, 0],
                "uv": [0, 0]
              }
            ],
            "parent": "bottom"
          },
          {
            "name": "front",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [1, 2.5, -1],
                "size": [16, 8, 2],
                "rotation": [0, 90, 0],
                "uv": [0, 0]
              }
            ],
            "parent": "bottom"
          },
          {
            "name": "right",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-8, 2.5, -8],
                "size": [16, 8, 2],
                "rotation": [0, 180, 0],
                "uv": [0, 0]
              }
            ],
            "parent": "bottom"
          },
          {
            "name": "left",
            "pivot": [0, 0, 0],
            "cubes": [
              {"origin": [-8, 2.5, 6], "size": [16, 8, 2], "uv": [0, 0]}
            ],
            "parent": "bottom"
          }
        ],
        "texturewidth": 64,
        "textureheight": 32
      }
    },
    "scripts": {
      "pre_animation": ["variable.hurt = query.hurt_time - query.frame_alpha;"],
      "animate": ["move"]
    },
    "animations": {
      "move": {
        "loop": true,
        "bones": {
          "bottom": {
            "position": [
              "variable.rail_offset.x / query.model_scale",
              "variable.rail_offset.y / query.model_scale",
              "variable.rail_offset.z / query.model_scale"
            ],
            "rotation": [
              "variable.hurt > 0 ? -Math.sin(variable.hurt * 360 / (Math.pi * 2)) * variable.hurt * (((20 * 2 - query.structural_integrity) - query.frame_alpha) < 0 ? 0: (20 * 2 - query.structural_integrity) - query.frame_alpha) / 10 * query.hurt_direction : 0",
              0,
              "-variable.rail_rotation.z"
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.minecart"]
  },
  "chicken": {
    "identifier": "minecraft:chicken",
    "min_engine_version": "1.12.0",
    "materials": {"default": "chicken", "legs": "chicken_legs"},
    "textures": {"default": "textures/entity/chicken"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 8, 0],
            "cubes": [
              {
                "origin": [-3, 4, -3],
                "rotation": [90, 0, 0],
                "size": [6, 8, 6],
                "uv": [0, 9]
              }
            ]
          },
          {
            "name": "head",
            "pivot": [0, 9, -4],
            "cubes": [{"origin": [-2, 9, -6], "size": [4, 6, 3], "uv": [0, 0]}],
            "locators": {"lead": [0, 9, -4]}
          },
          {
            "name": "comb",
            "parent": "head",
            "pivot": [0, 9, -4],
            "cubes": [{"origin": [-1, 9, -7], "size": [2, 2, 2], "uv": [14, 4]}]
          },
          {
            "name": "beak",
            "parent": "head",
            "pivot": [0, 9, -4],
            "cubes": [
              {"origin": [-2, 11, -8], "size": [4, 2, 2], "uv": [14, 0]}
            ]
          },
          {
            "name": "leg0",
            "pivot": [-2, 5, 1],
            "cubes": [{"origin": [-3, 0, -2], "size": [3, 5, 3], "uv": [26, 0]}]
          },
          {
            "name": "leg1",
            "pivot": [1, 5, 1],
            "cubes": [{"origin": [0, 0, -2], "size": [3, 5, 3], "uv": [26, 0]}]
          },
          {
            "name": "wing0",
            "pivot": [-3, 11, 0],
            "cubes": [
              {"origin": [-4, 7, -3], "size": [1, 4, 6], "uv": [24, 13]}
            ]
          },
          {
            "name": "wing1",
            "pivot": [3, 11, 0],
            "cubes": [{"origin": [3, 7, -3], "size": [1, 4, 6], "uv": [24, 13]}]
          }
        ],
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 1.5,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 32
      }
    },
    "animations": {
      "move": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          },
          "leg1": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          }
        }
      },
      "general": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["-this", 0, 0]},
          "wing0": {"rotation": [0, 0, "variable.wing_flap - this"]},
          "wing1": {"rotation": [0, 0, "-variable.wing_flap - this"]}
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "baby_transform": {"loop": true, "bones": {"head": {"scale": 2}}}
    },
    "scripts": {
      "animate": [
        "general",
        {"move": "query.modified_move_speed"},
        "look_at_target",
        {"baby_transform": "query.is_baby"}
      ]
    },
    "render_controllers": ["controller.render.chicken"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 0}
  },
  "cod": {
    "identifier": "minecraft:cod",
    "materials": {"default": "cod"},
    "textures": {"default": "textures/entity/fish/cod"},
    "geometry": {
      "default": {
        "visible_bounds_width": 0.5,
        "visible_bounds_height": 0.5,
        "texturewidth": 32,
        "textureheight": 32,
        "bones": [
          {
            "name": "body",
            "cubes": [
              {"origin": [-1, 0, 1], "size": [2, 4, 7], "uv": [0, 0]},
              {"origin": [0, 4, 0], "size": [0, 1, 6], "uv": [20, -6]},
              {"origin": [0, -1, 3], "size": [0, 1, 2], "uv": [22, -1]}
            ]
          },
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 2, 0],
            "cubes": [
              {
                "origin": [-0.9992, 1.0008, -3],
                "size": [2, 3, 1],
                "uv": [0, 0]
              },
              {"origin": [-1, 0, -2], "size": [2, 4, 3], "uv": [11, 0]}
            ]
          },
          {
            "name": "leftFin",
            "parent": "body",
            "pivot": [1, 1, 0],
            "rotation": [0, 0, 35],
            "cubes": [{"origin": [1, 0, 0], "size": [2, 1, 2], "uv": [24, 4]}]
          },
          {
            "name": "rightFin",
            "parent": "body",
            "pivot": [-1, 1, 0],
            "rotation": [0, 0, -35],
            "cubes": [{"origin": [-3, 0, 0], "size": [2, 1, 2], "uv": [24, 1]}]
          },
          {
            "name": "tailfin",
            "parent": "body",
            "pivot": [0, 0, 8],
            "cubes": [{"origin": [0, 0, 8], "size": [0, 4, 6], "uv": [20, 1]}]
          },
          {"name": "waist", "parent": "body", "cubes": []}
        ]
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.ZRot = !query.is_in_water ? Math.cos((query.time_stamp + query.frame_alpha) * 14.32) * 90 : 0.0;",
        "variable.AnimationAmountBlend = Math.lerp(variable.AnimationAmountPrev, variable.AnimationAmount, query.frame_alpha);"
      ]
    },
    "animations": {
      "flop": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 45.0) * 2.0",
              "variable.zrot"
            ]
          },
          "head": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 45.0) * 4.0",
              0
            ]
          },
          "tailfin": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 45.0) * -25.75",
              0
            ]
          }
        }
      },
      "swim": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 30.0) * 2.0",
              0
            ]
          },
          "head": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 30.0) * 4.0",
              0
            ]
          },
          "tailfin": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 30.0) * -25.75",
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "general": {
        "initial_state": "flopping",
        "states": {
          "flopping": {
            "animations": ["flop"],
            "transitions": [
              {"swimming": "query.is_in_water || query.is_levitating"}
            ]
          },
          "swimming": {
            "animations": ["swim"],
            "transitions": [
              {"flopping": "!query.is_in_water && !query.is_levitating"}
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.cod"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 45}
  },
  "command_block_minecart": {
    "identifier": "minecraft:command_block_minecart",
    "min_engine_version": "1.8.0",
    "materials": {"default": "minecart"},
    "textures": {"default": "textures/entity/minecart"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "bottom",
            "pivot": [0, 6, 0],
            "cubes": [
              {
                "origin": [-10, -6.5, -1],
                "size": [20, 16, 2],
                "rotation": [90, 0, 0],
                "uv": [0, 10]
              }
            ]
          },
          {
            "name": "back",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-17, 2.5, -1],
                "size": [16, 8, 2],
                "rotation": [0, 270, 0],
                "uv": [0, 0]
              }
            ],
            "parent": "bottom"
          },
          {
            "name": "front",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [1, 2.5, -1],
                "size": [16, 8, 2],
                "rotation": [0, 90, 0],
                "uv": [0, 0]
              }
            ],
            "parent": "bottom"
          },
          {
            "name": "right",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-8, 2.5, -8],
                "size": [16, 8, 2],
                "rotation": [0, 180, 0],
                "uv": [0, 0]
              }
            ],
            "parent": "bottom"
          },
          {
            "name": "left",
            "pivot": [0, 0, 0],
            "cubes": [
              {"origin": [-8, 2.5, 6], "size": [16, 8, 2], "uv": [0, 0]}
            ],
            "parent": "bottom"
          }
        ],
        "texturewidth": 64,
        "textureheight": 32
      }
    },
    "scripts": {
      "pre_animation": ["variable.hurt = query.hurt_time - query.frame_alpha;"],
      "animate": ["move"]
    },
    "animations": {
      "move": {
        "loop": true,
        "bones": {
          "bottom": {
            "position": [
              "variable.rail_offset.x / query.model_scale",
              "variable.rail_offset.y / query.model_scale",
              "variable.rail_offset.z / query.model_scale"
            ],
            "rotation": [
              "variable.hurt > 0 ? -Math.sin(variable.hurt * 360 / (Math.pi * 2)) * variable.hurt * (((20 * 2 - query.structural_integrity) - query.frame_alpha) < 0 ? 0: (20 * 2 - query.structural_integrity) - query.frame_alpha) / 10 * query.hurt_direction : 0",
              0,
              "-variable.rail_rotation.z"
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.minecart"]
  },
  "cow": {
    "identifier": "minecraft:cow",
    "min_engine_version": "1.8.0",
    "materials": {"default": "cow"},
    "textures": {"default": "textures/entity/cow/cow"},
    "geometry": {
      "default": {
        "visible_bounds_width": 2,
        "visible_bounds_height": 1.75,
        "visible_bounds_offset": [0, 0.75, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "body",
            "pivot": [0, 19, 2],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {"origin": [-6, 11, -5], "size": [12, 18, 10], "uv": [18, 4]},
              {"origin": [-2, 11, -6], "size": [4, 6, 1], "uv": [52, 0]}
            ]
          },
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 20, -8],
            "locators": {"lead": [0, 20, -8]},
            "cubes": [
              {"origin": [-4, 16, -14], "size": [8, 8, 6], "uv": [0, 0]},
              {"origin": [-5, 22, -12], "size": [1, 3, 1], "uv": [22, 0]},
              {"origin": [4, 22, -12], "size": [1, 3, 1], "uv": [22, 0]}
            ]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-4, 12, 7],
            "cubes": [{"origin": [-6, 0, 5], "size": [4, 12, 4], "uv": [0, 16]}]
          },
          {
            "name": "leg1",
            "parent": "body",
            "mirror": true,
            "pivot": [4, 12, 7],
            "cubes": [{"origin": [2, 0, 5], "size": [4, 12, 4], "uv": [0, 16]}]
          },
          {
            "name": "leg2",
            "parent": "body",
            "pivot": [-4, 12, -6],
            "cubes": [
              {"origin": [-6, 0, -7], "size": [4, 12, 4], "uv": [0, 16]}
            ]
          },
          {
            "name": "leg3",
            "parent": "body",
            "mirror": true,
            "pivot": [4, 12, -6],
            "cubes": [{"origin": [2, 0, -7], "size": [4, 12, 4], "uv": [0, 16]}]
          }
        ]
      }
    },
    "animations": {
      "setup": {"loop": true, "bones": {"body": {"rotation": ["-this", 0, 0]}}},
      "walk": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          },
          "leg1": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          },
          "leg2": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          },
          "leg3": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          }
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "baby_transform": {
        "loop": true,
        "bones": {"head": {"position": [0, 4, 4], "scale": 2}}
      }
    },
    "scripts": {
      "animate": [
        "setup",
        {"walk": "query.modified_move_speed"},
        "look_at_target",
        {"baby_transform": "query.is_baby"}
      ]
    },
    "render_controllers": ["controller.render.cow"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 1}
  },
  "creeper": {
    "identifier": "minecraft:creeper",
    "min_engine_version": "1.8.0",
    "materials": {"default": "creeper", "charged": "charged_creeper"},
    "textures": {
      "default": "textures/entity/creeper/creeper",
      "charged": "textures/entity/creeper/creeper_armor"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 1,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 0.75, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "body",
            "cubes": [
              {"origin": [-4, 6, -2], "size": [8, 12, 4], "uv": [16, 16]}
            ]
          },
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 18, 0],
            "cubes": [{"origin": [-4, 18, -4], "size": [8, 8, 8], "uv": [0, 0]}]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-2, 6, 4],
            "cubes": [{"origin": [-4, 0, 2], "size": [4, 6, 4], "uv": [0, 16]}]
          },
          {
            "name": "leg1",
            "parent": "body",
            "pivot": [2, 6, 4],
            "cubes": [{"origin": [0, 0, 2], "size": [4, 6, 4], "uv": [0, 16]}]
          },
          {
            "name": "leg2",
            "parent": "body",
            "pivot": [-2, 6, -4],
            "cubes": [{"origin": [-4, 0, -6], "size": [4, 6, 4], "uv": [0, 16]}]
          },
          {
            "name": "leg3",
            "parent": "body",
            "pivot": [2, 6, -4],
            "cubes": [{"origin": [0, 0, -6], "size": [4, 6, 4], "uv": [0, 16]}]
          }
        ]
      },
      "charged": {
        "visible_bounds_width": 1,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 0.75, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "body",
            "cubes": [
              {"origin": [-4, 6, -2], "size": [8, 12, 4], "uv": [16, 16]}
            ],
            "inflate": 2
          },
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 18, 0],
            "cubes": [
              {"origin": [-4, 18, -4], "size": [8, 8, 8], "uv": [0, 0]}
            ],
            "inflate": 2
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-2, 6, 4],
            "cubes": [{"origin": [-4, 0, 2], "size": [4, 6, 4], "uv": [0, 16]}],
            "inflate": 2
          },
          {
            "name": "leg1",
            "parent": "body",
            "pivot": [2, 6, 4],
            "cubes": [{"origin": [0, 0, 2], "size": [4, 6, 4], "uv": [0, 16]}],
            "inflate": 2
          },
          {
            "name": "leg2",
            "parent": "body",
            "pivot": [-2, 6, -4],
            "cubes": [
              {"origin": [-4, 0, -6], "size": [4, 6, 4], "uv": [0, 16]}
            ],
            "inflate": 2
          },
          {
            "name": "leg3",
            "parent": "body",
            "pivot": [2, 6, -4],
            "cubes": [{"origin": [0, 0, -6], "size": [4, 6, 4], "uv": [0, 16]}],
            "inflate": 2
          }
        ]
      }
    },
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 6},
    "scripts": {
      "pre_animation": [
        "variable.wobble = Math.sin(query.swell_amount * 5730) * query.swell_amount * 0.01 + 1.0;",
        "variable.swelling_scale1 = (Math.pow(Math.clamp(query.swell_amount, 0.0, 1.0), 4.0) * 0.4 + 1.0) * variable.wobble;",
        "variable.swelling_scale2 = (Math.pow(Math.clamp(query.swell_amount, 0.0, 1.0), 4.0) * 0.1 + 1.0) / variable.wobble;",
        "variable.leg_rot = Math.cos(query.modified_distance_moved * 38.17326) * 80.22 * query.modified_move_speed;",
        "variable.flash = Math.mod(Math.Round(query.swell_amount * 10.0), 2.0);"
      ]
    },
    "animations": {
      "creeper_head": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "creeper_legs": {
        "loop": true,
        "bones": {
          "leg0": {"rotation": ["variable.leg_rot - this", 0, 0]},
          "leg1": {"rotation": ["-variable.leg_rot - this", 0, 0]},
          "leg2": {"rotation": ["-variable.leg_rot - this", 0, 0]},
          "leg3": {"rotation": ["variable.leg_rot - this", 0, 0]}
        }
      },
      "creeper_swelling": {
        "loop": true,
        "bones": {
          "body": {
            "scale": [
              "variable.swelling_scale1",
              "variable.swelling_scale2",
              "variable.swelling_scale1"
            ]
          },
          "head": {
            "scale": [
              "variable.swelling_scale1",
              "variable.swelling_scale2",
              "variable.swelling_scale1"
            ]
          },
          "leg0": {
            "scale": [
              "variable.swelling_scale1",
              "variable.swelling_scale2",
              "variable.swelling_scale1"
            ]
          },
          "leg1": {
            "scale": [
              "variable.swelling_scale1",
              "variable.swelling_scale2",
              "variable.swelling_scale1"
            ]
          },
          "leg2": {
            "scale": [
              "variable.swelling_scale1",
              "variable.swelling_scale2",
              "variable.swelling_scale1"
            ]
          },
          "leg3": {
            "scale": [
              "variable.swelling_scale1",
              "variable.swelling_scale2",
              "variable.swelling_scale1"
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "creeper_head": {
        "initial_state": "default",
        "states": {"default": {"animations": ["creeper_head"]}}
      },
      "creeper_legs": {
        "initial_state": "default",
        "states": {"default": {"animations": ["creeper_legs"]}}
      },
      "creeper_swelling": {
        "initial_state": "default",
        "states": {"default": {"animations": ["creeper_swelling"]}}
      }
    },
    "render_controllers": [
      "controller.render.creeper",
      "controller.render.creeper_armor"
    ]
  },
  "dolphin": {
    "identifier": "minecraft:dolphin",
    "materials": {"default": "dolphin"},
    "textures": {"default": "textures/entity/dolphin"},
    "geometry": {
      "default": {
        "bones": [
          {
            "pivot": [0, 0, -3],
            "locators": {"lead": [0, 0, 0]},
            "cubes": [
              {
                "origin": [-4, 0, -9],
                "size": [8, 7, 6],
                "uv": [0, 0],
                "inflate": 0,
                "mirror": false
              }
            ],
            "name": "head",
            "parent": "body"
          },
          {
            "pivot": [0, 0, -3],
            "cubes": [
              {
                "origin": [-4, 0, -3],
                "size": [8, 7, 13],
                "uv": [0, 13],
                "inflate": 0,
                "mirror": false
              }
            ],
            "name": "body"
          },
          {
            "pivot": [0, 2.5, 11],
            "cubes": [
              {
                "origin": [-2, 0, 10],
                "size": [4, 5, 11],
                "uv": [0, 33],
                "inflate": 0,
                "mirror": false
              }
            ],
            "name": "tail",
            "parent": "body"
          },
          {
            "pivot": [0, 2.5, 20],
            "cubes": [
              {
                "origin": [-5, 2, 19],
                "size": [10, 1, 6],
                "uv": [0, 49],
                "inflate": 0,
                "mirror": false
              }
            ],
            "name": "tail_fin",
            "parent": "tail"
          },
          {
            "pivot": [0, 7, 2],
            "rotation": [-30, 0, 0],
            "cubes": [
              {
                "origin": [-0.5, 6.25, 1],
                "size": [1, 5, 4],
                "uv": [29, 0],
                "inflate": 0,
                "mirror": false
              }
            ],
            "name": "back_fin",
            "parent": "body"
          },
          {
            "pivot": [3, 1, -1],
            "rotation": [0, -25, 20],
            "cubes": [
              {
                "origin": [3, 1, -2.5],
                "size": [8, 1, 4],
                "uv": [40, 0],
                "inflate": 0,
                "mirror": false
              }
            ],
            "name": "left_fin",
            "parent": "body"
          },
          {
            "pivot": [-3, 1, -1],
            "rotation": [0, 25, -20],
            "cubes": [
              {
                "origin": [-11, 1, -2.5],
                "size": [8, 1, 4],
                "uv": [40, 6],
                "inflate": 0,
                "mirror": false
              }
            ],
            "name": "right_fin",
            "parent": "body"
          },
          {
            "pivot": [0, 0, -13],
            "cubes": [
              {
                "origin": [-1, 0, -13],
                "size": [2, 2, 4],
                "uv": [0, 13],
                "inflate": 0,
                "mirror": false
              }
            ],
            "name": "nose",
            "parent": "head"
          }
        ],
        "texturewidth": 64,
        "textureheight": 64
      }
    },
    "animations": {
      "move": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              "query.target_x_rotation - 2.865 + (-2.865 * math.cos(query.life_time * 343.8))",
              "query.target_y_rotation",
              0
            ]
          },
          "left_fin": {
            "rotation": [
              0,
              0,
              "17.19 * math.cos(query.life_time * 229.2) + 22.92"
            ]
          },
          "right_fin": {
            "rotation": [
              0,
              0,
              "-17.19 * math.cos(query.life_time * 229.2) - 22.92"
            ]
          },
          "tail": {
            "rotation": ["-5.73 * math.cos(query.life_time * 343.8)", 0, 0]
          },
          "tail_fin": {
            "rotation": ["-11.46 * math.cos(query.life_time * 343.8)", 0, 0]
          }
        }
      }
    },
    "animation_controllers": {
      "general": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move"]}}
      }
    },
    "render_controllers": ["controller.render.dolphin"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 49}
  },
  "donkey": {
    "identifier": "minecraft:donkey",
    "textures": {
      "base_brown": "textures/entity/horse/horse_brown",
      "base_white": "textures/entity/horse/horse_white",
      "base_chestnut": "textures/entity/horse/horse_chestnut",
      "base_creamy": "textures/entity/horse/horse_creamy",
      "base_black": "textures/entity/horse/horse_black",
      "base_gray": "textures/entity/horse/horse_gray",
      "base_darkbrown": "textures/entity/horse/horse_darkbrown",
      "markings_none": "textures/entity/horse/horse_markings_none",
      "markings_white": "textures/entity/horse/horse_markings_white",
      "markings_whitefield": "textures/entity/horse/horse_markings_whitefield",
      "markings_whitedots": "textures/entity/horse/horse_markings_whitedots",
      "markings_blackdots": "textures/entity/horse/horse_markings_blackdots",
      "mule": "textures/entity/horse/mule",
      "donkey": "textures/entity/horse/donkey",
      "skeleton": "textures/entity/horse/horse_skeleton",
      "zombie": "textures/entity/horse/horse_zombie",
      "armor_none": "textures/entity/horse/armor/horse_armor_none",
      "armor_leather": "textures/entity/horse/armor/horse_armor_leather",
      "armor_iron": "textures/entity/horse/armor/horse_armor_iron",
      "armor_gold": "textures/entity/horse/armor/horse_armor_gold",
      "armor_diamond": "textures/entity/horse/armor/horse_armor_diamond"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 2,
        "visible_bounds_height": 3,
        "visible_bounds_offset": [0, 1, 0],
        "texturewidth": 128,
        "textureheight": 128,
        "bones": [
          {
            "name": "Body",
            "pivot": [0, 13, 9],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5, 11, -10], "size": [10, 10, 24], "uv": [0, 34]}
            ]
          },
          {
            "name": "TailA",
            "pivot": [0, 21, 14],
            "rotation": [-65, 0, 0],
            "cubes": [
              {"origin": [-1, 20, 14], "size": [2, 2, 3], "uv": [44, 0]}
            ]
          },
          {
            "name": "TailB",
            "pivot": [0, 21, 14],
            "rotation": [-65, 0, 0],
            "cubes": [
              {"origin": [-1.5, 19, 17], "size": [3, 4, 7], "uv": [38, 7]}
            ]
          },
          {
            "name": "TailC",
            "pivot": [0, 21, 14],
            "rotation": [-80.34, 0, 0],
            "cubes": [
              {"origin": [-1.5, 21.5, 23], "size": [3, 4, 7], "uv": [24, 3]}
            ]
          },
          {
            "name": "Leg1A",
            "pivot": [4, 15, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.5, 8, 8.5], "size": [4, 9, 5], "uv": [78, 29]}
            ]
          },
          {
            "name": "Leg1B",
            "pivot": [4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2, 3, 9.5], "size": [3, 5, 3], "uv": [78, 43]}
            ]
          },
          {
            "name": "Leg1C",
            "pivot": [4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.5, -0.1, 9], "size": [4, 3, 4], "uv": [78, 51]}
            ]
          },
          {
            "name": "Leg2A",
            "pivot": [-4, 15, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, 8, 8.5], "size": [4, 9, 5], "uv": [96, 29]}
            ]
          },
          {
            "name": "Leg2B",
            "pivot": [-4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5, 3, 9.5], "size": [3, 5, 3], "uv": [96, 43]}
            ]
          },
          {
            "name": "Leg2C",
            "pivot": [-4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, -0.1, 9], "size": [4, 3, 4], "uv": [96, 51]}
            ]
          },
          {
            "name": "Leg3A",
            "pivot": [4, 15, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2.1, 8, -10.1], "size": [3, 8, 4], "uv": [44, 29]}
            ]
          },
          {
            "name": "Leg3B",
            "pivot": [4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2.1, 3, -9.6], "size": [3, 5, 3], "uv": [44, 41]}
            ]
          },
          {
            "name": "Leg3C",
            "pivot": [4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.6, -0.1, -10.1], "size": [4, 3, 4], "uv": [44, 51]}
            ]
          },
          {
            "name": "Leg4A",
            "pivot": [-4, 15, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.1, 8, -10.1], "size": [3, 8, 4], "uv": [60, 29]}
            ]
          },
          {
            "name": "Leg4B",
            "pivot": [-4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.1, 3, -9.6], "size": [3, 5, 3], "uv": [60, 41]}
            ]
          },
          {
            "name": "Leg4C",
            "pivot": [-4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.6, -0.1, -10.1], "size": [4, 3, 4], "uv": [60, 51]}
            ]
          },
          {
            "name": "Head",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.5, 25, -11.5], "size": [5, 5, 7], "uv": [0, 0]}
            ]
          },
          {
            "name": "UMouth",
            "pivot": [0, 20.05, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2, 27.05, -17], "size": [4, 3, 6], "uv": [24, 18]}
            ]
          },
          {
            "name": "LMouth",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2, 25, -16.5], "size": [4, 2, 5], "uv": [24, 27]}
            ]
          },
          {
            "name": "Ear1",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [0.45, 29, -6], "size": [2, 3, 1], "uv": [0, 0]}
            ]
          },
          {
            "name": "Ear2",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.45, 29, -6], "size": [2, 3, 1], "uv": [0, 0]}
            ]
          },
          {
            "name": "MuleEarL",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 15],
            "cubes": [
              {"origin": [-2, 29, -6], "size": [2, 7, 1], "uv": [0, 12]}
            ]
          },
          {
            "name": "MuleEarR",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, -15],
            "cubes": [{"origin": [0, 29, -6], "size": [2, 7, 1], "uv": [0, 12]}]
          },
          {
            "name": "Neck",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.05, 15.8, -12], "size": [4, 14, 8], "uv": [0, 12]}
            ]
          },
          {
            "name": "Bag1",
            "pivot": [-7.5, 21, 10],
            "rotation": [0, 90, 0],
            "cubes": [
              {"origin": [-10.5, 13, 10], "size": [8, 8, 3], "uv": [0, 34]}
            ]
          },
          {
            "name": "Bag2",
            "pivot": [4.5, 21, 10],
            "rotation": [0, 90, 0],
            "cubes": [
              {"origin": [1.5, 13, 10], "size": [8, 8, 3], "uv": [0, 47]}
            ]
          },
          {
            "name": "Saddle",
            "pivot": [0, 22, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5, 21, -1], "size": [10, 1, 8], "uv": [80, 0]}
            ]
          },
          {
            "name": "SaddleB",
            "pivot": [0, 22, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-1.5, 22, -1], "size": [3, 1, 2], "uv": [106, 9]}
            ]
          },
          {
            "name": "SaddleC",
            "pivot": [0, 22, 2],
            "rotation": [0, 0, 0],
            "cubes": [{"origin": [-4, 22, 5], "size": [8, 1, 2], "uv": [80, 9]}]
          },
          {
            "name": "SaddleL2",
            "pivot": [5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [4.5, 13, 1], "size": [1, 2, 2], "uv": [74, 0]}
            ]
          },
          {
            "name": "SaddleL",
            "pivot": [5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [4.5, 15, 1.5], "size": [1, 6, 1], "uv": [70, 0]}
            ]
          },
          {
            "name": "SaddleR2",
            "pivot": [-5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, 13, 1], "size": [1, 2, 2], "uv": [74, 4]}
            ]
          },
          {
            "name": "SaddleR",
            "pivot": [-5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, 15, 1.5], "size": [1, 6, 1], "uv": [80, 0]}
            ]
          },
          {
            "name": "SaddleMouthL",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [1.5, 26, -14], "size": [1, 2, 2], "uv": [74, 13]}
            ]
          },
          {
            "name": "SaddleMouthR",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.5, 26, -14], "size": [1, 2, 2], "uv": [74, 13]}
            ]
          },
          {
            "name": "SaddleMouthLine",
            "pivot": [0, 20, -10],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2.6, 23, -16], "size": [0, 3, 16], "uv": [44, 10]}
            ]
          },
          {
            "name": "SaddleMouthLineR",
            "pivot": [0, 20, -10],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-2.6, 23, -16], "size": [0, 3, 16], "uv": [44, 5]}
            ]
          },
          {
            "name": "Mane",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-1, 15.5, -5], "size": [2, 16, 4], "uv": [58, 0]}
            ]
          },
          {
            "name": "HeadSaddle",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {
                "origin": [-2.5, 25.1, -17],
                "size": [5, 5, 12],
                "uv": [80, 12],
                "inflate": 0.05
              }
            ]
          }
        ]
      }
    },
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 30}
  },
  "dragon_fireball": {
    "identifier": "minecraft:dragon_fireball",
    "materials": {"default": "fireball"},
    "textures": {"default": "textures/entity/enderdragon/dragon_fireball"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-8, -4, 0],
                "size": [16, 16, 0],
                "uv": {"south": {"uv": [0, 0]}}
              }
            ]
          }
        ],
        "texturewidth": 16,
        "textureheight": 16
      }
    },
    "animations": {
      "face_player": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              "query.camera_rotation(0)",
              "query.camera_rotation(1)",
              0
            ]
          }
        }
      }
    },
    "scripts": {"scale": "2.0", "animate": ["face_player"]},
    "render_controllers": ["controller.render.fireball"]
  },
  "drowned": {
    "identifier": "minecraft:drowned",
    "min_engine_version": "1.16.0",
    "materials": {"default": "drowned"},
    "textures": {"default": "textures/entity/zombie/drowned"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 12, -2], "size": [8, 12, 4], "uv": [16, 16]}
            ]
          },
          {
            "name": "jacket",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-4, 12, -2],
                "size": [8, 12, 4],
                "uv": [16, 32],
                "inflate": 0.5
              }
            ]
          },
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-4, 24, -4],
                "size": [8, 8, 8],
                "uv": [0, 0],
                "inflate": 0.5
              }
            ]
          },
          {
            "name": "hat",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-4, 24, -4],
                "size": [8, 8, 8],
                "uv": [32, 0],
                "inflate": 1
              }
            ]
          },
          {
            "name": "rightArm",
            "parent": "body",
            "pivot": [-5, 22, 0],
            "cubes": [
              {"origin": [-7, 12, -2], "size": [4, 12, 4], "uv": [0, 16]}
            ]
          },
          {
            "name": "leftArm",
            "parent": "body",
            "pivot": [5, 22, 0],
            "cubes": [
              {
                "origin": [4, 12, -2],
                "size": [4, 12, 4],
                "uv": [40, 16],
                "mirror": true
              }
            ]
          },
          {
            "name": "rightSleeve",
            "parent": "rightArm",
            "pivot": [-5, 22, 0],
            "cubes": [
              {
                "origin": [-7, 12, -2],
                "size": [4, 12, 4],
                "uv": [48, 48],
                "inflate": 0.5
              }
            ]
          },
          {
            "name": "leftSleeve",
            "parent": "leftArm",
            "pivot": [5, 22, 0],
            "cubes": [
              {
                "origin": [4, 12, -2],
                "size": [4, 12, 4],
                "uv": [40, 32],
                "inflate": 0.5,
                "mirror": true
              }
            ]
          },
          {
            "name": "rightLeg",
            "parent": "body",
            "pivot": [-1.9, 12, 0],
            "cubes": [
              {"origin": [-4.05, 0, -2], "size": [4, 12, 4], "uv": [16, 48]}
            ]
          },
          {
            "name": "leftLeg",
            "parent": "body",
            "pivot": [1.9, 12, 0],
            "cubes": [
              {
                "origin": [0.05, 0, -2],
                "size": [4, 12, 4],
                "uv": [32, 48],
                "mirror": true
              }
            ]
          },
          {
            "name": "rightPants",
            "parent": "rightLeg",
            "pivot": [-1.9, 12, 0],
            "cubes": [
              {
                "origin": [-4.25, 0, -2],
                "size": [4, 12, 4],
                "uv": [0, 48],
                "inflate": 0.25
              }
            ]
          },
          {
            "name": "leftPants",
            "parent": "leftLeg",
            "pivot": [1.9, 12, 0],
            "cubes": [
              {
                "origin": [0.25, 0, -2],
                "size": [4, 12, 4],
                "uv": [0, 32],
                "inflate": 0.25,
                "mirror": true
              }
            ]
          },
          {"name": "waist", "parent": "body", "pivot": [0, 12, 0]},
          {"name": "rightItem", "parent": "rightArm", "pivot": [-6, 15, 1]},
          {"name": "leftItem", "parent": "leftArm", "pivot": [6, 15, 1]}
        ],
        "visible_bounds_width": 2.5,
        "visible_bounds_height": 2.5,
        "visible_bounds_offset": [0, 1.25, 0],
        "texturewidth": 64,
        "textureheight": 64
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.tcos0 = (Math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed / variable.gliding_speed_value) * 57.3;"
      ],
      "animate": [
        {"humanoid_big_head": "query.is_baby"},
        "look_at_target",
        "move",
        {"riding.arms": "query.is_riding"},
        {"riding.legs": "query.is_riding"},
        "holding",
        {"charging": "query.is_charging"},
        "attack_controller",
        {"brandish_spear": "variable.is_brandishing_spear"},
        "bob",
        {"damage_nearby_mobs": "variable.damage_nearby_mobs"},
        {
          "use_item_progress": "( variable.use_item_interval_progress > 0.0 ) || ( variable.use_item_startup_progress > 0.0 )"
        },
        {"swimming": "variable.swim_amount > 0.0"}
      ]
    },
    "animations": {
      "humanoid_big_head": {"loop": true, "bones": {"head": {"scale": 1.4}}},
      "look_at_target_default": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_gliding": {
        "loop": true,
        "bones": {"head": {"rotation": [-45, "query.target_y_rotation", 0]}}
      },
      "look_at_target_swimming": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "math.lerp(query.target_x_rotation, -45.0, variable.swim_amount)",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "move": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["variable.tcos0", 0, 0]},
          "leftleg": {"rotation": ["variable.tcos0 * -1.4", 0, 0]},
          "rightarm": {"rotation": ["-variable.tcos0", 0, 0]},
          "rightleg": {"rotation": ["variable.tcos0 * 1.4", 0, 0]}
        }
      },
      "riding.arms": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": [-36, 0, 0]},
          "rightarm": {"rotation": [-36, 0, 0]}
        }
      },
      "riding.legs": {
        "loop": true,
        "bones": {
          "leftleg": {"rotation": ["-72.0 - this", "-18.0 - this", "-this"]},
          "rightleg": {"rotation": ["-72.0 - this", "18.0 - this", "-this"]}
        }
      },
      "holding": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "variable.is_holding_left ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "variable.is_holding_right ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          }
        }
      },
      "brandish_spear": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "this * -0.5 - 157.5 - 22.5 * variable.charge_amount",
              "-this",
              0
            ]
          }
        }
      },
      "charging": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": ["22.5 * variable.charge_amount - this", "-this", 0]
          }
        }
      },
      "bob": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              0,
              0,
              "((math.cos(query.life_time * 103.2) * 2.865) + 2.865) *-1.0"
            ]
          },
          "rightarm": {
            "rotation": [
              0,
              0,
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "damage_nearby_mobs": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["-45.0-this", "-this", "-this"]},
          "leftleg": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightarm": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-45.0-this", "-this", "-this"]}
        }
      },
      "use_item_progress": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "variable.use_item_startup_progress * -60.0 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -22.5 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -5.625 + variable.use_item_interval_progress * 11.25"
            ]
          }
        }
      },
      "zombie_attack_bare_hand": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "-90.0 - ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4) - (math.sin(query.life_time * 76.776372) * 2.865) - this",
              "5.73 - ((math.sin(variable.attack_time * 180.0) * 57.3) * 0.6) - this",
              "math.cos(query.life_time * 103.13244) * -2.865 - 2.865 - this"
            ]
          },
          "rightarm": {
            "rotation": [
              "90.0 * (variable.is_brandishing_spear - 1.0) - ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4) + (math.sin(query.life_time * 76.776372) * 2.865) - this",
              "(math.sin(variable.attack_time * 180.0) * 57.3) * 0.6 - 5.73 - this",
              "math.cos(query.life_time * 103.13244) * 2.865 + 2.865 - this"
            ]
          }
        }
      },
      "attack_rotations": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              0,
              "math.sin(math.sqrt(variable.attack_time) * 360.0) * 11.46",
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "-(math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) + math.sin(variable.attack_time * 180.0)) * 5.73",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "-(math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 34.38 + math.sin(variable.attack_time * 180.0) * 28.65) * (variable.is_brandishing_spear ? -2.5 : 1.0 )",
              "variable.is_brandishing_spear ? 0.0 : math.sin(math.sqrt(variable.attack_time) * 360.0) * 22.92",
              0
            ]
          }
        }
      },
      "swimming": {
        "loop": true,
        "bones": {
          "body": {
            "position": [
              0,
              "variable.swim_amount * -10.0 - this",
              "variable.swim_amount * 9.0 - this"
            ],
            "rotation": [
              "variable.swim_amount * (90.0 + query.target_x_rotation)",
              0,
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.lerp(this, -180.0, variable.swim_amount) - (variable.swim_amount * ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4)) - (variable.swim_amount * (math.sin(query.life_time * 76.776372) * 2.865)) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - (variable.swim_amount * (math.cos(query.life_time * 103.13244) * 2.865 + 2.865)) - this"
            ]
          },
          "leftleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.life_time * 390.0 + 180.0) * 0.3, variable.swim_amount)",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.lerp(this, -180.0, variable.swim_amount) - (variable.swim_amount * ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4)) + (variable.swim_amount * (math.sin(query.life_time * 76.776372) * 2.865)) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - this",
              "math.lerp(this, -14.325, variable.swim_amount) + (variable.swim_amount * (math.cos(query.life_time * 103.13244) * 2.865 + 2.865)) - this"
            ]
          },
          "rightleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.life_time * 390.0) * 0.3, variable.swim_amount)",
              0,
              0
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.drowned"],
    "enable_attachables": true,
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 48},
    "animation_controllers": {
      "look_at_target": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["look_at_target_default"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"swimming": "query.is_swimming"}
            ]
          },
          "gliding": {
            "animations": ["look_at_target_gliding"],
            "transitions": [
              {"swimming": "query.is_swimming"},
              {"default": "!query.is_gliding"}
            ]
          },
          "swimming": {
            "animations": ["look_at_target_swimming"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"default": "!query.is_swimming"}
            ]
          }
        }
      },
      "attack_controller": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["zombie_attack_bare_hand"],
            "transitions": [
              {"one_hand_attack": "query.is_item_equipped('off_hand')"},
              {
                "spear_attack": "variable.is_brandishing_spear && !query.is_item_equipped('off_hand')"
              }
            ]
          },
          "one_hand_attack": {
            "animations": ["attack_rotations"],
            "transitions": [
              {"default": "!query.is_item_equipped('off_hand')"},
              {
                "spear_attack": "variable.is_brandishing_spear && !query.is_item_equipped('off_hand')"
              }
            ]
          },
          "spear_attack": {
            "animations": ["zombie_attack_bare_hand", "attack_rotations"],
            "transitions": [
              {
                "default": "!query.is_item_equipped('off_hand') && !variable.is_brandishing_spear"
              },
              {"one_hand_attack": "query.is_item_equipped('off_hand')"}
            ]
          }
        }
      }
    }
  },
  "egg": {
    "identifier": "minecraft:egg",
    "materials": {"default": "egg"},
    "textures": {"default": "textures/items/egg"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-8, -8, 0],
                "size": [16, 16, 0],
                "uv": [0, 0],
                "rotation": [0, 0, 0]
              }
            ]
          }
        ],
        "texturewidth": 16,
        "textureheight": 16
      }
    },
    "render_controllers": ["controller.render.item_sprite"],
    "animations": {
      "flying": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              "query.camera_rotation(0)",
              "query.camera_rotation(1)",
              0
            ]
          }
        }
      }
    },
    "scripts": {"animate": ["flying"]}
  },
  "elder_guardian": {
    "identifier": "minecraft:elder_guardian",
    "min_engine_version": "1.8.0",
    "materials": {"default": "guardian", "ghost": "guardian_ghost"},
    "textures": {
      "default": "textures/entity/guardian",
      "elder": "textures/entity/guardian_elder",
      "beam": "textures/entity/guardian_beam"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 3.5,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 64,
        "bones": [
          {
            "name": "head",
            "pivot": [0, 0, 0],
            "mirror": true,
            "cubes": [
              {
                "mirror": false,
                "origin": [-6, 2, -8],
                "size": [12, 12, 16],
                "uv": [0, 0]
              },
              {
                "mirror": false,
                "origin": [-8, 2, -6],
                "size": [2, 12, 12],
                "uv": [0, 28]
              },
              {"origin": [6, 2, -6], "size": [2, 12, 12], "uv": [0, 28]},
              {"origin": [-6, 14, -6], "size": [12, 2, 12], "uv": [16, 40]},
              {"origin": [-6, 0, -6], "size": [12, 2, 12], "uv": [16, 40]}
            ]
          },
          {
            "name": "eye",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-1, 6, 0], "size": [2, 2, 1], "uv": [8, 0]}]
          },
          {
            "name": "tailpart0",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-2, 6, 7], "size": [4, 4, 8], "uv": [40, 0]}]
          },
          {
            "name": "tailpart1",
            "parent": "tailpart0",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 7, 0], "size": [3, 3, 7], "uv": [0, 54]}]
          },
          {
            "name": "tailpart2",
            "parent": "tailpart1",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [0, 8, 0], "size": [2, 2, 6], "uv": [41, 32]},
              {"origin": [1, 4.5, 3], "size": [1, 9, 9], "uv": [25, 19]}
            ]
          },
          {
            "name": "spikepart0",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart1",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart2",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart3",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart4",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart5",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart6",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart7",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart8",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart9",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart10",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart11",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          }
        ]
      },
      "ghost": {
        "visible_bounds_width": 3.5,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 64,
        "bones": [
          {
            "name": "head",
            "pivot": [0, 24, 0],
            "mirror": true,
            "cubes": [
              {
                "mirror": false,
                "origin": [-6, 2, -8],
                "size": [12, 12, 16],
                "uv": [0, 0]
              },
              {
                "mirror": false,
                "origin": [-8, 2, -6],
                "size": [2, 12, 12],
                "uv": [0, 28]
              },
              {"origin": [6, 2, -6], "size": [2, 12, 12], "uv": [0, 28]},
              {"origin": [-6, 14, -6], "size": [12, 2, 12], "uv": [16, 40]},
              {"origin": [-6, 0, -6], "size": [12, 2, 12], "uv": [16, 40]}
            ]
          },
          {
            "name": "eye",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-1, 7, 0], "size": [2, 2, 1], "uv": [8, 0]}]
          },
          {
            "name": "tailpart0",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-2, 6, 7], "size": [4, 4, 8], "uv": [40, 0]}]
          },
          {
            "name": "tailpart1",
            "parent": "tailpart0",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 7, 0], "size": [3, 3, 7], "uv": [0, 54]}]
          },
          {
            "name": "tailpart2",
            "parent": "tailpart1",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [0, 8, 0], "size": [2, 2, 6], "uv": [41, 32]},
              {"origin": [1, 4.5, 3], "size": [1, 9, 9], "uv": [25, 19]}
            ]
          },
          {
            "name": "spikepart0",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart1",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart2",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart3",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart4",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart5",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart6",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart7",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart8",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart9",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart10",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart11",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          }
        ]
      }
    },
    "animations": {
      "setup": {
        "loop": true,
        "bones": {
          "spikepart0": {
            "rotation": ["-45.0 - this", "0.0 - this", "0.0 - this"]
          },
          "spikepart1": {
            "rotation": ["45.0 - this", "0.0 - this", "0.0 - this"]
          },
          "spikepart10": {
            "rotation": ["0.0 - this", "0.0 - this", " 135.0 - this"]
          },
          "spikepart11": {
            "rotation": ["0.0 - this", "0.0 - this", " -135.0 - this"]
          },
          "spikepart2": {
            "rotation": ["0.0 - this", "0.0 - this", "45.0 - this"]
          },
          "spikepart3": {
            "rotation": ["0.0 - this", "0.0  - this", "-45.0 - this"]
          },
          "spikepart4": {
            "rotation": ["90.0 - this", "45.0 - this", " 0.0 - this"]
          },
          "spikepart5": {
            "rotation": ["90.0 - this", "-45.0 - this", " 0.0 - this"]
          },
          "spikepart6": {
            "rotation": ["90.0 - this", "-135.0 - this", "0.0 - this"]
          },
          "spikepart7": {
            "rotation": ["90.0 - this", "135.0 - this", " 0.0 - this"]
          },
          "spikepart8": {
            "rotation": ["-135.0 - this", "0.0 - this", " 0.0 - this"]
          },
          "spikepart9": {
            "rotation": ["135.0 - this", "0.0 - this", " 0.0 - this"]
          }
        }
      },
      "spikes": {
        "loop": true,
        "bones": {
          "spikepart0": {
            "position": [
              "-this",
              "24 + -8 * (1 + math.cos(query.life_time * 20 * 1.5) * 0.01 + (variable.spike_extension + variable.spike_shake)) - this",
              "8 * (1 + math.cos(query.life_time * 20 * 1.5) * 0.01 - (variable.spike_extension + variable.spike_shake)) - this"
            ]
          },
          "spikepart1": {
            "position": [
              "-this",
              "24 + -8 * (1 + math.cos(query.life_time * 20 * 1.5 + 1) * 0.01 + (variable.spike_extension + variable.spike_shake)) - this",
              "-8 * (1 + math.cos(query.life_time * 20 * 1.5 + 1) * 0.01 - (variable.spike_extension + variable.spike_shake)) - this"
            ]
          },
          "spikepart10": {
            "position": [
              "8 * (1 + math.cos(query.life_time * 20 * 1.5 + 10) * 0.01 - (variable.spike_extension + variable.spike_shake)) - this",
              "-8 + 8 * (1 + math.cos(query.life_time * 20 * 1.5 + 10) * 0.01 + (variable.spike_extension + variable.spike_shake)) - this",
              "-this"
            ]
          },
          "spikepart11": {
            "position": [
              "-8 * (1 + math.cos(query.life_time * 20 * 1.5 + 11) * 0.01 - (variable.spike_extension + variable.spike_shake)) - this",
              "-8 + 8 * (1 + math.cos(query.life_time * 20 * 1.5 + 11) * 0.01 + (variable.spike_extension + variable.spike_shake)) - this",
              "-this"
            ]
          },
          "spikepart2": {
            "position": [
              "8 * (1 + math.cos(query.life_time * 20 * 1.5 + 2) * 0.01 - (variable.spike_extension + variable.spike_shake)) - this",
              "24 + -8 * (1 + math.cos(query.life_time * 20 * 1.5 + 2) * 0.01 + (variable.spike_extension + variable.spike_shake)) - this",
              "-this"
            ]
          },
          "spikepart3": {
            "position": [
              "-8 * (1 + math.cos(query.life_time * 20 * 1.5 + 3) * 0.01 - (variable.spike_extension + variable.spike_shake)) - this",
              "24 + -8 * (1 + math.cos(query.life_time * 20 * 1.5 + 3) * 0.01 + (variable.spike_extension + variable.spike_shake)) - this",
              0
            ]
          },
          "spikepart4": {
            "position": [
              "-8 * (1 + math.cos(query.life_time * 20 * 1.5 + 4) * 0.01 - (variable.spike_extension - variable.spike_shake)) - this",
              "8 - this",
              "-8 * (1 + math.cos(query.life_time * 20 * 1.5 + 4) * 0.01 - (variable.spike_extension - variable.spike_shake)) - this"
            ]
          },
          "spikepart5": {
            "position": [
              "8 * (1 + math.cos(query.life_time * 20 * 1.5 + 5) * 0.01 - (variable.spike_extension - variable.spike_shake)) - this",
              "8 - this",
              "-8 * (1 + math.cos(query.life_time * 20 * 1.5 + 5) * 0.01 - (variable.spike_extension - variable.spike_shake)) - this"
            ]
          },
          "spikepart6": {
            "position": [
              "8 * (1 + math.cos(query.life_time * 20 * 1.5 + 6) * 0.01 - (variable.spike_extension - variable.spike_shake)) - this",
              "8 - this",
              "8 * (1 + math.cos(query.life_time * 20 * 1.5 + 6) * 0.01 - (variable.spike_extension - variable.spike_shake)) - this"
            ]
          },
          "spikepart7": {
            "position": [
              "-8 * (1 + math.cos(query.life_time * 20 * 1.5 + 7) * 0.01 - (variable.spike_extension - variable.spike_shake)) - this",
              "8 - this",
              "8 * (1 + math.cos(query.life_time * 20 * 1.5 + 7) * 0.01 - (variable.spike_extension - variable.spike_shake)) - this"
            ]
          },
          "spikepart8": {
            "position": [
              "-this",
              "-8  + 8 * (1 + math.cos(query.life_time * 20 * 1.5 + 8) * 0.01 + (variable.spike_extension + variable.spike_shake)) - this",
              "8 * (1 + math.cos(query.life_time * 20 * 1.5 + 8) * 0.01 - (variable.spike_extension + variable.spike_shake)) - this"
            ]
          },
          "spikepart9": {
            "position": [
              "-this",
              "-8 + 8 * (1 + math.cos(query.life_time * 20 * 1.5 + 9) * 0.01 + (variable.spike_extension + variable.spike_shake)) - this",
              "-8 * (1 + math.cos(query.life_time * 20 * 1.5 + 9) * 0.01 - (variable.spike_extension + variable.spike_shake)) - this"
            ]
          }
        }
      },
      "swim": {
        "loop": true,
        "bones": {
          "tailpart0": {
            "rotation": [0, "variable.tail_base_angle * 11.6 - this", 0]
          },
          "tailpart1": {
            "position": ["-1.5 - this", "-0.5 - this", "14.0 - this"],
            "rotation": [0, "variable.tail_base_angle * 22.8 - this", 0]
          },
          "tailpart2": {
            "position": ["0.5 - this", "-0.5 - this", "6.0 - this"],
            "rotation": [0, "variable.tail_base_angle * 34.4 - this", 0]
          }
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "move_eye": {
        "loop": true,
        "bones": {
          "eye": {
            "position": [
              "query.eye_target_x_rotation - this",
              "24 + query.eye_target_y_rotation - this",
              "-8.25 - this"
            ]
          }
        }
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.spike_shake = Math.sin(query.life_time * 2000)/50;",
        "variable.spike_animation_speed = query.life_time < 0.1 ? 0.0 : (!query.is_in_water ? (Math.round(Math.sin(query.life_time * 2000)) == 0.0 ? (Math.random(0.0, 1.0)) : (variable.spike_animation_speed)) : (query.is_moving ? (variable.spike_animation_speed - variable.spike_animation_speed * 0.06) : (variable.spike_animation_speed + (1.0 - variable.spike_animation_speed) * 0.06)));",
        "variable.spike_extension = (1.0 - variable.spike_animation_speed) * 0.55;",
        "variable.tail_animation_speed = query.life_time < 0.1 ? 0.0 : (!query.is_in_water ? 2.0 : query.is_moving ? (variable.tail_animation_speed < 0.5 ? 4.0 : variable.tail_animation_speed + (0.5 - variable.tail_animation_speed) * 0.1) : variable.tail_animation_speed + (0.125 - variable.tail_animation_speed) * 0.2);",
        "variable.tail_swim = query.life_time < 0.1 ? 0.0 : (variable.tail_swim + variable.tail_animation_speed);",
        "variable.tail_base_angle = Math.sin(variable.tail_swim*20.0);"
      ],
      "scale": "2.35",
      "animate": ["setup", "spikes", "swim", "look_at_target", "move_eye"]
    },
    "render_controllers": ["controller.render.guardian"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 36}
  },
  "ender_dragon": {
    "identifier": "minecraft:ender_dragon",
    "textures": {
      "default": "textures/entity/enderdragon/dragon",
      "exploding": "textures/entity/enderdragon/dragon_exploding",
      "beam": "textures/entity/end_crystal/end_crystal_beam"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 14,
        "visible_bounds_height": 13,
        "visible_bounds_offset": [0, 2, 0],
        "texturewidth": 256,
        "textureheight": 256,
        "bones": [
          {
            "name": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-6, 20, -24], "size": [12, 5, 16], "uv": [176, 44]},
              {"origin": [-8, 16, -10], "size": [16, 16, 16], "uv": [112, 30]},
              {
                "mirror": true,
                "origin": [-5, 32, -4],
                "size": [2, 4, 6],
                "uv": [0, 0]
              },
              {
                "mirror": true,
                "origin": [-5, 25, -22],
                "size": [2, 2, 4],
                "uv": [112, 0]
              },
              {"origin": [3, 32, -4], "size": [2, 4, 6], "uv": [0, 0]},
              {"origin": [3, 25, -22], "size": [2, 2, 4], "uv": [112, 0]}
            ]
          },
          {
            "name": "jaw",
            "pivot": [0, 20, -8],
            "cubes": [
              {"origin": [-6, 16, -24], "size": [12, 4, 16], "uv": [176, 65]}
            ]
          },
          {
            "name": "neck",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-5, 19, -5], "size": [10, 10, 10], "uv": [192, 104]},
              {"origin": [-1, 29, -3], "size": [2, 4, 6], "uv": [48, 0]}
            ]
          },
          {
            "name": "body",
            "pivot": [0, 20, 8],
            "cubes": [
              {"origin": [-12, -4, -8], "size": [24, 24, 64], "uv": [0, 0]},
              {"origin": [-1, 20, -2], "size": [2, 6, 12], "uv": [220, 53]},
              {"origin": [-1, 20, 18], "size": [2, 6, 12], "uv": [220, 53]},
              {"origin": [-1, 20, 38], "size": [2, 6, 12], "uv": [220, 53]}
            ]
          },
          {
            "name": "wing",
            "pivot": [-12, 19, 2],
            "cubes": [
              {"origin": [-68, 15, -2], "size": [56, 8, 8], "uv": [112, 88]},
              {"origin": [-68, 19, 4], "size": [56, 0, 56], "uv": [-56, 88]}
            ]
          },
          {
            "name": "wingtip",
            "pivot": [-56, 24, 0],
            "cubes": [
              {"origin": [-112, 22, -2], "size": [56, 4, 4], "uv": [112, 136]},
              {"origin": [-112, 24, 2], "size": [56, 0, 56], "uv": [-56, 144]}
            ]
          },
          {
            "name": "wing1",
            "pivot": [12, 19, 2],
            "cubes": [
              {"origin": [-44, 15, -2], "size": [56, 8, 8], "uv": [112, 88]},
              {"origin": [-44, 19, 4], "size": [56, 0, 56], "uv": [-56, 88]}
            ]
          },
          {
            "name": "wingtip1",
            "pivot": [-56, 24, 0],
            "cubes": [
              {"origin": [-112, 22, -2], "size": [56, 4, 4], "uv": [112, 136]},
              {"origin": [-112, 24, 2], "size": [56, 0, 56], "uv": [-56, 144]}
            ]
          },
          {
            "name": "rearleg",
            "pivot": [-16, 8, 42],
            "cubes": [
              {"origin": [-24, -20, 34], "size": [16, 32, 16], "uv": [0, 0]}
            ]
          },
          {
            "name": "rearleg1",
            "pivot": [16, 8, 42],
            "cubes": [
              {"origin": [8, -20, 34], "size": [16, 32, 16], "uv": [0, 0]}
            ]
          },
          {
            "name": "frontleg",
            "pivot": [-12, 4, 2],
            "cubes": [
              {"origin": [-16, -16, -2], "size": [8, 24, 8], "uv": [112, 104]}
            ]
          },
          {
            "name": "frontleg1",
            "pivot": [12, 4, 2],
            "cubes": [
              {"origin": [8, -16, -2], "size": [8, 24, 8], "uv": [112, 104]}
            ]
          },
          {
            "name": "rearlegtip",
            "pivot": [0, -8, -4],
            "cubes": [
              {"origin": [-6, -38, -4], "size": [12, 32, 12], "uv": [196, 0]}
            ]
          },
          {
            "name": "rearlegtip1",
            "pivot": [0, -8, -4],
            "cubes": [
              {"origin": [-6, -38, -4], "size": [12, 32, 12], "uv": [196, 0]}
            ]
          },
          {
            "name": "frontlegtip",
            "pivot": [0, 4, -1],
            "cubes": [
              {"origin": [-3, -19, -4], "size": [6, 24, 6], "uv": [226, 138]}
            ]
          },
          {
            "name": "frontlegtip1",
            "pivot": [0, 4, -1],
            "cubes": [
              {"origin": [-3, -19, -4], "size": [6, 24, 6], "uv": [226, 138]}
            ]
          },
          {
            "name": "rearfoot",
            "pivot": [0, -7, 4],
            "cubes": [
              {"origin": [-9, -13, -16], "size": [18, 6, 24], "uv": [112, 0]}
            ]
          },
          {
            "name": "rearfoot1",
            "pivot": [0, -7, 4],
            "cubes": [
              {"origin": [-9, -13, -16], "size": [18, 6, 24], "uv": [112, 0]}
            ]
          },
          {
            "name": "frontfoot",
            "pivot": [0, 1, 0],
            "cubes": [
              {"origin": [-4, -3, -12], "size": [8, 4, 16], "uv": [144, 104]}
            ]
          },
          {
            "name": "frontfoot1",
            "pivot": [0, 1, 0],
            "cubes": [
              {"origin": [-4, -3, -12], "size": [8, 4, 16], "uv": [144, 104]}
            ]
          }
        ]
      }
    }
  },
  "eye_of_ender": {
    "identifier": "minecraft:eye_of_ender_signal",
    "materials": {"default": "eye_of_ender_signal"},
    "textures": {"default": "textures/items/ender_eye"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-8, -8, 0],
                "size": [16, 16, 0],
                "uv": [0, 0],
                "rotation": [0, 0, 0]
              }
            ]
          }
        ],
        "texturewidth": 16,
        "textureheight": 16
      }
    },
    "render_controllers": ["controller.render.item_sprite"],
    "animations": {
      "flying": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              "query.camera_rotation(0)",
              "query.camera_rotation(1)",
              0
            ]
          }
        }
      }
    },
    "scripts": {"animate": ["flying"]}
  },
  "ender_pearl": {
    "identifier": "minecraft:ender_pearl",
    "materials": {"default": "ender_pearl"},
    "textures": {"default": "textures/items/ender_pearl"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-8, -8, 0],
                "size": [16, 16, 0],
                "uv": [0, 0],
                "rotation": [0, 0, 0]
              }
            ]
          }
        ],
        "texturewidth": 16,
        "textureheight": 16
      }
    },
    "render_controllers": ["controller.render.item_sprite"],
    "animations": {
      "flying": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              "query.camera_rotation(0)",
              "query.camera_rotation(1)",
              0
            ]
          }
        }
      }
    },
    "scripts": {"animate": ["flying"]}
  },
  "enderman": {
    "identifier": "minecraft:enderman",
    "min_engine_version": "1.8.0",
    "materials": {"default": "enderman", "invisible": "enderman_invisible"},
    "textures": {"default": "textures/entity/enderman/enderman"},
    "geometry": {
      "default": {
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 3,
        "visible_bounds_offset": [0, 1.5, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "hat",
            "parent": "head",
            "reset": true,
            "pivot": [0, 38, 0],
            "cubes": [
              {
                "origin": [-4, 37.5, -4],
                "size": [8, 8, 8],
                "uv": [0, 16],
                "inflate": -0.5
              }
            ],
            "neverRender": false
          },
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-4, 24, -4], "size": [8, 8, 8], "uv": [0, 0]}]
          },
          {
            "name": "body",
            "reset": true,
            "pivot": [0, 38, 0],
            "cubes": [
              {"origin": [-4, 26, -2], "size": [8, 12, 4], "uv": [32, 16]}
            ]
          },
          {
            "name": "rightArm",
            "parent": "body",
            "reset": true,
            "pivot": [-3, 36, 0],
            "cubes": [
              {"origin": [-4, 8, -1], "size": [2, 30, 2], "uv": [56, 0]}
            ]
          },
          {
            "name": "rightItem",
            "pivot": [-6, 15, 1],
            "neverRender": true,
            "parent": "rightArm"
          },
          {
            "name": "leftArm",
            "parent": "body",
            "reset": true,
            "mirror": true,
            "pivot": [5, 36, 0],
            "cubes": [{"origin": [4, 8, -1], "size": [2, 30, 2], "uv": [56, 0]}]
          },
          {
            "name": "rightLeg",
            "parent": "body",
            "reset": true,
            "pivot": [-2, 26, 0],
            "cubes": [
              {"origin": [-3, -4, -1], "size": [2, 30, 2], "uv": [56, 0]}
            ]
          },
          {
            "name": "leftLeg",
            "parent": "body",
            "reset": true,
            "mirror": true,
            "pivot": [2, 26, 0],
            "cubes": [
              {"origin": [1, -4, -1], "size": [2, 30, 2], "uv": [56, 0]}
            ]
          }
        ]
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.tcos0 = (Math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed / variable.gliding_speed_value) * 28.65;"
      ]
    },
    "animations": {
      "look_at_target_default": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_gliding": {
        "loop": true,
        "bones": {"head": {"rotation": [-45, "query.target_y_rotation", 0]}}
      },
      "look_at_target_swimming": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "math.lerp(query.target_x_rotation, -45.0, variable.swim_amount)",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "move": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["variable.tcos0", 0, 0]},
          "leftleg": {"rotation": ["variable.tcos0 * -1.4", 0, 0]},
          "rightarm": {"rotation": ["-variable.tcos0", 0, 0]},
          "rightleg": {"rotation": ["variable.tcos0 * 1.4", 0, 0]}
        }
      },
      "attack.rotations": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              0,
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46 - this",
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.sin(1.0 - math.pow(1.0 - variable.attack_time, 3.0) * 180.0) * (variable.is_brandishing_spear ? -1.0 : 1.0 )",
              "variable.is_brandishing_spear ? 0.0 : (math.sin(math.sqrt(variable.attack_time) * 360) * 11.46) * 2.0",
              0
            ]
          }
        }
      },
      "bob": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              0,
              0,
              "((math.cos(query.life_time * 103.2) * 2.865) + 2.865) *-1.0"
            ]
          },
          "rightarm": {
            "rotation": [
              0,
              0,
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "base_pose": {
        "loop": true,
        "bones": {
          "body": {
            "position": ["-this", "11.0 - this", "-this"],
            "rotation": ["-this", 0, 0]
          },
          "hat": {
            "position": ["-this", "-this", "-this"],
            "rotation": ["-this", "-this", "-this"]
          },
          "head": {"position": ["-this", "-this", "-this"]},
          "leftarm": {"position": ["4.0-this", 0, "-this"]},
          "leftleg": {"position": [0, "-5.0 - this", 0]},
          "rightarm": {"position": ["-4.0-this", 0, "-this"]},
          "rightleg": {"position": [0, "-5.0 - this", 0]}
        }
      },
      "arms_legs": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": ["math.clamp(this, -22.92, 22.92) - this", 0, 0]
          },
          "leftleg": {
            "rotation": ["math.clamp(this, -22.92, 22.92) - this", 0, 0]
          },
          "rightarm": {
            "rotation": ["math.clamp(this, -22.92, 22.92) - this", 0, 0]
          },
          "rightleg": {
            "rotation": ["math.clamp(this, -22.92, 22.92) - this", 0, 0]
          }
        }
      },
      "carrying": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["-28.65 - this", 0, "-2.87 - this"]},
          "rightarm": {"rotation": ["-28.65 - this", 0, "2.87 - this"]}
        }
      },
      "scary_face": {
        "loop": true,
        "bones": {
          "hat": {"position": [0, "-5.0 - this", 0]},
          "head": {"position": [0, "5.0 - this", 0]}
        }
      }
    },
    "animation_controllers": {
      "look_at_target": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["look_at_target_default"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"swimming": "query.is_swimming"}
            ]
          },
          "gliding": {
            "animations": ["look_at_target_gliding"],
            "transitions": [
              {"swimming": "query.is_swimming"},
              {"default": "!query.is_gliding"}
            ]
          },
          "swimming": {
            "animations": ["look_at_target_swimming"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"default": "!query.is_swimming"}
            ]
          }
        }
      },
      "move": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move"]}}
      },
      "attack": {
        "initial_state": "default",
        "states": {
          "attacking": {
            "animations": ["attack.rotations"],
            "transitions": [{"default": "variable.attack_time < 0.0"}]
          },
          "default": {
            "transitions": [{"attacking": "variable.attack_time >= 0.0"}]
          }
        }
      },
      "bob": {
        "initial_state": "default",
        "states": {"default": {"animations": ["bob"]}}
      },
      "base_pose": {
        "initial_state": "default",
        "states": {"default": {"animations": ["base_pose", "arms_legs"]}}
      },
      "carrying": {
        "initial_state": "default",
        "states": {
          "carry": {
            "animations": ["carrying"],
            "transitions": [{"default": "!query.is_carrying_block"}]
          },
          "default": {"transitions": [{"carry": "query.is_carrying_block"}]}
        }
      },
      "scary_face": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"scary": "query.is_angry"}]},
          "scary": {
            "animations": ["scary_face"],
            "transitions": [{"default": "!query.is_angry"}]
          }
        }
      }
    },
    "render_controllers": ["controller.render.enderman"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 7}
  },
  "endermite": {
    "identifier": "minecraft:endermite",
    "materials": {"default": "endermite"},
    "textures": {"default": "textures/entity/endermite"},
    "geometry": {
      "default": {
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "section_0",
            "parent": "section_2",
            "pivot": [0, 0, 0],
            "cubes": [
              {"origin": [-2, 0, -4.4], "size": [4, 3, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "section_1",
            "parent": "section_2",
            "pivot": [0, 0, 0],
            "cubes": [
              {"origin": [-3, 0, -2.4], "size": [6, 4, 5], "uv": [0, 5]}
            ]
          },
          {
            "name": "section_2",
            "pivot": [0, 0, 2.5],
            "cubes": [
              {"origin": [-1.5, 0, 2.5], "size": [3, 3, 1], "uv": [0, 14]}
            ]
          },
          {
            "name": "section_3",
            "parent": "section_2",
            "pivot": [0, 0, 0],
            "cubes": [
              {"origin": [-0.5, 0, 3.5], "size": [1, 2, 1], "uv": [0, 18]}
            ]
          }
        ]
      }
    },
    "animations": {
      "move": {
        "loop": true,
        "bones": {
          "section_0": {
            "position": [
              "(math.cos(-query.life_time * 928.26) * 1.26) - this",
              0,
              0
            ],
            "rotation": [
              0,
              "(math.cos(query.life_time * 928.26) * 26.9) - this",
              0
            ]
          },
          "section_1": {
            "position": [
              "(math.sin(query.life_time * 928.26) * 0.63) - this",
              0,
              0
            ],
            "rotation": [
              0,
              "(math.cos(query.life_time * 928.26) * 16) - this",
              0
            ]
          },
          "section_2": {
            "position": [0, 0, 0],
            "rotation": [
              0,
              "(math.cos(query.life_time * 928.26) * 9.17) - this",
              0
            ]
          },
          "section_3": {
            "position": [
              "(math.cos(query.life_time * 928.26) * 0.8) - this",
              0,
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "move": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move"]}}
      }
    },
    "render_controllers": ["controller.render.endermite"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 25}
  },
  "evoker_fangs": {
    "identifier": "minecraft:evocation_fang",
    "materials": {"default": "fang"},
    "textures": {"default": "textures/entity/illager/evoker_fangs"},
    "geometry": {
      "default": {
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 3,
        "visible_bounds_offset": [0, 1.5, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "upper_jaw",
            "parent": "base",
            "pivot": [0, 11, 0],
            "cubes": [
              {
                "origin": [-1.5, 0, -4],
                "size": [4, 14, 8],
                "uv": [40, 0],
                "inflate": 0.01
              }
            ]
          },
          {
            "name": "lower_jaw",
            "parent": "base",
            "pivot": [0, 11, 0],
            "bind_pose_rotation": [0, 180, 0],
            "cubes": [
              {"origin": [-1.5, 0, -4], "size": [4, 14, 8], "uv": [40, 0]}
            ]
          },
          {
            "name": "base",
            "pivot": [0, 0, 0],
            "bind_pose_rotation": [0, 90, 0],
            "cubes": [
              {"origin": [-5, 0, -5], "size": [10, 12, 10], "uv": [0, 0]}
            ]
          }
        ]
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.remaining_life = (query.life_span - 2.0) - (query.life_time*20.0);",
        "variable.animation_progress = query.life_span == 0.0 ? 0.0 : (variable.remaining_life < 1.0 ? 1.0 : 1.0 - Math.min(1.0, variable.remaining_life / 20.0));",
        "variable.bite_amount = (1 - Math.Pow(Math.Clamp(variable.animation_progress*2.0, 0, 1.0), 3.0)) * 0.35 * 180;",
        "variable.y_offset = (variable.animation_progress + Math.sin(variable.animation_progress*2.7*80.0)) * 0.6 * 12.0;"
      ],
      "animate": ["bite"],
      "scale": "variable.animation_progress > 0.9 ? (1.0 - variable.animation_progress) / 0.1 : 1.0"
    },
    "animations": {
      "bite": {
        "loop": true,
        "bones": {
          "base": {"position": [0, "-12.0 + variable.y_offset", 0]},
          "lower_jaw": {"rotation": [0, 0, "180.0 + variable.bite_amount"]},
          "upper_jaw": {"rotation": [0, 0, "180.0 - variable.bite_amount"]}
        }
      }
    },
    "render_controllers": ["controller.render.evocation_fang"]
  },
  "evoker": {
    "identifier": "minecraft:evocation_illager",
    "min_engine_version": "1.8.0",
    "materials": {"default": "evoker"},
    "textures": {"default": "textures/entity/illager/evoker"},
    "geometry": {
      "default": {
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 2.5,
        "visible_bounds_offset": [0, 1.25, 0],
        "texturewidth": 64,
        "textureheight": 64,
        "bones": [
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 24, -4], "size": [8, 10, 8], "uv": [0, 0]}
            ]
          },
          {
            "name": "nose",
            "parent": "head",
            "pivot": [0, 26, 0],
            "cubes": [
              {"origin": [-1, 23, -6], "size": [2, 4, 2], "uv": [24, 0]}
            ]
          },
          {
            "name": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 12, -3], "size": [8, 12, 6], "uv": [16, 20]},
              {
                "origin": [-4, 6, -3],
                "size": [8, 18, 6],
                "uv": [0, 38],
                "inflate": 0.5
              }
            ]
          },
          {
            "name": "arms",
            "parent": "body",
            "pivot": [0, 22, 0],
            "cubes": [
              {"origin": [-8, 16, -2], "size": [4, 8, 4], "uv": [44, 22]},
              {"origin": [4, 16, -2], "size": [4, 8, 4], "uv": [44, 22]},
              {"origin": [-4, 16, -2], "size": [8, 4, 4], "uv": [40, 38]}
            ]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-2, 12, 0],
            "cubes": [
              {"origin": [-4, 0, -2], "size": [4, 12, 4], "uv": [0, 22]}
            ]
          },
          {
            "name": "leg1",
            "parent": "body",
            "pivot": [2, 12, 0],
            "mirror": true,
            "cubes": [{"origin": [0, 0, -2], "size": [4, 12, 4], "uv": [0, 22]}]
          },
          {
            "name": "rightArm",
            "parent": "body",
            "pivot": [-5, 22, 0],
            "locators": {"right_hand": [-6, 12, 0]},
            "cubes": [
              {"origin": [-8, 12, -2], "size": [4, 12, 4], "uv": [40, 46]}
            ]
          },
          {
            "name": "rightItem",
            "pivot": [-5.5, 16, 0.5],
            "neverRender": true,
            "parent": "rightArm"
          },
          {
            "name": "leftArm",
            "parent": "body",
            "pivot": [5, 22, 0],
            "locators": {"left_hand": [6, 12, 0]},
            "mirror": true,
            "cubes": [
              {"origin": [4, 12, -2], "size": [4, 12, 4], "uv": [40, 46]}
            ]
          }
        ]
      }
    },
    "scripts": {
      "scale": "0.9375",
      "animate": ["controller_general", "controller_move"]
    },
    "animations": {
      "general": {
        "loop": true,
        "bones": {
          "arms": {
            "position": [0, "-3.0 - this", "-1.0 - this"],
            "rotation": ["-42.97 - this", "- this", "- this"],
            "scale": 1
          },
          "leftarm": {"scale": 0},
          "rightarm": {"scale": 0}
        }
      },
      "casting": {
        "loop": true,
        "bones": {
          "arms": {"scale": 0},
          "leftarm": {
            "rotation": [
              "math.cos(query.life_time * 763.4) * 14.3239 - this",
              "-this",
              "-135.0 - this"
            ],
            "scale": 1
          },
          "rightarm": {
            "rotation": [
              "math.cos(query.life_time * 763.4) * 14.3239 - this",
              "-this",
              "135.0 - this"
            ],
            "scale": 1
          }
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "move": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": [
              "(math.cos(query.anim_time * 38.17) * 40.0) - this",
              "-this",
              0
            ]
          },
          "leg1": {
            "rotation": [
              "(math.cos(query.anim_time * 38.17 + 180) * 40.0) - this",
              "-this",
              0
            ]
          }
        }
      },
      "celebrating": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "(math.cos(query.life_time * 800.0) * 2.865)",
              180,
              -135
            ]
          },
          "rightarm": {
            "rotation": [
              "(math.cos(query.life_time * 800.0) * 2.865)",
              180,
              153
            ]
          }
        }
      }
    },
    "particle_effects": {"spell": "minecraft:evoker_spell"},
    "render_controllers": ["controller.render.evoker"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 40},
    "animation_controllers": {
      "controller_general": {
        "initial_state": "default",
        "states": {
          "casting": {
            "animations": ["look_at_target", "casting"],
            "particle_effects": [
              {"effect": "spell", "locator": "left_hand"},
              {"effect": "spell", "locator": "right_hand"}
            ],
            "transitions": [{"default": "!query.is_casting"}]
          },
          "celebrating": {
            "animations": ["celebrating"],
            "blend_transition": 0.2,
            "blend_via_shortest_path": true,
            "transitions": [{"default": "!query.is_celebrating"}]
          },
          "default": {
            "animations": ["look_at_target", "general"],
            "blend_transition": 0.2,
            "blend_via_shortest_path": true,
            "transitions": [
              {"casting": "query.is_casting"},
              {"celebrating": "query.is_celebrating"}
            ]
          }
        }
      },
      "controller_move": {
        "initial_state": "default",
        "states": {
          "default": {"animations": [{"move": "query.modified_move_speed"}]}
        }
      }
    }
  },
  "experience_bottle": {
    "identifier": "minecraft:xp_bottle",
    "materials": {"default": "xp_bottle"},
    "textures": {
      "default": "textures/items/experience_bottle",
      "enchanted": "textures/misc/enchanted_item_glint"
    },
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-8, -8, 0],
                "size": [16, 16, 0],
                "uv": [0, 0],
                "rotation": [0, 0, 0]
              }
            ]
          }
        ],
        "texturewidth": 16,
        "textureheight": 16
      }
    },
    "render_controllers": ["controller.render.experience_bottle"],
    "animations": {
      "flying": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              "query.camera_rotation(0)",
              "query.camera_rotation(1)",
              0
            ]
          }
        }
      }
    },
    "scripts": {"animate": ["flying"]}
  },
  "experience_orb": {
    "identifier": "minecraft:xp_orb",
    "materials": {"default": "experience_orb"},
    "textures": {"default": "textures/entity/experience_orb"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [8, 8, 0],
            "cubes": [
              {
                "origin": [0, 0, 0],
                "size": [16, 16, 0],
                "uv": {"south": {"uv": [0, 0]}}
              }
            ]
          }
        ],
        "texturewidth": 64,
        "textureheight": 64
      }
    },
    "animations": {
      "face_player": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              "query.camera_rotation(0)",
              "query.camera_rotation(1)",
              0
            ]
          }
        }
      }
    },
    "scripts": {
      "scale": "0.3",
      "pre_animation": [
        "variable.u = Math.mod(query.texture_frame_index, 4) * 16.0 / 64.0;",
        "variable.v = math.round(query.texture_frame_index / 4) * 16.0 / 64.0;"
      ],
      "animate": ["face_player"]
    },
    "render_controllers": ["controller.render.experience_orb"]
  },
  "fireball": {
    "identifier": "minecraft:fireball",
    "materials": {"default": "fireball"},
    "textures": {"default": "textures/items/fire_charge"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-8, -4, 0],
                "size": [16, 16, 0],
                "uv": {"south": {"uv": [0, 0]}}
              }
            ]
          }
        ],
        "texturewidth": 16,
        "textureheight": 16
      }
    },
    "animations": {
      "face_player": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              "query.camera_rotation(0)",
              "query.camera_rotation(1)",
              0
            ]
          }
        }
      }
    },
    "scripts": {"scale": "2.0", "animate": ["face_player"]},
    "render_controllers": ["controller.render.fireball"]
  },
  "firework_rocket": {
    "identifier": "minecraft:fireworks_rocket",
    "materials": {"default": "fireworks_rocket"},
    "textures": {"default": "textures/entity/fireworks"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-8, -8, 0],
                "rotation": [0, 90, 0],
                "size": [16, 16, 0],
                "uv": {"north": {"uv": [0, 0]}}
              },
              {
                "origin": [-8, -8, 0],
                "rotation": [90, 90, 0],
                "size": [16, 16, 0],
                "uv": {"north": {"uv": [0, 0]}}
              }
            ]
          }
        ],
        "texturewidth": 32,
        "textureheight": 32
      }
    },
    "animations": {
      "move": {
        "loop": true,
        "bones": {
          "body": {
            "position": [0, -3.5, 0],
            "rotation": [
              "-query.target_x_rotation",
              "-query.target_y_rotation",
              0
            ],
            "scale": 0.6
          }
        }
      }
    },
    "scripts": {"animate": ["move"]},
    "render_controllers": ["controller.render.fireworks_rocket"]
  },
  "fishing_bobber": {
    "identifier": "minecraft:fishing_hook",
    "materials": {"default": "fishing_hook"},
    "textures": {"default": "textures/entity/fishing_hook"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-1.5, -1.5, -1.5],
                "size": [3, 3, 3],
                "rotation": [0, 0, 180],
                "uv": {
                  "up": {"uv": [0, 0]},
                  "down": {"uv": [3, 0]},
                  "south": {"uv": [9, 0], "uv_size": [-3, 3]},
                  "north": {"uv": [9, 0]},
                  "east": {"uv": [12, 0]},
                  "west": {"uv": [15, 0]}
                }
              },
              {
                "origin": [0, -4.5, -0.5],
                "size": [0, 3, 3],
                "uv": {"east": {"uv": [18, 0]}}
              },
              {
                "origin": [0, 1.5, -1.5],
                "size": [0, 3, 3],
                "uv": {"east": {"uv": [21, 0]}}
              },
              {
                "origin": [-1.5, 1.5, 0],
                "size": [3, 3, 0],
                "uv": {"north": {"uv": [21, 0]}}
              }
            ]
          }
        ],
        "texturewidth": 24,
        "textureheight": 3
      }
    },
    "render_controllers": ["controller.render.fishing_hook"]
  },
  "fox": {
    "identifier": "minecraft:fox",
    "materials": {"default": "fox"},
    "textures": {
      "red": "textures/entity/fox/fox",
      "arctic": "textures/entity/fox/snow_fox"
    },
    "geometry": {
      "default": {
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "world",
            "pivot": [0, 0, 0],
            "bind_pose_rotation": [0, 0, 0],
            "cubes": []
          },
          {
            "name": "root",
            "parent": "world",
            "pivot": [0, 0, 0],
            "bind_pose_rotation": [0, 0, 0],
            "cubes": []
          },
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 8, -3],
            "locators": {"held_item": [-2, 5, -10], "lead": [0, 4, -4]},
            "bind_pose_rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-4, 4, -9], "size": [8, 6, 6], "uv": [0, 0]},
              {"origin": [-4, 10, -8], "size": [2, 2, 1], "uv": [0, 0]},
              {"origin": [2, 10, -8], "size": [2, 2, 1], "uv": [22, 0]},
              {"origin": [-2, 4, -12], "size": [4, 2, 3], "uv": [0, 24]}
            ]
          },
          {
            "name": "head_sleeping",
            "parent": "head",
            "pivot": [0, 8, -3],
            "bind_pose_rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-4, 4, -9], "size": [8, 6, 6], "uv": [0, 12]},
              {"origin": [-4, 10, -8], "size": [2, 2, 1], "uv": [0, 0]},
              {"origin": [2, 10, -8], "size": [2, 2, 1], "uv": [22, 0]},
              {"origin": [-2, 4, -12], "size": [4, 2, 3], "uv": [0, 24]}
            ]
          },
          {
            "name": "body",
            "parent": "root",
            "pivot": [0, 8, 0],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {"origin": [-3, 0, -3], "size": [6, 11, 6], "uv": [30, 15]}
            ]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-3, 6, 6],
            "bind_pose_rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-3.005, 0, 5], "size": [2, 6, 2], "uv": [14, 24]}
            ]
          },
          {
            "name": "leg1",
            "parent": "body",
            "pivot": [1, 6, 6],
            "bind_pose_rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.005, 0, 5], "size": [2, 6, 2], "uv": [22, 24]}
            ]
          },
          {
            "name": "leg2",
            "parent": "body",
            "pivot": [-3, 6, -1],
            "bind_pose_rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-3.005, 0, -2], "size": [2, 6, 2], "uv": [14, 24]}
            ]
          },
          {
            "name": "leg3",
            "parent": "body",
            "pivot": [1, 6, -1],
            "bind_pose_rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.005, 0, -2], "size": [2, 6, 2], "uv": [22, 24]}
            ]
          },
          {
            "name": "tail",
            "parent": "body",
            "pivot": [0, 8, 7],
            "bind_pose_rotation": [80, 0, 0],
            "cubes": [
              {"origin": [-2, -2, 4.75], "size": [4, 9, 5], "uv": [28, 0]}
            ]
          }
        ]
      }
    },
    "animations": {
      "walk": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          },
          "leg1": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          },
          "leg2": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          },
          "leg3": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          }
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "baby_transform": {
        "loop": true,
        "bones": {
          "head": {
            "position": [
              0,
              "query.is_baby ? 4.0 : 0.0",
              "query.is_baby ? 4.0 : 0.0"
            ],
            "scale": "query.is_baby ? 1.3 : 1.0"
          }
        }
      },
      "crouch": {
        "loop": true,
        "bones": {
          "body": {"position": [0, -1.8, 0], "rotation": [0, 0, 0]},
          "head": {"position": [0, -0.8, 0], "rotation": [0, 0, 0]},
          "leg0": {"position": [0, 1.6, 0], "rotation": [0, 0, 0]},
          "leg1": {"position": [0, 1.6, 0], "rotation": [0, 0, 0]},
          "leg2": {"position": [0, 1.6, 0], "rotation": [0, 0, 0]},
          "leg3": {"position": [0, 1.6, 0], "rotation": [0, 0, 0]}
        }
      },
      "sleep": {
        "loop": true,
        "bones": {
          "body": {"position": [0, -4.8, 0], "rotation": [0, 0, -90]},
          "head": {
            "position": [1.8, -0.4, -2],
            "rotation": [
              0,
              -115,
              "math.cos(query.anim_time * 160.0) + 90 -this"
            ]
          },
          "tail": {"position": [0, 0, 1.5], "rotation": [-125, 0, 0]}
        }
      },
      "setup": {"loop": true, "bones": {"body": {"rotation": ["-this", 0, 0]}}},
      "sit": {
        "loop": true,
        "bones": {
          "body": {"position": [0, 1, 0], "rotation": [-60, 0, 0]},
          "head": {"position": [0, 3, -3], "rotation": [60, 0, 0]},
          "leg0": {"position": [0, 4, 2.5], "rotation": [-15, 0, 0]},
          "leg1": {"position": [0, 4, 2.5], "rotation": [-15, 0, 0]},
          "leg2": {"position": [0, 0.75, 3.5], "rotation": [40, 0, 0]},
          "leg3": {"position": [0, 0.75, 3.5], "rotation": [40, 0, 0]},
          "tail": {"position": [0, -0.075, -0.15], "rotation": [60, 0, 0]}
        }
      },
      "wiggle": {
        "loop": true,
        "bones": {
          "body": {
            "position": [0, -1.8, 0],
            "rotation": [
              0,
              "math.cos(query.life_time * 20.0 * 53.7) * 5.0 -this",
              0
            ]
          },
          "head": {
            "position": [0, -0.8, 0],
            "rotation": [
              "math.clamp(-5.0 * (query.anim_time / 2.0), -5.0, 0)",
              0,
              "math.clamp(25.0 * (query.anim_time / 2.0), 0, 25.0)"
            ]
          },
          "leg0": {"position": [0, 1.6, 0], "rotation": [0, 0, 0]},
          "leg1": {"position": [0, 1.6, 0], "rotation": [0, 0, 0]},
          "leg2": {"position": [0, 1.6, 0], "rotation": [0, 0, 0]},
          "leg3": {"position": [0, 1.6, 0], "rotation": [0, 0, 0]},
          "tail": {
            "rotation": [
              0,
              "math.cos(query.life_time * 20.0 * 53.7) * 10.0 -this",
              0
            ]
          }
        }
      },
      "stuck": {
        "loop": true,
        "bones": {"body": {"position": [0, -0.3, 0], "rotation": [60, 0, 0]}}
      },
      "pounce": {
        "loop": true,
        "bones": {
          "body": {
            "position": [0, 0, 0],
            "rotation": ["query.vertical_speed * -7.0", 0, 0]
          },
          "head": {
            "position": [0, 0, 0],
            "rotation": ["query.vertical_speed * -7.0", 0, 0]
          }
        }
      }
    },
    "scripts": {
      "animate": ["setup", {"baby_transform": "query.is_baby"}, "move"]
    },
    "render_controllers": ["controller.render.fox"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 55},
    "animation_controllers": {
      "move": {
        "initial_state": "default",
        "states": {
          "crouch": {
            "animations": [
              "look_at_target",
              "crouch",
              {"walk": "query.modified_move_speed"}
            ],
            "blend_transition": 0.4,
            "transitions": [
              {"default": "!query.is_stalking && !query.is_interested"},
              {"wiggle": "query.is_interested"}
            ]
          },
          "default": {
            "animations": [
              "look_at_target",
              {"walk": "query.modified_move_speed"}
            ],
            "blend_transition": 0.1,
            "transitions": [
              {"crouch": "query.is_stalking"},
              {"sleep": "query.is_sleeping"},
              {"stuck": "query.is_stunned"},
              {"sit": "query.is_sitting"}
            ]
          },
          "pounce": {
            "animations": [
              "look_at_target",
              "pounce",
              {"walk": "query.modified_move_speed"}
            ],
            "transitions": [{"default": "query.is_on_ground"}]
          },
          "sit": {
            "animations": ["sit"],
            "blend_transition": 0.2,
            "transitions": [{"default": "!query.is_sitting"}]
          },
          "sleep": {
            "animations": ["sleep"],
            "blend_transition": 0.2,
            "transitions": [{"default": "!query.is_sleeping"}]
          },
          "stuck": {
            "animations": [
              "stuck",
              {"walk": "math.cos(query.life_time * 20.0 * 53.7)"}
            ],
            "blend_transition": 0.2,
            "transitions": [{"default": "!query.is_stunned"}]
          },
          "wiggle": {
            "animations": [
              "look_at_target",
              "wiggle",
              {"walk": "query.modified_move_speed"}
            ],
            "blend_transition": 0.2,
            "transitions": [
              {
                "default": "query.is_on_ground && !query.is_interested && !query.is_stalking"
              },
              {
                "crouch": "query.is_on_ground && !query.is_interested && query.is_stalking"
              },
              {"pounce": "!query.is_on_ground"}
            ]
          }
        }
      }
    }
  },
  "ghast": {
    "identifier": "minecraft:ghast",
    "materials": {"default": "ghast"},
    "textures": {
      "default": "textures/entity/ghast/ghast",
      "shooting": "textures/entity/ghast/ghast_shooting"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 6,
        "visible_bounds_height": 9,
        "visible_bounds_offset": [0, 4.5, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "tentacles_0",
            "parent": "body",
            "pivot": [-3.8, 1, -5],
            "cubes": [
              {"origin": [-4.8, -8, -6], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "tentacles_1",
            "parent": "body",
            "pivot": [1.3, 1, -5],
            "cubes": [
              {"origin": [0.3, -10, -6], "size": [2, 11, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "tentacles_2",
            "parent": "body",
            "pivot": [6.3, 1, -5],
            "cubes": [
              {"origin": [5.3, -7, -6], "size": [2, 8, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "tentacles_3",
            "parent": "body",
            "pivot": [-6.3, 1, 0],
            "cubes": [
              {"origin": [-7.3, -8, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "tentacles_4",
            "parent": "body",
            "pivot": [-1.3, 1, 0],
            "cubes": [
              {"origin": [-2.3, -12, -1], "size": [2, 13, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "tentacles_5",
            "parent": "body",
            "pivot": [3.8, 1, 0],
            "cubes": [
              {"origin": [2.8, -10, -1], "size": [2, 11, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "tentacles_6",
            "parent": "body",
            "pivot": [-3.8, 1, 5],
            "cubes": [
              {"origin": [-4.8, -11, 4], "size": [2, 12, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "tentacles_7",
            "parent": "body",
            "pivot": [1.3, 1, 5],
            "cubes": [
              {"origin": [0.3, -11, 4], "size": [2, 12, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "tentacles_8",
            "parent": "body",
            "pivot": [6.3, 1, 5],
            "cubes": [
              {"origin": [5.3, -12, 4], "size": [2, 13, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "body",
            "pivot": [0, 1.5, 0],
            "cubes": [
              {"origin": [-8, 0, -8], "size": [16, 16, 16], "uv": [0, 0]}
            ]
          }
        ]
      }
    },
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 19},
    "animations": {
      "move": {
        "loop": true,
        "bones": {
          "tentacles_0": {
            "rotation": [
              "math.sin(query.life_time * 360.0 + 0) * 11.5 + 23",
              0,
              0
            ]
          },
          "tentacles_1": {
            "rotation": [
              "math.sin(query.life_time * 360.0 + 57) * 11.5 + 23",
              0,
              0
            ]
          },
          "tentacles_2": {
            "rotation": [
              "math.sin(query.life_time * 360.0 + 115) * 11.5 + 23",
              0,
              0
            ]
          },
          "tentacles_3": {
            "rotation": [
              "math.sin(query.life_time * 360.0 + 172) * 11.5 + 23",
              0,
              0
            ]
          },
          "tentacles_4": {
            "rotation": [
              "math.sin(query.life_time * 360.0 + 229) * 11.5 + 23",
              0,
              0
            ]
          },
          "tentacles_5": {
            "rotation": [
              "math.sin(query.life_time * 360.0 + 286) * 11.5 + 23",
              0,
              0
            ]
          },
          "tentacles_6": {
            "rotation": [
              "math.sin(query.life_time * 360.0 + 344) * 11.5 + 23",
              0,
              0
            ]
          },
          "tentacles_7": {
            "rotation": [
              "math.sin(query.life_time * 360.0 + 402) * 11.5 + 23",
              0,
              0
            ]
          },
          "tentacles_8": {
            "rotation": [
              "math.sin(query.life_time * 360.0 + 458) * 11.5 + 23",
              0,
              0
            ]
          }
        }
      },
      "scale": {"loop": true, "bones": {"body": {"scale": 4.5}}}
    },
    "animation_controllers": {
      "move": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move"]}}
      },
      "scale": {
        "initial_state": "default",
        "states": {"default": {"animations": ["scale"]}}
      }
    },
    "render_controllers": ["controller.render.ghast"]
  },
  "guardian": {
    "identifier": "minecraft:guardian",
    "min_engine_version": "1.8.0",
    "materials": {"default": "guardian", "ghost": "guardian_ghost"},
    "textures": {
      "default": "textures/entity/guardian",
      "elder": "textures/entity/guardian_elder",
      "beam": "textures/entity/guardian_beam"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 3.5,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 64,
        "bones": [
          {
            "name": "head",
            "pivot": [0, 0, 0],
            "mirror": true,
            "cubes": [
              {
                "mirror": false,
                "origin": [-6, 2, -8],
                "size": [12, 12, 16],
                "uv": [0, 0]
              },
              {
                "mirror": false,
                "origin": [-8, 2, -6],
                "size": [2, 12, 12],
                "uv": [0, 28]
              },
              {"origin": [6, 2, -6], "size": [2, 12, 12], "uv": [0, 28]},
              {"origin": [-6, 14, -6], "size": [12, 2, 12], "uv": [16, 40]},
              {"origin": [-6, 0, -6], "size": [12, 2, 12], "uv": [16, 40]}
            ]
          },
          {
            "name": "eye",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-1, 6, 0], "size": [2, 2, 1], "uv": [8, 0]}]
          },
          {
            "name": "tailpart0",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-2, 6, 7], "size": [4, 4, 8], "uv": [40, 0]}]
          },
          {
            "name": "tailpart1",
            "parent": "tailpart0",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 7, 0], "size": [3, 3, 7], "uv": [0, 54]}]
          },
          {
            "name": "tailpart2",
            "parent": "tailpart1",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [0, 8, 0], "size": [2, 2, 6], "uv": [41, 32]},
              {"origin": [1, 4.5, 3], "size": [1, 9, 9], "uv": [25, 19]}
            ]
          },
          {
            "name": "spikepart0",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart1",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart2",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart3",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart4",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart5",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart6",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart7",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart8",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart9",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart10",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart11",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          }
        ]
      },
      "ghost": {
        "visible_bounds_width": 3.5,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 64,
        "bones": [
          {
            "name": "head",
            "pivot": [0, 24, 0],
            "mirror": true,
            "cubes": [
              {
                "mirror": false,
                "origin": [-6, 2, -8],
                "size": [12, 12, 16],
                "uv": [0, 0]
              },
              {
                "mirror": false,
                "origin": [-8, 2, -6],
                "size": [2, 12, 12],
                "uv": [0, 28]
              },
              {"origin": [6, 2, -6], "size": [2, 12, 12], "uv": [0, 28]},
              {"origin": [-6, 14, -6], "size": [12, 2, 12], "uv": [16, 40]},
              {"origin": [-6, 0, -6], "size": [12, 2, 12], "uv": [16, 40]}
            ]
          },
          {
            "name": "eye",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-1, 7, 0], "size": [2, 2, 1], "uv": [8, 0]}]
          },
          {
            "name": "tailpart0",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-2, 6, 7], "size": [4, 4, 8], "uv": [40, 0]}]
          },
          {
            "name": "tailpart1",
            "parent": "tailpart0",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 7, 0], "size": [3, 3, 7], "uv": [0, 54]}]
          },
          {
            "name": "tailpart2",
            "parent": "tailpart1",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [0, 8, 0], "size": [2, 2, 6], "uv": [41, 32]},
              {"origin": [1, 4.5, 3], "size": [1, 9, 9], "uv": [25, 19]}
            ]
          },
          {
            "name": "spikepart0",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart1",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart2",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart3",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart4",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart5",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart6",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart7",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart8",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart9",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart10",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "spikepart11",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-1, 19.5, -1], "size": [2, 9, 2], "uv": [0, 0]}
            ]
          }
        ]
      }
    },
    "animations": {
      "setup": {
        "loop": true,
        "bones": {
          "spikepart0": {
            "rotation": ["-45.0 - this", "0.0 - this", "0.0 - this"]
          },
          "spikepart1": {
            "rotation": ["45.0 - this", "0.0 - this", "0.0 - this"]
          },
          "spikepart10": {
            "rotation": ["0.0 - this", "0.0 - this", " 135.0 - this"]
          },
          "spikepart11": {
            "rotation": ["0.0 - this", "0.0 - this", " -135.0 - this"]
          },
          "spikepart2": {
            "rotation": ["0.0 - this", "0.0 - this", "45.0 - this"]
          },
          "spikepart3": {
            "rotation": ["0.0 - this", "0.0  - this", "-45.0 - this"]
          },
          "spikepart4": {
            "rotation": ["90.0 - this", "45.0 - this", " 0.0 - this"]
          },
          "spikepart5": {
            "rotation": ["90.0 - this", "-45.0 - this", " 0.0 - this"]
          },
          "spikepart6": {
            "rotation": ["90.0 - this", "-135.0 - this", "0.0 - this"]
          },
          "spikepart7": {
            "rotation": ["90.0 - this", "135.0 - this", " 0.0 - this"]
          },
          "spikepart8": {
            "rotation": ["-135.0 - this", "0.0 - this", " 0.0 - this"]
          },
          "spikepart9": {
            "rotation": ["135.0 - this", "0.0 - this", " 0.0 - this"]
          }
        }
      },
      "spikes": {
        "loop": true,
        "bones": {
          "spikepart0": {
            "position": [
              "-this",
              "24 + -8 * (1 + math.cos(query.life_time * 20 * 1.5) * 0.01 + (variable.spike_extension + variable.spike_shake)) - this",
              "8 * (1 + math.cos(query.life_time * 20 * 1.5) * 0.01 - (variable.spike_extension + variable.spike_shake)) - this"
            ]
          },
          "spikepart1": {
            "position": [
              "-this",
              "24 + -8 * (1 + math.cos(query.life_time * 20 * 1.5 + 1) * 0.01 + (variable.spike_extension + variable.spike_shake)) - this",
              "-8 * (1 + math.cos(query.life_time * 20 * 1.5 + 1) * 0.01 - (variable.spike_extension + variable.spike_shake)) - this"
            ]
          },
          "spikepart10": {
            "position": [
              "8 * (1 + math.cos(query.life_time * 20 * 1.5 + 10) * 0.01 - (variable.spike_extension + variable.spike_shake)) - this",
              "-8 + 8 * (1 + math.cos(query.life_time * 20 * 1.5 + 10) * 0.01 + (variable.spike_extension + variable.spike_shake)) - this",
              "-this"
            ]
          },
          "spikepart11": {
            "position": [
              "-8 * (1 + math.cos(query.life_time * 20 * 1.5 + 11) * 0.01 - (variable.spike_extension + variable.spike_shake)) - this",
              "-8 + 8 * (1 + math.cos(query.life_time * 20 * 1.5 + 11) * 0.01 + (variable.spike_extension + variable.spike_shake)) - this",
              "-this"
            ]
          },
          "spikepart2": {
            "position": [
              "8 * (1 + math.cos(query.life_time * 20 * 1.5 + 2) * 0.01 - (variable.spike_extension + variable.spike_shake)) - this",
              "24 + -8 * (1 + math.cos(query.life_time * 20 * 1.5 + 2) * 0.01 + (variable.spike_extension + variable.spike_shake)) - this",
              "-this"
            ]
          },
          "spikepart3": {
            "position": [
              "-8 * (1 + math.cos(query.life_time * 20 * 1.5 + 3) * 0.01 - (variable.spike_extension + variable.spike_shake)) - this",
              "24 + -8 * (1 + math.cos(query.life_time * 20 * 1.5 + 3) * 0.01 + (variable.spike_extension + variable.spike_shake)) - this",
              0
            ]
          },
          "spikepart4": {
            "position": [
              "-8 * (1 + math.cos(query.life_time * 20 * 1.5 + 4) * 0.01 - (variable.spike_extension - variable.spike_shake)) - this",
              "8 - this",
              "-8 * (1 + math.cos(query.life_time * 20 * 1.5 + 4) * 0.01 - (variable.spike_extension - variable.spike_shake)) - this"
            ]
          },
          "spikepart5": {
            "position": [
              "8 * (1 + math.cos(query.life_time * 20 * 1.5 + 5) * 0.01 - (variable.spike_extension - variable.spike_shake)) - this",
              "8 - this",
              "-8 * (1 + math.cos(query.life_time * 20 * 1.5 + 5) * 0.01 - (variable.spike_extension - variable.spike_shake)) - this"
            ]
          },
          "spikepart6": {
            "position": [
              "8 * (1 + math.cos(query.life_time * 20 * 1.5 + 6) * 0.01 - (variable.spike_extension - variable.spike_shake)) - this",
              "8 - this",
              "8 * (1 + math.cos(query.life_time * 20 * 1.5 + 6) * 0.01 - (variable.spike_extension - variable.spike_shake)) - this"
            ]
          },
          "spikepart7": {
            "position": [
              "-8 * (1 + math.cos(query.life_time * 20 * 1.5 + 7) * 0.01 - (variable.spike_extension - variable.spike_shake)) - this",
              "8 - this",
              "8 * (1 + math.cos(query.life_time * 20 * 1.5 + 7) * 0.01 - (variable.spike_extension - variable.spike_shake)) - this"
            ]
          },
          "spikepart8": {
            "position": [
              "-this",
              "-8  + 8 * (1 + math.cos(query.life_time * 20 * 1.5 + 8) * 0.01 + (variable.spike_extension + variable.spike_shake)) - this",
              "8 * (1 + math.cos(query.life_time * 20 * 1.5 + 8) * 0.01 - (variable.spike_extension + variable.spike_shake)) - this"
            ]
          },
          "spikepart9": {
            "position": [
              "-this",
              "-8 + 8 * (1 + math.cos(query.life_time * 20 * 1.5 + 9) * 0.01 + (variable.spike_extension + variable.spike_shake)) - this",
              "-8 * (1 + math.cos(query.life_time * 20 * 1.5 + 9) * 0.01 - (variable.spike_extension + variable.spike_shake)) - this"
            ]
          }
        }
      },
      "swim": {
        "loop": true,
        "bones": {
          "tailpart0": {
            "rotation": [0, "variable.tail_base_angle * 11.6 - this", 0]
          },
          "tailpart1": {
            "position": ["-1.5 - this", "-0.5 - this", "14.0 - this"],
            "rotation": [0, "variable.tail_base_angle * 22.8 - this", 0]
          },
          "tailpart2": {
            "position": ["0.5 - this", "-0.5 - this", "6.0 - this"],
            "rotation": [0, "variable.tail_base_angle * 34.4 - this", 0]
          }
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "move_eye": {
        "loop": true,
        "bones": {
          "eye": {
            "position": [
              "query.eye_target_x_rotation - this",
              "24 + query.eye_target_y_rotation - this",
              "-8.25 - this"
            ]
          }
        }
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.spike_shake = Math.sin(query.life_time * 2000)/50;",
        "variable.spike_animation_speed = query.life_time < 0.1 ? 0.0 : (!query.is_in_water ? (Math.round(Math.sin(query.life_time * 2000)) == 0.0 ? (Math.random(0.0, 1.0)) : (variable.spike_animation_speed)) : (query.is_moving ? (variable.spike_animation_speed - variable.spike_animation_speed * 0.06) : (variable.spike_animation_speed + (1.0 - variable.spike_animation_speed) * 0.06)));",
        "variable.spike_extension = (1.0 - variable.spike_animation_speed) * 0.55;",
        "variable.tail_animation_speed = query.life_time < 0.1 ? 0.0 : (!query.is_in_water ? 2.0 : query.is_moving ? (variable.tail_animation_speed < 0.5 ? 4.0 : variable.tail_animation_speed + (0.5 - variable.tail_animation_speed) * 0.1) : variable.tail_animation_speed + (0.125 - variable.tail_animation_speed) * 0.2);",
        "variable.tail_swim = query.life_time < 0.1 ? 0.0 : (variable.tail_swim + variable.tail_animation_speed);",
        "variable.tail_base_angle = Math.sin(variable.tail_swim*20.0);"
      ],
      "animate": ["setup", "spikes", "swim", "look_at_target", "move_eye"]
    },
    "render_controllers": ["controller.render.guardian"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 26}
  },
  "hoglin": {
    "identifier": "minecraft:hoglin",
    "materials": {"default": "hoglin"},
    "textures": {"default": "textures/entity/hoglin/hoglin"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 19, -3],
            "cubes": [
              {
                "origin": [-8, 11, -7],
                "size": [16, 14, 26],
                "inflate": 0.02,
                "uv": [1, 1]
              },
              {
                "origin": [0, 22, -10],
                "size": [0, 10, 19],
                "inflate": 0.02,
                "uv": [90, 33]
              }
            ],
            "locators": {"lead": [0, 20, -5]}
          },
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 22, -5],
            "rotation": [50, 0, 0],
            "cubes": [
              {"origin": [-7, 21, -24], "size": [14, 6, 19], "uv": [61, 1]},
              {"origin": [-8, 22, -19], "size": [2, 11, 2], "uv": [1, 13]},
              {"origin": [6, 22, -19], "size": [2, 11, 2], "uv": [1, 13]}
            ]
          },
          {
            "name": "right_ear",
            "parent": "head",
            "pivot": [-7, 27, -7],
            "rotation": [0, 0, -50],
            "cubes": [
              {"origin": [-13, 26, -10], "size": [6, 1, 4], "uv": [1, 1]}
            ]
          },
          {
            "name": "left_ear",
            "parent": "head",
            "pivot": [7, 27, -7],
            "rotation": [0, 0, 50],
            "cubes": [{"origin": [7, 26, -10], "size": [6, 1, 4], "uv": [1, 6]}]
          },
          {
            "name": "leg_back_right",
            "pivot": [6, 8, 17],
            "cubes": [
              {"origin": [-8, 0, 13], "size": [5, 11, 5], "uv": [21, 45]}
            ]
          },
          {
            "name": "leg_back_left",
            "pivot": [-6, 8, 17],
            "cubes": [{"origin": [3, 0, 13], "size": [5, 11, 5], "uv": [0, 45]}]
          },
          {
            "name": "leg_front_right",
            "pivot": [-6, 12, -3],
            "cubes": [
              {"origin": [-8, 0, -6], "size": [6, 14, 6], "uv": [66, 42]}
            ]
          },
          {
            "name": "leg_front_left",
            "pivot": [6, 12, -3],
            "cubes": [
              {"origin": [2, 0, -6], "size": [6, 14, 6], "uv": [41, 42]}
            ]
          }
        ],
        "visible_bounds_width": 4,
        "visible_bounds_height": 3,
        "visible_bounds_offset": [0, 1.5, 0],
        "texturewidth": 128,
        "textureheight": 64
      }
    },
    "spawn_egg": {"base_color": "#C66E55", "overlay_color": "#5f6464"},
    "scripts": {
      "pre_animation": [
        "variable.tcos_right_side = (Math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed / variable.gliding_speed_value) * 57.3;",
        "variable.tcos_left_side = -variable.tcos_right_side;",
        "variable.attack_head_rot =  Math.sin(variable.attack_time * 180.0) * -37.3;"
      ]
    },
    "animations": {
      "walk": {
        "loop": true,
        "bones": {
          "left_ear": {"rotation": [0, 0, "variable.tcos_left_side * 0.5"]},
          "right_ear": {"rotation": [0, 0, "variable.tcos_right_side * 0.5"]},
          "leg_back_right": {"rotation": ["variable.tcos_right_side", 0, 0]},
          "leg_back_left": {"rotation": ["variable.tcos_left_side", 0, 0]},
          "leg_front_right": {"rotation": ["-variable.tcos_right_side", 0, 0]},
          "leg_front_left": {"rotation": ["-variable.tcos_left_side", 0, 0]}
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [0, "query.target_y_rotation - this", 0]
          }
        }
      },
      "attack": {
        "loop": true,
        "bones": {"head": {"rotation": ["variable.attack_head_rot", 0, 0]}}
      },
      "hoglin_baby_scaling": {
        "loop": true,
        "bones": {"head": {"position": [0, 10, 4], "scale": 1.4}}
      }
    },
    "animation_controllers": {
      "look_at_target": {
        "initial_state": "default",
        "states": {"default": {"animations": ["look_at_target"]}}
      },
      "walk": {
        "initial_state": "walking",
        "states": {"walking": {"animations": ["walk"]}}
      },
      "attack": {
        "initial_state": "default",
        "states": {
          "attack": {
            "animations": ["attack"],
            "transitions": [
              {"default": "!variable.has_target || variable.attack_time < 0.0"}
            ]
          },
          "default": {
            "transitions": [
              {"attack": "variable.has_target && variable.attack_time >= 0.0"}
            ]
          }
        }
      },
      "hoglin_baby_scaling": {
        "initial_state": "default",
        "states": {
          "baby": {
            "animations": ["hoglin_baby_scaling"],
            "transitions": [{"default": "!query.is_baby"}]
          },
          "default": {"transitions": [{"baby": "query.is_baby"}]}
        }
      }
    },
    "render_controllers": ["controller.render.hoglin"]
  },
  "hopper_minecart": {
    "identifier": "minecraft:hopper_minecart",
    "min_engine_version": "1.8.0",
    "materials": {"default": "minecart"},
    "textures": {"default": "textures/entity/minecart"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "bottom",
            "pivot": [0, 6, 0],
            "cubes": [
              {
                "origin": [-10, -6.5, -1],
                "size": [20, 16, 2],
                "rotation": [90, 0, 0],
                "uv": [0, 10]
              }
            ]
          },
          {
            "name": "back",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-17, 2.5, -1],
                "size": [16, 8, 2],
                "rotation": [0, 270, 0],
                "uv": [0, 0]
              }
            ],
            "parent": "bottom"
          },
          {
            "name": "front",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [1, 2.5, -1],
                "size": [16, 8, 2],
                "rotation": [0, 90, 0],
                "uv": [0, 0]
              }
            ],
            "parent": "bottom"
          },
          {
            "name": "right",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-8, 2.5, -8],
                "size": [16, 8, 2],
                "rotation": [0, 180, 0],
                "uv": [0, 0]
              }
            ],
            "parent": "bottom"
          },
          {
            "name": "left",
            "pivot": [0, 0, 0],
            "cubes": [
              {"origin": [-8, 2.5, 6], "size": [16, 8, 2], "uv": [0, 0]}
            ],
            "parent": "bottom"
          }
        ],
        "texturewidth": 64,
        "textureheight": 32
      }
    },
    "scripts": {
      "pre_animation": ["variable.hurt = query.hurt_time - query.frame_alpha;"],
      "animate": ["move"]
    },
    "animations": {
      "move": {
        "loop": true,
        "bones": {
          "bottom": {
            "position": [
              "variable.rail_offset.x / query.model_scale",
              "variable.rail_offset.y / query.model_scale",
              "variable.rail_offset.z / query.model_scale"
            ],
            "rotation": [
              "variable.hurt > 0 ? -Math.sin(variable.hurt * 360 / (Math.pi * 2)) * variable.hurt * (((20 * 2 - query.structural_integrity) - query.frame_alpha) < 0 ? 0: (20 * 2 - query.structural_integrity) - query.frame_alpha) / 10 * query.hurt_direction : 0",
              0,
              "-variable.rail_rotation.z"
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.minecart"]
  },
  "horse": {
    "identifier": "minecraft:horse",
    "textures": {
      "base_brown": "textures/entity/horse/horse_brown",
      "base_white": "textures/entity/horse/horse_white",
      "base_chestnut": "textures/entity/horse/horse_chestnut",
      "base_creamy": "textures/entity/horse/horse_creamy",
      "base_black": "textures/entity/horse/horse_black",
      "base_gray": "textures/entity/horse/horse_gray",
      "base_darkbrown": "textures/entity/horse/horse_darkbrown",
      "markings_none": "textures/entity/horse/horse_markings_none",
      "markings_white": "textures/entity/horse/horse_markings_white",
      "markings_whitefield": "textures/entity/horse/horse_markings_whitefield",
      "markings_whitedots": "textures/entity/horse/horse_markings_whitedots",
      "markings_blackdots": "textures/entity/horse/horse_markings_blackdots",
      "mule": "textures/entity/horse/mule",
      "donkey": "textures/entity/horse/donkey",
      "skeleton": "textures/entity/horse/horse_skeleton",
      "zombie": "textures/entity/horse/horse_zombie",
      "armor_none": "textures/entity/horse/armor/horse_armor_none",
      "armor_leather": "textures/entity/horse/armor/horse_armor_leather",
      "armor_iron": "textures/entity/horse/armor/horse_armor_iron",
      "armor_gold": "textures/entity/horse/armor/horse_armor_gold",
      "armor_diamond": "textures/entity/horse/armor/horse_armor_diamond"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 2,
        "visible_bounds_height": 3,
        "visible_bounds_offset": [0, 1, 0],
        "texturewidth": 128,
        "textureheight": 128,
        "bones": [
          {
            "name": "Body",
            "pivot": [0, 13, 9],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5, 11, -10], "size": [10, 10, 24], "uv": [0, 34]}
            ]
          },
          {
            "name": "TailA",
            "pivot": [0, 21, 14],
            "rotation": [-65, 0, 0],
            "cubes": [
              {"origin": [-1, 20, 14], "size": [2, 2, 3], "uv": [44, 0]}
            ]
          },
          {
            "name": "TailB",
            "pivot": [0, 21, 14],
            "rotation": [-65, 0, 0],
            "cubes": [
              {"origin": [-1.5, 19, 17], "size": [3, 4, 7], "uv": [38, 7]}
            ]
          },
          {
            "name": "TailC",
            "pivot": [0, 21, 14],
            "rotation": [-80.34, 0, 0],
            "cubes": [
              {"origin": [-1.5, 21.5, 23], "size": [3, 4, 7], "uv": [24, 3]}
            ]
          },
          {
            "name": "Leg1A",
            "pivot": [4, 15, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.5, 8, 8.5], "size": [4, 9, 5], "uv": [78, 29]}
            ]
          },
          {
            "name": "Leg1B",
            "pivot": [4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2, 3, 9.5], "size": [3, 5, 3], "uv": [78, 43]}
            ]
          },
          {
            "name": "Leg1C",
            "pivot": [4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.5, -0.1, 9], "size": [4, 3, 4], "uv": [78, 51]}
            ]
          },
          {
            "name": "Leg2A",
            "pivot": [-4, 15, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, 8, 8.5], "size": [4, 9, 5], "uv": [96, 29]}
            ]
          },
          {
            "name": "Leg2B",
            "pivot": [-4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5, 3, 9.5], "size": [3, 5, 3], "uv": [96, 43]}
            ]
          },
          {
            "name": "Leg2C",
            "pivot": [-4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, -0.1, 9], "size": [4, 3, 4], "uv": [96, 51]}
            ]
          },
          {
            "name": "Leg3A",
            "pivot": [4, 15, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2.1, 8, -10.1], "size": [3, 8, 4], "uv": [44, 29]}
            ]
          },
          {
            "name": "Leg3B",
            "pivot": [4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2.1, 3, -9.6], "size": [3, 5, 3], "uv": [44, 41]}
            ]
          },
          {
            "name": "Leg3C",
            "pivot": [4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.6, -0.1, -10.1], "size": [4, 3, 4], "uv": [44, 51]}
            ]
          },
          {
            "name": "Leg4A",
            "pivot": [-4, 15, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.1, 8, -10.1], "size": [3, 8, 4], "uv": [60, 29]}
            ]
          },
          {
            "name": "Leg4B",
            "pivot": [-4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.1, 3, -9.6], "size": [3, 5, 3], "uv": [60, 41]}
            ]
          },
          {
            "name": "Leg4C",
            "pivot": [-4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.6, -0.1, -10.1], "size": [4, 3, 4], "uv": [60, 51]}
            ]
          },
          {
            "name": "Head",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.5, 25, -11.5], "size": [5, 5, 7], "uv": [0, 0]}
            ]
          },
          {
            "name": "UMouth",
            "pivot": [0, 20.05, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2, 27.05, -17], "size": [4, 3, 6], "uv": [24, 18]}
            ]
          },
          {
            "name": "LMouth",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2, 25, -16.5], "size": [4, 2, 5], "uv": [24, 27]}
            ]
          },
          {
            "name": "Ear1",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [0.45, 29, -6], "size": [2, 3, 1], "uv": [0, 0]}
            ]
          },
          {
            "name": "Ear2",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.45, 29, -6], "size": [2, 3, 1], "uv": [0, 0]}
            ]
          },
          {
            "name": "MuleEarL",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 15],
            "cubes": [
              {"origin": [-2, 29, -6], "size": [2, 7, 1], "uv": [0, 12]}
            ]
          },
          {
            "name": "MuleEarR",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, -15],
            "cubes": [{"origin": [0, 29, -6], "size": [2, 7, 1], "uv": [0, 12]}]
          },
          {
            "name": "Neck",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.05, 15.8, -12], "size": [4, 14, 8], "uv": [0, 12]}
            ]
          },
          {
            "name": "Bag1",
            "pivot": [-7.5, 21, 10],
            "rotation": [0, 90, 0],
            "cubes": [
              {"origin": [-10.5, 13, 10], "size": [8, 8, 3], "uv": [0, 34]}
            ]
          },
          {
            "name": "Bag2",
            "pivot": [4.5, 21, 10],
            "rotation": [0, 90, 0],
            "cubes": [
              {"origin": [1.5, 13, 10], "size": [8, 8, 3], "uv": [0, 47]}
            ]
          },
          {
            "name": "Saddle",
            "pivot": [0, 22, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5, 21, -1], "size": [10, 1, 8], "uv": [80, 0]}
            ]
          },
          {
            "name": "SaddleB",
            "pivot": [0, 22, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-1.5, 22, -1], "size": [3, 1, 2], "uv": [106, 9]}
            ]
          },
          {
            "name": "SaddleC",
            "pivot": [0, 22, 2],
            "rotation": [0, 0, 0],
            "cubes": [{"origin": [-4, 22, 5], "size": [8, 1, 2], "uv": [80, 9]}]
          },
          {
            "name": "SaddleL2",
            "pivot": [5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [4.5, 13, 1], "size": [1, 2, 2], "uv": [74, 0]}
            ]
          },
          {
            "name": "SaddleL",
            "pivot": [5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [4.5, 15, 1.5], "size": [1, 6, 1], "uv": [70, 0]}
            ]
          },
          {
            "name": "SaddleR2",
            "pivot": [-5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, 13, 1], "size": [1, 2, 2], "uv": [74, 4]}
            ]
          },
          {
            "name": "SaddleR",
            "pivot": [-5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, 15, 1.5], "size": [1, 6, 1], "uv": [80, 0]}
            ]
          },
          {
            "name": "SaddleMouthL",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [1.5, 26, -14], "size": [1, 2, 2], "uv": [74, 13]}
            ]
          },
          {
            "name": "SaddleMouthR",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.5, 26, -14], "size": [1, 2, 2], "uv": [74, 13]}
            ]
          },
          {
            "name": "SaddleMouthLine",
            "pivot": [0, 20, -10],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2.6, 23, -16], "size": [0, 3, 16], "uv": [44, 10]}
            ]
          },
          {
            "name": "SaddleMouthLineR",
            "pivot": [0, 20, -10],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-2.6, 23, -16], "size": [0, 3, 16], "uv": [44, 5]}
            ]
          },
          {
            "name": "Mane",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-1, 15.5, -5], "size": [2, 16, 4], "uv": [58, 0]}
            ]
          },
          {
            "name": "HeadSaddle",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {
                "origin": [-2.5, 25.1, -17],
                "size": [5, 5, 12],
                "uv": [80, 12],
                "inflate": 0.05
              }
            ]
          }
        ]
      }
    },
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 23}
  },
  "husk": {
    "identifier": "minecraft:husk",
    "min_engine_version": "1.8.0",
    "materials": {"default": "husk"},
    "textures": {"default": "textures/entity/zombie/husk"},
    "geometry": {
      "default": {
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 2.5,
        "visible_bounds_offset": [0, 1.25, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 12, -2], "size": [8, 12, 4], "uv": [16, 16]}
            ],
            "parent": "waist"
          },
          {"name": "waist", "neverRender": true, "pivot": [0, 12, 0]},
          {
            "name": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 24, -4], "size": [8, 8, 8], "uv": [0, 0]}
            ],
            "parent": "body"
          },
          {
            "name": "hat",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-4, 24, -4],
                "size": [8, 8, 8],
                "uv": [32, 0],
                "inflate": 0.5
              }
            ],
            "neverRender": true,
            "parent": "head"
          },
          {
            "name": "rightArm",
            "pivot": [-5, 22, 0],
            "cubes": [
              {"origin": [-8, 12, -2], "size": [4, 12, 4], "uv": [40, 16]}
            ],
            "parent": "body"
          },
          {
            "name": "rightItem",
            "pivot": [-6, 15, 1],
            "neverRender": true,
            "parent": "rightArm"
          },
          {
            "name": "leftArm",
            "pivot": [5, 22, 0],
            "cubes": [
              {"origin": [4, 12, -2], "size": [4, 12, 4], "uv": [40, 16]}
            ],
            "mirror": true,
            "parent": "body"
          },
          {
            "name": "rightLeg",
            "pivot": [-1.9, 12, 0],
            "cubes": [
              {"origin": [-3.9, 0, -2], "size": [4, 12, 4], "uv": [0, 16]}
            ],
            "parent": "body"
          },
          {
            "name": "leftLeg",
            "pivot": [1.9, 12, 0],
            "cubes": [
              {"origin": [-0.1, 0, -2], "size": [4, 12, 4], "uv": [0, 16]}
            ],
            "mirror": true,
            "parent": "body"
          }
        ]
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.tcos0 = (Math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed / variable.gliding_speed_value) * 57.3;"
      ]
    },
    "animations": {
      "humanoid_big_head": {"loop": true, "bones": {"head": {"scale": 1.4}}},
      "look_at_target_default": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_gliding": {
        "loop": true,
        "bones": {"head": {"rotation": [-45, "query.target_y_rotation", 0]}}
      },
      "look_at_target_swimming": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "math.lerp(query.target_x_rotation, -45.0, variable.swim_amount)",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "move": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["variable.tcos0", 0, 0]},
          "leftleg": {"rotation": ["variable.tcos0 * -1.4", 0, 0]},
          "rightarm": {"rotation": ["-variable.tcos0", 0, 0]},
          "rightleg": {"rotation": ["variable.tcos0 * 1.4", 0, 0]}
        }
      },
      "riding.arms": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": [-36, 0, 0]},
          "rightarm": {"rotation": [-36, 0, 0]}
        }
      },
      "riding.legs": {
        "loop": true,
        "bones": {
          "leftleg": {"rotation": ["-72.0 - this", "-18.0 - this", "-this"]},
          "rightleg": {"rotation": ["-72.0 - this", "18.0 - this", "-this"]}
        }
      },
      "holding": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "variable.is_holding_left ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "variable.is_holding_right ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          }
        }
      },
      "brandish_spear": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "this * -0.5 - 157.5 - 22.5 * variable.charge_amount",
              "-this",
              0
            ]
          }
        }
      },
      "charging": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": ["22.5 * variable.charge_amount - this", "-this", 0]
          }
        }
      },
      "attack.rotations": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              0,
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46 - this",
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.sin(1.0 - math.pow(1.0 - variable.attack_time, 3.0) * 180.0) * (variable.is_brandishing_spear ? -1.0 : 1.0 )",
              "variable.is_brandishing_spear ? 0.0 : (math.sin(math.sqrt(variable.attack_time) * 360) * 11.46) * 2.0",
              0
            ]
          }
        }
      },
      "sneaking": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["0.5 - this", 0, 0]},
          "head": {"position": [0, 1, 0]},
          "leftarm": {"rotation": [72, 0, 0]},
          "leftleg": {"position": [0, -3, 4]},
          "rightarm": {"rotation": [72, 0, 0]},
          "rightleg": {"position": [0, -3, 4]}
        }
      },
      "bob": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              0,
              0,
              "((math.cos(query.life_time * 103.2) * 2.865) + 2.865) *-1.0"
            ]
          },
          "rightarm": {
            "rotation": [
              0,
              0,
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "damage_nearby_mobs": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["-45.0-this", "-this", "-this"]},
          "leftleg": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightarm": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-45.0-this", "-this", "-this"]}
        }
      },
      "bow_and_arrow": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "query.target_x_rotation - 90.0 - math.sin(query.life_time * 76.8) * 2.865 - this",
              "query.target_y_rotation + 28.65",
              "-(math.cos(query.life_time * 103.2) * 2.865) - 2.865"
            ]
          },
          "rightarm": {
            "rotation": [
              "query.target_x_rotation - 90.0 + math.sin(query.life_time * 76.8) * 2.865 - this",
              "query.target_y_rotation - 5.73",
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "use_item_progress": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "variable.use_item_startup_progress * -60.0 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -22.5 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -5.625 + variable.use_item_interval_progress * 11.25"
            ]
          }
        }
      },
      "zombie_attack_bare_hand": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "-90.0 - ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4) - (math.sin(query.life_time * 76.776372) * 2.865) - this",
              "5.73 - ((math.sin(variable.attack_time * 180.0) * 57.3) * 0.6) - this",
              "math.cos(query.life_time * 103.13244) * -2.865 - 2.865 - this"
            ]
          },
          "rightarm": {
            "rotation": [
              "90.0 * (variable.is_brandishing_spear - 1.0) - ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4) + (math.sin(query.life_time * 76.776372) * 2.865) - this",
              "(math.sin(variable.attack_time * 180.0) * 57.3) * 0.6 - 5.73 - this",
              "math.cos(query.life_time * 103.13244) * 2.865 + 2.865 - this"
            ]
          }
        }
      },
      "swimming": {
        "loop": true,
        "bones": {
          "body": {
            "position": [
              0,
              "variable.swim_amount * -10.0 - this",
              "variable.swim_amount * 9.0 - this"
            ],
            "rotation": [
              "variable.swim_amount * (90.0 + query.target_x_rotation)",
              0,
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.lerp(this, -180.0, variable.swim_amount) - (variable.swim_amount * ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4)) - (variable.swim_amount * (math.sin(query.life_time * 76.776372) * 2.865)) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - (variable.swim_amount * (math.cos(query.life_time * 103.13244) * 2.865 + 2.865)) - this"
            ]
          },
          "leftleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.life_time * 390.0 + 180.0) * 0.3, variable.swim_amount)",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.lerp(this, -180.0, variable.swim_amount) - (variable.swim_amount * ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4)) + (variable.swim_amount * (math.sin(query.life_time * 76.776372) * 2.865)) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - this",
              "math.lerp(this, -14.325, variable.swim_amount) + (variable.swim_amount * (math.cos(query.life_time * 103.13244) * 2.865 + 2.865)) - this"
            ]
          },
          "rightleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.life_time * 390.0) * 0.3, variable.swim_amount)",
              0,
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "humanoid_baby_big_head": {
        "initial_state": "default",
        "states": {
          "baby": {
            "animations": ["humanoid_big_head"],
            "transitions": [{"default": "!query.is_baby"}]
          },
          "default": {"transitions": [{"baby": "query.is_baby"}]}
        }
      },
      "look_at_target": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["look_at_target_default"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"swimming": "query.is_swimming"}
            ]
          },
          "gliding": {
            "animations": ["look_at_target_gliding"],
            "transitions": [
              {"swimming": "query.is_swimming"},
              {"default": "!query.is_gliding"}
            ]
          },
          "swimming": {
            "animations": ["look_at_target_swimming"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"default": "!query.is_swimming"}
            ]
          }
        }
      },
      "move": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move"]}}
      },
      "riding": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"riding": "query.is_riding"}]},
          "riding": {
            "animations": ["riding.arms", "riding.legs"],
            "transitions": [{"default": "!query.is_riding"}]
          }
        }
      },
      "holding": {
        "initial_state": "default",
        "states": {"default": {"animations": ["holding"]}}
      },
      "brandish_spear": {
        "initial_state": "default",
        "states": {
          "brandish_spear": {
            "animations": ["brandish_spear"],
            "transitions": [{"default": "!variable.is_brandishing_spear"}]
          },
          "default": {
            "transitions": [{"brandish_spear": "variable.is_brandishing_spear"}]
          }
        }
      },
      "charging": {
        "initial_state": "default",
        "states": {
          "charging": {
            "animations": ["charging"],
            "transitions": [{"default": "!query.is_charging"}]
          },
          "default": {"transitions": [{"charging": "query.is_charging"}]}
        }
      },
      "attack": {
        "initial_state": "default",
        "states": {
          "attacking": {
            "animations": ["attack.rotations"],
            "transitions": [{"default": "variable.attack_time < 0.0"}]
          },
          "default": {
            "transitions": [{"attacking": "variable.attack_time >= 0.0"}]
          }
        }
      },
      "sneaking": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"sneaking": "query.is_sneaking"}]},
          "sneaking": {
            "animations": ["sneaking"],
            "transitions": [{"default": "!query.is_sneaking"}]
          }
        }
      },
      "bob": {
        "initial_state": "default",
        "states": {"default": {"animations": ["bob"]}}
      },
      "damage_nearby_mobs": {
        "initial_state": "default",
        "states": {
          "damage_nearby_mobs": {
            "animations": ["damage_nearby_mobs"],
            "transitions": [{"default": "!variable.damage_nearby_mobs"}]
          },
          "default": {
            "transitions": [
              {"damage_nearby_mobs": "variable.damage_nearby_mobs"}
            ]
          }
        }
      },
      "bow_and_arrow": {
        "initial_state": "default",
        "states": {
          "bow_and_arrow": {
            "animations": ["bow_and_arrow"],
            "transitions": [{"default": "!query.has_target"}]
          },
          "default": {"transitions": [{"bow_and_arrow": "query.has_target"}]}
        }
      },
      "use_item_progress": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [
              {
                "use_item_progress": "( variable.use_item_interval_progress > 0.0 ) || ( variable.use_item_startup_progress > 0.0 )"
              }
            ]
          },
          "use_item_progress": {
            "animations": ["use_item_progress"],
            "transitions": [
              {
                "default": "( variable.use_item_interval_progress <= 0.0 ) && ( variable.use_item_startup_progress <= 0.0 )"
              }
            ]
          }
        }
      },
      "zombie_attack_bare_hand": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [{"is_bare_hand": "variable.is_holding_left != 1.0"}]
          },
          "is_bare_hand": {
            "animations": ["zombie_attack_bare_hand"],
            "transitions": [{"default": "variable.is_holding_left == 1.0"}]
          }
        }
      },
      "swimming": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [{"is_swimming": "variable.swim_amount > 0.0"}]
          },
          "is_swimming": {
            "animations": ["swimming"],
            "transitions": [{"default": "variable.swim_amount <= 0.0"}]
          }
        }
      }
    },
    "render_controllers": ["controller.render.husk"],
    "enable_attachables": true,
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 28}
  },
  "iron_golem": {
    "identifier": "minecraft:iron_golem",
    "materials": {"default": "iron_golem"},
    "textures": {"default": "textures/entity/iron_golem/iron_golem"},
    "geometry": {
      "default": {
        "visible_bounds_width": 3,
        "visible_bounds_height": 3,
        "visible_bounds_offset": [0, 1.5, 0],
        "texturewidth": 128,
        "textureheight": 128,
        "bones": [
          {
            "name": "body",
            "pivot": [0, 31, 0],
            "cubes": [
              {"origin": [-9, 21, -6], "size": [18, 12, 11], "uv": [0, 40]},
              {
                "origin": [-4.5, 16, -3],
                "size": [9, 5, 6],
                "uv": [0, 70],
                "inflate": 0.5
              }
            ]
          },
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 31, -2],
            "locators": {"lead": [0, 31, -2]},
            "cubes": [
              {"origin": [-4, 33, -7.5], "size": [8, 10, 8], "uv": [0, 0]},
              {"origin": [-1, 32, -9.5], "size": [2, 4, 2], "uv": [24, 0]}
            ]
          },
          {
            "name": "arm0",
            "parent": "body",
            "pivot": [0, 31, 0],
            "cubes": [
              {"origin": [-13, 3.5, -3], "size": [4, 30, 6], "uv": [60, 21]}
            ]
          },
          {
            "name": "arm1",
            "parent": "body",
            "pivot": [0, 31, 0],
            "cubes": [
              {"origin": [9, 3.5, -3], "size": [4, 30, 6], "uv": [60, 58]}
            ]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-4, 13, 0],
            "cubes": [
              {"origin": [-7.5, 0, -3], "size": [6, 16, 5], "uv": [37, 0]}
            ]
          },
          {
            "name": "leg1",
            "parent": "body",
            "mirror": true,
            "pivot": [5, 13, 0],
            "cubes": [
              {"origin": [1.5, 0, -3], "size": [6, 16, 5], "uv": [60, 0]}
            ]
          }
        ]
      }
    },
    "animations": {
      "walk": {
        "loop": true,
        "bones": {
          "body": {"rotation": [0, 0, "variable.modified_tcos0 / 1.5"]},
          "head": {"rotation": [0, 0, "variable.modified_tcos0 / 1.5"]},
          "leg0": {"rotation": ["variable.modified_tcos0 * 6.0", 0, 0]},
          "leg1": {"rotation": ["-variable.modified_tcos0 * 6.0", 0, 0]}
        }
      },
      "move": {
        "loop": true,
        "bones": {
          "arm0": {"rotation": ["-variable.modified_tcos0 * 2.0", 0, 0]},
          "arm1": {"rotation": ["variable.modified_tcos0 * 2.0", 0, 0]}
        }
      },
      "walk_to_target": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              0,
              0,
              "2.0 * (math.abs(math.mod(query.modified_distance_moved + 6, 13.0) - 6.5) - 3.25)"
            ]
          },
          "head": {
            "rotation": [
              0,
              0,
              "2.0 * (math.abs(math.mod(query.modified_distance_moved + 6, 13.0) - 6.5) - 3.25)"
            ]
          },
          "leg0": {
            "rotation": [
              "(math.cos(query.modified_distance_moved * 38.17) * 40.0)",
              0,
              0
            ]
          },
          "leg1": {
            "rotation": [
              "(math.cos(query.modified_distance_moved * 38.17 + 180) * 40.0)",
              0,
              0
            ]
          }
        }
      },
      "move_to_target": {
        "loop": true,
        "bones": {
          "arm0": {
            "rotation": [
              "((math.abs(math.mod(query.modified_distance_moved, 13) - 6.5) - 3.25) / 2.25) * 30.0",
              0,
              0
            ]
          },
          "arm1": {
            "rotation": [
              "((math.abs(math.mod(query.modified_distance_moved, 13) - 6.5) - 3.25) / 2.25) * -30.0",
              0,
              0
            ]
          }
        }
      },
      "attack": {
        "loop": true,
        "bones": {
          "arm0": {
            "rotation": [
              "-114 + ((1.5 * math.abs(math.mod(variable.attack_animation_tick - query.frame_alpha, 10) - 5) - 2.5) / 5) * 57.3",
              0,
              0
            ]
          },
          "arm1": {
            "rotation": [
              "-114 + ((1.5 * math.abs(math.mod(variable.attack_animation_tick - query.frame_alpha, 10) - 5) - 2.5) / 5) * 57.3",
              0,
              0
            ]
          }
        }
      },
      "flower": {
        "loop": true,
        "bones": {
          "arm0": {
            "rotation": [
              "-45.8 + ((0.25 * math.abs(math.mod(variable.offer_flower_tick, 70) - 35) - 17.5) / 35)",
              0,
              0
            ]
          }
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.modified_tcos0 = Math.clamp(((Math.cos(query.modified_distance_moved * 13.5) * Math.min(query.modified_move_speed, 0.6) / variable.gliding_speed_value) * 25.0), -12.5, 12.5);"
      ],
      "animate": ["look_at_target", "move_controller", "arm_controller"]
    },
    "render_controllers": ["controller.render.iron_golem"],
    "animation_controllers": {
      "move_controller": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": [{"walk": "query.modified_move_speed"}],
            "transitions": [{"has_target": "query.has_target"}]
          },
          "has_target": {
            "animations": [{"walk_to_target": "query.modified_move_speed"}],
            "transitions": [{"default": "!query.has_target"}]
          }
        }
      },
      "arm_controller": {
        "initial_state": "default",
        "states": {
          "attack": {
            "animations": ["attack"],
            "transitions": [
              {
                "default": "!query.has_target && variable.attack_animation_tick <= 0.0"
              },
              {
                "has_target": "query.has_target && variable.attack_animation_tick <= 0.0"
              }
            ]
          },
          "default": {
            "animations": ["move"],
            "transitions": [
              {"attack": "variable.attack_animation_tick > 0.0"},
              {"flower": "variable.offer_flower_tick"},
              {"has_target": "query.has_target"}
            ]
          },
          "flower": {
            "animations": ["flower"],
            "transitions": [
              {"attack": "variable.attack_animation_tick > 0.0"},
              {"default": "variable.offer_flower_tick <= 0.0"},
              {"has_target": "query.has_target"}
            ]
          },
          "has_target": {
            "animations": ["move_to_target"],
            "transitions": [
              {"attack": "variable.attack_animation_tick > 0.0"},
              {"default": "!query.has_target"},
              {"flower": "variable.offer_flower_tick"}
            ]
          }
        }
      }
    }
  },
  "leash_knot": {
    "identifier": "minecraft:leash_knot",
    "materials": {"default": "leash_knot"},
    "textures": {"default": "textures/entity/lead_knot"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "knot",
            "cubes": [{"origin": [-3, 2, -3], "size": [6, 8, 6]}]
          }
        ],
        "texturewidth": 32,
        "textureheight": 32
      }
    },
    "render_controllers": ["controller.render.leash_knot"]
  },
  "llama": {
    "identifier": "minecraft:llama",
    "min_engine_version": "1.8.0",
    "materials": {"default": "llama"},
    "textures": {
      "creamy": "textures/entity/llama/creamy",
      "white": "textures/entity/llama/white",
      "brown": "textures/entity/llama/brown",
      "gray": "textures/entity/llama/gray",
      "decor_none": "textures/entity/llama/decor/decor_none",
      "decor_white": "textures/entity/llama/decor/white",
      "decor_orange": "textures/entity/llama/decor/orange",
      "decor_magenta": "textures/entity/llama/decor/magenta",
      "decor_light_blue": "textures/entity/llama/decor/light_blue",
      "decor_yellow": "textures/entity/llama/decor/yellow",
      "decor_lime": "textures/entity/llama/decor/lime",
      "decor_pink": "textures/entity/llama/decor/pink",
      "decor_gray": "textures/entity/llama/decor/gray",
      "decor_silver": "textures/entity/llama/decor/light_gray",
      "decor_cyan": "textures/entity/llama/decor/cyan",
      "decor_purple": "textures/entity/llama/decor/purple",
      "decor_blue": "textures/entity/llama/decor/blue",
      "decor_brown": "textures/entity/llama/decor/brown",
      "decor_green": "textures/entity/llama/decor/green",
      "decor_red": "textures/entity/llama/decor/red",
      "decor_black": "textures/entity/llama/decor/black",
      "decor_wandering_trader": "textures/entity/llama/decor/trader_llama"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 2,
        "visible_bounds_height": 2.5,
        "visible_bounds_offset": [0, 1, 0],
        "texturewidth": 128,
        "textureheight": 64,
        "bones": [
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 17, -6],
            "locators": {"lead": [0, 18, -11]},
            "cubes": [
              {"origin": [-2, 27, -16], "size": [4, 4, 9], "uv": [0, 0]},
              {"origin": [-4, 15, -12], "size": [8, 18, 6], "uv": [0, 14]},
              {"origin": [-4, 33, -10], "size": [3, 3, 2], "uv": [17, 0]},
              {"origin": [1, 33, -10], "size": [3, 3, 2], "uv": [17, 0]}
            ]
          },
          {
            "name": "chest1",
            "parent": "body",
            "pivot": [-8.5, 21, 3],
            "rotation": [0, 90, 0],
            "cubes": [
              {"origin": [-11.5, 13, 3], "size": [8, 8, 3], "uv": [45, 28]}
            ]
          },
          {
            "name": "chest2",
            "parent": "body",
            "pivot": [5.5, 21, 3],
            "rotation": [0, 90, 0],
            "cubes": [
              {"origin": [2.5, 13, 3], "size": [8, 8, 3], "uv": [45, 41]}
            ]
          },
          {
            "name": "body",
            "pivot": [0, 19, 2],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {"origin": [-6, 11, -5], "size": [12, 18, 10], "uv": [29, 0]}
            ]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-3.5, 14, 6],
            "cubes": [
              {"origin": [-5.5, 0, 4], "size": [4, 14, 4], "uv": [29, 29]}
            ]
          },
          {
            "name": "leg1",
            "parent": "body",
            "pivot": [3.5, 14, 6],
            "cubes": [
              {"origin": [1.5, 0, 4], "size": [4, 14, 4], "uv": [29, 29]}
            ]
          },
          {
            "name": "leg2",
            "parent": "body",
            "pivot": [-3.5, 14, -5],
            "cubes": [
              {"origin": [-5.5, 0, -7], "size": [4, 14, 4], "uv": [29, 29]}
            ]
          },
          {
            "name": "leg3",
            "parent": "body",
            "pivot": [3.5, 14, -5],
            "cubes": [
              {"origin": [1.5, 0, -7], "size": [4, 14, 4], "uv": [29, 29]}
            ]
          }
        ]
      }
    },
    "animations": {
      "setup": {"loop": true, "bones": {"body": {"rotation": ["-this", 0, 0]}}},
      "walk": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          },
          "leg1": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          },
          "leg2": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          },
          "leg3": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          }
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "baby_transform": {
        "loop": true,
        "bones": {
          "body": {"position": [0, -5.5, -5], "scale": [1.2, 1, 1]},
          "head": {"position": [0, 2, 0], "scale": [1.3, 1.2, 1.2]},
          "leg0": {"position": [0, -1, 0], "scale": [0.91, 0.56, 0.91]},
          "leg1": {"position": [0, -1, 0], "scale": [0.91, 0.56, 0.91]},
          "leg2": {"position": [0, -1, 0], "scale": [0.91, 0.56, 0.91]},
          "leg3": {"position": [0, -1, 0], "scale": [0.91, 0.56, 0.91]}
        }
      }
    },
    "animation_controllers": {
      "move": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": [
              "setup",
              {"walk": "query.modified_move_speed"},
              "look_at_target"
            ]
          }
        }
      },
      "baby": {
        "initial_state": "baby",
        "states": {
          "baby": {"animations": [{"baby_transform": "query.is_baby"}]}
        }
      }
    },
    "render_controllers": ["controller.render.llama"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 38}
  },
  "llama_spit": {
    "identifier": "minecraft:llama_spit",
    "materials": {"default": "llama_spit"},
    "textures": {"default": "textures/entity/llama/spit"},
    "geometry": {
      "default": {
        "visible_bounds_width": 1,
        "visible_bounds_height": 1,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 64,
        "bones": [
          {
            "name": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 22, 0], "size": [2, 2, 2], "uv": [0, 0]},
              {"origin": [0, 26, 0], "size": [2, 2, 2], "uv": [0, 0]},
              {"origin": [0, 22, -4], "size": [2, 2, 2], "uv": [0, 0]},
              {"origin": [0, 22, 0], "size": [2, 2, 2], "uv": [0, 0]},
              {"origin": [2, 22, 0], "size": [2, 2, 2], "uv": [0, 0]},
              {"origin": [0, 20, 0], "size": [2, 2, 2], "uv": [0, 0]},
              {"origin": [0, 22, 2], "size": [2, 2, 2], "uv": [0, 0]}
            ]
          }
        ]
      }
    },
    "animations": {
      "setup": {
        "loop": true,
        "bones": {
          "body": {
            "position": [0, -15, 0],
            "rotation": [
              0,
              "query.target_y_rotation - 90.0 - this",
              "query.target_x_rotation - this"
            ]
          }
        }
      }
    },
    "scripts": {"animate": ["setup"]},
    "render_controllers": ["controller.render.llama_spit"]
  },
  "magma_cube": {
    "identifier": "minecraft:magma_cube",
    "materials": {"default": "magma_cube"},
    "textures": {"default": "textures/entity/slime/magmacube"},
    "geometry": {
      "default": {
        "visible_bounds_width": 2.5,
        "visible_bounds_height": 5,
        "visible_bounds_offset": [0, 2.5, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "bodyCube_0",
            "parent": "insideCube",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-4, 7, -4], "size": [8, 1, 8], "uv": [0, 0]}]
          },
          {
            "name": "bodyCube_1",
            "parent": "insideCube",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-4, 6, -4], "size": [8, 1, 8], "uv": [0, 1]}]
          },
          {
            "name": "bodyCube_2",
            "parent": "insideCube",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 5, -4], "size": [8, 1, 8], "uv": [24, 10]}
            ]
          },
          {
            "name": "bodyCube_3",
            "parent": "insideCube",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 4, -4], "size": [8, 1, 8], "uv": [24, 19]}
            ]
          },
          {
            "name": "bodyCube_4",
            "parent": "insideCube",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-4, 3, -4], "size": [8, 1, 8], "uv": [0, 4]}]
          },
          {
            "name": "bodyCube_5",
            "parent": "insideCube",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-4, 2, -4], "size": [8, 1, 8], "uv": [0, 5]}]
          },
          {
            "name": "bodyCube_6",
            "parent": "insideCube",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-4, 1, -4], "size": [8, 1, 8], "uv": [0, 6]}]
          },
          {
            "name": "bodyCube_7",
            "parent": "insideCube",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-4, 0, -4], "size": [8, 1, 8], "uv": [0, 7]}]
          },
          {
            "name": "insideCube",
            "pivot": [0, 0, 0],
            "cubes": [{"origin": [-2, 2, -2], "size": [4, 4, 4], "uv": [0, 16]}]
          }
        ]
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.slime_squish_factor = (query.previous_squish_value + (query.current_squish_value - query.previous_squish_value) * query.frame_alpha);",
        "variable.bounce = 1 / ((variable.slime_squish_factor / (query.variant * 0.5 + 1)) + 1);",
        "variable.horizontal_scale_amount = variable.bounce * query.variant;",
        "variable.vertical_scale_amount = (1 / variable.bounce) * query.variant;"
      ],
      "scaleX": "variable.horizontal_scale_amount",
      "scaleY": "variable.vertical_scale_amount",
      "scaleZ": "variable.horizontal_scale_amount"
    },
    "animations": {
      "move": {
        "loop": true,
        "bones": {
          "bodycube_0": {
            "position": [
              0,
              "3.0 * (variable.slime_squish_factor < 0.0 ? 0.0 : variable.slime_squish_factor) * 1.7",
              0
            ]
          },
          "bodycube_1": {
            "position": [
              0,
              "2.0 * (variable.slime_squish_factor < 0.0 ? 0.0 : variable.slime_squish_factor) * 1.7",
              0
            ]
          },
          "bodycube_2": {
            "position": [
              0,
              "1.0 * (variable.slime_squish_factor < 0.0 ? 0.0 : variable.slime_squish_factor) * 1.7",
              0
            ]
          },
          "bodycube_4": {
            "position": [
              0,
              "-1.0 * (variable.slime_squish_factor < 0.0 ? 0.0 : variable.slime_squish_factor) * 1.7",
              0
            ]
          },
          "bodycube_5": {
            "position": [
              0,
              "-2.0 * (variable.slime_squish_factor < 0.0 ? 0.0 : variable.slime_squish_factor) * 1.7",
              0
            ]
          },
          "bodycube_6": {
            "position": [
              0,
              "-3.0 * (variable.slime_squish_factor < 0.0 ? 0.0 : variable.slime_squish_factor) * 1.7",
              0
            ]
          },
          "bodycube_7": {
            "position": [
              0,
              "-4.0 * (variable.slime_squish_factor < 0.0 ? 0.0 : variable.slime_squish_factor) * 1.7",
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "general": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move"]}}
      }
    },
    "render_controllers": ["controller.render.magma_cube"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 20}
  },
  "minecart": {
    "identifier": "minecraft:minecart",
    "min_engine_version": "1.8.0",
    "materials": {"default": "minecart"},
    "textures": {"default": "textures/entity/minecart"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "bottom",
            "pivot": [0, 6, 0],
            "cubes": [
              {
                "origin": [-10, -6.5, -1],
                "size": [20, 16, 2],
                "rotation": [90, 0, 0],
                "uv": [0, 10]
              }
            ]
          },
          {
            "name": "back",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-17, 2.5, -1],
                "size": [16, 8, 2],
                "rotation": [0, 270, 0],
                "uv": [0, 0]
              }
            ],
            "parent": "bottom"
          },
          {
            "name": "front",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [1, 2.5, -1],
                "size": [16, 8, 2],
                "rotation": [0, 90, 0],
                "uv": [0, 0]
              }
            ],
            "parent": "bottom"
          },
          {
            "name": "right",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-8, 2.5, -8],
                "size": [16, 8, 2],
                "rotation": [0, 180, 0],
                "uv": [0, 0]
              }
            ],
            "parent": "bottom"
          },
          {
            "name": "left",
            "pivot": [0, 0, 0],
            "cubes": [
              {"origin": [-8, 2.5, 6], "size": [16, 8, 2], "uv": [0, 0]}
            ],
            "parent": "bottom"
          }
        ],
        "texturewidth": 64,
        "textureheight": 32
      }
    },
    "scripts": {
      "pre_animation": ["variable.hurt = query.hurt_time - query.frame_alpha;"],
      "animate": ["move"]
    },
    "animations": {
      "move": {
        "loop": true,
        "bones": {
          "bottom": {
            "position": [
              "variable.rail_offset.x / query.model_scale",
              "variable.rail_offset.y / query.model_scale",
              "variable.rail_offset.z / query.model_scale"
            ],
            "rotation": [
              "variable.hurt > 0 ? -Math.sin(variable.hurt * 360 / (Math.pi * 2)) * variable.hurt * (((20 * 2 - query.structural_integrity) - query.frame_alpha) < 0 ? 0: (20 * 2 - query.structural_integrity) - query.frame_alpha) / 10 * query.hurt_direction : 0",
              0,
              "-variable.rail_rotation.z"
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.minecart"]
  },
  "mooshroom": {
    "identifier": "minecraft:mooshroom",
    "min_engine_version": "1.8.0",
    "materials": {"default": "mooshroom"},
    "textures": {
      "default": "textures/entity/cow/red_mooshroom",
      "brown": "textures/entity/cow/brown_mooshroom"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 2,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 1, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "body",
            "pivot": [0, 19, 2],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {"origin": [-6, 11, -5], "size": [12, 18, 10], "uv": [18, 4]},
              {"origin": [-2, 11, -6], "size": [4, 6, 1], "uv": [52, 0]}
            ]
          },
          {
            "name": "head",
            "pivot": [0, 20, -8],
            "locators": {"lead": [0, 20, -8]},
            "cubes": [
              {"origin": [-4, 16, -14], "size": [8, 8, 6], "uv": [0, 0]},
              {"origin": [-5, 22, -12], "size": [1, 3, 1], "uv": [22, 0]},
              {"origin": [4, 22, -12], "size": [1, 3, 1], "uv": [22, 0]}
            ]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-4, 12, 7],
            "cubes": [{"origin": [-6, 0, 5], "size": [4, 12, 4], "uv": [0, 16]}]
          },
          {
            "name": "leg1",
            "parent": "body",
            "mirror": true,
            "pivot": [4, 12, 7],
            "cubes": [{"origin": [2, 0, 5], "size": [4, 12, 4], "uv": [0, 16]}]
          },
          {
            "name": "leg2",
            "parent": "body",
            "pivot": [-4, 12, -6],
            "cubes": [
              {"origin": [-6, 0, -7], "size": [4, 12, 4], "uv": [0, 16]}
            ]
          },
          {
            "name": "leg3",
            "parent": "body",
            "mirror": true,
            "pivot": [4, 12, -6],
            "cubes": [{"origin": [2, 0, -7], "size": [4, 12, 4], "uv": [0, 16]}]
          }
        ]
      }
    },
    "animations": {
      "setup": {"loop": true, "bones": {"body": {"rotation": ["-this", 0, 0]}}},
      "walk": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          },
          "leg1": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          },
          "leg2": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          },
          "leg3": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          }
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "baby_transform": {
        "loop": true,
        "bones": {
          "head": {
            "position": [
              0,
              "query.is_baby ? 4.0 : 0.0",
              "query.is_baby ? 4.0 : 0.0"
            ],
            "scale": "query.is_baby ? 2.0 : 1.0"
          }
        }
      }
    },
    "animation_controllers": {
      "setup": {
        "initial_state": "default",
        "states": {"default": {"animations": ["setup"]}}
      },
      "move": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": [
              {"walk": "query.modified_move_speed"},
              "look_at_target"
            ]
          }
        }
      },
      "baby": {
        "initial_state": "default",
        "states": {"default": {"animations": ["baby_transform"]}}
      }
    },
    "render_controllers": ["controller.render.mooshroom"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 5}
  },
  "mule": {
    "identifier": "minecraft:mule",
    "textures": {
      "base_brown": "textures/entity/horse/horse_brown",
      "base_white": "textures/entity/horse/horse_white",
      "base_chestnut": "textures/entity/horse/horse_chestnut",
      "base_creamy": "textures/entity/horse/horse_creamy",
      "base_black": "textures/entity/horse/horse_black",
      "base_gray": "textures/entity/horse/horse_gray",
      "base_darkbrown": "textures/entity/horse/horse_darkbrown",
      "markings_none": "textures/entity/horse/horse_markings_none",
      "markings_white": "textures/entity/horse/horse_markings_white",
      "markings_whitefield": "textures/entity/horse/horse_markings_whitefield",
      "markings_whitedots": "textures/entity/horse/horse_markings_whitedots",
      "markings_blackdots": "textures/entity/horse/horse_markings_blackdots",
      "mule": "textures/entity/horse/mule",
      "donkey": "textures/entity/horse/donkey",
      "skeleton": "textures/entity/horse/horse_skeleton",
      "zombie": "textures/entity/horse/horse_zombie",
      "armor_none": "textures/entity/horse/armor/horse_armor_none",
      "armor_leather": "textures/entity/horse/armor/horse_armor_leather",
      "armor_iron": "textures/entity/horse/armor/horse_armor_iron",
      "armor_gold": "textures/entity/horse/armor/horse_armor_gold",
      "armor_diamond": "textures/entity/horse/armor/horse_armor_diamond"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 2,
        "visible_bounds_height": 3,
        "visible_bounds_offset": [0, 1, 0],
        "texturewidth": 128,
        "textureheight": 128,
        "bones": [
          {
            "name": "Body",
            "pivot": [0, 13, 9],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5, 11, -10], "size": [10, 10, 24], "uv": [0, 34]}
            ]
          },
          {
            "name": "TailA",
            "pivot": [0, 21, 14],
            "rotation": [-65, 0, 0],
            "cubes": [
              {"origin": [-1, 20, 14], "size": [2, 2, 3], "uv": [44, 0]}
            ]
          },
          {
            "name": "TailB",
            "pivot": [0, 21, 14],
            "rotation": [-65, 0, 0],
            "cubes": [
              {"origin": [-1.5, 19, 17], "size": [3, 4, 7], "uv": [38, 7]}
            ]
          },
          {
            "name": "TailC",
            "pivot": [0, 21, 14],
            "rotation": [-80.34, 0, 0],
            "cubes": [
              {"origin": [-1.5, 21.5, 23], "size": [3, 4, 7], "uv": [24, 3]}
            ]
          },
          {
            "name": "Leg1A",
            "pivot": [4, 15, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.5, 8, 8.5], "size": [4, 9, 5], "uv": [78, 29]}
            ]
          },
          {
            "name": "Leg1B",
            "pivot": [4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2, 3, 9.5], "size": [3, 5, 3], "uv": [78, 43]}
            ]
          },
          {
            "name": "Leg1C",
            "pivot": [4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.5, -0.1, 9], "size": [4, 3, 4], "uv": [78, 51]}
            ]
          },
          {
            "name": "Leg2A",
            "pivot": [-4, 15, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, 8, 8.5], "size": [4, 9, 5], "uv": [96, 29]}
            ]
          },
          {
            "name": "Leg2B",
            "pivot": [-4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5, 3, 9.5], "size": [3, 5, 3], "uv": [96, 43]}
            ]
          },
          {
            "name": "Leg2C",
            "pivot": [-4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, -0.1, 9], "size": [4, 3, 4], "uv": [96, 51]}
            ]
          },
          {
            "name": "Leg3A",
            "pivot": [4, 15, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2.1, 8, -10.1], "size": [3, 8, 4], "uv": [44, 29]}
            ]
          },
          {
            "name": "Leg3B",
            "pivot": [4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2.1, 3, -9.6], "size": [3, 5, 3], "uv": [44, 41]}
            ]
          },
          {
            "name": "Leg3C",
            "pivot": [4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.6, -0.1, -10.1], "size": [4, 3, 4], "uv": [44, 51]}
            ]
          },
          {
            "name": "Leg4A",
            "pivot": [-4, 15, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.1, 8, -10.1], "size": [3, 8, 4], "uv": [60, 29]}
            ]
          },
          {
            "name": "Leg4B",
            "pivot": [-4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.1, 3, -9.6], "size": [3, 5, 3], "uv": [60, 41]}
            ]
          },
          {
            "name": "Leg4C",
            "pivot": [-4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.6, -0.1, -10.1], "size": [4, 3, 4], "uv": [60, 51]}
            ]
          },
          {
            "name": "Head",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.5, 25, -11.5], "size": [5, 5, 7], "uv": [0, 0]}
            ]
          },
          {
            "name": "UMouth",
            "pivot": [0, 20.05, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2, 27.05, -17], "size": [4, 3, 6], "uv": [24, 18]}
            ]
          },
          {
            "name": "LMouth",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2, 25, -16.5], "size": [4, 2, 5], "uv": [24, 27]}
            ]
          },
          {
            "name": "Ear1",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [0.45, 29, -6], "size": [2, 3, 1], "uv": [0, 0]}
            ]
          },
          {
            "name": "Ear2",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.45, 29, -6], "size": [2, 3, 1], "uv": [0, 0]}
            ]
          },
          {
            "name": "MuleEarL",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 15],
            "cubes": [
              {"origin": [-2, 29, -6], "size": [2, 7, 1], "uv": [0, 12]}
            ]
          },
          {
            "name": "MuleEarR",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, -15],
            "cubes": [{"origin": [0, 29, -6], "size": [2, 7, 1], "uv": [0, 12]}]
          },
          {
            "name": "Neck",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.05, 15.8, -12], "size": [4, 14, 8], "uv": [0, 12]}
            ]
          },
          {
            "name": "Bag1",
            "pivot": [-7.5, 21, 10],
            "rotation": [0, 90, 0],
            "cubes": [
              {"origin": [-10.5, 13, 10], "size": [8, 8, 3], "uv": [0, 34]}
            ]
          },
          {
            "name": "Bag2",
            "pivot": [4.5, 21, 10],
            "rotation": [0, 90, 0],
            "cubes": [
              {"origin": [1.5, 13, 10], "size": [8, 8, 3], "uv": [0, 47]}
            ]
          },
          {
            "name": "Saddle",
            "pivot": [0, 22, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5, 21, -1], "size": [10, 1, 8], "uv": [80, 0]}
            ]
          },
          {
            "name": "SaddleB",
            "pivot": [0, 22, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-1.5, 22, -1], "size": [3, 1, 2], "uv": [106, 9]}
            ]
          },
          {
            "name": "SaddleC",
            "pivot": [0, 22, 2],
            "rotation": [0, 0, 0],
            "cubes": [{"origin": [-4, 22, 5], "size": [8, 1, 2], "uv": [80, 9]}]
          },
          {
            "name": "SaddleL2",
            "pivot": [5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [4.5, 13, 1], "size": [1, 2, 2], "uv": [74, 0]}
            ]
          },
          {
            "name": "SaddleL",
            "pivot": [5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [4.5, 15, 1.5], "size": [1, 6, 1], "uv": [70, 0]}
            ]
          },
          {
            "name": "SaddleR2",
            "pivot": [-5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, 13, 1], "size": [1, 2, 2], "uv": [74, 4]}
            ]
          },
          {
            "name": "SaddleR",
            "pivot": [-5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, 15, 1.5], "size": [1, 6, 1], "uv": [80, 0]}
            ]
          },
          {
            "name": "SaddleMouthL",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [1.5, 26, -14], "size": [1, 2, 2], "uv": [74, 13]}
            ]
          },
          {
            "name": "SaddleMouthR",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.5, 26, -14], "size": [1, 2, 2], "uv": [74, 13]}
            ]
          },
          {
            "name": "SaddleMouthLine",
            "pivot": [0, 20, -10],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2.6, 23, -16], "size": [0, 3, 16], "uv": [44, 10]}
            ]
          },
          {
            "name": "SaddleMouthLineR",
            "pivot": [0, 20, -10],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-2.6, 23, -16], "size": [0, 3, 16], "uv": [44, 5]}
            ]
          },
          {
            "name": "Mane",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-1, 15.5, -5], "size": [2, 16, 4], "uv": [58, 0]}
            ]
          },
          {
            "name": "HeadSaddle",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {
                "origin": [-2.5, 25.1, -17],
                "size": [5, 5, 12],
                "uv": [80, 12],
                "inflate": 0.05
              }
            ]
          }
        ]
      }
    },
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 31}
  },
  "ocelot": {
    "identifier": "minecraft:ocelot",
    "min_engine_version": "1.8.0",
    "materials": {"default": "ocelot"},
    "textures": {
      "black": "textures/entity/cat/black",
      "red": "textures/entity/cat/red",
      "siamese": "textures/entity/cat/siamese",
      "wild": "textures/entity/cat/ocelot"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 2.5,
        "visible_bounds_height": 1,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "pivot": [0, 9, -9],
            "locators": {"lead": [0, 9, -9]},
            "cubes": [
              {"origin": [-2.5, 7, -12], "size": [5, 4, 5], "uv": [0, 0]},
              {
                "origin": [-1.5, 7.01562, -13],
                "size": [3, 2, 2],
                "uv": [0, 24]
              },
              {"origin": [-2, 11, -9], "size": [1, 1, 2], "uv": [0, 10]},
              {"origin": [1, 11, -9], "size": [1, 1, 2], "uv": [6, 10]}
            ],
            "name": "head",
            "parent": "body"
          },
          {
            "pivot": [0, 7, 1],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {"origin": [-2, -1, -2], "size": [4, 16, 6], "uv": [20, 0]}
            ],
            "name": "body"
          },
          {
            "pivot": [0, 9, 8],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {"origin": [-0.5, 1, 8], "size": [1, 8, 1], "uv": [0, 15]}
            ],
            "name": "tail1",
            "parent": "body"
          },
          {
            "pivot": [0, 9, 16],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {"origin": [-0.5, 1, 16], "size": [1, 8, 1], "uv": [4, 15]}
            ],
            "name": "tail2",
            "parent": "tail1"
          },
          {
            "pivot": [1.1, 6, 7],
            "cubes": [
              {"origin": [0.1, 0, 6], "size": [2, 6, 2], "uv": [8, 13]}
            ],
            "name": "backLegL",
            "parent": "body"
          },
          {
            "pivot": [-1.1, 6, 7],
            "cubes": [
              {"origin": [-2.1, 0, 6], "size": [2, 6, 2], "uv": [8, 13]}
            ],
            "name": "backLegR",
            "parent": "body"
          },
          {
            "pivot": [1.2, 10, -4],
            "cubes": [
              {"origin": [0.2, 0.2, -5], "size": [2, 10, 2], "uv": [40, 0]}
            ],
            "name": "frontLegL",
            "parent": "body"
          },
          {
            "pivot": [-1.2, 10, -4],
            "cubes": [
              {"origin": [-2.2, 0.2, -5], "size": [2, 10, 2], "uv": [40, 0]}
            ],
            "name": "frontLegR",
            "parent": "body"
          }
        ]
      }
    },
    "animations": {
      "sneak": {
        "loop": true,
        "bones": {
          "backlegl": {
            "position": [0, "1.0 - this", 0],
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17) * 57.3 * query.modified_move_speed - this",
              0,
              0
            ]
          },
          "backlegr": {
            "position": [0, "1.0 - this", 0],
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17 + 180.0) * 57.3 * query.modified_move_speed - this",
              0,
              0
            ]
          },
          "body": {"position": [0, -1, 0]},
          "frontlegl": {
            "position": [0, "1.0 - this", 0],
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17 + 180.0) * 57.3 * query.modified_move_speed - this",
              0,
              0
            ]
          },
          "frontlegr": {
            "position": [0, "1.0 - this", 0],
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17) * 57.3 * query.modified_move_speed - this",
              0,
              0
            ]
          },
          "head": {"position": [0, -1, 0]},
          "tail1": {"position": [0, "1.0 - this", 0]},
          "tail2": {
            "rotation": [
              "62.0 + math.cos(query.modified_distance_moved * 57.3) * 27.0 * query.modified_move_speed - this",
              0,
              0
            ]
          }
        }
      },
      "walk": {
        "loop": true,
        "bones": {
          "backlegl": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17) * 57.3 * query.modified_move_speed - this",
              0,
              0
            ]
          },
          "backlegr": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17 + 180.0) * 57.3 * query.modified_move_speed - this",
              0,
              0
            ]
          },
          "frontlegl": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17 + 180.0) * 57.3 * query.modified_move_speed - this",
              0,
              0
            ]
          },
          "frontlegr": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17) * 57.3 * query.modified_move_speed - this",
              0,
              0
            ]
          },
          "tail1": {"rotation": ["-51.57 - this", 0, 0]},
          "tail2": {
            "rotation": [
              "62.0 + math.cos(query.modified_distance_moved * 57.3) * 45.0 * query.modified_move_speed - this",
              0,
              0
            ]
          }
        }
      },
      "sprint": {
        "loop": true,
        "bones": {
          "backlegl": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17) * 57.3 * query.modified_move_speed - this",
              0,
              0
            ]
          },
          "backlegr": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17 + 17.19) * 57.3 * query.modified_move_speed - this",
              0,
              0
            ]
          },
          "frontlegl": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17 + 197.19) * 57.3 * query.modified_move_speed - this",
              0,
              0
            ]
          },
          "frontlegr": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17 + 180.0) * 57.3 * query.modified_move_speed - this",
              0,
              0
            ]
          },
          "tail1": {"rotation": ["-this", 0, 0]},
          "tail2": {
            "rotation": [
              "62.0 + math.cos(query.modified_distance_moved * 57.3) * 18.0 * query.modified_move_speed - this",
              0,
              0
            ]
          }
        }
      },
      "sit": {
        "loop": true,
        "bones": {
          "backlegl": {
            "position": [0, "-2.0 - this", "6.0 - this"],
            "rotation": [-45, 0, 0]
          },
          "backlegr": {
            "position": [0, "-2.0 - this", "6.0 - this"],
            "rotation": ["-45 - this", 0, 0]
          },
          "body": {"rotation": ["-45 - this", 0, 0]},
          "frontlegl": {
            "position": [0, "-1.5 - this", "-7.0 - this"],
            "rotation": ["42.15 - this", 0, 0]
          },
          "frontlegr": {
            "position": [0, "-1.5 - this", "-7.0 - this"],
            "rotation": ["42.15 - this", 0, 0]
          },
          "head": {"position": [0, -2, 0]},
          "tail1": {"position": [0, 0, 0], "rotation": ["45 - this", 0, 0]},
          "tail2": {"position": [0, 0, 0], "rotation": ["45 - this", 0, 0]}
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "baby_transform": {"loop": true, "bones": {"head": {"scale": 1.5}}}
    },
    "animation_controllers": {
      "move": {
        "initial_state": "sitting",
        "states": {
          "sitting": {
            "animations": ["sit"],
            "transitions": [
              {"sneaking": "variable.state == 0"},
              {"sprinting": "variable.state == 1"},
              {"walking": "variable.state == 3"}
            ]
          },
          "sneaking": {
            "animations": ["sneak"],
            "transitions": [
              {"sprinting": "variable.state == 1"},
              {"sitting": "variable.state == 2"},
              {"walking": "variable.state == 3"}
            ]
          },
          "sprinting": {
            "animations": ["sprint"],
            "transitions": [
              {"sneaking": "variable.state == 0"},
              {"sitting": "variable.state == 2"},
              {"walking": "variable.state == 3"}
            ]
          },
          "walking": {
            "animations": ["walk"],
            "transitions": [
              {"sneaking": "variable.state == 0"},
              {"sprinting": "variable.state == 1"},
              {"sitting": "variable.state == 2"}
            ]
          }
        }
      },
      "baby": {
        "initial_state": "baby",
        "states": {
          "baby": {"animations": [{"baby_transform": "query.is_baby"}]}
        }
      },
      "look_at_target": {
        "initial_state": "default",
        "states": {"default": {"animations": ["look_at_target"]}}
      }
    },
    "render_controllers": ["controller.render.ocelot"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 16}
  },
  "panda": {
    "identifier": "minecraft:panda",
    "materials": {"default": "panda"},
    "textures": {
      "default": "textures/entity/panda/panda",
      "lazy": "textures/entity/panda/lazy_panda",
      "worried": "textures/entity/panda/worried_panda",
      "playful": "textures/entity/panda/playful_panda",
      "brown": "textures/entity/panda/brown_panda",
      "weak": "textures/entity/panda/weak_panda",
      "aggressive": "textures/entity/panda/aggressive_panda"
    },
    "geometry": {
      "default": {
        "texturewidth": 64,
        "textureheight": 64,
        "bones": [
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 12.5, -17],
            "locators": {"lead": [0, 14, -16]},
            "cubes": [
              {"origin": [-6.5, 7.5, -21], "size": [13, 10, 9], "uv": [0, 6]},
              {"origin": [-3.5, 7.5, -23], "size": [7, 5, 2], "uv": [45, 16]},
              {"origin": [-8.5, 16.5, -18], "size": [5, 4, 1], "uv": [52, 25]},
              {"origin": [3.5, 16.5, -18], "size": [5, 4, 1], "uv": [52, 25]}
            ]
          },
          {
            "name": "body",
            "pivot": [0, 14, 0],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {"origin": [-9.5, 1, -6.5], "size": [19, 26, 13], "uv": [0, 25]}
            ]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-5.5, 9, 9],
            "cubes": [
              {"origin": [-8.5, 0, 6], "size": [6, 9, 6], "uv": [40, 0]}
            ]
          },
          {
            "name": "leg1",
            "parent": "body",
            "pivot": [5.5, 9, 9],
            "cubes": [{"origin": [2.5, 0, 6], "size": [6, 9, 6], "uv": [40, 0]}]
          },
          {
            "name": "leg2",
            "parent": "body",
            "pivot": [-5.5, 9, -9],
            "cubes": [
              {"origin": [-8.5, 0, -12], "size": [6, 9, 6], "uv": [40, 0]}
            ]
          },
          {
            "name": "leg3",
            "parent": "body",
            "pivot": [5.5, 9, -9],
            "cubes": [
              {"origin": [2.5, 0, -12], "size": [6, 9, 6], "uv": [40, 0]}
            ]
          }
        ]
      }
    },
    "animations": {
      "unhappy": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              0,
              "(math.sin(query.life_time * 327.6) * 20) - this",
              "(math.sin(query.life_time * 327.6) * 20) - this"
            ]
          },
          "leg2": {
            "rotation": [
              "(math.sin(query.life_time * 343.8) * -43) - this",
              0,
              0
            ]
          },
          "leg3": {
            "rotation": [
              "(math.sin(query.life_time * 343.8) * 43) - this",
              0,
              0
            ]
          }
        }
      },
      "sneezing": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "(query.sneeze_counter < 15) ? (-45 * (query.sneeze_counter / 14)) : (-45 + (9 * (query.sneeze_counter - 15)) - this)",
              0,
              0
            ]
          }
        }
      },
      "walk": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          },
          "leg1": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          },
          "leg2": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          },
          "leg3": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          }
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "baby_transform": {
        "loop": true,
        "bones": {
          "body": {"position": [0, 1.77, 0], "scale": [1.15, 1.15, 1]},
          "head": {"position": [0, -0.18, 0.15], "scale": 1.8}
        }
      },
      "sitting": {
        "loop": true,
        "bones": {
          "body": {
            "position": [0, "-2.15 -10 -this", 0],
            "rotation": [
              "(query.is_scared * math.cos(query.life_time * 71.62) * 16.2) + (query.sit_amount * -90) - this",
              0,
              0
            ]
          },
          "head": {
            "rotation": [
              "query.is_eating ? (90.0 + 11.5 * math.sin(query.life_time * 750)) : (query.sit_amount * (100 - this)) - this",
              0,
              0
            ]
          },
          "leg0": {"rotation": [0, 0, "32.7 - this"]},
          "leg1": {"rotation": [0, 0, "-32.7 - this"]},
          "leg2": {
            "rotation": [
              "(query.is_eating ? (-23 - (16.5 * math.sin(query.life_time * 750))) : 0) - this",
              0,
              -15
            ]
          },
          "leg3": {
            "rotation": [
              "(query.is_eating ? (-23 - (16.5 * math.sin(query.life_time * 750))) : 0) - this",
              0,
              15
            ]
          }
        }
      },
      "rolling": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["(query.roll_counter / 32) * 360", 0, 0]},
          "head": {
            "rotation": ["(this * -118) + query.roll_counter - this", 0, 0]
          },
          "leg0": {
            "rotation": [
              "math.sin(query.life_time * 60 * (query.is_baby ? 0.95 : 0.5) * 57.3) * 8.6 - this",
              0,
              0
            ]
          },
          "leg1": {
            "rotation": [
              "math.sin(query.life_time * 60 * (query.is_baby ? 0.95 : 0.5) * 57.3) * -8.6 - this",
              0,
              0
            ]
          },
          "leg2": {
            "rotation": [
              "math.sin(query.life_time * 60 * (query.is_baby ? 0.95 : 0.5) * 57.3) * -8.6 - this",
              0,
              0
            ]
          },
          "leg3": {
            "rotation": [
              "math.sin(query.life_time * 60 * (query.is_baby ? 0.95 : 0.5) * 57.3) * 8.6 - this",
              0,
              0
            ]
          }
        }
      },
      "lying": {
        "loop": true,
        "bones": {
          "body": {
            "position": [
              0,
              "(query.is_baby ? -8.8 : -7.67) * query.lie_amount -10 - this",
              0
            ],
            "rotation": ["query.lie_amount * -180 - this", 0, 0]
          },
          "head": {"rotation": ["(query.lie_amount * 90) - this", 0, 0]},
          "leg0": {
            "rotation": [
              "math.sin(query.life_time * 206.28) * -17.2 - this",
              0,
              0
            ]
          },
          "leg1": {
            "rotation": [
              "math.sin(query.life_time * 263.58) * 17.2 - this",
              0,
              0
            ]
          },
          "leg2": {
            "rotation": ["math.sin(query.life_time * 172) * 17.2 - this", 0, 0]
          },
          "leg3": {
            "rotation": [
              "math.sin(query.life_time * 229.2) * -17.2 - this",
              0,
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "move": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": [
              {"walk": "query.modified_move_speed"},
              "look_at_target"
            ],
            "transitions": [
              {"rolling": "query.roll_counter > 0"},
              {"sitting": "query.sit_amount > 0"},
              {"lying": "query.lie_amount > 0"}
            ]
          },
          "lying": {
            "animations": ["lying"],
            "transitions": [
              {"default": "query.lie_amount <= 0"},
              {"rolling": "query.roll_counter > 0"},
              {"sitting": "query.sit_amount > 0"}
            ]
          },
          "rolling": {
            "animations": ["rolling"],
            "transitions": [
              {"default": "query.roll_counter <= 0"},
              {"sitting": "query.sit_amount > 0"},
              {"lying": "query.lie_amount > 0"}
            ]
          },
          "sitting": {
            "animations": ["sitting"],
            "transitions": [
              {"default": "query.sit_amount <= 0"},
              {"rolling": "query.roll_counter > 0"},
              {"lying": "query.lie_amount > 0"}
            ]
          }
        }
      },
      "unhappy": {
        "initial_state": "baby",
        "states": {
          "baby": {"animations": [{"unhappy": "query.unhappy_counter"}]}
        }
      },
      "sneezing": {
        "initial_state": "baby",
        "states": {
          "baby": {"animations": [{"sneezing": "query.sneeze_counter"}]}
        }
      },
      "baby": {
        "initial_state": "baby",
        "states": {
          "baby": {"animations": [{"baby_transform": "query.is_baby"}]}
        }
      }
    },
    "render_controllers": ["controller.render.panda"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 54}
  },
  "parrot": {
    "identifier": "minecraft:parrot",
    "materials": {"default": "parrot"},
    "textures": {
      "blue": "textures/entity/parrot/parrot_blue",
      "green": "textures/entity/parrot/parrot_green",
      "red_blue": "textures/entity/parrot/parrot_red_blue",
      "yellow_blue": "textures/entity/parrot/parrot_yellow_blue",
      "grey": "textures/entity/parrot/parrot_grey"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 1,
        "visible_bounds_height": 1,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 32,
        "textureheight": 32,
        "bones": [
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 8.3, -2.8],
            "locators": {"lead": [0, 6, -4]},
            "cubes": [
              {"origin": [-1, 6.8, -3.8], "size": [2, 3, 2], "uv": [2, 2]}
            ]
          },
          {
            "name": "head2",
            "parent": "head",
            "pivot": [0, 26, -1],
            "cubes": [
              {"origin": [-1, 9.8, -5.8], "size": [2, 1, 4], "uv": [10, 0]}
            ]
          },
          {
            "name": "beak1",
            "parent": "head",
            "pivot": [0, 24.5, -1.5],
            "cubes": [
              {"origin": [-0.5, 7.8, -4.7], "size": [1, 2, 1], "uv": [11, 7]}
            ]
          },
          {
            "name": "beak2",
            "parent": "head",
            "pivot": [0, 25.8, -2.5],
            "cubes": [
              {"origin": [-0.5, 8.1, -5.7], "size": [1, 1.7, 1], "uv": [16, 7]}
            ]
          },
          {
            "name": "body",
            "pivot": [0, 7.5, -3],
            "bind_pose_rotation": [28.287, 0, 0],
            "cubes": [
              {"origin": [-1.5, 1.5, -4.5], "size": [3, 6, 3], "uv": [2, 8]}
            ]
          },
          {
            "name": "tail",
            "parent": "body",
            "pivot": [0, 2.9, 1.2],
            "cubes": [
              {"origin": [-1.5, -0.1, 0.2], "size": [3, 4, 1], "uv": [22, 1]}
            ]
          },
          {
            "name": "wing0",
            "parent": "body",
            "pivot": [1.5, 7.1, -2.8],
            "cubes": [
              {"origin": [1, 2.1, -4.3], "size": [1, 5, 3], "uv": [19, 8]}
            ]
          },
          {
            "name": "wing1",
            "parent": "body",
            "pivot": [-1.5, 7.1, -2.8],
            "cubes": [
              {"origin": [-2, 2.1, -4.3], "size": [1, 5, 3], "uv": [19, 8]}
            ]
          },
          {
            "name": "feather",
            "parent": "head",
            "pivot": [0, 10.1, 0.2],
            "bind_pose_rotation": [-12.685, 0, 0],
            "cubes": [
              {"origin": [0, 9.1, -4.9], "size": [0, 5, 4], "uv": [2, 18]}
            ]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [1.5, 1, -0.5],
            "cubes": [
              {"origin": [0.5, -0.5, -1.5], "size": [1, 2, 1], "uv": [14, 18]}
            ]
          },
          {
            "name": "leg1",
            "parent": "body",
            "pivot": [-0.5, 1, -0.5],
            "cubes": [
              {"origin": [-1.5, -0.5, -1.5], "size": [1, 2, 1], "uv": [14, 18]}
            ]
          }
        ]
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.state = query.is_dancing ? 3 : (query.is_sitting ? 2 : (!query.is_on_ground && !query.is_jumping && !query.is_riding ? 0 : 1));",
        "variable.dance.x = Math.cos(query.life_time * 57.3 * 20.0);",
        "variable.dance.y = -Math.sin(query.life_time * 57.3 * 20.0);",
        "variable.wing_flap = ((math.sin(query.wing_flap_position * 57.3) + 1) * query.wing_flap_speed);"
      ]
    },
    "animations": {
      "moving": {
        "loop": true,
        "bones": {
          "body": {"position": [0, "variable.wing_flap * 0.3", 0]},
          "tail": {
            "rotation": [
              "60.0 + math.cos(query.anim_time * 38.17) * 17.0 - this",
              0,
              0
            ]
          },
          "wing0": {"rotation": [0, 0, "-5.0 - variable.wing_flap * 57.3"]},
          "wing1": {"rotation": [0, 0, "5.0 + variable.wing_flap * 57.3"]}
        }
      },
      "base": {
        "loop": true,
        "bones": {
          "body": {"position": ["-this", 0, 0], "rotation": ["-this", 0, 0]},
          "feather": {"rotation": ["-this", 0, 0]},
          "head": {"position": ["-this", 0, 0], "rotation": [0, 0, "-this"]},
          "leg0": {
            "position": [0, "-6.0-this", -0.5],
            "rotation": ["-16.713-this", 0, "-this"]
          },
          "leg1": {
            "position": [0, "-6.0-this", -0.5],
            "rotation": ["-16.713-this", 0, "-this"]
          },
          "tail": {"position": ["-this", 0, 0]},
          "wing0": {
            "position": ["1.5-this", 0, 0],
            "rotation": ["-40.0-this", "-180.0-this", 0]
          },
          "wing1": {
            "position": ["-1.5-this", 0, 0],
            "rotation": ["-40.0-this", "-180.0-this", 0]
          }
        }
      },
      "dance": {
        "loop": true,
        "bones": {
          "body": {
            "position": ["variable.dance.x-this", "variable.dance.y", 0]
          },
          "head": {
            "rotation": ["-this", "-this", "variable.dance.y*23.0 - this"]
          },
          "leg0": {
            "position": ["-variable.dance.x", "-variable.dance.y", 0],
            "rotation": [0, 0, "-20.0 - this"]
          },
          "leg1": {
            "position": ["-variable.dance.x", "-variable.dance.y", 0],
            "rotation": [0, 0, "20.0 - this"]
          },
          "tail": {"rotation": ["60.0 - this", 0, 0]},
          "wing0": {"rotation": [0, 0, "-5.0 - variable.wing_flap"]},
          "wing1": {"rotation": [0, 0, "5.0 + variable.wing_flap"]}
        }
      },
      "sitting": {
        "loop": true,
        "bones": {
          "body": {"position": [0, -1.9, 0]},
          "leg0": {"rotation": [90, 0, 0]},
          "leg1": {"rotation": [90, 0, 0]},
          "tail": {"rotation": ["90.0 - this", 0, 0]},
          "wing0": {"rotation": [0, 0, "-5.0-this"]},
          "wing1": {"rotation": [0, 0, "5.0-this"]}
        }
      },
      "flying": {
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": [
              "math.cos(query.anim_time * 38.17) * 80.0 * query.modified_move_speed",
              0,
              0
            ]
          },
          "leg1": {
            "rotation": [
              "math.cos(query.anim_time * 38.17) * -80.0 * query.modified_move_speed",
              0,
              0
            ]
          }
        }
      },
      "standing": {
        "loop": true,
        "bones": {
          "leg0": {"rotation": [20, 0, 0]},
          "leg1": {"rotation": [20, 0, 0]}
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "setup": {
        "initial_state": "default",
        "states": {"default": {"animations": ["look_at_target", "base"]}}
      },
      "move": {
        "initial_state": "dancing",
        "states": {
          "dancing": {
            "animations": ["dance"],
            "transitions": [
              {"flying": "variable.state == 0"},
              {"standing": "variable.state == 1"},
              {"sitting": "variable.state == 2"}
            ]
          },
          "flying": {
            "animations": ["moving", "flying"],
            "transitions": [
              {"standing": "variable.state == 1"},
              {"sitting": "variable.state == 2"},
              {"dancing": "variable.state == 3"}
            ]
          },
          "sitting": {
            "animations": ["sitting"],
            "transitions": [
              {"flying": "variable.state == 0"},
              {"standing": "variable.state == 1"},
              {"dancing": "variable.state == 3"}
            ]
          },
          "standing": {
            "animations": ["moving", "standing"],
            "transitions": [
              {"flying": "variable.state == 0"},
              {"sitting": "variable.state == 2"},
              {"dancing": "variable.state == 3"}
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.parrot"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 43}
  },
  "phantom": {
    "identifier": "minecraft:phantom",
    "materials": {"default": "phantom", "invisible": "phantom_invisible"},
    "textures": {"default": "textures/entity/phantom"},
    "geometry": {
      "default": {
        "texturewidth": 64,
        "textureheight": 64,
        "bones": [
          {
            "name": "body",
            "pivot": [0, 24, 0],
            "bind_pose_rotation": [0, 0, 0],
            "cubes": [{"origin": [-3, 23, -8], "size": [5, 3, 9], "uv": [0, 8]}]
          },
          {
            "name": "wing0",
            "pivot": [2, 26, -8],
            "bind_pose_rotation": [0, 0, 5.7],
            "cubes": [
              {"origin": [2, 24, -8], "size": [6, 2, 9], "uv": [23, 12]}
            ],
            "parent": "body"
          },
          {
            "name": "wingtip0",
            "pivot": [8, 26, -8],
            "bind_pose_rotation": [0, 0, 5.7],
            "locators": {"left_wing": [21, 26, 0]},
            "cubes": [
              {"origin": [8, 25, -8], "size": [13, 1, 9], "uv": [16, 24]}
            ],
            "parent": "wing0"
          },
          {
            "name": "wing1",
            "pivot": [-3, 26, -8],
            "bind_pose_rotation": [0, 0, -5.7],
            "mirror": true,
            "cubes": [
              {"origin": [-9, 24, -8], "size": [6, 2, 9], "uv": [23, 12]}
            ],
            "parent": "body"
          },
          {
            "name": "wingtip1",
            "pivot": [-9, 24, -8],
            "bind_pose_rotation": [0, 0, -5.7],
            "locators": {"right_wing": [-22, 24, 0]},
            "mirror": true,
            "cubes": [
              {"origin": [-22, 25, -8], "size": [13, 1, 9], "uv": [16, 24]}
            ],
            "parent": "wing1"
          },
          {
            "name": "head",
            "pivot": [0, 23, -7],
            "bind_pose_rotation": [11.5, 0, 0],
            "cubes": [
              {"origin": [-4, 22, -12], "size": [7, 3, 5], "uv": [0, 0]}
            ],
            "parent": "body"
          },
          {
            "name": "tail",
            "pivot": [0, 26, 1],
            "bind_pose_rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-2, 24, 1], "size": [3, 2, 6], "uv": [3, 20]}
            ],
            "parent": "body"
          },
          {
            "name": "tailtip",
            "pivot": [0, 25.5, 7],
            "bind_pose_rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-1, 24.5, 7], "size": [1, 1, 6], "uv": [4, 29]}
            ],
            "parent": "tail"
          }
        ]
      }
    },
    "scripts": {
      "initialize": [
        "variable.runtimeid = 0;",
        "variable.tailrotx = -5.0;",
        "variable.wingrotz = 0.0;"
      ],
      "animate": ["phantom_base_pose_controller", "move"]
    },
    "animations": {
      "phantom_base_pose": {
        "loop": true,
        "bones": {
          "body": {
            "position": [0, -20, 0],
            "rotation": ["-query.target_x_rotation", 0, 0]
          }
        }
      },
      "move": {
        "loop": true,
        "start_delay": "math.random(0, 2.417)",
        "animation_length": 2.417,
        "bones": {
          "tail": {
            "rotation": [
              "-5.0 * Math.cos(297.9380535 * query.anim_time) - 5.0",
              0,
              0
            ]
          },
          "tailtip": {
            "rotation": [
              "-5.0 * Math.cos(297.9380535 * query.anim_time) - 5.0",
              0,
              0
            ]
          },
          "wing0": {
            "rotation": [0, 0, "16.0 * Math.cos(148.9690267 * query.anim_time)"]
          },
          "wing1": {
            "rotation": [
              0,
              0,
              "-16.0 * Math.cos(148.9690267 * query.anim_time)"
            ]
          },
          "wingtip0": {
            "rotation": [0, 0, "16.0 * Math.cos(148.9690267 * query.anim_time)"]
          },
          "wingtip1": {
            "rotation": [
              0,
              0,
              "-16.0 * Math.cos(148.9690267 * query.anim_time)"
            ]
          }
        },
        "sound_effects": {"1.4": {"effect": "flap"}}
      }
    },
    "particle_effects": {"wing_dust": "minecraft:phantom_trail_particle"},
    "sound_effects": {"flap": "mob.phantom.flap"},
    "render_controllers": ["controller.render.phantom"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 51},
    "animation_controllers": {
      "phantom_base_pose_controller": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["phantom_base_pose"],
            "particle_effects": [
              {"effect": "wing_dust", "locator": "left_wing"},
              {"effect": "wing_dust", "locator": "right_wing"}
            ]
          }
        }
      }
    }
  },
  "pig": {
    "identifier": "minecraft:pig",
    "min_engine_version": "1.8.0",
    "materials": {"default": "pig"},
    "textures": {
      "default": "textures/entity/pig/pig",
      "saddled": "textures/entity/pig/pig_saddle"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 2,
        "visible_bounds_height": 1.5,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "body",
            "pivot": [0, 13, 2],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {"origin": [-5, 7, -5], "size": [10, 16, 8], "uv": [28, 8]}
            ]
          },
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 12, -6],
            "locators": {"lead": [0, 14, -6]},
            "cubes": [
              {"origin": [-4, 8, -14], "size": [8, 8, 8], "uv": [0, 0]},
              {"origin": [-2, 9, -15], "size": [4, 3, 1], "uv": [16, 16]}
            ]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-3, 6, 7],
            "cubes": [{"origin": [-5, 0, 5], "size": [4, 6, 4], "uv": [0, 16]}]
          },
          {
            "name": "leg1",
            "parent": "body",
            "mirror": true,
            "pivot": [3, 6, 7],
            "cubes": [{"origin": [1, 0, 5], "size": [4, 6, 4], "uv": [0, 16]}]
          },
          {
            "name": "leg2",
            "parent": "body",
            "pivot": [-3, 6, -5],
            "cubes": [{"origin": [-5, 0, -7], "size": [4, 6, 4], "uv": [0, 16]}]
          },
          {
            "name": "leg3",
            "parent": "body",
            "mirror": true,
            "pivot": [3, 6, -5],
            "cubes": [{"origin": [1, 0, -7], "size": [4, 6, 4], "uv": [0, 16]}]
          }
        ]
      }
    },
    "animations": {
      "setup": {"loop": true, "bones": {"body": {"rotation": ["-this", 0, 0]}}},
      "walk": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          },
          "leg1": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          },
          "leg2": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          },
          "leg3": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          }
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "baby_transform": {
        "loop": true,
        "bones": {"head": {"position": [0, 4, 4], "scale": 2}}
      }
    },
    "scripts": {
      "animate": [
        "setup",
        {"walk": "query.modified_move_speed"},
        "look_at_target",
        {"baby_transform": "query.is_baby"}
      ]
    },
    "render_controllers": ["controller.render.pig"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 2}
  },
  "piglin": {
    "identifier": "minecraft:piglin",
    "materials": {"default": "piglin"},
    "textures": {"default": "textures/entity/piglin/piglin"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 12, -2], "size": [8, 12, 4], "uv": [16, 16]},
              {
                "origin": [-4, 12, -2],
                "size": [8, 12, 4],
                "uv": [16, 32],
                "inflate": 0.25
              }
            ]
          },
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-5, 24, -4],
                "size": [10, 8, 8],
                "uv": [0, 0],
                "inflate": -0.02
              },
              {"origin": [-2, 24, -5], "size": [4, 4, 1], "uv": [31, 1]},
              {"origin": [2, 24, -5], "size": [1, 2, 1], "uv": [2, 4]},
              {"origin": [-3, 24, -5], "size": [1, 2, 1], "uv": [2, 0]}
            ],
            "inflate": -0.02
          },
          {
            "name": "leftear",
            "parent": "head",
            "pivot": [5, 30, 0],
            "rotation": [0, 0, -30],
            "cubes": [{"origin": [4, 25, -2], "size": [1, 5, 4], "uv": [51, 6]}]
          },
          {
            "name": "rightear",
            "parent": "head",
            "pivot": [-5, 30, 0],
            "rotation": [0, 0, 30],
            "cubes": [
              {"origin": [-5, 25, -2], "size": [1, 5, 4], "uv": [39, 6]}
            ]
          },
          {"name": "hat", "parent": "head", "pivot": [0, 24, 0]},
          {
            "name": "rightarm",
            "parent": "body",
            "pivot": [-5, 22, 0],
            "cubes": [
              {"origin": [-8, 12, -2], "size": [4, 12, 4], "uv": [40, 16]},
              {
                "origin": [-8, 12, -2],
                "size": [4, 12, 4],
                "uv": [40, 32],
                "inflate": 0.25
              }
            ]
          },
          {"name": "rightItem", "parent": "rightarm", "pivot": [-6, 15, 1]},
          {
            "name": "leftarm",
            "parent": "body",
            "pivot": [5, 22, 0],
            "cubes": [
              {"origin": [4, 12, -2], "size": [4, 12, 4], "uv": [32, 48]},
              {
                "origin": [4, 12, -2],
                "size": [4, 12, 4],
                "uv": [48, 48],
                "inflate": 0.25
              }
            ]
          },
          {"name": "leftItem", "parent": "leftArm", "pivot": [6, 15, 1]},
          {
            "name": "rightleg",
            "parent": "body",
            "pivot": [-1.9, 12, 0],
            "cubes": [
              {"origin": [-4, 0, -2], "size": [4, 12, 4], "uv": [0, 16]},
              {
                "origin": [-4, 0, -2],
                "size": [4, 12, 4],
                "uv": [0, 32],
                "inflate": 0.25
              }
            ]
          },
          {
            "name": "leftleg",
            "parent": "body",
            "pivot": [1.9, 12, 0],
            "cubes": [
              {"origin": [0, 0, -2], "size": [4, 12, 4], "uv": [16, 48]},
              {
                "origin": [0, 0, -2],
                "size": [4, 12, 4],
                "uv": [0, 48],
                "inflate": 0.25
              }
            ]
          }
        ],
        "visible_bounds_width": 2,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 1, 0],
        "texturewidth": 64,
        "textureheight": 64
      }
    },
    "spawn_egg": {"base_color": "#995f40", "overlay_color": "#f9f3a4"},
    "scripts": {
      "pre_animation": [
        "variable.tcos0 = (Math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed / variable.gliding_speed_value) * 57.3;",
        "variable.attack = Math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3;",
        "variable.attack2 = Math.sin(variable.attack_time * 180.0) * 57.3;",
        "variable.z_bob = Math.cos(query.life_time * 103.13244) * 2.865 + 2.865;",
        "variable.x_bob = Math.sin(query.life_time * 76.776372) * 2.865;"
      ],
      "animate": [
        {"admire": "query.is_admiring"},
        {"humanoid_big_head": "query.is_baby"},
        {"celebrate_hunt": "query.is_celebrating"},
        {"celebrate_hunt_special": "query.is_celebrating_special"},
        "move",
        "bob",
        "look_at_target_controller",
        "piglin_attack_controller",
        "riding_controller"
      ]
    },
    "animations": {
      "move": {
        "loop": true,
        "bones": {
          "leftear": {"rotation": [0, 0, "variable.tcos0 * 0.5"]},
          "rightear": {"rotation": [0, 0, "-variable.tcos0 * 0.5"]},
          "leftarm": {"rotation": ["variable.tcos0", 0, 0]},
          "leftleg": {"rotation": ["-variable.tcos0 * 1.4", 0, 0]},
          "rightarm": {"rotation": ["-variable.tcos0", 0, 0]},
          "rightleg": {"rotation": ["variable.tcos0 * 1.4", 0, 0]}
        }
      },
      "bob": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              0,
              0,
              "((math.cos(query.life_time * 103.2) * 2.865) + 2.865) *-1.0"
            ]
          },
          "rightarm": {
            "rotation": [
              0,
              0,
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "humanoid_big_head": {"loop": true, "bones": {"head": {"scale": 1.4}}},
      "admire": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": [320, 25, 0]},
          "head": {"rotation": [30, 0, 0]}
        }
      },
      "celebrate_hunt": {
        "loop": true,
        "bones": {
          "leftear": {
            "rotation": [0, 0, "Math.sin(query.time_stamp * 30) * 10"]
          },
          "rightear": {
            "rotation": [0, 0, "Math.cos(query.time_stamp * 30) * 10"]
          }
        }
      },
      "celebrate_hunt_special": {
        "loop": true,
        "bones": {
          "leftear": {
            "rotation": [0, 0, "Math.sin(query.time_stamp * 30) * 10"]
          },
          "rightear": {
            "rotation": [0, 0, "Math.cos(query.time_stamp * 30) * 10"]
          },
          "head": {
            "position": [
              "Math.sin(query.time_stamp * 10)",
              "Math.sin(query.time_stamp * 40)",
              0
            ]
          },
          "rightarm": {
            "rotation": [0, 0, "70 + Math.cos(query.time_stamp * 40) * 10"]
          },
          "leftarm": {
            "rotation": [0, 0, "-70 - Math.cos(query.time_stamp * 40) * 10"]
          },
          "body": {"position": [0, "Math.sin(query.time_stamp * 40) * 0.35", 0]}
        }
      },
      "look_at_target_default": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_gliding": {
        "loop": true,
        "bones": {"head": {"rotation": [-45, "query.target_y_rotation", 0]}}
      },
      "look_at_target_swimming": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "math.lerp(query.target_x_rotation, -45.0, variable.swim_amount)",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "crossbow_hold": {
        "bones": {
          "leftarm": {
            "rotation": [
              "-85.94 + query.target_x_rotation -this",
              "34.38 + math.clamp(query.target_y_rotation, -45, 25) -this",
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "-90.0 + 5.73 + query.target_x_rotation -this",
              "-17.19 + math.clamp(query.target_y_rotation, -25, 45) -this",
              0
            ]
          }
        },
        "loop": true
      },
      "crossbow_charge": {
        "bones": {
          "leftarm": {
            "rotation": [
              "math.lerp(-90.0 + 34.38, -90.0, variable.crossbow_charge)  -this",
              "math.lerp(22.92, 48.70, variable.crossbow_charge) -this",
              0
            ]
          },
          "rightarm": {"rotation": ["-90.0 + 34.38 -this", "-45.84 -this", 0]}
        },
        "loop": true
      },
      "melee_attack": {
        "bones": {
          "leftarm": {
            "rotation": [
              "(math.cos(query.life_time * 20.0 * 10.89) * 28.65) + (math.sin(variable.attack_time * 180.0) * 68.76 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0)) * 22.92)",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "-100 + (variable.attack2 * 2.2 - variable.attack * 0.4) + variable.x_bob - this",
              "variable.attack2 * 0.6 - 5.73 - this",
              "variable.z_bob - this"
            ]
          }
        },
        "loop": true
      },
      "hand_attack": {
        "bones": {
          "leftarm": {
            "rotation": [
              "-(math.sin((1 - math.pow((1 - variable.attack_time), 4)) * 180) * 1.2 + math.sin(variable.attack_time * 180)) * 10.0",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "-(math.sin((1 - math.pow((1 - variable.attack_time), 4)) * 180) * 1.2 + math.sin(variable.attack_time * 180)) * 30.0",
              "-(math.sin((1 - math.pow((1 - variable.attack_time), 4)) * 180) ? (-90.0 * math.sin((1 - math.pow((1 - variable.attack_time), 4)) * 180)) + 30.0 : 0.0)",
              0
            ]
          }
        },
        "loop": true
      },
      "riding.arms": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": [-36, 0, 0]},
          "rightarm": {"rotation": [-36, 0, 0]}
        }
      },
      "riding.legs": {
        "loop": true,
        "bones": {
          "leftleg": {"rotation": ["-72.0 - this", "-18.0 - this", "-this"]},
          "rightleg": {"rotation": ["-72.0 - this", "18.0 - this", "-this"]}
        }
      }
    },
    "render_controllers": ["controller.render.piglin"],
    "enable_attachables": true,
    "animation_controllers": {
      "look_at_target_controller": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["look_at_target_default"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"swimming": "query.is_swimming"}
            ]
          },
          "gliding": {
            "animations": ["look_at_target_gliding"],
            "transitions": [
              {"swimming": "query.is_swimming"},
              {"default": "!query.is_gliding"}
            ]
          },
          "swimming": {
            "animations": ["look_at_target_swimming"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"default": "!query.is_swimming"}
            ]
          }
        }
      },
      "piglin_attack_controller": {
        "initial_state": "default",
        "states": {
          "ranged_charge": {
            "animations": ["crossbow_charge"],
            "transitions": [
              {"default": "variable.attack_state == 0  ||  query.is_admiring"},
              {"ranged_hold": "variable.attack_state == 1"}
            ]
          },
          "default": {
            "transitions": [
              {
                "ranged_hold": "query.variant == 0 && variable.attack_state == 1 && !query.is_admiring"
              },
              {
                "ranged_charge": "query.variant == 0 && variable.attack_state == 2 && !query.is_admiring"
              },
              {
                "hand_attack": "variable.has_target && query.variant == 1 && !query.is_item_equipped && variable.attack_time >= 0.0 && !query.is_admiring"
              },
              {
                "melee_attack": "variable.has_target && query.variant == 1 && query.is_item_equipped && variable.attack_time >= 0.0 && !query.is_admiring"
              }
            ]
          },
          "ranged_hold": {
            "animations": ["crossbow_hold"],
            "transitions": [
              {"default": "variable.attack_state == 0  ||  query.is_admiring"},
              {"ranged_charge": "variable.attack_state == 2"}
            ]
          },
          "melee_attack": {
            "animations": ["melee_attack"],
            "transitions": [
              {
                "default": "!query.is_item_equipped || !variable.has_target || variable.attack_time < 0.0 ||  query.is_admiring"
              }
            ]
          },
          "hand_attack": {
            "animations": ["hand_attack"],
            "transitions": [
              {
                "default": "query.is_item_equipped || !variable.has_target || variable.attack_time < 0.0 ||  query.is_admiring"
              }
            ]
          }
        }
      },
      "riding_controller": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"riding": "query.is_riding"}]},
          "riding": {
            "animations": ["riding.arms", "riding.legs"],
            "transitions": [{"default": "!query.is_riding"}]
          }
        }
      }
    }
  },
  "piglin_brute": {
    "identifier": "minecraft:piglin_brute",
    "materials": {"default": "piglin_brute"},
    "textures": {"default": "textures/entity/piglin/piglin_brute"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 12, -2], "size": [8, 12, 4], "uv": [16, 16]},
              {
                "origin": [-4, 12, -2],
                "size": [8, 12, 4],
                "uv": [16, 32],
                "inflate": 0.25
              }
            ]
          },
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-5, 24, -4],
                "size": [10, 8, 8],
                "uv": [0, 0],
                "inflate": -0.02
              },
              {"origin": [-2, 24, -5], "size": [4, 4, 1], "uv": [31, 1]},
              {"origin": [2, 24, -5], "size": [1, 2, 1], "uv": [2, 4]},
              {"origin": [-3, 24, -5], "size": [1, 2, 1], "uv": [2, 0]}
            ],
            "inflate": -0.02
          },
          {
            "name": "leftear",
            "parent": "head",
            "pivot": [5, 30, 0],
            "rotation": [0, 0, -30],
            "cubes": [{"origin": [4, 25, -2], "size": [1, 5, 4], "uv": [51, 6]}]
          },
          {
            "name": "rightear",
            "parent": "head",
            "pivot": [-5, 30, 0],
            "rotation": [0, 0, 30],
            "cubes": [
              {"origin": [-5, 25, -2], "size": [1, 5, 4], "uv": [39, 6]}
            ]
          },
          {"name": "hat", "parent": "head", "pivot": [0, 24, 0]},
          {
            "name": "rightarm",
            "parent": "body",
            "pivot": [-5, 22, 0],
            "cubes": [
              {"origin": [-8, 12, -2], "size": [4, 12, 4], "uv": [40, 16]},
              {
                "origin": [-8, 12, -2],
                "size": [4, 12, 4],
                "uv": [40, 32],
                "inflate": 0.25
              }
            ]
          },
          {"name": "rightItem", "parent": "rightarm", "pivot": [-6, 15, 1]},
          {
            "name": "leftarm",
            "parent": "body",
            "pivot": [5, 22, 0],
            "cubes": [
              {"origin": [4, 12, -2], "size": [4, 12, 4], "uv": [32, 48]},
              {
                "origin": [4, 12, -2],
                "size": [4, 12, 4],
                "uv": [48, 48],
                "inflate": 0.25
              }
            ]
          },
          {"name": "leftItem", "parent": "leftArm", "pivot": [6, 15, 1]},
          {
            "name": "rightleg",
            "parent": "body",
            "pivot": [-1.9, 12, 0],
            "cubes": [
              {"origin": [-4, 0, -2], "size": [4, 12, 4], "uv": [0, 16]},
              {
                "origin": [-4, 0, -2],
                "size": [4, 12, 4],
                "uv": [0, 32],
                "inflate": 0.25
              }
            ]
          },
          {
            "name": "leftleg",
            "parent": "body",
            "pivot": [1.9, 12, 0],
            "cubes": [
              {"origin": [0, 0, -2], "size": [4, 12, 4], "uv": [16, 48]},
              {
                "origin": [0, 0, -2],
                "size": [4, 12, 4],
                "uv": [0, 48],
                "inflate": 0.25
              }
            ]
          }
        ],
        "visible_bounds_width": 2,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 1, 0],
        "texturewidth": 64,
        "textureheight": 64
      }
    },
    "spawn_egg": {"base_color": "#592A10", "overlay_color": "#F9F3A4"},
    "scripts": {
      "pre_animation": [
        "variable.tcos0 = (Math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed / variable.gliding_speed_value) * 57.3;",
        "variable.attack = Math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3;",
        "variable.attack2 = Math.sin(variable.attack_time * 180.0) * 57.3;",
        "variable.z_bob = Math.cos(query.life_time * 103.13244) * 2.865 + 2.865;",
        "variable.x_bob = Math.sin(query.life_time * 76.776372) * 2.865;"
      ],
      "animate": [
        {"humanoid_big_head": "query.is_baby"},
        "move",
        "bob",
        "look_at_target_controller",
        "piglin_attack_controller",
        "riding_controller"
      ]
    },
    "animations": {
      "move": {
        "loop": true,
        "bones": {
          "leftear": {"rotation": [0, 0, "variable.tcos0 * 0.5"]},
          "rightear": {"rotation": [0, 0, "-variable.tcos0 * 0.5"]},
          "leftarm": {"rotation": ["variable.tcos0", 0, 0]},
          "leftleg": {"rotation": ["-variable.tcos0 * 1.4", 0, 0]},
          "rightarm": {"rotation": ["-variable.tcos0", 0, 0]},
          "rightleg": {"rotation": ["variable.tcos0 * 1.4", 0, 0]}
        }
      },
      "bob": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              0,
              0,
              "((math.cos(query.life_time * 103.2) * 2.865) + 2.865) *-1.0"
            ]
          },
          "rightarm": {
            "rotation": [
              0,
              0,
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "humanoid_big_head": {"loop": true, "bones": {"head": {"scale": 1.4}}},
      "look_at_target_default": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_gliding": {
        "loop": true,
        "bones": {"head": {"rotation": [-45, "query.target_y_rotation", 0]}}
      },
      "look_at_target_swimming": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "math.lerp(query.target_x_rotation, -45.0, variable.swim_amount)",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "crossbow_hold": {
        "bones": {
          "leftarm": {
            "rotation": [
              "-85.94 + query.target_x_rotation -this",
              "34.38 + math.clamp(query.target_y_rotation, -45, 25) -this",
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "-90.0 + 5.73 + query.target_x_rotation -this",
              "-17.19 + math.clamp(query.target_y_rotation, -25, 45) -this",
              0
            ]
          }
        },
        "loop": true
      },
      "crossbow_charge": {
        "bones": {
          "leftarm": {
            "rotation": [
              "math.lerp(-90.0 + 34.38, -90.0, variable.crossbow_charge)  -this",
              "math.lerp(22.92, 48.70, variable.crossbow_charge) -this",
              0
            ]
          },
          "rightarm": {"rotation": ["-90.0 + 34.38 -this", "-45.84 -this", 0]}
        },
        "loop": true
      },
      "melee_attack": {
        "bones": {
          "leftarm": {
            "rotation": [
              "(math.cos(query.life_time * 20.0 * 10.89) * 28.65) + (math.sin(variable.attack_time * 180.0) * 68.76 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0)) * 22.92)",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "-100 + (variable.attack2 * 2.2 - variable.attack * 0.4) + variable.x_bob - this",
              "variable.attack2 * 0.6 - 5.73 - this",
              "variable.z_bob - this"
            ]
          }
        },
        "loop": true
      },
      "hand_attack": {
        "bones": {
          "leftarm": {
            "rotation": [
              "-(math.sin((1 - math.pow((1 - variable.attack_time), 4)) * 180) * 1.2 + math.sin(variable.attack_time * 180)) * 10.0",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "-(math.sin((1 - math.pow((1 - variable.attack_time), 4)) * 180) * 1.2 + math.sin(variable.attack_time * 180)) * 30.0",
              "-(math.sin((1 - math.pow((1 - variable.attack_time), 4)) * 180) ? (-90.0 * math.sin((1 - math.pow((1 - variable.attack_time), 4)) * 180)) + 30.0 : 0.0)",
              0
            ]
          }
        },
        "loop": true
      },
      "riding.arms": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": [-36, 0, 0]},
          "rightarm": {"rotation": [-36, 0, 0]}
        }
      },
      "riding.legs": {
        "loop": true,
        "bones": {
          "leftleg": {"rotation": ["-72.0 - this", "-18.0 - this", "-this"]},
          "rightleg": {"rotation": ["-72.0 - this", "18.0 - this", "-this"]}
        }
      }
    },
    "render_controllers": ["controller.render.piglin_brute"],
    "enable_attachables": true,
    "animation_controllers": {
      "look_at_target_controller": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["look_at_target_default"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"swimming": "query.is_swimming"}
            ]
          },
          "gliding": {
            "animations": ["look_at_target_gliding"],
            "transitions": [
              {"swimming": "query.is_swimming"},
              {"default": "!query.is_gliding"}
            ]
          },
          "swimming": {
            "animations": ["look_at_target_swimming"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"default": "!query.is_swimming"}
            ]
          }
        }
      },
      "piglin_attack_controller": {
        "initial_state": "default",
        "states": {
          "ranged_charge": {
            "animations": ["crossbow_charge"],
            "transitions": [
              {"default": "variable.attack_state == 0  ||  query.is_admiring"},
              {"ranged_hold": "variable.attack_state == 1"}
            ]
          },
          "default": {
            "transitions": [
              {
                "ranged_hold": "query.variant == 0 && variable.attack_state == 1 && !query.is_admiring"
              },
              {
                "ranged_charge": "query.variant == 0 && variable.attack_state == 2 && !query.is_admiring"
              },
              {
                "hand_attack": "variable.has_target && query.variant == 1 && !query.is_item_equipped && variable.attack_time >= 0.0 && !query.is_admiring"
              },
              {
                "melee_attack": "variable.has_target && query.variant == 1 && query.is_item_equipped && variable.attack_time >= 0.0 && !query.is_admiring"
              }
            ]
          },
          "ranged_hold": {
            "animations": ["crossbow_hold"],
            "transitions": [
              {"default": "variable.attack_state == 0  ||  query.is_admiring"},
              {"ranged_charge": "variable.attack_state == 2"}
            ]
          },
          "melee_attack": {
            "animations": ["melee_attack"],
            "transitions": [
              {
                "default": "!query.is_item_equipped || !variable.has_target || variable.attack_time < 0.0 ||  query.is_admiring"
              }
            ]
          },
          "hand_attack": {
            "animations": ["hand_attack"],
            "transitions": [
              {
                "default": "query.is_item_equipped || !variable.has_target || variable.attack_time < 0.0 ||  query.is_admiring"
              }
            ]
          }
        }
      },
      "riding_controller": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"riding": "query.is_riding"}]},
          "riding": {
            "animations": ["riding.arms", "riding.legs"],
            "transitions": [{"default": "!query.is_riding"}]
          }
        }
      }
    }
  },
  "pillager": {
    "identifier": "minecraft:pillager",
    "materials": {"default": "pillager"},
    "textures": {"default": "textures/entity/illager/pillager"},
    "geometry": {
      "default": {
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 2.5,
        "visible_bounds_offset": [0, 1.25, 0],
        "texturewidth": 64,
        "textureheight": 64,
        "bones": [
          {
            "name": "head",
            "pivot": [0, 24, 0],
            "locators": {"lead": [0, 0, 0]},
            "cubes": [
              {"origin": [-4, 24, -4], "size": [8, 10, 8], "uv": [0, 0]}
            ],
            "parent": "body"
          },
          {
            "name": "nose",
            "pivot": [0, 26, 0],
            "cubes": [
              {"origin": [-1, 23, -6], "size": [2, 4, 2], "uv": [24, 0]}
            ],
            "parent": "head"
          },
          {
            "name": "body",
            "parent": "waist",
            "pivot": [0, 0, 0],
            "cubes": [
              {"origin": [-4, 12, -3], "size": [8, 12, 6], "uv": [16, 20]},
              {
                "origin": [-4, 6, -3],
                "size": [8, 18, 6],
                "uv": [0, 38],
                "inflate": 0.5
              }
            ]
          },
          {"name": "waist", "neverRender": true, "pivot": [0, 12, 0]},
          {
            "name": "leftLeg",
            "parent": "body",
            "pivot": [2, 12, 0],
            "cubes": [{"origin": [0, 0, -2], "size": [4, 12, 4], "uv": [0, 22]}]
          },
          {
            "name": "rightLeg",
            "parent": "body",
            "pivot": [-2, 12, 0],
            "mirror": true,
            "cubes": [
              {"origin": [-4, 0, -2], "size": [4, 12, 4], "uv": [0, 22]}
            ]
          },
          {
            "name": "rightarm",
            "parent": "body",
            "pivot": [-5, 22, 0],
            "cubes": [
              {"origin": [-8, 12, -2], "size": [4, 12, 4], "uv": [40, 46]}
            ]
          },
          {
            "name": "rightItem",
            "pivot": [-6, 15, 1],
            "neverRender": true,
            "parent": "rightArm"
          },
          {
            "name": "leftarm",
            "parent": "body",
            "pivot": [5, 22, 0],
            "mirror": true,
            "cubes": [
              {"origin": [4, 12, -2], "size": [4, 12, 4], "uv": [40, 46]}
            ]
          }
        ]
      }
    },
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 56},
    "scripts": {
      "pre_animation": [
        "variable.tcos0 = (Math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed / variable.gliding_speed_value) * 57.3;"
      ],
      "animate": ["pillager_root_controller"]
    },
    "animations": {
      "humanoid_base_pose": {
        "loop": true,
        "bones": {"waist": {"rotation": [0, 0, 0]}}
      },
      "look_at_target_default": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_gliding": {
        "loop": true,
        "bones": {"head": {"rotation": [-45, "query.target_y_rotation", 0]}}
      },
      "look_at_target_swimming": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "math.lerp(query.target_x_rotation, -45.0, variable.swim_amount)",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "move": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["variable.tcos0", 0, 0]},
          "leftleg": {"rotation": ["variable.tcos0 * -1.4", 0, 0]},
          "rightarm": {"rotation": ["-variable.tcos0", 0, 0]},
          "rightleg": {"rotation": ["variable.tcos0 * 1.4", 0, 0]}
        }
      },
      "riding.arms": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": [-36, 0, 0]},
          "rightarm": {"rotation": [-36, 0, 0]}
        }
      },
      "riding.legs": {
        "loop": true,
        "bones": {
          "leftleg": {"rotation": ["-72.0 - this", "-18.0 - this", "-this"]},
          "rightleg": {"rotation": ["-72.0 - this", "18.0 - this", "-this"]}
        }
      },
      "holding": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "variable.is_holding_left ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "variable.is_holding_right ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          }
        }
      },
      "attack.rotations": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              0,
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46 - this",
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.sin(1.0 - math.pow(1.0 - variable.attack_time, 3.0) * 180.0) * (variable.is_brandishing_spear ? -1.0 : 1.0 )",
              "variable.is_brandishing_spear ? 0.0 : (math.sin(math.sqrt(variable.attack_time) * 360) * 11.46) * 2.0",
              0
            ]
          }
        }
      },
      "bob": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              0,
              0,
              "((math.cos(query.life_time * 103.2) * 2.865) + 2.865) *-1.0"
            ]
          },
          "rightarm": {
            "rotation": [
              0,
              0,
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "crossbow_hold": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "-85.94 + query.target_x_rotation -this",
              "34.38 + math.clamp(query.target_y_rotation, -45, 25) -this",
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "-90.0 + 5.73 + query.target_x_rotation -this",
              "-17.19 + math.clamp(query.target_y_rotation, -25, 45) -this",
              0
            ]
          }
        }
      },
      "crossbow_charge": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "math.lerp(-90.0 + 34.38, -90.0, variable.crossbow_charge)  -this",
              "math.lerp(22.92, 48.70, variable.crossbow_charge) -this",
              0
            ]
          },
          "rightarm": {"rotation": ["-90.0 + 34.38 -this", "-45.84 -this", 0]}
        }
      },
      "celebrating": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "(math.cos(query.life_time * 800.0) * 2.865)",
              180,
              -135
            ]
          },
          "rightarm": {
            "rotation": [
              "(math.cos(query.life_time * 800.0) * 2.865)",
              180,
              153
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.pillager"],
    "enable_attachables": true,
    "animation_controllers": {
      "controller_humanoid_base_pose": {
        "initial_state": "default",
        "states": {"default": {"animations": ["humanoid_base_pose"]}}
      },
      "controller_look_at_target": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["look_at_target_default"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"swimming": "query.is_swimming"}
            ]
          },
          "gliding": {
            "animations": ["look_at_target_gliding"],
            "transitions": [
              {"swimming": "query.is_swimming"},
              {"default": "!query.is_gliding"}
            ]
          },
          "swimming": {
            "animations": ["look_at_target_swimming"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"default": "!query.is_swimming"}
            ]
          }
        }
      },
      "controller_move": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move"]}}
      },
      "controller_riding": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"riding": "query.is_riding"}]},
          "riding": {
            "animations": ["riding.arms", "riding.legs"],
            "transitions": [{"default": "!query.is_riding"}]
          }
        }
      },
      "controller_attack": {
        "initial_state": "default",
        "states": {
          "attacking": {
            "animations": ["attack.rotations"],
            "transitions": [{"default": "variable.attack_time < 0.0"}]
          },
          "default": {
            "transitions": [{"attacking": "variable.attack_time >= 0.0"}]
          }
        }
      },
      "controller_bob": {
        "initial_state": "default",
        "states": {"default": {"animations": ["bob"]}}
      },
      "controller_pillager_attack": {
        "initial_state": "default",
        "states": {
          "charge": {
            "animations": ["crossbow_charge"],
            "transitions": [
              {"default": "variable.attack_state == 0"},
              {"hold": "variable.attack_state == 1"}
            ]
          },
          "default": {
            "transitions": [
              {"hold": "variable.attack_state == 1"},
              {"charge": "variable.attack_state == 2"}
            ]
          },
          "hold": {
            "animations": ["crossbow_hold"],
            "transitions": [
              {"default": "variable.attack_state == 0"},
              {"charge": "variable.attack_state == 2"}
            ]
          }
        }
      },
      "pillager_root_controller": {
        "initial_state": "default",
        "states": {
          "default": {
            "blend_transition": 0.2,
            "blend_via_shortest_path": true,
            "animations": [
              "controller_humanoid_base_pose",
              "controller_look_at_target",
              "controller_move",
              "controller_riding",
              "controller_attack",
              "controller_bob",
              "controller_pillager_attack"
            ],
            "transitions": [{"celebrating": "query.is_celebrating"}]
          },
          "celebrating": {
            "animations": ["celebrating", {"riding.legs": "query.is_riding"}],
            "blend_transition": 0.2,
            "blend_via_shortest_path": true,
            "transitions": [{"default": "!query.is_celebrating"}]
          }
        }
      }
    }
  },
  "player": {
    "identifier": "minecraft:player",
    "materials": {
      "default": "entity_alphatest",
      "cape": "entity_alphatest",
      "animated": "player_animated"
    },
    "textures": {
      "default": "textures/entity/steve",
      "cape": "textures/entity/cape_invisible"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 1,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 1, 0],
        "bones": [
          {"name": "root", "pivot": [0, 0, 0]},
          {
            "name": "body",
            "parent": "waist",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 12, -2], "size": [8, 12, 4], "uv": [16, 16]}
            ]
          },
          {"name": "waist", "parent": "root", "pivot": [0, 12, 0]},
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-4, 24, -4], "size": [8, 8, 8], "uv": [0, 0]}]
          },
          {"name": "cape", "pivot": [0, 24, 3], "parent": "body"},
          {
            "name": "hat",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-4, 24, -4],
                "size": [8, 8, 8],
                "uv": [32, 0],
                "inflate": 0.5
              }
            ]
          },
          {
            "name": "leftArm",
            "parent": "body",
            "pivot": [5, 22, 0],
            "cubes": [
              {"origin": [4, 12, -2], "size": [4, 12, 4], "uv": [32, 48]}
            ]
          },
          {
            "name": "leftSleeve",
            "parent": "leftArm",
            "pivot": [5, 22, 0],
            "cubes": [
              {
                "origin": [4, 12, -2],
                "size": [4, 12, 4],
                "uv": [48, 48],
                "inflate": 0.25
              }
            ]
          },
          {"name": "leftItem", "pivot": [6, 15, 1], "parent": "leftArm"},
          {
            "name": "rightArm",
            "parent": "body",
            "pivot": [-5, 22, 0],
            "cubes": [
              {"origin": [-8, 12, -2], "size": [4, 12, 4], "uv": [40, 16]}
            ]
          },
          {
            "name": "rightSleeve",
            "parent": "rightArm",
            "pivot": [-5, 22, 0],
            "cubes": [
              {
                "origin": [-8, 12, -2],
                "size": [4, 12, 4],
                "uv": [40, 32],
                "inflate": 0.25
              }
            ]
          },
          {
            "name": "rightItem",
            "pivot": [-6, 15, 1],
            "locators": {"lead_hold": [-6, 15, 1]},
            "parent": "rightArm"
          },
          {
            "name": "leftLeg",
            "parent": "root",
            "pivot": [1.9, 12, 0],
            "cubes": [
              {"origin": [-0.1, 0, -2], "size": [4, 12, 4], "uv": [16, 48]}
            ]
          },
          {
            "name": "leftPants",
            "parent": "leftLeg",
            "pivot": [1.9, 12, 0],
            "cubes": [
              {
                "origin": [-0.1, 0, -2],
                "size": [4, 12, 4],
                "uv": [0, 48],
                "inflate": 0.25
              }
            ]
          },
          {
            "name": "rightLeg",
            "parent": "root",
            "pivot": [-1.9, 12, 0],
            "cubes": [
              {"origin": [-3.9, 0, -2], "size": [4, 12, 4], "uv": [0, 16]}
            ]
          },
          {
            "name": "rightPants",
            "parent": "rightLeg",
            "pivot": [-1.9, 12, 0],
            "cubes": [
              {
                "origin": [-3.9, 0, -2],
                "size": [4, 12, 4],
                "uv": [0, 32],
                "inflate": 0.25
              }
            ]
          },
          {
            "name": "jacket",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-4, 12, -2],
                "size": [8, 12, 4],
                "uv": [16, 32],
                "inflate": 0.25
              }
            ]
          }
        ]
      },
      "cape": {
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {"name": "root", "pivot": [0, 0, 0]},
          {"name": "body", "pivot": [0, 24, 0], "parent": "waist"},
          {
            "name": "waist",
            "parent": "root",
            "neverRender": true,
            "pivot": [0, 12, 0]
          },
          {
            "name": "cape",
            "parent": "body",
            "pivot": [0, 24, 3],
            "bind_pose_rotation": [0, 180, 0],
            "rotation": [0, 180, 0],
            "cubes": [{"origin": [-5, 8, 3], "size": [10, 16, 1], "uv": [0, 0]}]
          }
        ]
      }
    },
    "scripts": {
      "scale": "0.9375",
      "initialize": [
        "variable.is_holding_right = 0.0;",
        "variable.is_blinking = 0.0;",
        "variable.last_blink_time = 0.0;",
        "variable.hand_bob = 0.0;"
      ],
      "pre_animation": [
        "variable.helmet_layer_visible = 1.0;",
        "variable.leg_layer_visible = 1.0;",
        "variable.boot_layer_visible = 1.0;",
        "variable.chest_layer_visible = 1.0;",
        "variable.attack_body_rot_y = Math.sin(360*Math.sqrt(variable.attack_time)) * 5.0;",
        "variable.tcos0 = (math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed / variable.gliding_speed_value) * 57.3;",
        "variable.first_person_rotation_factor = math.sin((1 - variable.attack_time) * 180.0);",
        "variable.hand_bob = query.life_time < 0.01 ? 0.0 : variable.hand_bob + ((query.is_on_ground && query.is_alive ? math.clamp(math.sqrt(math.pow(query.position_delta(0), 2.0) + math.pow(query.position_delta(2), 2.0)), 0.0, 0.1) : 0.0) - variable.hand_bob) * 0.02;",
        "variable.map_angle = math.clamp(1 - variable.player_x_rotation / 45.1, 0.0, 1.0);",
        "variable.item_use_normalized = query.main_hand_item_use_duration / query.main_hand_item_max_duration;"
      ],
      "animate": ["root"]
    },
    "animations": {
      "humanoid_base_pose": {
        "loop": true,
        "bones": {"waist": {"rotation": [0, 0, 0]}}
      },
      "look_at_target_ui": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_default": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_gliding": {
        "loop": true,
        "bones": {"head": {"rotation": [-45, "query.target_y_rotation", 0]}}
      },
      "look_at_target_swimming": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "math.lerp(query.target_x_rotation, -45.0, variable.swim_amount)",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_inverted": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "-query.target_x_rotation",
              "-query.target_y_rotation",
              0
            ]
          }
        }
      },
      "cape": {
        "loop": true,
        "bones": {
          "cape": {
            "position": [
              0,
              "query.get_root_locator_offset('armor_offset.default_neck', 1)",
              0
            ],
            "rotation": [
              "math.lerp(0.0, -126.0, query.cape_flap_amount) - 6.0",
              0,
              0
            ]
          }
        }
      },
      "move.arms": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["variable.tcos0", 0, 0]},
          "rightarm": {"rotation": ["-variable.tcos0", 0, 0]}
        }
      },
      "move.legs": {
        "loop": true,
        "bones": {
          "leftleg": {"rotation": ["variable.tcos0 * -1.4", 0, 0]},
          "rightleg": {"rotation": ["variable.tcos0 * 1.4", 0, 0]}
        }
      },
      "swimming": {
        "animation_length": 1.3,
        "loop": true,
        "override_previous_animation": true,
        "bones": {
          "leftarm": {
            "rotation": {
              "0": [0, 180, 180],
              "0.7": [0, 180, 287.2],
              "1.1": [90, 180, 180],
              "1.3": [0, 180, 180]
            }
          },
          "rightarm": {
            "rotation": {
              "0": [0, 180, -180],
              "0.7": [0, 180, -287.2],
              "1.1": [90, 180, -180],
              "1.3": [0, 180, -180]
            }
          },
          "root": {
            "position": [
              0,
              "(math.sin(query.target_x_rotation) * 24.0 + 3.0) * variable.swim_amount",
              "(math.cos(query.target_x_rotation) * 24.0 + 9.0) * variable.swim_amount"
            ],
            "rotation": [
              "variable.swim_amount * (90 + query.target_x_rotation)",
              0,
              0
            ]
          }
        }
      },
      "swimming.legs": {
        "loop": true,
        "override_previous_animation": true,
        "bones": {
          "leftleg": {
            "rotation": [
              "math.lerp(0.0, math.cos(query.life_time * 390.0 + 180.0) * 17.2, variable.swim_amount)",
              0,
              0
            ]
          },
          "rightleg": {
            "rotation": [
              "math.lerp(0.0, math.cos(query.life_time * 390.0) * 17.2, variable.swim_amount)",
              0,
              0
            ]
          }
        }
      },
      "riding.arms": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": [-36, 0, 0]},
          "rightarm": {"rotation": [-36, 0, 0]}
        }
      },
      "riding.legs": {
        "loop": true,
        "bones": {
          "leftleg": {"rotation": ["-72.0 - this", "-18.0 - this", "-this"]},
          "rightleg": {"rotation": ["-72.0 - this", "18.0 - this", "-this"]}
        }
      },
      "holding": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "variable.is_holding_left ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "variable.is_holding_right ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          }
        }
      },
      "brandish_spear": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "this * -0.5 - 157.5 - 22.5 * variable.charge_amount",
              "-this",
              0
            ]
          }
        }
      },
      "charging": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": ["22.5 * variable.charge_amount - this", "-this", 0]
          }
        }
      },
      "attack.positions": {
        "loop": true,
        "bones": {"head": {"rotation": [0, 0, 0]}}
      },
      "attack.rotations": {
        "loop": true,
        "bones": {
          "body": {"rotation": [0, "variable.attack_body_rot_y", 0]},
          "leftarm": {
            "rotation": [
              "-(math.sin((1 - math.pow((1 - variable.attack_time), 4)) * 180) * 1.2 + math.sin(variable.attack_time * 180)) * 10.0",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "-(math.sin((1 - math.pow((1 - variable.attack_time), 4)) * 180) * 1.2 + math.sin(variable.attack_time * 180)) * 30.0",
              "-(math.sin((1 - math.pow((1 - variable.attack_time), 4)) * 180) ? (-90.0 * math.sin((1 - math.pow((1 - variable.attack_time), 4)) * 180)) + 30.0 : 0.0)",
              0
            ]
          }
        }
      },
      "sneaking": {
        "loop": true,
        "bones": {
          "body": {"position": [0, -2, 0]},
          "head": {"position": [0, -1, 0]},
          "leftarm": {"rotation": [-5.7, 0, 0]},
          "leftleg": {"rotation": [-28, 0, 0]},
          "rightarm": {"rotation": [-5.7, 0, 0]},
          "rightleg": {"rotation": [-28, 0, 0]},
          "root": {"position": [0, 1.25, 9], "rotation": ["28.0 - this", 0, 0]}
        }
      },
      "bob": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              0,
              0,
              "-((math.cos(query.life_time * 103.2) * 2.865) + 2.865)"
            ]
          },
          "rightarm": {
            "rotation": [
              0,
              0,
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "damage_nearby_mobs": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["-45.0-this", "-this", "-this"]},
          "leftleg": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightarm": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-45.0-this", "-this", "-this"]}
        }
      },
      "fishing_rod": {
        "loop": true,
        "bones": {"rightarm": {"rotation": [" -19.0 - this", "-this", "-this"]}}
      },
      "use_item_progress": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "variable.use_item_startup_progress * -60.0 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -22.5 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -5.625 + variable.use_item_interval_progress * 11.25"
            ]
          }
        }
      },
      "skeleton_attack": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "-68.75 * math.sin(variable.attack_time * 180.0) + 22.92 * (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0))",
              "5.73 - math.sin(variable.attack_time * 180.0) * 34.38 - this",
              "-this"
            ]
          },
          "rightarm": {
            "rotation": [
              "-68.75 * math.sin(variable.attack_time * 180.0) + 22.92 * (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0))",
              "-5.73 + math.sin(variable.attack_time * 180.0) * 34.38 - this",
              "-this"
            ]
          }
        }
      },
      "sleeping": {
        "loop": true,
        "override_previous_animation": true,
        "bones": {
          "head": {"rotation": ["30.0 - this", "-this", "-this"]},
          "root": {
            "position": [
              "24.0 * math.cos(query.body_y_rotation) * math.cos(query.sleep_rotation) - 24.0 * math.sin(query.body_y_rotation) * math.sin(query.sleep_rotation)",
              0,
              "24.0 * math.cos(query.body_y_rotation) * math.sin(query.sleep_rotation) + 24.0 * math.sin(query.body_y_rotation) * math.cos(query.sleep_rotation)"
            ],
            "rotation": [
              -90,
              "270.0 - query.sleep_rotation - query.body_y_rotation",
              0
            ]
          }
        }
      },
      "first_person_base_pose": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "first_person_empty_hand": {
        "loop": true,
        "bones": {
          "rightarm": {
            "position": [13.5, -10, 12],
            "rotation": [
              "95.0 + variable.is_using_vr * 7.5",
              "-45.0 + variable.is_using_vr * 7.5",
              "115.0 + variable.is_using_vr * -2.5"
            ]
          }
        }
      },
      "first_person_swap_item": {
        "loop": true,
        "bones": {
          "leftarm": {
            "position": [
              0,
              "query.get_equipped_item_name('off_hand') == 'map' ? 0.0 : -10.0 * (1.0 - variable.player_arm_height)",
              0
            ]
          },
          "rightarm": {
            "position": [0, "-10.0 * (1.0 - variable.player_arm_height)", 0]
          }
        }
      },
      "first_person_attack_rotation": {
        "loop": true,
        "bones": {
          "rightarm": {
            "position": [
              "math.clamp(-15.5 * math.sin(variable.first_person_rotation_factor * variable.attack_time * 112.0), -7.0, 999.0) * math.sin(variable.first_person_rotation_factor * variable.attack_time * 112.0)",
              "math.sin(variable.first_person_rotation_factor * (1.0 - variable.attack_time) * (1.0 - variable.attack_time) * 200.0) * 7.5 - variable.first_person_rotation_factor * variable.attack_time * 15.0 + variable.short_arm_offset_right",
              "math.sin(variable.first_person_rotation_factor * variable.attack_time * 120.0) * 1.75"
            ],
            "rotation": [
              "math.sin(variable.first_person_rotation_factor * (1.0 - variable.attack_time) * (1.0 - variable.attack_time) * 280.0) * -60.0",
              "math.sin(variable.first_person_rotation_factor * (1.0 - variable.attack_time) * (1.0 - variable.attack_time) * 280.0) * 40.0",
              "math.sin(variable.first_person_rotation_factor * (1.0 - variable.attack_time) * (1.0 - variable.attack_time) * 280.0) * 20.0"
            ]
          }
        }
      },
      "first_person_vr_attack_rotation": {
        "loop": true,
        "bones": {
          "rightarm": {
            "position": [
              "5.0 * math.sin(variable.first_person_rotation_factor * variable.attack_time * 112.0)",
              "(math.sin(variable.first_person_rotation_factor * (1.0 - variable.attack_time) * (1.0 - variable.attack_time) * 200.0) - 0.8) * 8.75 + 5.0",
              "math.sin(variable.first_person_rotation_factor * variable.attack_time * 120.0) * 15.0"
            ],
            "rotation": [
              "30.7 * math.sin(variable.first_person_rotation_factor * variable.attack_time * -180.0 - 45.0) * 1.5",
              0,
              "21.8 * math.sin(variable.first_person_rotation_factor * variable.attack_time * 200.0 + 30.0) * 1.25"
            ]
          }
        }
      },
      "first_person_map_hold": {
        "loop": true,
        "bones": {
          "leftarm": {
            "position": [
              "-16.250 + variable.is_vertical_splitscreen * 7.0",
              "-10.75 - variable.map_angle * 8.0 + variable.is_vertical_splitscreen * 0.6 - variable.short_arm_offset_left",
              "9.0 - variable.map_angle * 8.0 + variable.short_arm_offset_left"
            ],
            "rotation": [40, -20, -155],
            "scale": [1.15, 1.15, 1.15]
          },
          "rightarm": {
            "position": [
              "12.50 + variable.is_vertical_splitscreen * 1.75",
              "-7.5 - variable.map_angle * 8.0 + variable.is_vertical_splitscreen * 0.5 - variable.short_arm_offset_right",
              "5.25 - variable.map_angle * 8.0 + variable.short_arm_offset_right"
            ],
            "rotation": [77.5, 7.5, 160]
          }
        }
      },
      "first_person_map_hold_attack": {
        "loop": true,
        "bones": {
          "leftarm": {
            "position": [
              "math.sin(variable.first_person_rotation_factor * variable.attack_time * 112.0) * -10.75",
              "math.sin(variable.first_person_rotation_factor * (1.0 - variable.attack_time) * (1.0 - variable.attack_time) * 200.0) * 3.75 - variable.first_person_rotation_factor * variable.attack_time * 1.25 + variable.short_arm_offset_left",
              "math.sin(variable.first_person_rotation_factor * variable.attack_time * 120.0) * 5.75"
            ],
            "rotation": [
              "variable.map_angle * 90.0",
              "-15.0 * math.sin(variable.first_person_rotation_factor * (1.0 - variable.attack_time) * -100.0)",
              0
            ]
          },
          "rightarm": {
            "position": [
              "math.sin(variable.first_person_rotation_factor * variable.attack_time * 112.0) * -6.25",
              "math.sin(variable.first_person_rotation_factor * (1.0 - variable.attack_time) * (1.0 - variable.attack_time) * 200.0) * 1.75 + variable.short_arm_offset_right",
              "math.sin(variable.first_person_rotation_factor * variable.attack_time * 120.0) * 5.25"
            ],
            "rotation": ["variable.map_angle * 90.0", 0, 0]
          }
        }
      },
      "first_person_map_hold_off_hand": {
        "loop": true,
        "bones": {
          "leftarm": {
            "position": [
              "-14.50 + variable.is_horizontal_splitscreen * 2.0 + variable.is_vertical_splitscreen * 8.7",
              "-8.250 + variable.short_arm_offset_left",
              "11.50 + variable.is_horizontal_splitscreen * 0.5"
            ],
            "rotation": [195, 182.5, 2.5],
            "scale": [0.75, 0.75, 0.75]
          }
        }
      },
      "first_person_map_hold_main_hand": {
        "loop": true,
        "bones": {
          "rightarm": {
            "position": [
              "14.50 - variable.is_vertical_splitscreen * 0.75",
              "-8.25 + variable.short_arm_offset_right + math.sin(variable.first_person_rotation_factor * (1.0 - variable.attack_time) * (1.0 - variable.attack_time) * 200.0) * 2.75 - variable.first_person_rotation_factor * variable.attack_time * 3.0 - variable.is_horizontal_splitscreen",
              "11.5 + math.sin(variable.first_person_rotation_factor * variable.attack_time * 120.0) * 3.5 + variable.is_horizontal_splitscreen * 3.5"
            ],
            "rotation": [195, 182.5, -5],
            "scale": [0.75, 0.75, 0.75]
          }
        }
      },
      "first_person_crossbow_equipped": {
        "loop": true,
        "override_previous_animation": true,
        "bones": {
          "leftarm": {
            "position": [
              "1.5 - variable.item_use_normalized * 3.5",
              "-3.799999952316284 + variable.short_arm_offset_left",
              "8.25 - (1 - variable.item_use_normalized)"
            ],
            "rotation": [165, -60, 45],
            "scale": [0.4, 0.4, 0.4]
          }
        }
      },
      "third_person_crossbow_equipped": {
        "loop": true,
        "bones": {
          "leftarm": {
            "position": [0, 0, 0.5],
            "rotation": [
              "-this - 65.0 - (1.0 - variable.item_use_normalized) * 5.0",
              "-this + (1.0 - variable.item_use_normalized) * 30.0",
              "-this + 20.0 + (1.0 - variable.item_use_normalized) * 10.0"
            ]
          },
          "rightarm": {
            "rotation": ["- 60.0 - this", "- 45.0 - this", "- 2.5 - this"]
          }
        }
      },
      "third_person_bow_equipped": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "-90.0 + query.target_x_rotation - query.is_sneaking * 15.0 - this",
              "27.5 + query.target_y_rotation",
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "-90.0 + query.target_x_rotation - query.is_sneaking * 15.0 - this",
              "-5.0 + query.target_y_rotation",
              0
            ]
          },
          "rightitem": {"rotation": [0, -10, 0]}
        }
      },
      "crossbow_hold": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "query.is_swimming ? 0.0 : -93.0 + query.target_x_rotation - query.is_sneaking * 27.0 -this",
              "query.is_swimming ? 0.0 : 42.0 + math.clamp(query.target_y_rotation, -45.0, 5.0) -this",
              "query.is_sneaking * -15.0"
            ]
          },
          "rightarm": {
            "rotation": [
              "query.is_swimming ? 0.0 : -93.0 + query.target_x_rotation - query.is_sneaking * 27.0 -this",
              "query.is_swimming ? 0.0 : math.clamp(query.target_y_rotation, -60.0, 45.0) -this",
              0
            ]
          }
        }
      },
      "shield_block_main_hand": {
        "loop": true,
        "bones": {
          "rightarm": {"rotation": [-20, -30, -25]},
          "rightitem": {"position": [-1, -3, 0], "rotation": [0, -60, -45]}
        }
      },
      "shield_block_off_hand": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": [-20, 20, 20]},
          "leftitem": {
            "position": [
              "1.0 + query.item_is_charged * 1.5",
              "-3.0 + query.item_is_charged",
              0
            ],
            "rotation": [
              "query.item_is_charged * 30.0",
              "70.0 - query.item_is_charged * 60.0",
              "65.0 - query.item_is_charged * 15.0"
            ]
          }
        }
      }
    },
    "render_controllers": [
      {"controller.render.player.first_person": "variable.is_first_person"},
      {
        "controller.render.player.third_person": "!variable.is_first_person && !variable.map_face_icon"
      },
      {"controller.render.player.map": "variable.map_face_icon"}
    ],
    "enable_attachables": true,
    "animation_controllers": {
      "root": {
        "initial_state": "first_person",
        "states": {
          "first_person": {
            "animations": [
              "first_person_swap_item",
              {
                "first_person_attack_controller": "variable.attack_time > 0.0f && query.get_equipped_item_name != 'map'"
              },
              "first_person_base_pose",
              {
                "first_person_empty_hand": "query.get_equipped_item_name(0, 1) != 'map'"
              },
              {
                "first_person_map_controller": "(query.get_equipped_item_name(0, 1) == 'map' || query.get_equipped_item_name('off_hand') == 'map')"
              },
              {
                "first_person_crossbow_equipped": "query.get_equipped_item_name == 'crossbow' && (variable.item_use_normalized > 0 && variable.item_use_normalized < 1.0)"
              }
            ],
            "transitions": [
              {"paperdoll": "variable.is_paperdoll"},
              {"map_player": "variable.map_face_icon"},
              {"third_person": "!variable.is_first_person"}
            ]
          },
          "map_player": {
            "transitions": [
              {"paperdoll": "variable.is_paperdoll"},
              {"first_person": "variable.is_first_person"},
              {
                "third_person": "!variable.map_face_icon && !variable.is_first_person"
              }
            ]
          },
          "paperdoll": {
            "animations": [
              "humanoid_base_pose",
              {"look_at_target_ui": "variable.should_look_at_target_ui"},
              "move.arms",
              "move.legs",
              "cape"
            ],
            "transitions": [
              {
                "first_person": "!variable.is_paperdoll && variable.is_first_person"
              },
              {"map_player": "variable.map_face_icon"},
              {
                "third_person": "!variable.is_paperdoll && !variable.is_first_person"
              }
            ]
          },
          "third_person": {
            "animations": [
              "humanoid_base_pose",
              {"look_at_target": "!query.is_sleeping && !query.is_emoting"},
              "move.arms",
              "move.legs",
              "cape",
              {"riding.arms": "query.is_riding"},
              {"riding.legs": "query.is_riding"},
              "holding",
              {"brandish_spear": "variable.is_brandishing_spear"},
              {"charging": "query.is_charging"},
              {"sneaking": "query.is_sneaking && !query.is_sleeping"},
              "bob",
              {"damage_nearby_mobs": "variable.damage_nearby_mobs"},
              {"swimming": "variable.swim_amount > 0.0"},
              {"swimming.legs": "variable.swim_amount > 0.0"},
              {
                "use_item_progress": "( variable.use_item_interval_progress > 0.0 ) || ( variable.use_item_startup_progress > 0.0 ) && !variable.is_brandishing_spear"
              },
              {"fishing_rod": "query.get_equipped_item_name == 'fishing_rod'"},
              {"sleeping": "query.is_sleeping && query.is_alive"},
              {"attack.positions": "variable.attack_time >= 0.0"},
              {"attack.rotations": "variable.attack_time >= 0.0"},
              {
                "shield_block_main_hand": "query.blocking && query.get_equipped_item_name('off_hand') != 'shield' && query.get_equipped_item_name == 'shield'"
              },
              {
                "shield_block_off_hand": "query.blocking && query.get_equipped_item_name('off_hand') == 'shield' && !(variable.item_use_normalized > 0 && variable.item_use_normalized < 1.0)"
              },
              {
                "crossbow_controller": "query.get_equipped_item_name == 'crossbow'"
              },
              {
                "third_person_bow_equipped": "query.get_equipped_item_name == 'bow' && (variable.item_use_normalized > 0 && variable.item_use_normalized < 1.0)"
              }
            ],
            "transitions": [
              {"paperdoll": "variable.is_paperdoll"},
              {"first_person": "variable.is_first_person"},
              {"map_player": "variable.map_face_icon"}
            ]
          }
        }
      },
      "base_controller": "controller.animation.player.base",
      "hudplayer": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": [
              "humanoid_base_pose",
              {"look_at_target": "!query.is_sleeping && !query.is_emoting"},
              "move.arms",
              "move.legs",
              "cape",
              {"riding.arms": "query.is_riding"},
              {"riding.legs": "query.is_riding"},
              "holding",
              {"brandish_spear": "variable.is_brandishing_spear"},
              {"charging": "query.is_charging"},
              {"sneaking": "query.is_sneaking && !query.is_sleeping"},
              "bob",
              {"damage_nearby_mobs": "variable.damage_nearby_mobs"},
              {"swimming": "variable.swim_amount > 0.0"},
              {"swimming.legs": "variable.swim_amount > 0.0"},
              {
                "use_item_progress": "( variable.use_item_interval_progress > 0.0 ) || ( variable.use_item_startup_progress > 0.0 ) && !variable.is_brandishing_spear"
              },
              {"sleeping": "query.is_sleeping && query.is_alive"},
              {"attack.positions": "variable.attack_time >= 0.0"},
              {"attack.rotations": "variable.attack_time >= 0.0"},
              {
                "shield_block_main_hand": "query.blocking && query.get_equipped_item_name('off_hand') != 'shield' && query.get_equipped_item_name == 'shield'"
              },
              {
                "shield_block_off_hand": "query.blocking && query.get_equipped_item_name('off_hand') == 'shield' && !(variable.item_use_normalized > 0 && variable.item_use_normalized < 1.0)"
              },
              {
                "crossbow_controller": "query.get_equipped_item_name == 'crossbow'"
              },
              {
                "third_person_bow_equipped": "query.get_equipped_item_name == 'bow' && (variable.item_use_normalized > 0 && variable.item_use_normalized < 1.0)"
              }
            ]
          }
        }
      },
      "look_at_target": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["look_at_target_default"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"swimming": "query.is_swimming"}
            ]
          },
          "gliding": {
            "animations": ["look_at_target_gliding"],
            "transitions": [
              {"swimming": "query.is_swimming"},
              {"default": "!query.is_gliding"}
            ]
          },
          "swimming": {
            "animations": ["look_at_target_swimming"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"default": "!query.is_swimming"}
            ]
          }
        }
      },
      "first_person_attack_controller": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["first_person_attack_rotation"],
            "transitions": [{"vr_attack": "variable.is_using_vr"}]
          },
          "vr_attack": {
            "animations": ["first_person_vr_attack_rotation"],
            "transitions": [{"default": "!variable.is_using_vr"}]
          }
        }
      },
      "first_person_map_controller": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [
              {
                "one_hand": "query.get_equipped_item_name('off_hand') == 'map' || query.get_equipped_item_name('off_hand') == 'shield'"
              },
              {
                "two_hand": "query.get_equipped_item_name('off_hand') != 'map' && query.get_equipped_item_name('off_hand') != 'shield'"
              }
            ]
          },
          "one_hand": {
            "animations": [
              {
                "first_person_map_hold_main_hand": "query.get_equipped_item_name(0, 1) == 'map'"
              },
              {
                "first_person_map_hold_off_hand": "query.get_equipped_item_name('off_hand') == 'map' && (query.get_equipped_item_name == 'bow' ? !(variable.item_use_normalized > 0 && variable.item_use_normalized < 1.0) : 1.0)"
              }
            ],
            "transitions": [
              {
                "default": "query.get_equipped_item_name(0, 1) != 'map' && query.get_equipped_item_name('off_hand') != 'map'"
              },
              {
                "two_hand": "query.get_equipped_item_name('off_hand') != 'map' && query.get_equipped_item_name('off_hand') != 'shield'"
              }
            ]
          },
          "two_hand": {
            "animations": [
              "first_person_map_hold",
              "first_person_map_hold_attack"
            ],
            "transitions": [
              {
                "default": "query.get_equipped_item_name(0, 1) != 'map' && query.get_equipped_item_name('off_hand') != 'map'"
              },
              {
                "one_hand": "query.get_equipped_item_name('off_hand') == 'map' || query.get_equipped_item_name('off_hand') == 'shield'"
              }
            ]
          }
        }
      },
      "crossbow_controller": {
        "initial_state": "default",
        "states": {
          "charge": {
            "animations": ["third_person_crossbow_equipped"],
            "transitions": [
              {
                "default": "query.get_equipped_item_name != 'crossbow' || (query.item_remaining_use_duration <= 0.0 && !query.item_is_charged)"
              },
              {"hold": "query.item_is_charged"}
            ]
          },
          "default": {
            "transitions": [
              {"hold": "query.item_is_charged"},
              {"charge": "query.item_remaining_use_duration > 0.0"}
            ]
          },
          "hold": {
            "animations": ["crossbow_hold"],
            "transitions": [
              {
                "default": "query.get_equipped_item_name != 'crossbow' || (query.item_remaining_use_duration <= 0.0 && !query.item_is_charged)"
              },
              {"charge": "query.item_remaining_use_duration > 0.0"}
            ]
          }
        }
      },
      "blink": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [
              {
                "open": "variable.is_blinking = 0; variable.last_blink_time = query.life_time; return variable.last_blink_time != 0;"
              }
            ]
          },
          "open": {
            "transitions": [
              {
                "blink": "variable.is_blinking = 0; variable.return_from_blink = query.life_time + math.random(0, 0.2); return query.life_time > (variable.last_blink_time + math.random(3, 40));"
              }
            ]
          },
          "blink": {
            "transitions": [
              {
                "open": "variable.is_blinking = 1; variable.last_blink_time = query.life_time; return query.all_animations_finished && (query.life_time > variable.return_from_blink);"
              }
            ]
          }
        }
      }
    }
  },
  "polar_bear": {
    "identifier": "minecraft:polar_bear",
    "materials": {"default": "polar_bear"},
    "textures": {"default": "textures/entity/bear/polarbear"},
    "geometry": {
      "default": {
        "visible_bounds_width": 3,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 128,
        "textureheight": 64,
        "bones": [
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 14, -16],
            "locators": {"lead": [0, 14, -16]},
            "mirror": true,
            "cubes": [
              {
                "mirror": false,
                "origin": [-3.5, 10, -19],
                "size": [7, 7, 7],
                "uv": [0, 0]
              },
              {
                "mirror": false,
                "origin": [-2.5, 10, -22],
                "size": [5, 3, 3],
                "uv": [0, 44]
              },
              {
                "mirror": false,
                "origin": [-4.5, 16, -17],
                "size": [2, 2, 1],
                "uv": [26, 0]
              },
              {"origin": [2.5, 16, -17], "size": [2, 2, 1], "uv": [26, 0]}
            ]
          },
          {
            "name": "body",
            "pivot": [-2, 15, 12],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {"origin": [-7, 14, 5], "size": [14, 14, 11], "uv": [0, 19]},
              {"origin": [-6, 28, 5], "size": [12, 12, 10], "uv": [39, 0]}
            ]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-4.5, 10, 6],
            "cubes": [
              {"origin": [-6.5, 0, 4], "size": [4, 10, 8], "uv": [50, 22]}
            ]
          },
          {
            "name": "leg1",
            "parent": "body",
            "pivot": [4.5, 10, 6],
            "cubes": [
              {"origin": [2.5, 0, 4], "size": [4, 10, 8], "uv": [50, 22]}
            ]
          },
          {
            "name": "leg2",
            "parent": "body",
            "pivot": [-3.5, 10, -8],
            "cubes": [
              {"origin": [-5.5, 0, -10], "size": [4, 10, 6], "uv": [50, 40]}
            ]
          },
          {
            "name": "leg3",
            "parent": "body",
            "pivot": [3.5, 10, -8],
            "cubes": [
              {"origin": [1.5, 0, -10], "size": [4, 10, 6], "uv": [50, 40]}
            ]
          }
        ]
      }
    },
    "animations": {
      "walk": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          },
          "leg1": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          },
          "leg2": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          },
          "leg3": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          }
        }
      },
      "move": {
        "loop": true,
        "bones": {
          "body": {
            "position": [0, "-9 - 2 * query.standing_scale - this", 0],
            "rotation": ["(-(query.standing_scale) * 63) - this", 0, 0]
          },
          "leg0": {
            "position": [
              0,
              "-1 * query.standing_scale",
              "6 * query.standing_scale"
            ],
            "rotation": ["query.standing_scale * 63", 0, 0]
          },
          "leg1": {
            "position": [
              0,
              "-1 * query.standing_scale",
              "6 * query.standing_scale"
            ],
            "rotation": ["query.standing_scale * 63", 0, 0]
          },
          "leg2": {
            "rotation": [
              "(query.standing_scale > 1) ? (query.standing_scale * 81) - this : 0",
              0,
              0
            ]
          },
          "leg3": {
            "rotation": [
              "(query.standing_scale > 1) ? (query.standing_scale * 81) - this : 0",
              0,
              0
            ]
          }
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "baby_transform": {
        "loop": true,
        "bones": {"head": {"position": [0, -1, 3], "scale": 1.25}}
      }
    },
    "scripts": {"scale": "1.2"},
    "animation_controllers": {
      "move": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": [
              {"walk": "query.modified_move_speed"},
              "move",
              "look_at_target"
            ]
          }
        }
      },
      "baby": {
        "initial_state": "baby",
        "states": {
          "baby": {"animations": [{"baby_transform": "query.is_baby"}]}
        }
      }
    },
    "render_controllers": ["controller.render.polarbear"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 37}
  },
  "pufferfish": {
    "identifier": "minecraft:pufferfish",
    "min_engine_version": "1.8.0",
    "materials": {"default": "pufferfish"},
    "textures": {"default": "textures/entity/fish/pufferfish"},
    "geometry": {
      "default": {
        "visible_bounds_width": 0.5,
        "visible_bounds_height": 0.5,
        "texturewidth": 32,
        "textureheight": 32,
        "bones": [
          {
            "name": "body",
            "cubes": [
              {"origin": [-1.5, 0, -1.5], "size": [3, 2, 3], "uv": [0, 27]},
              {"origin": [0.5, 2, -1.5], "size": [1, 1, 1], "uv": [24, 6]},
              {"origin": [-1.5, 2, -1.5], "size": [1, 1, 1], "uv": [28, 6]}
            ],
            "locators": {"lead": [0, 0, 0]}
          },
          {
            "name": "tailfin",
            "parent": "body",
            "cubes": [
              {"origin": [-1.5, 1, 1.5], "size": [3, 0, 3], "uv": [-3, 0]}
            ]
          },
          {
            "name": "leftFin",
            "parent": "body",
            "pivot": [6.5, 5, 0.5],
            "cubes": [
              {
                "origin": [1.5, 0, -1.5],
                "size": [1, 1, 2],
                "uv": [25, 0],
                "mirror": true
              }
            ]
          },
          {
            "name": "rightFin",
            "parent": "body",
            "pivot": [-6.5, 5, 0.5],
            "cubes": [
              {"origin": [-2.5, 0, -1.5], "size": [1, 1, 2], "uv": [25, 0]}
            ]
          }
        ]
      },
      "mid": {
        "visible_bounds_width": 0.5,
        "visible_bounds_height": 0.5,
        "texturewidth": 32,
        "textureheight": 32,
        "bones": [
          {
            "name": "body",
            "cubes": [
              {"origin": [-2.5, 1, -2.5], "size": [5, 5, 5], "uv": [12, 22]}
            ]
          },
          {
            "name": "leftFin",
            "parent": "body",
            "pivot": [2.5, 5, 0.5],
            "cubes": [
              {"origin": [2.5, 4, -1.5], "size": [2, 1, 2], "uv": [24, 3]}
            ]
          },
          {
            "name": "rightFin",
            "parent": "body",
            "pivot": [-2.5, 5, 0.5],
            "cubes": [
              {"origin": [-4.5, 4, -1.5], "size": [2, 1, 2], "uv": [24, 0]}
            ]
          },
          {
            "name": "spines_top_front",
            "parent": "body",
            "bind_pose_rotation": [45, 0, 0],
            "pivot": [0, 6, -2.5],
            "cubes": [
              {"origin": [-2.5, 6, -2.5], "size": [5, 1, 0], "uv": [19, 17]}
            ]
          },
          {
            "name": "spines_top_back",
            "parent": "body",
            "bind_pose_rotation": [-45, 0, 0],
            "pivot": [0, 6, 2.5],
            "cubes": [
              {"origin": [-2.5, 6, 2.5], "size": [5, 1, 0], "uv": [11, 17]}
            ]
          },
          {
            "name": "spines_bottom_front",
            "parent": "body",
            "bind_pose_rotation": [-45, 0, 0],
            "pivot": [0, 1, -2.5],
            "cubes": [
              {"origin": [-2.5, 0, -2.5], "size": [5, 1, 0], "uv": [18, 20]}
            ]
          },
          {
            "name": "spines_bottom_back",
            "parent": "body",
            "bind_pose_rotation": [45, 0, 0],
            "pivot": [0, 1, 2.5],
            "rotation": [45, 0, 0],
            "cubes": [
              {"origin": [-2.5, 0, 2.5], "size": [5, 1, 0], "uv": [18, 20]}
            ]
          },
          {
            "name": "spines_left_front",
            "parent": "body",
            "bind_pose_rotation": [0, 45, 0],
            "pivot": [2.5, 0, -2.5],
            "rotation": [0, 45, 0],
            "cubes": [
              {"origin": [2.5, 1, -2.5], "size": [1, 5, 0], "uv": [1, 17]}
            ]
          },
          {
            "name": "spines_left_back",
            "parent": "body",
            "bind_pose_rotation": [0, -45, 0],
            "pivot": [2.5, 0, 2.5],
            "rotation": [0, -45, 0],
            "cubes": [
              {"origin": [2.5, 1, 2.5], "size": [1, 5, 0], "uv": [1, 17]}
            ]
          },
          {
            "name": "spines_right_front",
            "parent": "body",
            "bind_pose_rotation": [0, -45, 0],
            "pivot": [-2.5, 0, -2.5],
            "rotation": [0, -45, 0],
            "cubes": [
              {"origin": [-3.5, 1, -2.5], "size": [1, 5, 0], "uv": [5, 17]}
            ]
          },
          {
            "name": "spines_right_back",
            "parent": "body",
            "bind_pose_rotation": [0, 45, 0],
            "pivot": [-2.5, 0, 2.5],
            "rotation": [0, 45, 0],
            "cubes": [
              {"origin": [-3.5, 1, 2.5], "size": [1, 5, 0], "uv": [9, 17]}
            ]
          }
        ]
      },
      "large": {
        "visible_bounds_width": 0.5,
        "visible_bounds_height": 0.5,
        "texturewidth": 32,
        "textureheight": 32,
        "bones": [
          {
            "name": "body",
            "cubes": [{"origin": [-4, 0, -4], "size": [8, 8, 8], "uv": [0, 0]}]
          },
          {
            "name": "leftFin",
            "parent": "body",
            "pivot": [4, 7, 1],
            "cubes": [
              {"origin": [4, 6, -2.9904], "size": [2, 1, 2], "uv": [24, 3]}
            ]
          },
          {
            "name": "rightFin",
            "parent": "body",
            "pivot": [-4, 7, 1],
            "cubes": [
              {"origin": [-5.9968, 6, -2.992], "size": [2, 1, 2], "uv": [24, 0]}
            ]
          },
          {
            "name": "spines_top_front",
            "parent": "body",
            "pivot": [-4, 8, -4],
            "bind_pose_rotation": [45, 0, 0],
            "cubes": [
              {"origin": [-4, 8, -4], "size": [8, 1, 1], "uv": [14, 16]}
            ]
          },
          {
            "name": "spines_top_mid",
            "parent": "body",
            "pivot": [0, 8, 0],
            "cubes": [{"origin": [-4, 8, 0], "size": [8, 1, 1], "uv": [14, 16]}]
          },
          {
            "name": "spines_top_back",
            "parent": "body",
            "pivot": [0, 8, 4],
            "bind_pose_rotation": [-45, 0, 0],
            "cubes": [{"origin": [-4, 8, 4], "size": [8, 1, 1], "uv": [14, 16]}]
          },
          {
            "name": "spines_bottom_front",
            "parent": "body",
            "pivot": [0, 0, -4],
            "bind_pose_rotation": [-45, 0, 0],
            "cubes": [
              {"origin": [-4, -1, -4], "size": [8, 1, 1], "uv": [14, 19]}
            ]
          },
          {
            "name": "spines_bottom_mid",
            "parent": "body",
            "pivot": [0, -1, 0],
            "cubes": [
              {"origin": [-4, -1, 0], "size": [8, 1, 1], "uv": [14, 19]}
            ]
          },
          {
            "name": "spines_bottom_back",
            "parent": "body",
            "pivot": [0, 0, 4],
            "bind_pose_rotation": [45, 0, 0],
            "cubes": [
              {"origin": [-4, -1, 4], "size": [8, 1, 1], "uv": [14, 19]}
            ]
          },
          {
            "name": "spines_left_front",
            "parent": "body",
            "pivot": [4, 0, -4],
            "bind_pose_rotation": [0, 45, 0],
            "cubes": [{"origin": [4, 0, -4], "size": [1, 8, 1], "uv": [0, 16]}]
          },
          {
            "name": "spines_left_mid",
            "parent": "body",
            "pivot": [4, 0, 0],
            "cubes": [
              {
                "origin": [4, 0, 0],
                "size": [1, 8, 1],
                "uv": [4, 16],
                "mirror": true
              }
            ]
          },
          {
            "name": "spines_left_back",
            "parent": "body",
            "pivot": [4, 0, 4],
            "bind_pose_rotation": [0, -45, 0],
            "cubes": [
              {
                "origin": [4, 0, 4],
                "size": [1, 8, 1],
                "uv": [8, 16],
                "mirror": true
              }
            ]
          },
          {
            "name": "spines_right_front",
            "parent": "body",
            "pivot": [-4, 0, -4],
            "bind_pose_rotation": [0, -45, 0],
            "cubes": [{"origin": [-5, 0, -4], "size": [1, 8, 1], "uv": [4, 16]}]
          },
          {
            "name": "spines_right_mid",
            "parent": "body",
            "pivot": [-4, 0, 0],
            "cubes": [{"origin": [-5, 0, 0], "size": [1, 8, 1], "uv": [8, 16]}]
          },
          {
            "name": "spines_right_back",
            "parent": "body",
            "pivot": [-4, 0, 4],
            "bind_pose_rotation": [0, 45, 0],
            "cubes": [{"origin": [-5, 0, 4], "size": [1, 8, 1], "uv": [8, 16]}]
          }
        ]
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.ZRot = !query.is_in_water ? Math.cos(query.time_stamp * 14.32) * 90 : 0.0;",
        "variable.AnimationAmountBlend = Math.lerp(variable.AnimationAmountPrev, variable.AnimationAmount, query.frame_alpha);"
      ],
      "animate": ["general"]
    },
    "animations": {
      "flop": {
        "loop": true,
        "bones": {
          "body": {"rotation": [0, 0, "variable.zrot"]},
          "tailfin": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 45.0) * -5.75",
              0
            ]
          }
        }
      },
      "swim": {
        "loop": true,
        "bones": {
          "tailfin": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 30.0) * -5.75",
              0
            ]
          }
        }
      }
    },
    "render_controllers": [
      {"controller.render.pufferfish.small": "query.variant == 0"},
      {"controller.render.pufferfish.medium": "query.variant == 1"},
      {"controller.render.pufferfish.large": "query.variant == 2"}
    ],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 46},
    "animation_controllers": {
      "general": {
        "initial_state": "flopping",
        "states": {
          "flopping": {
            "animations": ["flop"],
            "transitions": [
              {"swimming": "query.is_in_water || query.is_levitating"}
            ]
          },
          "swimming": {
            "animations": ["swim"],
            "transitions": [
              {"flopping": "!query.is_in_water && !query.is_levitating"}
            ]
          }
        }
      }
    }
  },
  "rabbit": {
    "identifier": "minecraft:rabbit",
    "min_engine_version": "1.8.0",
    "materials": {"default": "rabbit"},
    "textures": {
      "brown": "textures/entity/rabbit/brown",
      "white": "textures/entity/rabbit/white",
      "black": "textures/entity/rabbit/black",
      "white_splotched": "textures/entity/rabbit/white_splotched",
      "gold": "textures/entity/rabbit/gold",
      "salt": "textures/entity/rabbit/salt",
      "toast": "textures/entity/rabbit/toast"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 1,
        "visible_bounds_height": 1,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "rearFootLeft",
            "pivot": [3, 6.5, 3.7],
            "mirror": true,
            "parent": "body",
            "cubes": [{"origin": [2, 0, 0], "size": [2, 1, 7], "uv": [8, 24]}]
          },
          {
            "name": "rearFootRight",
            "pivot": [-3, 6.5, 3.7],
            "mirror": true,
            "parent": "body",
            "cubes": [{"origin": [-4, 0, 0], "size": [2, 1, 7], "uv": [26, 24]}]
          },
          {
            "name": "haunchLeft",
            "pivot": [3, 6.5, 3.7],
            "bind_pose_rotation": [-20, 0, 0],
            "mirror": true,
            "parent": "body",
            "cubes": [
              {"origin": [2, 2.5, 3.7], "size": [2, 4, 5], "uv": [16, 15]}
            ]
          },
          {
            "name": "haunchRight",
            "pivot": [-3, 6.5, 3.7],
            "bind_pose_rotation": [-20, 0, 0],
            "mirror": true,
            "parent": "body",
            "cubes": [
              {"origin": [-4, 2.5, 3.7], "size": [2, 4, 5], "uv": [30, 15]}
            ]
          },
          {
            "name": "body",
            "pivot": [0, 5, 8],
            "bind_pose_rotation": [-20, 0, 0],
            "mirror": true,
            "cubes": [{"origin": [-3, 2, -2], "size": [6, 5, 10], "uv": [0, 0]}]
          },
          {
            "name": "frontLegLeft",
            "pivot": [3, 7, -1],
            "bind_pose_rotation": [-10, 0, 0],
            "mirror": true,
            "parent": "body",
            "cubes": [{"origin": [2, 0, -2], "size": [2, 7, 2], "uv": [8, 15]}]
          },
          {
            "name": "frontLegRight",
            "pivot": [-3, 7, -1],
            "bind_pose_rotation": [-10, 0, 0],
            "mirror": true,
            "parent": "body",
            "cubes": [{"origin": [-4, 0, -2], "size": [2, 7, 2], "uv": [0, 15]}]
          },
          {
            "name": "head",
            "pivot": [0, 8, -1],
            "locators": {"lead": [0, 8, -1]},
            "mirror": true,
            "parent": "body",
            "cubes": [
              {"origin": [-2.5, 8, -6], "size": [5, 4, 5], "uv": [32, 0]}
            ]
          },
          {
            "name": "earRight",
            "pivot": [0, 8, -1],
            "bind_pose_rotation": [0, -15, 0],
            "mirror": true,
            "parent": "body",
            "cubes": [
              {"origin": [-2.5, 12, -2], "size": [2, 5, 1], "uv": [58, 0]}
            ]
          },
          {
            "name": "earLeft",
            "pivot": [0, 8, -1],
            "bind_pose_rotation": [0, 15, 0],
            "mirror": true,
            "parent": "body",
            "cubes": [
              {"origin": [0.5, 12, -2], "size": [2, 5, 1], "uv": [52, 0]}
            ]
          },
          {
            "name": "tail",
            "pivot": [0, 4, 7],
            "bind_pose_rotation": [-20, 0, 0],
            "mirror": true,
            "parent": "body",
            "cubes": [
              {"origin": [-1.5, 2.5, 7], "size": [3, 3, 2], "uv": [52, 6]}
            ]
          },
          {
            "name": "nose",
            "pivot": [0, 8, -1],
            "mirror": true,
            "parent": "body",
            "cubes": [
              {"origin": [-0.5, 9.5, -6.5], "size": [1, 1, 1], "uv": [32, 9]}
            ]
          }
        ]
      }
    },
    "animations": {
      "move": {
        "loop": true,
        "bones": {
          "earleft": {
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this + 15.0",
              0
            ]
          },
          "earright": {
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this - 15.0",
              0
            ]
          },
          "frontlegleft": {
            "rotation": ["variable.jump_rotation * -40.0 - 11.0 - this", 0, 0]
          },
          "frontlegright": {
            "rotation": ["variable.jump_rotation * -40.0 - 11.0 - this", 0, 0]
          },
          "haunchleft": {
            "rotation": ["variable.jump_rotation * 50.0 - 21.0 - this", 0, 0]
          },
          "haunchright": {
            "rotation": ["variable.jump_rotation * 50.0 - 21.0 - this", 0, 0]
          },
          "nose": {
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          },
          "rearfootleft": {
            "rotation": ["variable.jump_rotation * 50.0 - this", 0, 0]
          },
          "rearfootright": {
            "rotation": ["variable.jump_rotation * 50.0 - this", 0, 0]
          }
        }
      },
      "baby_transform": {
        "loop": true,
        "bones": {
          "earleft": {"position": [0, -1, 1], "scale": 1.5},
          "earright": {"position": [0, -1, 1], "scale": 1.5},
          "head": {"position": [0, -1, 1], "scale": 1.5},
          "nose": {"position": [0, -1, 1], "scale": 1.5}
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "general": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move", "look_at_target"]}}
      },
      "baby": {
        "initial_state": "baby",
        "states": {
          "baby": {"animations": [{"baby_transform": "query.is_baby"}]}
        }
      }
    },
    "render_controllers": ["controller.render.rabbit"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 24}
  },
  "ravager": {
    "identifier": "minecraft:ravager",
    "textures": {"default": "textures/entity/illager/ravager"},
    "materials": {"default": "ravager"},
    "geometry": {
      "default": {
        "bones": [
          {
            "pivot": [0, 19, 2],
            "rotation": [90, 0, 0],
            "cubes": [
              {"origin": [-7, 10, -2], "size": [14, 16, 20], "uv": [0, 55]},
              {"origin": [-6, -3, -2], "size": [12, 13, 18], "uv": [0, 91]}
            ],
            "name": "body"
          },
          {
            "pivot": [0, 15, -10],
            "cubes": [
              {"origin": [-8, 13, -24], "size": [16, 3, 16], "uv": [0, 36]}
            ],
            "name": "mouth",
            "parent": "head"
          },
          {
            "pivot": [0, 20, -20],
            "cubes": [
              {"origin": [-5, 21, -10], "size": [10, 10, 18], "uv": [68, 73]}
            ],
            "name": "neck"
          },
          {
            "locators": {"stun": [0, 32, -15]},
            "pivot": [0, 28, -10],
            "cubes": [
              {"origin": [-8, 14, -24], "size": [16, 20, 16], "uv": [0, 0]},
              {"origin": [-2, 12, -28], "size": [4, 8, 4], "uv": [0, 0]}
            ],
            "name": "head",
            "parent": "neck"
          },
          {
            "pivot": [-12, 30, 22],
            "cubes": [
              {"origin": [-12, 0, 17], "size": [8, 37, 8], "uv": [96, 0]}
            ],
            "name": "leg0"
          },
          {
            "pivot": [4, 30, 22],
            "cubes": [
              {"origin": [4, 0, 17], "size": [8, 37, 8], "uv": [96, 0]}
            ],
            "name": "leg1"
          },
          {
            "pivot": [-4, 26, -4],
            "cubes": [
              {"origin": [-12, 0, -8], "size": [8, 37, 8], "uv": [64, 0]}
            ],
            "name": "leg2"
          },
          {
            "pivot": [-4, 26, -4],
            "cubes": [
              {"origin": [4, 0, -8], "size": [8, 37, 8], "uv": [64, 0]}
            ],
            "name": "leg3"
          },
          {
            "pivot": [-5, 27, -19],
            "rotation": [60, 0, 0],
            "cubes": [
              {"origin": [-10, 27, -20], "size": [2, 14, 4], "uv": [74, 55]},
              {"origin": [8, 27, -20], "size": [2, 14, 4], "uv": [74, 55]}
            ],
            "name": "horns",
            "parent": "head"
          }
        ],
        "texturewidth": 128,
        "textureheight": 128,
        "visible_bounds_width": 4,
        "visible_bounds_height": 3.5,
        "visible_bounds_offset": [0, 1.25, 0]
      }
    },
    "render_controllers": ["controller.render.ravager"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 57},
    "animations": {
      "walk": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  22.92", 0, 0]
          },
          "leg1": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -22.92", 0, 0]
          },
          "leg2": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -22.92", 0, 0]
          },
          "leg3": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  22.92", 0, 0]
          }
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "idle_mouth": {
        "loop": true,
        "bones": {
          "mouth": {"rotation": [1.8, 0, 0]},
          "neck": {"position": [0, 0, 0]}
        }
      },
      "stunned": {
        "loop": true,
        "bones": {
          "neck": {
            "position": ["math.sin(query.life_time * 180.0) * 2.8", -5, 0],
            "rotation": [15, 0, 0]
          }
        }
      },
      "roaring": {
        "loop": true,
        "bones": {
          "mouth": {
            "rotation": [
              "math.sin(math.min(query.anim_time / variable.roar_length, 1.0) * 180) * 40.0",
              0,
              0
            ]
          }
        }
      },
      "biting": {
        "loop": true,
        "bones": {
          "mouth": {
            "rotation": [
              "math.sin(math.min(query.anim_time / (variable.bite_anim_duration / 2.0f), 1.0f) * 180.0f) * 40.0f",
              0,
              0
            ]
          },
          "neck": {
            "position": [
              0,
              0,
              "-math.sin(math.min(query.anim_time / variable.bite_anim_duration, 1.0f) * 180.0f) * variable.bite_neck_length"
            ]
          }
        }
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.roar_length = 1.0;",
        "variable.bite_anim_duration = 0.75f;",
        "variable.bite_neck_length = 10;"
      ],
      "animate": ["move", "head"]
    },
    "particle_effects": {"stun_particles": "minecraft:stunned_emitter"},
    "animation_controllers": {
      "move": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": [
              {"walk": "query.modified_move_speed"},
              "look_at_target"
            ],
            "transitions": [{"stunned": "query.is_stunned == 1"}]
          },
          "stunned": {
            "animations": ["stunned"],
            "particle_effects": [
              {"effect": "stun_particles", "locator": "stun"}
            ],
            "transitions": [{"default": "query.is_stunned == 0"}]
          }
        }
      },
      "head": {
        "initial_state": "default",
        "states": {
          "biting": {
            "animations": ["biting"],
            "transitions": [{"default": "query.is_delayed_attacking == 0"}]
          },
          "default": {
            "animations": ["idle_mouth"],
            "transitions": [
              {"roaring": "query.is_roaring == 1"},
              {"biting": "query.is_delayed_attacking == 1"}
            ]
          },
          "roaring": {
            "animations": ["roaring"],
            "transitions": [{"default": "query.is_roaring == 0"}]
          }
        }
      }
    }
  },
  "salmon": {
    "identifier": "minecraft:salmon",
    "materials": {"default": "salmon"},
    "textures": {"default": "textures/entity/fish/salmon"},
    "geometry": {
      "default": {
        "visible_bounds_width": 0.5,
        "visible_bounds_height": 0.5,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 32,
        "textureheight": 32,
        "bones": [
          {
            "name": "body_front",
            "pivot": [0, 0, -4],
            "cubes": [
              {"origin": [-1.5, 3.5, -4], "size": [3, 5, 8], "uv": [0, 0]}
            ]
          },
          {
            "name": "body_back",
            "parent": "body_front",
            "pivot": [0, 0, 4],
            "cubes": [
              {"origin": [-1.5, 3.5, 4], "size": [3, 5, 8], "uv": [0, 13]}
            ]
          },
          {
            "name": "dorsal_front",
            "parent": "body_front",
            "pivot": [0, 5, 2],
            "cubes": [{"origin": [0, 8.5, 2], "size": [0, 2, 2], "uv": [4, 2]}]
          },
          {
            "name": "dorsal_back",
            "parent": "body_back",
            "pivot": [0, 5, 4],
            "cubes": [{"origin": [0, 8.5, 4], "size": [0, 2, 3], "uv": [2, 3]}]
          },
          {
            "name": "tailfin",
            "parent": "body_back",
            "pivot": [0, 0, 12],
            "cubes": [
              {"origin": [0, 3.5, 12], "size": [0, 5, 6], "uv": [20, 10]}
            ]
          },
          {
            "name": "head",
            "parent": "body_front",
            "pivot": [0, 3, -4],
            "locators": {"lead": [0, 3, -4]},
            "cubes": [
              {"origin": [-1, 4.5, -7], "size": [2, 4, 3], "uv": [22, 0]}
            ]
          },
          {
            "name": "leftFin",
            "parent": "body_front",
            "pivot": [1.5, 1, -4],
            "rotation": [0, 0, 35],
            "cubes": [
              {
                "origin": [-0.50752, 3.86703, -4],
                "size": [2, 0, 2],
                "uv": [2, 0]
              }
            ]
          },
          {
            "name": "rightFin",
            "parent": "body_front",
            "pivot": [-1.5, 1, -4],
            "rotation": [0, 0, -35],
            "cubes": [
              {
                "origin": [-1.49258, 3.86703, -4],
                "size": [2, 0, 2],
                "uv": [-2, 0]
              }
            ]
          }
        ]
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.ZRot = !query.is_in_water ? Math.cos((query.time_stamp + query.frame_alpha) * 14.32) * 90 : 0.0;",
        "variable.AnimationAmountBlend = Math.lerp(variable.AnimationAmountPrev, variable.AnimationAmount, query.frame_alpha);"
      ]
    },
    "animations": {
      "flop": {
        "loop": true,
        "bones": {
          "body_back": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 45.0) * -21.75",
              0
            ]
          },
          "body_front": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 45.0) * 4.0",
              "variable.zrot"
            ]
          },
          "tailfin": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 45.0) * -40.0",
              0
            ]
          }
        }
      },
      "swim": {
        "loop": true,
        "bones": {
          "body_back": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 30.0) * -21.75",
              0
            ]
          },
          "body_front": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 30.0) * 4.0",
              0
            ]
          },
          "tailfin": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 30.0) * -40.0",
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "general": {
        "initial_state": "flopping",
        "states": {
          "flopping": {
            "animations": ["flop"],
            "transitions": [
              {"swimming": "query.is_in_water || query.is_levitating"}
            ]
          },
          "swimming": {
            "animations": ["swim"],
            "transitions": [
              {"flopping": "!query.is_in_water && !query.is_levitating"}
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.salmon"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 47}
  },
  "sheep": {
    "identifier": "minecraft:sheep",
    "min_engine_version": "1.8.0",
    "materials": {"default": "sheep"},
    "textures": {
      "default": "textures/entity/sheep/sheep_fur",
      "sheared": "textures/entity/sheep/sheep"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 2,
        "visible_bounds_height": 1.75,
        "visible_bounds_offset": [0, 0.5, 0],
        "bones": [
          {
            "name": "head",
            "pivot": [0, 18, -8],
            "cubes": [
              {
                "origin": [-3, 16, -12],
                "size": [6, 6, 6],
                "uv": [0, 32],
                "inflate": 0.6
              }
            ]
          },
          {
            "name": "body",
            "pivot": [0, 19, 2],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {
                "origin": [-4, 13, -5],
                "size": [8, 16, 6],
                "uv": [28, 40],
                "inflate": 1.75
              }
            ]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-3, 12, 7],
            "cubes": [
              {
                "origin": [-5, 6, 5],
                "size": [4, 6, 4],
                "uv": [0, 48],
                "inflate": 0.5
              }
            ]
          },
          {
            "name": "leg1",
            "parent": "body",
            "pivot": [3, 12, 7],
            "cubes": [
              {
                "origin": [1, 6, 5],
                "size": [4, 6, 4],
                "uv": [0, 48],
                "inflate": 0.5
              }
            ]
          },
          {
            "name": "leg2",
            "parent": "body",
            "pivot": [-3, 12, -5],
            "cubes": [
              {
                "origin": [-5, 6, -7],
                "size": [4, 6, 4],
                "uv": [0, 48],
                "inflate": 0.5
              }
            ]
          },
          {
            "name": "leg3",
            "parent": "body",
            "pivot": [3, 12, -5],
            "cubes": [
              {
                "origin": [1, 6, -7],
                "size": [4, 6, 4],
                "uv": [0, 48],
                "inflate": 0.5
              }
            ]
          }
        ]
      },
      "sheared": {
        "visible_bounds_width": 2,
        "visible_bounds_height": 1.75,
        "visible_bounds_offset": [0, 0.5, 0],
        "bones": [
          {
            "name": "body",
            "pivot": [0, 19, 2],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {"origin": [-4, 13, -5], "size": [8, 16, 6], "uv": [28, 8]}
            ]
          },
          {
            "name": "head",
            "pivot": [0, 18, -8],
            "locators": {"lead": [0, 18, -8]},
            "cubes": [
              {"origin": [-3, 16, -14], "size": [6, 6, 8], "uv": [0, 0]}
            ]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-3, 12, 7],
            "cubes": [{"origin": [-5, 0, 5], "size": [4, 12, 4], "uv": [0, 16]}]
          },
          {
            "name": "leg1",
            "parent": "body",
            "pivot": [3, 12, 7],
            "cubes": [{"origin": [1, 0, 5], "size": [4, 12, 4], "uv": [0, 16]}]
          },
          {
            "name": "leg2",
            "parent": "body",
            "pivot": [-3, 12, -5],
            "cubes": [
              {"origin": [-5, 0, -7], "size": [4, 12, 4], "uv": [0, 16]}
            ]
          },
          {
            "name": "leg3",
            "parent": "body",
            "pivot": [3, 12, -5],
            "cubes": [{"origin": [1, 0, -7], "size": [4, 12, 4], "uv": [0, 16]}]
          }
        ]
      }
    },
    "animations": {
      "setup": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["-this", 0, 0]},
          "head": {"position": [0, "-6.0 - this", 0]}
        }
      },
      "grazing": {
        "animation_length": 2,
        "loop": true,
        "bones": {
          "head": {
            "position": {
              "0": [0, 0, 0],
              "2": [0, 0, 0],
              "0.2": [0, -9, 0],
              "1.8": [0, -9, 0]
            },
            "rotation": {
              "0.2": {
                "post": [
                  "180.0 * (0.2 + 0.07 * math.sin(query.key_frame_lerp_time * 1644.39))",
                  0,
                  0
                ],
                "pre": [36, 0, 0]
              },
              "1.8": {
                "post": [36, 0, 0],
                "pre": [
                  "180.0 * (0.2 + 0.07 * math.sin(query.key_frame_lerp_time * 1644.39))",
                  0,
                  0
                ]
              }
            }
          }
        }
      },
      "walk": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          },
          "leg1": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          },
          "leg2": {
            "rotation": ["math.cos(query.anim_time * 38.17) * -80.0", 0, 0]
          },
          "leg3": {
            "rotation": ["math.cos(query.anim_time * 38.17) *  80.0", 0, 0]
          }
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "baby_transform": {
        "loop": true,
        "bones": {"head": {"position": [0, 4, 4], "scale": 2}}
      }
    },
    "scripts": {
      "animate": [
        "setup",
        "look_at_target",
        "move",
        {"baby_transform": "query.is_baby"}
      ]
    },
    "render_controllers": ["controller.render.sheep"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 3},
    "animation_controllers": {
      "move": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": [{"walk": "query.modified_move_speed"}],
            "transitions": [{"grazing": "query.is_grazing"}]
          },
          "grazing": {
            "animations": ["grazing"],
            "transitions": [{"default": "query.all_animations_finished"}]
          }
        }
      }
    }
  },
  "shulker": {
    "identifier": "minecraft:shulker",
    "min_engine_version": "1.8.0",
    "materials": {"default": "shulker"},
    "textures": {
      "undyed": "textures/entity/shulker/shulker",
      "white": "textures/entity/shulker/shulker_white",
      "orange": "textures/entity/shulker/shulker_orange",
      "magenta": "textures/entity/shulker/shulker_magenta",
      "light_blue": "textures/entity/shulker/shulker_light_blue",
      "yellow": "textures/entity/shulker/shulker_yellow",
      "lime": "textures/entity/shulker/shulker_lime",
      "pink": "textures/entity/shulker/shulker_pink",
      "gray": "textures/entity/shulker/shulker_gray",
      "silver": "textures/entity/shulker/shulker_light_gray",
      "cyan": "textures/entity/shulker/shulker_cyan",
      "purple": "textures/entity/shulker/shulker_purple",
      "blue": "textures/entity/shulker/shulker_blue",
      "brown": "textures/entity/shulker/shulker_brown",
      "green": "textures/entity/shulker/shulker_green",
      "red": "textures/entity/shulker/shulker_red",
      "black": "textures/entity/shulker/shulker_black"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 3,
        "visible_bounds_height": 3,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 64,
        "bones": [
          {
            "name": "lid",
            "parent": "base",
            "pivot": [0, 0, 0],
            "cubes": [
              {"origin": [-8, 4, -8], "size": [16, 12, 16], "uv": [0, 0]}
            ]
          },
          {
            "name": "base",
            "pivot": [0, 0, 0],
            "cubes": [
              {"origin": [-8, 0, -8], "size": [16, 8, 16], "uv": [0, 28]}
            ]
          },
          {
            "name": "head",
            "parent": "base",
            "pivot": [0, 12, 0],
            "cubes": [{"origin": [-3, 6, -3], "size": [6, 6, 6], "uv": [0, 52]}]
          }
        ]
      }
    },
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 34},
    "scripts": {
      "pre_animation": [
        "variable.Shulker.LidPositionFactor = 180 * (0.5 + variable.Shulker.PeekAmount);",
        "variable.Shulker.LidRotationFactor = -1 + Math.sin(180 * (0.5 + variable.Shulker.PeekAmount));",
        "variable.Shulker.UpFacing = variable.Shulker.FacingDirection == 1;",
        "variable.Shulker.NorthFacing = variable.Shulker.FacingDirection == 2;",
        "variable.Shulker.SouthFacing = variable.Shulker.FacingDirection == 3;",
        "variable.Shulker.WestFacing = variable.Shulker.FacingDirection == 4;",
        "variable.Shulker.EastFacing = variable.Shulker.FacingDirection == 5;",
        "variable.Shulker.XPreRotation = variable.Shulker.UpFacing * 180 + variable.Shulker.NorthFacing * 90 - variable.Shulker.SouthFacing * 90;",
        "variable.Shulker.ZPreRotation = variable.Shulker.NorthFacing * 180 + variable.Shulker.WestFacing * 90 - variable.Shulker.EastFacing * 90;",
        "variable.Shulker.XOffset = -variable.Shulker.WestFacing * 7.99 + variable.Shulker.EastFacing * 7.99;",
        "variable.Shulker.YOffset = variable.Shulker.UpFacing * 16 + variable.Shulker.NorthFacing * 7.99 + variable.Shulker.SouthFacing * 7.99 + variable.Shulker.WestFacing * 7.99 + variable.Shulker.EastFacing * 7.99;",
        "variable.Shulker.ZOffset = variable.Shulker.NorthFacing * 7.99 - variable.Shulker.SouthFacing * 7.99;"
      ]
    },
    "animations": {
      "facing": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "base": {
            "position": [
              "variable.shulker.xoffset",
              "variable.shulker.yoffset",
              "variable.shulker.zoffset"
            ],
            "rotation": [
              "variable.shulker.xprerotation",
              0,
              "variable.shulker.zprerotation"
            ],
            "scale": 0.98
          }
        }
      },
      "move": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "head": {
            "position": [
              0,
              "-variable.shulker.upfacing * 6 - (3 * (variable.shulker.northfacing + variable.shulker.southfacing + variable.shulker.westfacing + variable.shulker.eastfacing))",
              0
            ]
          },
          "lid": {
            "position": [
              0,
              "7.2 - math.sin(variable.shulker.lidpositionfactor * 1.16) * 7.5 + (variable.shulker.lidpositionfactor > 180 ? math.sin(query.life_time * 114.6) * 0.7 + 1.5: 0.0)",
              0
            ],
            "rotation": [
              0,
              "variable.shulker.peekamount > 0.3 ? math.pow(variable.shulker.lidrotationfactor, 4) * 22.5 : 0.0",
              0
            ]
          }
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "facing": {
        "initial_state": "default",
        "states": {"default": {"animations": ["facing"]}}
      },
      "move": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move", "look_at_target"]}}
      }
    },
    "render_controllers": ["controller.render.shulker"]
  },
  "shulker_bullet": {
    "identifier": "minecraft:shulker_bullet",
    "materials": {"default": "shulker_bullet"},
    "textures": {"default": "textures/entity/shulker/spark"},
    "geometry": {
      "default": {
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "body",
            "pivot": [0, 0, 0],
            "cubes": [
              {"origin": [-4, -4, -1], "size": [8, 8, 2], "uv": [0, 0]},
              {"origin": [-1, -4, -4], "size": [2, 8, 8], "uv": [0, 10]},
              {"origin": [-4, -1, -4], "size": [8, 2, 8], "uv": [20, 0]}
            ]
          }
        ]
      }
    },
    "animations": {
      "move": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              "math.cos(query.life_time * 120) * 180",
              "math.sin(query.life_time * 120) * 180",
              "math.sin(query.life_time * 170) * 360"
            ]
          }
        }
      }
    },
    "scripts": {"animate": ["move"]},
    "render_controllers": ["controller.render.shulker_bullet"]
  },
  "silverfish": {
    "identifier": "minecraft:silverfish",
    "materials": {"default": "silverfish", "body_layer": "silverfish_layers"},
    "textures": {"default": "textures/entity/silverfish"},
    "geometry": {
      "default": {
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 1,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "bodyPart_0",
            "parent": "bodyPart_2",
            "pivot": [0, 2, -3.5],
            "cubes": [
              {"origin": [-1.5, 0, -4.5], "size": [3, 2, 2], "uv": [0, 0]}
            ]
          },
          {
            "name": "bodyPart_1",
            "parent": "bodyPart_2",
            "pivot": [0, 3, -1.5],
            "cubes": [
              {"origin": [-2, 0, -2.5], "size": [4, 3, 2], "uv": [0, 4]}
            ]
          },
          {
            "name": "bodyPart_2",
            "pivot": [0, 4, 1],
            "cubes": [
              {"origin": [-3, 0, -0.5], "size": [6, 4, 3], "uv": [0, 9]}
            ]
          },
          {
            "name": "bodyPart_3",
            "parent": "bodyPart_2",
            "pivot": [0, 3, 4],
            "cubes": [
              {"origin": [-1.5, 0, 2.5], "size": [3, 3, 3], "uv": [0, 16]}
            ]
          },
          {
            "name": "bodyPart_4",
            "parent": "bodyPart_2",
            "pivot": [0, 2, 7],
            "cubes": [
              {"origin": [-1, 0, 5.5], "size": [2, 2, 3], "uv": [0, 22]}
            ]
          },
          {
            "name": "bodyPart_5",
            "parent": "bodyPart_2",
            "pivot": [0, 1, 9.5],
            "cubes": [
              {"origin": [-1, 0, 8.5], "size": [2, 1, 2], "uv": [11, 0]}
            ]
          },
          {
            "name": "bodyPart_6",
            "parent": "bodyPart_2",
            "pivot": [0, 1, 11.5],
            "cubes": [
              {"origin": [-0.5, 0, 10.5], "size": [1, 1, 2], "uv": [13, 4]}
            ]
          },
          {
            "name": "bodyLayer_0",
            "parent": "bodyPart_2",
            "pivot": [0, 8, 1],
            "cubes": [
              {"origin": [-5, 0, -0.5], "size": [10, 8, 3], "uv": [20, 0]}
            ]
          },
          {
            "name": "bodyLayer_1",
            "parent": "bodyPart_4",
            "pivot": [0, 4, 7],
            "cubes": [
              {"origin": [-3, 0, 5.5], "size": [6, 4, 3], "uv": [20, 11]}
            ]
          },
          {
            "name": "bodyLayer_2",
            "parent": "bodyPart_1",
            "pivot": [0, 5, -1.5],
            "cubes": [
              {"origin": [-3, 0, -3], "size": [6, 5, 2], "uv": [20, 18]}
            ]
          }
        ]
      }
    },
    "animations": {
      "move": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "bodypart_0": {
            "position": ["math.sin(query.life_time * 1031.4) * 1.26", 0, 0],
            "rotation": [0, "math.cos(query.life_time * 1031.4) * 27", 0]
          },
          "bodypart_1": {
            "position": [
              "math.sin(query.life_time * 1031.4 + 27) * 0.63",
              0,
              0
            ],
            "rotation": [0, "math.cos(query.life_time * 1031.4 + 27) * 18", 0]
          },
          "bodypart_2": {
            "rotation": [0, "math.cos(query.life_time * 1031.4 + 54) * 9", 0]
          },
          "bodypart_3": {
            "position": [
              "math.sin(query.life_time * 1031.4 + 81) * 0.63",
              0,
              0
            ],
            "rotation": [0, "math.cos(query.life_time * 1031.4 + 81) * 18", 0]
          },
          "bodypart_4": {
            "position": [
              "math.sin(query.life_time * 1031.4 + 108) * 1.26",
              0,
              0
            ],
            "rotation": [0, "math.cos(query.life_time * 1031.4 + 108) * 27", 0]
          },
          "bodypart_5": {
            "position": [
              "math.sin(query.life_time * 1031.4 + 135) * 1.89",
              0,
              0
            ],
            "rotation": [0, "math.cos(query.life_time * 1031.4 + 135) * 36", 0]
          },
          "bodypart_6": {
            "position": [
              "math.sin(query.life_time * 1031.4 + 162) * 2.52",
              0,
              0
            ],
            "rotation": [0, "math.cos(query.life_time * 1031.4 + 162) * 45", 0]
          }
        }
      }
    },
    "animation_controllers": {
      "move": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move"]}}
      }
    },
    "render_controllers": ["controller.render.silverfish"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 8}
  },
  "skeleton": {
    "identifier": "minecraft:skeleton",
    "min_engine_version": "1.8.0",
    "materials": {"default": "skeleton"},
    "textures": {"default": "textures/entity/skeleton/skeleton"},
    "geometry": {
      "default": {
        "texturewidth": 64,
        "textureheight": 32,
        "visible_bounds_width": 2,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 1, 0],
        "bones": [
          {
            "name": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 12, -2], "size": [8, 12, 4], "uv": [16, 16]}
            ],
            "parent": "waist"
          },
          {"name": "waist", "pivot": [0, 12, 0]},
          {
            "name": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 24, -4], "size": [8, 8, 8], "uv": [0, 0]}
            ],
            "parent": "body"
          },
          {
            "name": "hat",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-4, 24, -4],
                "size": [8, 8, 8],
                "uv": [32, 0],
                "inflate": 0.5
              }
            ],
            "neverRender": true,
            "parent": "head"
          },
          {
            "name": "rightArm",
            "pivot": [-5, 22, 0],
            "cubes": [
              {"origin": [-6, 12, -1], "size": [2, 12, 2], "uv": [40, 16]}
            ],
            "parent": "body"
          },
          {
            "name": "rightItem",
            "pivot": [-6, 15, 1],
            "neverRender": true,
            "parent": "rightArm"
          },
          {
            "name": "leftArm",
            "pivot": [5, 22, 0],
            "cubes": [
              {"origin": [4, 12, -1], "size": [2, 12, 2], "uv": [40, 16]}
            ],
            "mirror": true,
            "parent": "body"
          },
          {
            "name": "leftItem",
            "pivot": [6, 15, 1],
            "neverRender": true,
            "parent": "leftArm"
          },
          {
            "name": "rightLeg",
            "pivot": [-2, 12, 0],
            "cubes": [
              {"origin": [-3, 0, -1], "size": [2, 12, 2], "uv": [0, 16]}
            ],
            "parent": "body"
          },
          {
            "name": "leftLeg",
            "pivot": [2, 12, 0],
            "cubes": [
              {"origin": [1, 0, -1], "size": [2, 12, 2], "uv": [0, 16]}
            ],
            "mirror": true,
            "parent": "body"
          }
        ]
      }
    },
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 9},
    "scripts": {
      "pre_animation": [
        "variable.tcos0 = (Math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed / variable.gliding_speed_value) * 57.3;"
      ]
    },
    "animations": {
      "look_at_target_default": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_gliding": {
        "loop": true,
        "bones": {"head": {"rotation": [-45, "query.target_y_rotation", 0]}}
      },
      "look_at_target_swimming": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "math.lerp(query.target_x_rotation, -45.0, variable.swim_amount)",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "move": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["variable.tcos0", 0, 0]},
          "leftleg": {"rotation": ["variable.tcos0 * -1.4", 0, 0]},
          "rightarm": {"rotation": ["-variable.tcos0", 0, 0]},
          "rightleg": {"rotation": ["variable.tcos0 * 1.4", 0, 0]}
        }
      },
      "riding.arms": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": [-36, 0, 0]},
          "rightarm": {"rotation": [-36, 0, 0]}
        }
      },
      "riding.legs": {
        "loop": true,
        "bones": {
          "leftleg": {"rotation": ["-72.0 - this", "-18.0 - this", "-this"]},
          "rightleg": {"rotation": ["-72.0 - this", "18.0 - this", "-this"]}
        }
      },
      "holding": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "variable.is_holding_left ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "variable.is_holding_right ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          }
        }
      },
      "brandish_spear": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "this * -0.5 - 157.5 - 22.5 * variable.charge_amount",
              "-this",
              0
            ]
          }
        }
      },
      "charging": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": ["22.5 * variable.charge_amount - this", "-this", 0]
          }
        }
      },
      "attack.rotations": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              0,
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46 - this",
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.sin(1.0 - math.pow(1.0 - variable.attack_time, 3.0) * 180.0) * (variable.is_brandishing_spear ? -1.0 : 1.0 )",
              "variable.is_brandishing_spear ? 0.0 : (math.sin(math.sqrt(variable.attack_time) * 360) * 11.46) * 2.0",
              0
            ]
          }
        }
      },
      "sneaking": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["0.5 - this", 0, 0]},
          "head": {"position": [0, 1, 0]},
          "leftarm": {"rotation": [72, 0, 0]},
          "leftleg": {"position": [0, -3, 4]},
          "rightarm": {"rotation": [72, 0, 0]},
          "rightleg": {"position": [0, -3, 4]}
        }
      },
      "bob": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              0,
              0,
              "((math.cos(query.life_time * 103.2) * 2.865) + 2.865) *-1.0"
            ]
          },
          "rightarm": {
            "rotation": [
              0,
              0,
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "damage_nearby_mobs": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["-45.0-this", "-this", "-this"]},
          "leftleg": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightarm": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-45.0-this", "-this", "-this"]}
        }
      },
      "bow_and_arrow": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "query.target_x_rotation - 90.0 - math.sin(query.life_time * 76.8) * 2.865 - this",
              "query.target_y_rotation + 28.65",
              "-(math.cos(query.life_time * 103.2) * 2.865) - 2.865"
            ]
          },
          "rightarm": {
            "rotation": [
              "query.target_x_rotation - 90.0 + math.sin(query.life_time * 76.8) * 2.865 - this",
              "query.target_y_rotation - 5.73",
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "swimming": {
        "loop": true,
        "bones": {
          "body": {
            "position": [
              0,
              "variable.swim_amount * -10.0 - this",
              "variable.swim_amount * 9.0 - this"
            ],
            "rotation": [
              "variable.swim_amount * (90.0 + query.target_x_rotation)",
              0,
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.lerp(this, -180.0, variable.swim_amount) - (variable.swim_amount * ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4)) - (variable.swim_amount * (math.sin(query.life_time * 76.776372) * 2.865)) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - (variable.swim_amount * (math.cos(query.life_time * 103.13244) * 2.865 + 2.865)) - this"
            ]
          },
          "leftleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.life_time * 390.0 + 180.0) * 0.3, variable.swim_amount)",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.lerp(this, -180.0, variable.swim_amount) - (variable.swim_amount * ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4)) + (variable.swim_amount * (math.sin(query.life_time * 76.776372) * 2.865)) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - this",
              "math.lerp(this, -14.325, variable.swim_amount) + (variable.swim_amount * (math.cos(query.life_time * 103.13244) * 2.865 + 2.865)) - this"
            ]
          },
          "rightleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.life_time * 390.0) * 0.3, variable.swim_amount)",
              0,
              0
            ]
          }
        }
      },
      "use_item_progress": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "variable.use_item_startup_progress * -60.0 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -22.5 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -5.625 + variable.use_item_interval_progress * 11.25"
            ]
          }
        }
      },
      "skeleton_attack": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "-68.75 * math.sin(variable.attack_time * 180.0) + 22.92 * (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0))",
              "5.73 - math.sin(variable.attack_time * 180.0) * 34.38 - this",
              "-this"
            ]
          },
          "rightarm": {
            "rotation": [
              "-68.75 * math.sin(variable.attack_time * 180.0) + 22.92 * (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0))",
              "-5.73 + math.sin(variable.attack_time * 180.0) * 34.38 - this",
              "-this"
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "look_at_target": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["look_at_target_default"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"swimming": "query.is_swimming"}
            ]
          },
          "gliding": {
            "animations": ["look_at_target_gliding"],
            "transitions": [
              {"swimming": "query.is_swimming"},
              {"default": "!query.is_gliding"}
            ]
          },
          "swimming": {
            "animations": ["look_at_target_swimming"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"default": "!query.is_swimming"}
            ]
          }
        }
      },
      "move": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move"]}}
      },
      "riding": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"riding": "query.is_riding"}]},
          "riding": {
            "animations": ["riding.arms", "riding.legs"],
            "transitions": [{"default": "!query.is_riding"}]
          }
        }
      },
      "holding": {
        "initial_state": "default",
        "states": {"default": {"animations": ["holding"]}}
      },
      "brandish_spear": {
        "initial_state": "default",
        "states": {
          "brandish_spear": {
            "animations": ["brandish_spear"],
            "transitions": [{"default": "!variable.is_brandishing_spear"}]
          },
          "default": {
            "transitions": [{"brandish_spear": "variable.is_brandishing_spear"}]
          }
        }
      },
      "charging": {
        "initial_state": "default",
        "states": {
          "charging": {
            "animations": ["charging"],
            "transitions": [{"default": "!query.is_charging"}]
          },
          "default": {"transitions": [{"charging": "query.is_charging"}]}
        }
      },
      "attack": {
        "initial_state": "default",
        "states": {
          "attacking": {
            "animations": ["attack.rotations"],
            "transitions": [{"default": "variable.attack_time < 0.0"}]
          },
          "default": {
            "transitions": [{"attacking": "variable.attack_time >= 0.0"}]
          }
        }
      },
      "sneaking": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"sneaking": "query.is_sneaking"}]},
          "sneaking": {
            "animations": ["sneaking"],
            "transitions": [{"default": "!query.is_sneaking"}]
          }
        }
      },
      "bob": {
        "initial_state": "default",
        "states": {"default": {"animations": ["bob"]}}
      },
      "damage_nearby_mobs": {
        "initial_state": "default",
        "states": {
          "damage_nearby_mobs": {
            "animations": ["damage_nearby_mobs"],
            "transitions": [{"default": "!variable.damage_nearby_mobs"}]
          },
          "default": {
            "transitions": [
              {"damage_nearby_mobs": "variable.damage_nearby_mobs"}
            ]
          }
        }
      },
      "bow_and_arrow": {
        "initial_state": "default",
        "states": {
          "bow_and_arrow": {
            "animations": ["bow_and_arrow"],
            "transitions": [{"default": "!query.has_target"}]
          },
          "default": {"transitions": [{"bow_and_arrow": "query.has_target"}]}
        }
      },
      "swimming": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [{"is_swimming": "variable.swim_amount > 0.0"}]
          },
          "is_swimming": {
            "animations": ["swimming"],
            "transitions": [{"default": "variable.swim_amount <= 0.0"}]
          }
        }
      },
      "use_item_progress": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [
              {
                "use_item_progress": "( variable.use_item_interval_progress > 0.0 ) || ( variable.use_item_startup_progress > 0.0 )"
              }
            ]
          },
          "use_item_progress": {
            "animations": ["use_item_progress"],
            "transitions": [
              {
                "default": "( variable.use_item_interval_progress <= 0.0 ) && ( variable.use_item_startup_progress <= 0.0 )"
              }
            ]
          }
        }
      },
      "skeleton_attack": {
        "initial_state": "default",
        "states": {"default": {"animations": ["skeleton_attack"]}}
      }
    },
    "render_controllers": ["controller.render.skeleton"],
    "enable_attachables": true
  },
  "skeleton_horse": {
    "identifier": "minecraft:skeleton_horse",
    "textures": {
      "base_brown": "textures/entity/horse/horse_brown",
      "base_white": "textures/entity/horse/horse_white",
      "base_chestnut": "textures/entity/horse/horse_chestnut",
      "base_creamy": "textures/entity/horse/horse_creamy",
      "base_black": "textures/entity/horse/horse_black",
      "base_gray": "textures/entity/horse/horse_gray",
      "base_darkbrown": "textures/entity/horse/horse_darkbrown",
      "markings_none": "textures/entity/horse/horse_markings_none",
      "markings_white": "textures/entity/horse/horse_markings_white",
      "markings_whitefield": "textures/entity/horse/horse_markings_whitefield",
      "markings_whitedots": "textures/entity/horse/horse_markings_whitedots",
      "markings_blackdots": "textures/entity/horse/horse_markings_blackdots",
      "mule": "textures/entity/horse/mule",
      "donkey": "textures/entity/horse/donkey",
      "skeleton": "textures/entity/horse/horse_skeleton",
      "zombie": "textures/entity/horse/horse_zombie",
      "armor_none": "textures/entity/horse/armor/horse_armor_none",
      "armor_leather": "textures/entity/horse/armor/horse_armor_leather",
      "armor_iron": "textures/entity/horse/armor/horse_armor_iron",
      "armor_gold": "textures/entity/horse/armor/horse_armor_gold",
      "armor_diamond": "textures/entity/horse/armor/horse_armor_diamond"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 2,
        "visible_bounds_height": 3,
        "visible_bounds_offset": [0, 1, 0],
        "texturewidth": 128,
        "textureheight": 128,
        "bones": [
          {
            "name": "Body",
            "pivot": [0, 13, 9],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5, 11, -10], "size": [10, 10, 24], "uv": [0, 34]}
            ]
          },
          {
            "name": "TailA",
            "pivot": [0, 21, 14],
            "rotation": [-65, 0, 0],
            "cubes": [
              {"origin": [-1, 20, 14], "size": [2, 2, 3], "uv": [44, 0]}
            ]
          },
          {
            "name": "TailB",
            "pivot": [0, 21, 14],
            "rotation": [-65, 0, 0],
            "cubes": [
              {"origin": [-1.5, 19, 17], "size": [3, 4, 7], "uv": [38, 7]}
            ]
          },
          {
            "name": "TailC",
            "pivot": [0, 21, 14],
            "rotation": [-80.34, 0, 0],
            "cubes": [
              {"origin": [-1.5, 21.5, 23], "size": [3, 4, 7], "uv": [24, 3]}
            ]
          },
          {
            "name": "Leg1A",
            "pivot": [4, 15, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.5, 8, 8.5], "size": [4, 9, 5], "uv": [78, 29]}
            ]
          },
          {
            "name": "Leg1B",
            "pivot": [4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2, 3, 9.5], "size": [3, 5, 3], "uv": [78, 43]}
            ]
          },
          {
            "name": "Leg1C",
            "pivot": [4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.5, -0.1, 9], "size": [4, 3, 4], "uv": [78, 51]}
            ]
          },
          {
            "name": "Leg2A",
            "pivot": [-4, 15, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, 8, 8.5], "size": [4, 9, 5], "uv": [96, 29]}
            ]
          },
          {
            "name": "Leg2B",
            "pivot": [-4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5, 3, 9.5], "size": [3, 5, 3], "uv": [96, 43]}
            ]
          },
          {
            "name": "Leg2C",
            "pivot": [-4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, -0.1, 9], "size": [4, 3, 4], "uv": [96, 51]}
            ]
          },
          {
            "name": "Leg3A",
            "pivot": [4, 15, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2.1, 8, -10.1], "size": [3, 8, 4], "uv": [44, 29]}
            ]
          },
          {
            "name": "Leg3B",
            "pivot": [4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2.1, 3, -9.6], "size": [3, 5, 3], "uv": [44, 41]}
            ]
          },
          {
            "name": "Leg3C",
            "pivot": [4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.6, -0.1, -10.1], "size": [4, 3, 4], "uv": [44, 51]}
            ]
          },
          {
            "name": "Leg4A",
            "pivot": [-4, 15, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.1, 8, -10.1], "size": [3, 8, 4], "uv": [60, 29]}
            ]
          },
          {
            "name": "Leg4B",
            "pivot": [-4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.1, 3, -9.6], "size": [3, 5, 3], "uv": [60, 41]}
            ]
          },
          {
            "name": "Leg4C",
            "pivot": [-4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.6, -0.1, -10.1], "size": [4, 3, 4], "uv": [60, 51]}
            ]
          },
          {
            "name": "Head",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.5, 25, -11.5], "size": [5, 5, 7], "uv": [0, 0]}
            ]
          },
          {
            "name": "UMouth",
            "pivot": [0, 20.05, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2, 27.05, -17], "size": [4, 3, 6], "uv": [24, 18]}
            ]
          },
          {
            "name": "LMouth",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2, 25, -16.5], "size": [4, 2, 5], "uv": [24, 27]}
            ]
          },
          {
            "name": "Ear1",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [0.45, 29, -6], "size": [2, 3, 1], "uv": [0, 0]}
            ]
          },
          {
            "name": "Ear2",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.45, 29, -6], "size": [2, 3, 1], "uv": [0, 0]}
            ]
          },
          {
            "name": "MuleEarL",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 15],
            "cubes": [
              {"origin": [-2, 29, -6], "size": [2, 7, 1], "uv": [0, 12]}
            ]
          },
          {
            "name": "MuleEarR",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, -15],
            "cubes": [{"origin": [0, 29, -6], "size": [2, 7, 1], "uv": [0, 12]}]
          },
          {
            "name": "Neck",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.05, 15.8, -12], "size": [4, 14, 8], "uv": [0, 12]}
            ]
          },
          {
            "name": "Bag1",
            "pivot": [-7.5, 21, 10],
            "rotation": [0, 90, 0],
            "cubes": [
              {"origin": [-10.5, 13, 10], "size": [8, 8, 3], "uv": [0, 34]}
            ]
          },
          {
            "name": "Bag2",
            "pivot": [4.5, 21, 10],
            "rotation": [0, 90, 0],
            "cubes": [
              {"origin": [1.5, 13, 10], "size": [8, 8, 3], "uv": [0, 47]}
            ]
          },
          {
            "name": "Saddle",
            "pivot": [0, 22, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5, 21, -1], "size": [10, 1, 8], "uv": [80, 0]}
            ]
          },
          {
            "name": "SaddleB",
            "pivot": [0, 22, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-1.5, 22, -1], "size": [3, 1, 2], "uv": [106, 9]}
            ]
          },
          {
            "name": "SaddleC",
            "pivot": [0, 22, 2],
            "rotation": [0, 0, 0],
            "cubes": [{"origin": [-4, 22, 5], "size": [8, 1, 2], "uv": [80, 9]}]
          },
          {
            "name": "SaddleL2",
            "pivot": [5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [4.5, 13, 1], "size": [1, 2, 2], "uv": [74, 0]}
            ]
          },
          {
            "name": "SaddleL",
            "pivot": [5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [4.5, 15, 1.5], "size": [1, 6, 1], "uv": [70, 0]}
            ]
          },
          {
            "name": "SaddleR2",
            "pivot": [-5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, 13, 1], "size": [1, 2, 2], "uv": [74, 4]}
            ]
          },
          {
            "name": "SaddleR",
            "pivot": [-5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, 15, 1.5], "size": [1, 6, 1], "uv": [80, 0]}
            ]
          },
          {
            "name": "SaddleMouthL",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [1.5, 26, -14], "size": [1, 2, 2], "uv": [74, 13]}
            ]
          },
          {
            "name": "SaddleMouthR",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.5, 26, -14], "size": [1, 2, 2], "uv": [74, 13]}
            ]
          },
          {
            "name": "SaddleMouthLine",
            "pivot": [0, 20, -10],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2.6, 23, -16], "size": [0, 3, 16], "uv": [44, 10]}
            ]
          },
          {
            "name": "SaddleMouthLineR",
            "pivot": [0, 20, -10],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-2.6, 23, -16], "size": [0, 3, 16], "uv": [44, 5]}
            ]
          },
          {
            "name": "Mane",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-1, 15.5, -5], "size": [2, 16, 4], "uv": [58, 0]}
            ]
          },
          {
            "name": "HeadSaddle",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {
                "origin": [-2.5, 25.1, -17],
                "size": [5, 5, 12],
                "uv": [80, 12],
                "inflate": 0.05
              }
            ]
          }
        ]
      }
    },
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 32}
  },
  "slime": {
    "identifier": "minecraft:slime",
    "materials": {"default": "slime", "outer": "slime_outer"},
    "textures": {"default": "textures/entity/slime/slime"},
    "geometry": {
      "default": {
        "visible_bounds_width": 5,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 1, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "cube",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-3, 1, -3], "size": [6, 6, 6], "uv": [0, 16]}]
          },
          {
            "name": "eye0",
            "parent": "cube",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-3.3, 4, -3.5], "size": [2, 2, 2], "uv": [32, 0]}
            ]
          },
          {
            "name": "eye1",
            "parent": "cube",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [1.3, 4, -3.5], "size": [2, 2, 2], "uv": [32, 4]}
            ]
          },
          {
            "name": "mouth",
            "parent": "cube",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [0, 2, -3.5], "size": [1, 1, 1], "uv": [32, 8]}
            ]
          }
        ]
      },
      "armor": {
        "visible_bounds_width": 1,
        "visible_bounds_height": 1,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "cube",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-4, 0, -4], "size": [8, 8, 8], "uv": [0, 0]}]
          },
          {
            "name": "eye0",
            "parent": "cube",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-3.3, 4, -3.5], "size": [2, 2, 2], "uv": [32, 0]}
            ]
          },
          {
            "name": "eye1",
            "parent": "cube",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [1.3, 4, -3.5], "size": [2, 2, 2], "uv": [32, 4]}
            ]
          },
          {
            "name": "mouth",
            "parent": "cube",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [0, 2, -3.5], "size": [1, 1, 1], "uv": [32, 8]}
            ]
          }
        ]
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.squish_factor = (query.previous_squish_value + (query.current_squish_value - query.previous_squish_value) * query.frame_alpha);",
        "variable.bounce = 1 / ((variable.squish_factor / (query.variant * 0.5 + 1)) + 1);",
        "variable.horizontal_scale_amount = variable.bounce * query.variant;",
        "variable.vertical_scale_amount = (1 / variable.bounce) * query.variant;"
      ],
      "scaleX": "variable.horizontal_scale_amount",
      "scaleY": "variable.vertical_scale_amount",
      "scaleZ": "variable.horizontal_scale_amount"
    },
    "render_controllers": [
      "controller.render.slime",
      "controller.render.slime_armor"
    ],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 10}
  },
  "small_fireball": {
    "identifier": "minecraft:small_fireball",
    "materials": {"default": "fireball"},
    "textures": {"default": "textures/items/fire_charge"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-8, -4, 0],
                "size": [16, 16, 0],
                "uv": {"south": {"uv": [0, 0]}}
              }
            ]
          }
        ],
        "texturewidth": 16,
        "textureheight": 16
      }
    },
    "animations": {
      "face_player": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              "query.camera_rotation(0)",
              "query.camera_rotation(1)",
              0
            ]
          }
        }
      }
    },
    "scripts": {"scale": "0.5", "animate": ["face_player"]},
    "render_controllers": ["controller.render.fireball"]
  },
  "snow_golem": {
    "identifier": "minecraft:snow_golem",
    "min_engine_version": "1.8.0",
    "materials": {"default": "snow_golem", "head": "snow_golem_pumpkin"},
    "textures": {"default": "textures/entity/snow_golem"},
    "geometry": {
      "default": {
        "visible_bounds_width": 1,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 1, 0],
        "bones": [
          {
            "name": "head",
            "parent": "piece1",
            "pivot": [0, 20, 0],
            "locators": {"lead": [0, 20, 0]},
            "cubes": [
              {
                "origin": [-4, 20, -4],
                "size": [8, 8, 8],
                "uv": [0, 0],
                "inflate": -0.5
              }
            ]
          },
          {
            "name": "arm1",
            "parent": "piece1",
            "pivot": [0, 18, 0],
            "bind_pose_rotation": [0, 0, 57.3],
            "cubes": [
              {
                "origin": [1, 20, -1],
                "size": [12, 2, 2],
                "uv": [32, 0],
                "inflate": -0.5
              }
            ]
          },
          {
            "name": "arm2",
            "parent": "piece1",
            "pivot": [0, 18, 0],
            "bind_pose_rotation": [0, 180, -57.3],
            "cubes": [
              {
                "origin": [1, 20, -1],
                "size": [12, 2, 2],
                "uv": [32, 0],
                "inflate": -0.5
              }
            ]
          },
          {
            "name": "piece1",
            "parent": "piece2",
            "pivot": [0, 11, 0],
            "cubes": [
              {
                "origin": [-5, 11, -5],
                "size": [10, 10, 10],
                "uv": [0, 16],
                "inflate": -0.5
              }
            ]
          },
          {
            "name": "piece2",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-6, 0, -6],
                "size": [12, 12, 12],
                "uv": [0, 36],
                "inflate": -0.5
              }
            ]
          }
        ]
      }
    },
    "animations": {
      "move": {
        "loop": true,
        "bones": {
          "piece1": {"rotation": [0, "query.target_y_rotation * 0.25", 0]}
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "move": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": [
              {"move": "query.modified_move_speed"},
              "look_at_target"
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.snowgolem"]
  },
  "snowball": {
    "identifier": "minecraft:snowball",
    "materials": {"default": "snowball"},
    "textures": {"default": "textures/items/snowball"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-8, -8, 0],
                "size": [16, 16, 0],
                "uv": [0, 0],
                "rotation": [0, 0, 0]
              }
            ]
          }
        ],
        "texturewidth": 16,
        "textureheight": 16
      }
    },
    "render_controllers": ["controller.render.item_sprite"],
    "animations": {
      "flying": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              "query.camera_rotation(0)",
              "query.camera_rotation(1)",
              0
            ]
          }
        }
      }
    },
    "scripts": {"animate": ["flying"]}
  },
  "spider": {
    "identifier": "minecraft:spider",
    "min_engine_version": "1.8.0",
    "materials": {"default": "spider", "invisible": "spider_invisible"},
    "textures": {"default": "textures/entity/spider/spider"},
    "geometry": {
      "default": {
        "visible_bounds_width": 2,
        "visible_bounds_height": 1,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "head",
            "pivot": [0, 9, -3],
            "cubes": [
              {"origin": [-4, 5, -11], "size": [8, 8, 8], "uv": [32, 4]}
            ],
            "parent": "body0"
          },
          {
            "name": "body0",
            "pivot": [0, 9, 0],
            "cubes": [{"origin": [-3, 6, -3], "size": [6, 6, 6], "uv": [0, 0]}]
          },
          {
            "name": "body1",
            "pivot": [0, 9, 9],
            "cubes": [
              {"origin": [-5, 5, 3], "size": [10, 8, 12], "uv": [0, 12]}
            ],
            "parent": "body0"
          },
          {
            "name": "leg0",
            "pivot": [-4, 9, 2],
            "cubes": [
              {"origin": [-19, 8, 1], "size": [16, 2, 2], "uv": [18, 0]}
            ],
            "parent": "body0"
          },
          {
            "name": "leg1",
            "pivot": [4, 9, 2],
            "cubes": [{"origin": [3, 8, 1], "size": [16, 2, 2], "uv": [18, 0]}],
            "parent": "body0"
          },
          {
            "name": "leg2",
            "pivot": [-4, 9, 1],
            "cubes": [
              {"origin": [-19, 8, 0], "size": [16, 2, 2], "uv": [18, 0]}
            ],
            "parent": "body0"
          },
          {
            "name": "leg3",
            "pivot": [4, 9, 1],
            "cubes": [{"origin": [3, 8, 0], "size": [16, 2, 2], "uv": [18, 0]}],
            "parent": "body0"
          },
          {
            "name": "leg4",
            "pivot": [-4, 9, 0],
            "cubes": [
              {"origin": [-19, 8, -1], "size": [16, 2, 2], "uv": [18, 0]}
            ],
            "parent": "body0"
          },
          {
            "name": "leg5",
            "pivot": [4, 9, 0],
            "cubes": [
              {"origin": [3, 8, -1], "size": [16, 2, 2], "uv": [18, 0]}
            ],
            "parent": "body0"
          },
          {
            "name": "leg6",
            "pivot": [-4, 9, -1],
            "cubes": [
              {"origin": [-19, 8, -2], "size": [16, 2, 2], "uv": [18, 0]}
            ],
            "parent": "body0"
          },
          {
            "name": "leg7",
            "pivot": [4, 9, -1],
            "cubes": [
              {"origin": [3, 8, -2], "size": [16, 2, 2], "uv": [18, 0]}
            ],
            "parent": "body0"
          }
        ]
      }
    },
    "animations": {
      "default_leg_pose": {
        "loop": true,
        "bones": {
          "leg0": {"rotation": [0, "45.0 - this", "-45.0 - this"]},
          "leg1": {"rotation": [0, "-45.0 - this", "45.0 - this"]},
          "leg2": {"rotation": [0, "22.5 - this", "-33.3 - this"]},
          "leg3": {"rotation": [0, "-22.5 - this", "33.3 - this"]},
          "leg4": {"rotation": [0, "-22.5 - this", "-33.3 - this"]},
          "leg5": {"rotation": [0, "22.5 - this", "33.3 - this"]},
          "leg6": {"rotation": [0, "-45.0 - this", "-45.0 - this"]},
          "leg7": {"rotation": [0, "45.0 - this", "45.0 - this"]}
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "walk": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": [
              0,
              "-math.abs(math.cos(query.anim_time * 76.34 + 90 * 0) * 22.92)",
              "math.abs(math.sin(query.anim_time * 38.17 + 90 * 0) * 22.92)"
            ]
          },
          "leg1": {
            "rotation": [
              0,
              "math.abs(math.cos(query.anim_time * 76.34 + 90 * 0) * 22.92)",
              "-math.abs(math.sin(query.anim_time * 38.17 + 90 * 0) * 22.92)"
            ]
          },
          "leg2": {
            "rotation": [
              0,
              "-math.abs(math.cos(query.anim_time * 76.34 + 90 * 1) * 22.92)",
              "math.abs(math.sin(query.anim_time * 38.17 + 90 * 1) * 22.92)"
            ]
          },
          "leg3": {
            "rotation": [
              0,
              "math.abs(math.cos(query.anim_time * 76.34 + 90 * 1) * 22.92)",
              "-math.abs(math.sin(query.anim_time * 38.17 + 90 * 1) * 22.92)"
            ]
          },
          "leg4": {
            "rotation": [
              0,
              "-math.abs(math.cos(query.anim_time * 76.34 + 90 * 2) * 22.92)",
              "math.abs(math.sin(query.anim_time * 38.17 + 90 * 2) * 22.92)"
            ]
          },
          "leg5": {
            "rotation": [
              0,
              "math.abs(math.cos(query.anim_time * 76.34 + 90 * 2) * 22.92)",
              "-math.abs(math.sin(query.anim_time * 38.17 + 90 * 2) * 22.92)"
            ]
          },
          "leg6": {
            "rotation": [
              0,
              "-math.abs(math.cos(query.anim_time * 76.34 + 90 * 3) * 22.92)",
              "math.abs(math.sin(query.anim_time * 38.17 + 90 * 3) * 22.92)"
            ]
          },
          "leg7": {
            "rotation": [
              0,
              "math.abs(math.cos(query.anim_time * 76.34 + 90 * 3) * 22.92)",
              "-math.abs(math.sin(query.anim_time * 38.17 + 90 * 3) * 22.92)"
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "move": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": [
              "default_leg_pose",
              {"walk": "query.modified_move_speed"},
              "look_at_target"
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.spider"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 11}
  },
  "potion": {
    "identifier": "minecraft:splash_potion",
    "materials": {"default": "splash_potion_enchanted"},
    "textures": {
      "moveSlowdown": "textures/items/potion_bottle_splash_moveSlowdown",
      "moveSpeed": "textures/items/potion_bottle_splash_moveSpeed",
      "digSlowdown": "textures/items/potion_bottle_splash_digSlowdown",
      "digSpeed": "textures/items/potion_bottle_splash_digSpeed",
      "damageBoost": "textures/items/potion_bottle_splash_damageBoost",
      "heal": "textures/items/potion_bottle_splash_heal",
      "harm": "textures/items/potion_bottle_splash_harm",
      "jump": "textures/items/potion_bottle_splash_jump",
      "confusion": "textures/items/potion_bottle_splash_confusion",
      "regeneration": "textures/items/potion_bottle_splash_regeneration",
      "resistance": "textures/items/potion_bottle_splash_resistance",
      "fireResistance": "textures/items/potion_bottle_splash_fireResistance",
      "waterBreathing": "textures/items/potion_bottle_splash_waterBreathing",
      "invisibility": "textures/items/potion_bottle_splash_invisibility",
      "blindness": "textures/items/potion_bottle_splash_blindness",
      "nightVision": "textures/items/potion_bottle_splash_nightVision",
      "hunger": "textures/items/potion_bottle_splash_hunger",
      "weakness": "textures/items/potion_bottle_splash_weakness",
      "poison": "textures/items/potion_bottle_splash_poison",
      "wither": "textures/items/potion_bottle_splash_wither",
      "healthBoost": "textures/items/potion_bottle_splash_healthBoost",
      "absorption": "textures/items/potion_bottle_splash_absorption",
      "saturation": "textures/items/potion_bottle_splash_saturation",
      "levitation": "textures/items/potion_bottle_splash_levitation",
      "turtleMaster": "textures/items/potion_bottle_splash_turtleMaster",
      "slowFall": "textures/items/potion_bottle_splash_slowFall",
      "default": "textures/items/potion_bottle_splash",
      "enchanted": "textures/misc/enchanted_item_glint"
    },
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-8, -8, 0],
                "size": [16, 16, 0],
                "uv": [0, 0],
                "rotation": [0, 0, 0]
              }
            ]
          }
        ],
        "texturewidth": 16,
        "textureheight": 16
      }
    },
    "render_controllers": ["controller.render.splash_potion"],
    "animations": {
      "flying": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              "query.camera_rotation(0)",
              "query.camera_rotation(1)",
              0
            ]
          }
        }
      }
    },
    "scripts": {"animate": ["flying"]}
  },
  "squid": {
    "identifier": "minecraft:squid",
    "materials": {"default": "squid"},
    "textures": {"default": "textures/entity/squid"},
    "geometry": {
      "default": {
        "visible_bounds_width": 3,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "body",
            "cubes": [
              {"origin": [-6, -8, -6], "size": [12, 16, 12], "uv": [0, 0]}
            ]
          },
          {
            "name": "tentacle1",
            "parent": "body",
            "pivot": [5, -7, 0],
            "cubes": [
              {"origin": [4, -25, -1], "size": [2, 18, 2], "uv": [48, 0]}
            ],
            "rotation": [0, 90, 0]
          },
          {
            "name": "tentacle2",
            "parent": "body",
            "pivot": [3.5, -7, 3.5],
            "cubes": [
              {"origin": [2.5, -25, 2.5], "size": [2, 18, 2], "uv": [48, 0]}
            ],
            "rotation": [0, 45, 0]
          },
          {
            "name": "tentacle3",
            "parent": "body",
            "pivot": [0, -7, 5],
            "cubes": [
              {"origin": [-1, -25, 4], "size": [2, 18, 2], "uv": [48, 0]}
            ],
            "rotation": [0, 0, 0]
          },
          {
            "name": "tentacle4",
            "parent": "body",
            "pivot": [-3.5, -7, 3.5],
            "cubes": [
              {"origin": [-4.5, -25, 2.5], "size": [2, 18, 2], "uv": [48, 0]}
            ],
            "rotation": [0, -45, 0]
          },
          {
            "name": "tentacle5",
            "parent": "body",
            "pivot": [-5, -7, 0],
            "cubes": [
              {"origin": [-6, -25, -1], "size": [2, 18, 2], "uv": [48, 0]}
            ],
            "rotation": [0, -90, 0]
          },
          {
            "name": "tentacle6",
            "parent": "body",
            "pivot": [-3.5, -7, -3.5],
            "cubes": [
              {"origin": [-4.5, -25, -4.5], "size": [2, 18, 2], "uv": [48, 0]}
            ],
            "rotation": [0, -135, 0]
          },
          {
            "name": "tentacle7",
            "parent": "body",
            "pivot": [0, -7, -5],
            "cubes": [
              {"origin": [-1, -25, -6], "size": [2, 18, 2], "uv": [48, 0]}
            ],
            "rotation": [0, -180, 0]
          },
          {
            "name": "tentacle8",
            "parent": "body",
            "pivot": [3.5, -7, -3.5],
            "cubes": [
              {"origin": [2.5, -25, -4.5], "size": [2, 18, 2], "uv": [48, 0]}
            ],
            "rotation": [0, -225, 0]
          }
        ]
      }
    },
    "animations": {
      "move": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "body": {
            "position": [
              0,
              "query.is_in_water ? 0.0 : (query.is_baby ? 3.2 : 6.4)",
              0
            ]
          },
          "tentacle1": {
            "rotation": [
              "variable.squid.tentacle_angle - this",
              "90.0 - this",
              0
            ]
          },
          "tentacle2": {
            "rotation": [
              "variable.squid.tentacle_angle - this",
              "45.0 - this",
              0
            ]
          },
          "tentacle3": {
            "rotation": [
              "variable.squid.tentacle_angle - this",
              "0.0 - this",
              0
            ]
          },
          "tentacle4": {
            "rotation": [
              "variable.squid.tentacle_angle - this",
              "-45.0 - this",
              0
            ]
          },
          "tentacle5": {
            "rotation": [
              "variable.squid.tentacle_angle - this",
              "-90.0 - this",
              0
            ]
          },
          "tentacle6": {
            "rotation": [
              "variable.squid.tentacle_angle - this",
              "-135.0 - this",
              0
            ]
          },
          "tentacle7": {
            "rotation": [
              "variable.squid.tentacle_angle - this",
              "-180.0 - this",
              0
            ]
          },
          "tentacle8": {
            "rotation": [
              "variable.squid.tentacle_angle - this",
              "-225.0 - this",
              0
            ]
          }
        }
      },
      "squid_rotate": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              {"y": "180.0 - this"},
              {"x": "query.body_x_rotation"},
              {"y": "variable.squid.swim_rotation"}
            ]
          }
        }
      }
    },
    "scripts": {"animate": ["move", "squid_rotate"]},
    "render_controllers": ["controller.render.squid"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 15}
  },
  "stray": {
    "identifier": "minecraft:stray",
    "min_engine_version": "1.8.0",
    "materials": {"default": "stray", "overlay": "stray_clothes"},
    "textures": {
      "default": "textures/entity/skeleton/stray",
      "overlay": "textures/entity/skeleton/stray_overlay"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 1, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 12, -2], "size": [8, 12, 4], "uv": [16, 16]}
            ],
            "parent": "waist"
          },
          {"name": "waist", "pivot": [0, 12, 0]},
          {
            "name": "head",
            "pivot": [0, 24, 0],
            "locators": {"lead": [0, 24, 0]},
            "cubes": [
              {"origin": [-4, 24, -4], "size": [8, 8, 8], "uv": [0, 0]}
            ],
            "parent": "body"
          },
          {
            "name": "hat",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-4, 24, -4],
                "size": [8, 8, 8],
                "uv": [32, 0],
                "inflate": 0.5
              }
            ],
            "neverRender": true,
            "parent": "head"
          },
          {
            "name": "rightArm",
            "pivot": [-5, 22, 0],
            "cubes": [
              {"origin": [-6, 12, -1], "size": [2, 12, 2], "uv": [40, 16]}
            ],
            "parent": "body"
          },
          {
            "name": "rightItem",
            "pivot": [-6, 15, 1],
            "neverRender": true,
            "parent": "rightArm"
          },
          {
            "name": "leftArm",
            "pivot": [5, 22, 0],
            "cubes": [
              {"origin": [4, 12, -1], "size": [2, 12, 2], "uv": [40, 16]}
            ],
            "mirror": true,
            "parent": "body"
          },
          {
            "name": "leftItem",
            "pivot": [6, 15, 1],
            "neverRender": true,
            "parent": "leftArm"
          },
          {
            "name": "rightLeg",
            "pivot": [-2, 12, 0],
            "cubes": [
              {"origin": [-3, 0, -1], "size": [2, 12, 2], "uv": [0, 16]}
            ],
            "parent": "body"
          },
          {
            "name": "leftLeg",
            "pivot": [2, 12, 0],
            "cubes": [
              {"origin": [1, 0, -1], "size": [2, 12, 2], "uv": [0, 16]}
            ],
            "mirror": true,
            "parent": "body"
          }
        ]
      },
      "overlay": {
        "visible_bounds_width": 2,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 1, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "body",
            "parent": "waist",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 12, -2], "size": [8, 12, 4], "uv": [16, 16]}
            ],
            "inflate": 0.25
          },
          {"name": "waist", "neverRender": true, "pivot": [0, 12, 0]},
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 24, -4], "size": [8, 8, 8], "uv": [0, 0]}
            ],
            "inflate": 0.25
          },
          {
            "name": "hat",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-4, 24, -4],
                "size": [8, 8, 8],
                "uv": [32, 0],
                "inflate": 0.5
              }
            ],
            "neverRender": true
          },
          {
            "name": "rightArm",
            "parent": "body",
            "pivot": [-5, 22, 0],
            "cubes": [
              {"origin": [-8, 12, -2], "size": [4, 12, 4], "uv": [40, 16]}
            ],
            "inflate": 0.25
          },
          {
            "name": "rightItem",
            "parent": "rightArm",
            "pivot": [-6, 15, 1],
            "neverRender": true
          },
          {
            "name": "leftArm",
            "parent": "body",
            "pivot": [5, 22, 0],
            "cubes": [
              {"origin": [4, 12, -2], "size": [4, 12, 4], "uv": [40, 16]}
            ],
            "mirror": true,
            "inflate": 0.25
          },
          {
            "name": "rightLeg",
            "parent": "body",
            "pivot": [-1.9, 12, 0],
            "cubes": [
              {"origin": [-3.9, 0, -2], "size": [4, 12, 4], "uv": [0, 16]}
            ],
            "inflate": 0.25
          },
          {
            "name": "leftLeg",
            "parent": "body",
            "pivot": [1.9, 12, 0],
            "cubes": [
              {"origin": [-0.1, 0, -2], "size": [4, 12, 4], "uv": [0, 16]}
            ],
            "inflate": 0.25,
            "mirror": true
          }
        ]
      }
    },
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 27},
    "scripts": {
      "pre_animation": [
        "variable.tcos0 = (Math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed / variable.gliding_speed_value) * 57.3;"
      ]
    },
    "animations": {
      "look_at_target_default": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_gliding": {
        "loop": true,
        "bones": {"head": {"rotation": [-45, "query.target_y_rotation", 0]}}
      },
      "look_at_target_swimming": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "math.lerp(query.target_x_rotation, -45.0, variable.swim_amount)",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "move": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["variable.tcos0", 0, 0]},
          "leftleg": {"rotation": ["variable.tcos0 * -1.4", 0, 0]},
          "rightarm": {"rotation": ["-variable.tcos0", 0, 0]},
          "rightleg": {"rotation": ["variable.tcos0 * 1.4", 0, 0]}
        }
      },
      "riding.arms": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": [-36, 0, 0]},
          "rightarm": {"rotation": [-36, 0, 0]}
        }
      },
      "riding.legs": {
        "loop": true,
        "bones": {
          "leftleg": {"rotation": ["-72.0 - this", "-18.0 - this", "-this"]},
          "rightleg": {"rotation": ["-72.0 - this", "18.0 - this", "-this"]}
        }
      },
      "holding": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "variable.is_holding_left ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "variable.is_holding_right ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          }
        }
      },
      "brandish_spear": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "this * -0.5 - 157.5 - 22.5 * variable.charge_amount",
              "-this",
              0
            ]
          }
        }
      },
      "charging": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": ["22.5 * variable.charge_amount - this", "-this", 0]
          }
        }
      },
      "attack.rotations": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              0,
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46 - this",
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.sin(1.0 - math.pow(1.0 - variable.attack_time, 3.0) * 180.0) * (variable.is_brandishing_spear ? -1.0 : 1.0 )",
              "variable.is_brandishing_spear ? 0.0 : (math.sin(math.sqrt(variable.attack_time) * 360) * 11.46) * 2.0",
              0
            ]
          }
        }
      },
      "sneaking": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["0.5 - this", 0, 0]},
          "head": {"position": [0, 1, 0]},
          "leftarm": {"rotation": [72, 0, 0]},
          "leftleg": {"position": [0, -3, 4]},
          "rightarm": {"rotation": [72, 0, 0]},
          "rightleg": {"position": [0, -3, 4]}
        }
      },
      "bob": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              0,
              0,
              "((math.cos(query.life_time * 103.2) * 2.865) + 2.865) *-1.0"
            ]
          },
          "rightarm": {
            "rotation": [
              0,
              0,
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "damage_nearby_mobs": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["-45.0-this", "-this", "-this"]},
          "leftleg": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightarm": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-45.0-this", "-this", "-this"]}
        }
      },
      "bow_and_arrow": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "query.target_x_rotation - 90.0 - math.sin(query.life_time * 76.8) * 2.865 - this",
              "query.target_y_rotation + 28.65",
              "-(math.cos(query.life_time * 103.2) * 2.865) - 2.865"
            ]
          },
          "rightarm": {
            "rotation": [
              "query.target_x_rotation - 90.0 + math.sin(query.life_time * 76.8) * 2.865 - this",
              "query.target_y_rotation - 5.73",
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "swimming": {
        "loop": true,
        "bones": {
          "body": {
            "position": [
              0,
              "variable.swim_amount * -10.0 - this",
              "variable.swim_amount * 9.0 - this"
            ],
            "rotation": [
              "variable.swim_amount * (90.0 + query.target_x_rotation)",
              0,
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.lerp(this, -180.0, variable.swim_amount) - (variable.swim_amount * ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4)) - (variable.swim_amount * (math.sin(query.life_time * 76.776372) * 2.865)) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - (variable.swim_amount * (math.cos(query.life_time * 103.13244) * 2.865 + 2.865)) - this"
            ]
          },
          "leftleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.life_time * 390.0 + 180.0) * 0.3, variable.swim_amount)",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.lerp(this, -180.0, variable.swim_amount) - (variable.swim_amount * ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4)) + (variable.swim_amount * (math.sin(query.life_time * 76.776372) * 2.865)) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - this",
              "math.lerp(this, -14.325, variable.swim_amount) + (variable.swim_amount * (math.cos(query.life_time * 103.13244) * 2.865 + 2.865)) - this"
            ]
          },
          "rightleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.life_time * 390.0) * 0.3, variable.swim_amount)",
              0,
              0
            ]
          }
        }
      },
      "use_item_progress": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "variable.use_item_startup_progress * -60.0 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -22.5 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -5.625 + variable.use_item_interval_progress * 11.25"
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "look_at_target": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["look_at_target_default"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"swimming": "query.is_swimming"}
            ]
          },
          "gliding": {
            "animations": ["look_at_target_gliding"],
            "transitions": [
              {"swimming": "query.is_swimming"},
              {"default": "!query.is_gliding"}
            ]
          },
          "swimming": {
            "animations": ["look_at_target_swimming"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"default": "!query.is_swimming"}
            ]
          }
        }
      },
      "move": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move"]}}
      },
      "riding": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"riding": "query.is_riding"}]},
          "riding": {
            "animations": ["riding.arms", "riding.legs"],
            "transitions": [{"default": "!query.is_riding"}]
          }
        }
      },
      "holding": {
        "initial_state": "default",
        "states": {"default": {"animations": ["holding"]}}
      },
      "brandish_spear": {
        "initial_state": "default",
        "states": {
          "brandish_spear": {
            "animations": ["brandish_spear"],
            "transitions": [{"default": "!variable.is_brandishing_spear"}]
          },
          "default": {
            "transitions": [{"brandish_spear": "variable.is_brandishing_spear"}]
          }
        }
      },
      "charging": {
        "initial_state": "default",
        "states": {
          "charging": {
            "animations": ["charging"],
            "transitions": [{"default": "!query.is_charging"}]
          },
          "default": {"transitions": [{"charging": "query.is_charging"}]}
        }
      },
      "attack": {
        "initial_state": "default",
        "states": {
          "attacking": {
            "animations": ["attack.rotations"],
            "transitions": [{"default": "variable.attack_time < 0.0"}]
          },
          "default": {
            "transitions": [{"attacking": "variable.attack_time >= 0.0"}]
          }
        }
      },
      "sneaking": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"sneaking": "query.is_sneaking"}]},
          "sneaking": {
            "animations": ["sneaking"],
            "transitions": [{"default": "!query.is_sneaking"}]
          }
        }
      },
      "bob": {
        "initial_state": "default",
        "states": {"default": {"animations": ["bob"]}}
      },
      "damage_nearby_mobs": {
        "initial_state": "default",
        "states": {
          "damage_nearby_mobs": {
            "animations": ["damage_nearby_mobs"],
            "transitions": [{"default": "!variable.damage_nearby_mobs"}]
          },
          "default": {
            "transitions": [
              {"damage_nearby_mobs": "variable.damage_nearby_mobs"}
            ]
          }
        }
      },
      "bow_and_arrow": {
        "initial_state": "default",
        "states": {
          "bow_and_arrow": {
            "animations": ["bow_and_arrow"],
            "transitions": [{"default": "!query.has_target"}]
          },
          "default": {"transitions": [{"bow_and_arrow": "query.has_target"}]}
        }
      },
      "swimming": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [{"is_swimming": "variable.swim_amount > 0.0"}]
          },
          "is_swimming": {
            "animations": ["swimming"],
            "transitions": [{"default": "variable.swim_amount <= 0.0"}]
          }
        }
      },
      "use_item_progress": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [
              {
                "use_item_progress": "( variable.use_item_interval_progress > 0.0 ) || ( variable.use_item_startup_progress > 0.0 )"
              }
            ]
          },
          "use_item_progress": {
            "animations": ["use_item_progress"],
            "transitions": [
              {
                "default": "( variable.use_item_interval_progress <= 0.0 ) && ( variable.use_item_startup_progress <= 0.0 )"
              }
            ]
          }
        }
      }
    },
    "render_controllers": [
      "controller.render.stray_clothes",
      "controller.render.stray"
    ],
    "enable_attachables": true
  },
  "strider": {
    "identifier": "minecraft:strider",
    "materials": {"default": "strider"},
    "textures": {
      "default": "textures/entity/strider/strider",
      "saddled": "textures/entity/strider/strider",
      "suffocated": "textures/entity/strider/strider_cold",
      "suffocated_saddled": "textures/entity/strider/strider_cold"
    },
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "right_leg",
            "pivot": [-4, 16, 0],
            "cubes": [
              {"origin": [-6, 0, -2], "size": [4, 16, 4], "uv": [0, 32]}
            ]
          },
          {
            "name": "left_leg",
            "pivot": [4, 16, 0],
            "cubes": [{"origin": [2, 0, -2], "size": [4, 16, 4], "uv": [0, 55]}]
          },
          {
            "name": "body",
            "pivot": [0, 16, 0],
            "cubes": [
              {"origin": [-8, 14, -8], "size": [16, 14, 16], "uv": [0, 0]}
            ],
            "locators": {"lead": [0, 15, -1]}
          },
          {
            "name": "bristle5",
            "parent": "body",
            "pivot": [8, 19, 0],
            "cubes": [
              {
                "origin": [8, 19, -8],
                "size": [12, 0, 16],
                "pivot": [8, 19, 0],
                "rotation": [0, 0, 70],
                "uv": [16, 65]
              }
            ]
          },
          {
            "name": "bristle4",
            "parent": "body",
            "pivot": [8, 24, 0],
            "cubes": [
              {
                "origin": [8, 24, -8],
                "size": [12, 0, 16],
                "pivot": [8, 24, 0],
                "rotation": [0, 0, 65],
                "uv": [16, 49]
              }
            ]
          },
          {
            "name": "bristle3",
            "parent": "body",
            "pivot": [8, 28, 0],
            "cubes": [
              {
                "origin": [8, 28, -8],
                "size": [12, 0, 16],
                "pivot": [8, 28, 0],
                "rotation": [0, 0, 50],
                "uv": [16, 33]
              }
            ]
          },
          {
            "name": "bristle2",
            "parent": "body",
            "pivot": [-8, 28, 0],
            "cubes": [
              {
                "origin": [-20, 28, -8],
                "size": [12, 0, 16],
                "pivot": [-8, 28, 0],
                "rotation": [0, 0, -50],
                "uv": [16, 33],
                "mirror": true
              }
            ]
          },
          {
            "name": "bristle1",
            "parent": "body",
            "pivot": [-8, 24, 0],
            "cubes": [
              {
                "origin": [-20, 24, -8],
                "size": [12, 0, 16],
                "pivot": [-8, 24, 0],
                "rotation": [0, 0, -65],
                "uv": [16, 49],
                "mirror": true
              }
            ]
          },
          {
            "name": "bristle0",
            "parent": "body",
            "pivot": [-8, 19, 0],
            "cubes": [
              {
                "origin": [-20, 19, -8],
                "size": [12, 0, 16],
                "pivot": [-8, 19, 0],
                "rotation": [0, 0, -70],
                "uv": [16, 65],
                "mirror": true
              }
            ]
          }
        ],
        "visible_bounds_width": 3,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 1, 0],
        "texturewidth": 64,
        "textureheight": 128
      }
    },
    "spawn_egg": {"base_color": "#9c3436", "overlay_color": "#4d494d"},
    "scripts": {
      "pre_animation": [
        "variable.animation_speed = Math.min(query.modified_move_speed, 0.25);",
        "variable.speed = 85.9437;",
        "variable.bristle_flow=Math.cos(query.modified_distance_moved * variable.speed + 180) * variable.animation_speed;",
        "variable.bristle_range_mod=1;",
        "variable.bristle_speed_mod=1;"
      ],
      "animate": ["look_at_target", "walk", "bristle_flow"]
    },
    "animations": {
      "look_at_target": {
        "loop": true,
        "bones": {
          "body": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "walk": {
        "loop": true,
        "bones": {
          "right_leg": {
            "rotation": [
              "Math.sin(query.modified_distance_moved * variable.speed * 0.5 + 180) * 114.592 * variable.animation_speed",
              0,
              "10 * Math.cos(query.modified_distance_moved * variable.speed * 0.5f + 180) * variable.animation_speed"
            ],
            "position": [
              0,
              "-2 * Math.sin(query.modified_distance_moved * variable.speed * 0.5) * 2 * variable.animation_speed",
              0
            ]
          },
          "left_leg": {
            "rotation": [
              "Math.sin(query.modified_distance_moved * variable.speed * 0.5) * 114.592 * variable.animation_speed",
              0,
              "10 * Math.cos(query.modified_distance_moved * variable.speed * 0.5f) * variable.animation_speed"
            ],
            "position": [
              0,
              "-2 * Math.sin(query.modified_distance_moved * variable.speed * 0.5 + 180) * 2 * variable.animation_speed",
              0
            ]
          },
          "body": {
            "rotation": [
              0,
              0,
              "5.72958 * Math.sin(query.modified_distance_moved * variable.speed) * 4 * variable.animation_speed"
            ],
            "position": [
              0,
              "-2 * Math.cos(query.modified_distance_moved * variable.speed) * 2 * variable.animation_speed",
              0
            ]
          },
          "bristle1": {"rotation": [0, 0, "variable.bristle_flow * 74.4845"]},
          "bristle2": {"rotation": [0, 0, "variable.bristle_flow * 68.7549"]},
          "bristle3": {"rotation": [0, 0, "variable.bristle_flow * 34.3775"]},
          "bristle4": {"rotation": [0, 0, "variable.bristle_flow * 68.7549"]},
          "bristle5": {"rotation": [0, 0, "variable.bristle_flow * 74.4845"]}
        }
      },
      "bristle_flow": {
        "loop": true,
        "bones": {
          "bristle0": {
            "rotation": [
              0,
              0,
              "2.864789 * variable.bristle_range_mod * Math.sin(query.life_time * 20 * variable.bristle_speed_mod * -22.9183)"
            ]
          },
          "bristle1": {
            "rotation": [
              0,
              0,
              "5.72958 * variable.bristle_range_mod * Math.sin(query.life_time * 20 * variable.bristle_speed_mod * 11.4592)"
            ]
          },
          "bristle2": {
            "rotation": [
              0,
              0,
              "5.72958 * variable.bristle_range_mod * Math.sin(query.life_time * 20 * variable.bristle_speed_mod * 22.9183)"
            ]
          },
          "bristle3": {
            "rotation": [
              0,
              0,
              "5.72958 * variable.bristle_range_mod * Math.sin(query.life_time * 20 * variable.bristle_speed_mod * 22.9183)"
            ]
          },
          "bristle4": {
            "rotation": [
              0,
              0,
              "5.72958 * variable.bristle_range_mod * Math.sin(query.life_time * 20 * variable.bristle_speed_mod * 11.4592)"
            ]
          },
          "bristle5": {
            "rotation": [
              0,
              0,
              "2.864789 * variable.bristle_range_mod * Math.sin(query.life_time * 20 * variable.bristle_speed_mod * -22.9183)"
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.strider"]
  },
  "trident": {
    "identifier": "minecraft:thrown_trident",
    "textures": {
      "default": "textures/entity/trident",
      "loyalty_rope": "textures/entity/lead_knot"
    },
    "geometry": {
      "default": {
        "texturewidth": 32,
        "textureheight": 32,
        "bones": [
          {
            "name": "pole",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-0.5, -3, -0.5],
                "size": [1, 31, 1],
                "inflate": 0.01,
                "uv": [0, 0]
              },
              {"origin": [-1.5, 22, -0.5], "size": [3, 2, 1], "uv": [4, 0]},
              {"origin": [-2.5, 23, -0.5], "size": [1, 4, 1], "uv": [4, 3]},
              {"origin": [1.5, 23, -0.5], "size": [1, 4, 1], "uv": [4, 3]}
            ]
          }
        ]
      }
    }
  },
  "tnt_minecart": {
    "identifier": "minecraft:tnt_minecart",
    "min_engine_version": "1.8.0",
    "materials": {"default": "minecart"},
    "textures": {"default": "textures/entity/minecart"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "bottom",
            "pivot": [0, 6, 0],
            "cubes": [
              {
                "origin": [-10, -6.5, -1],
                "size": [20, 16, 2],
                "rotation": [90, 0, 0],
                "uv": [0, 10]
              }
            ]
          },
          {
            "name": "back",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-17, 2.5, -1],
                "size": [16, 8, 2],
                "rotation": [0, 270, 0],
                "uv": [0, 0]
              }
            ],
            "parent": "bottom"
          },
          {
            "name": "front",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [1, 2.5, -1],
                "size": [16, 8, 2],
                "rotation": [0, 90, 0],
                "uv": [0, 0]
              }
            ],
            "parent": "bottom"
          },
          {
            "name": "right",
            "pivot": [0, 0, 0],
            "cubes": [
              {
                "origin": [-8, 2.5, -8],
                "size": [16, 8, 2],
                "rotation": [0, 180, 0],
                "uv": [0, 0]
              }
            ],
            "parent": "bottom"
          },
          {
            "name": "left",
            "pivot": [0, 0, 0],
            "cubes": [
              {"origin": [-8, 2.5, 6], "size": [16, 8, 2], "uv": [0, 0]}
            ],
            "parent": "bottom"
          }
        ],
        "texturewidth": 64,
        "textureheight": 32
      }
    },
    "scripts": {
      "pre_animation": ["variable.hurt = query.hurt_time - query.frame_alpha;"],
      "animate": ["move"]
    },
    "animations": {
      "move": {
        "loop": true,
        "bones": {
          "bottom": {
            "position": [
              "variable.rail_offset.x / query.model_scale",
              "variable.rail_offset.y / query.model_scale",
              "variable.rail_offset.z / query.model_scale"
            ],
            "rotation": [
              "variable.hurt > 0 ? -Math.sin(variable.hurt * 360 / (Math.pi * 2)) * variable.hurt * (((20 * 2 - query.structural_integrity) - query.frame_alpha) < 0 ? 0: (20 * 2 - query.structural_integrity) - query.frame_alpha) / 10 * query.hurt_direction : 0",
              0,
              "-variable.rail_rotation.z"
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.minecart"]
  },
  "tropical_fish": {
    "identifier": "minecraft:tropicalfish",
    "materials": {"default": "tropicalfish"},
    "textures": {
      "typeA": "textures/entity/fish/tropical_a",
      "typeB": "textures/entity/fish/tropical_b",
      "aPattern1": "textures/entity/fish/tropical_a_pattern_1",
      "aPattern2": "textures/entity/fish/tropical_a_pattern_2",
      "aPattern3": "textures/entity/fish/tropical_a_pattern_3",
      "aPattern4": "textures/entity/fish/tropical_a_pattern_4",
      "aPattern5": "textures/entity/fish/tropical_a_pattern_5",
      "aPattern6": "textures/entity/fish/tropical_a_pattern_6",
      "bPattern1": "textures/entity/fish/tropical_b_pattern_1",
      "bPattern2": "textures/entity/fish/tropical_b_pattern_2",
      "bPattern3": "textures/entity/fish/tropical_b_pattern_3",
      "bPattern4": "textures/entity/fish/tropical_b_pattern_4",
      "bPattern5": "textures/entity/fish/tropical_b_pattern_5",
      "bPattern6": "textures/entity/fish/tropical_b_pattern_6"
    },
    "scripts": {
      "pre_animation": [
        "variable.ZRot = !query.is_in_water ? Math.cos((query.time_stamp + query.frame_alpha) * 0.25) * 90 : 0.0;",
        "variable.AnimationAmountBlend = Math.lerp(variable.AnimationAmountPrev, variable.AnimationAmount, query.frame_alpha);"
      ]
    },
    "geometry": {
      "typeA": {
        "visible_bounds_width": 0.5,
        "visible_bounds_height": 0.5,
        "bones": [
          {
            "pivot": [-0.5, 0, 0],
            "cubes": [
              {"origin": [-1, 0, -3], "size": [2, 3, 6], "uv": [0, 0]},
              {"origin": [0, 3, -2.9992], "size": [0, 4, 6], "uv": [10, -6]}
            ],
            "name": "body"
          },
          {
            "pivot": [0, 0, 3],
            "cubes": [{"origin": [0, 0, 3], "size": [0, 3, 4], "uv": [24, -4]}],
            "name": "tailfin",
            "parent": "body"
          },
          {
            "pivot": [0.5, 0, 1],
            "bind_pose_rotation": [0, -35, 0],
            "cubes": [
              {"origin": [0.336, 0, -0.10594], "size": [2, 2, 0], "uv": [2, 12]}
            ],
            "name": "leftFin",
            "parent": "body"
          },
          {
            "pivot": [-0.5, 0, 1],
            "bind_pose_rotation": [0, 35, 0],
            "cubes": [
              {
                "origin": [-2.336, 0, -0.10594],
                "size": [2, 2, 0],
                "uv": [2, 16]
              }
            ],
            "name": "rightFin",
            "parent": "body"
          }
        ],
        "texturewidth": 32,
        "textureheight": 32
      },
      "typeB": {
        "visible_bounds_width": 0.5,
        "visible_bounds_height": 0.5,
        "bones": [
          {
            "pivot": [-0.5, 0, 0],
            "cubes": [
              {"origin": [-1, 0, -0.0008], "size": [2, 6, 6], "uv": [0, 20]},
              {"origin": [0, -5, -0.0008], "size": [0, 5, 6], "uv": [20, 21]},
              {"origin": [0, 6, -0.0008], "size": [0, 5, 6], "uv": [20, 10]}
            ],
            "name": "body"
          },
          {
            "pivot": [0, 0, 6],
            "cubes": [
              {"origin": [0, 0.0008, 6], "size": [0, 6, 5], "uv": [21, 16]}
            ],
            "name": "tailfin",
            "parent": "body"
          },
          {
            "pivot": [0.5, 0, 1],
            "bind_pose_rotation": [0, -35, 0],
            "cubes": [
              {
                "origin": [2.05673, 0, 2.35152],
                "size": [2, 2, 0],
                "uv": [2, 12]
              }
            ],
            "name": "leftFin",
            "parent": "body"
          },
          {
            "pivot": [-0.5, 0, 1],
            "bind_pose_rotation": [0, 35, 0],
            "cubes": [
              {
                "origin": [-4.05673, 0, 2.35152],
                "size": [2, 2, 0],
                "uv": [2, 16]
              }
            ],
            "name": "rightFin",
            "parent": "body"
          }
        ],
        "texturewidth": 32,
        "textureheight": 32
      }
    },
    "animations": {
      "flop": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 45.0) * 2.0",
              "variable.zrot"
            ]
          },
          "tailfin": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 45.0) * -25.75",
              0
            ]
          }
        }
      },
      "swim": {
        "loop": true,
        "bones": {
          "tailfin": {
            "rotation": [
              0,
              "math.cos(variable.animationamountblend * 30.0) * -25.75",
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "general": {
        "initial_state": "flopping",
        "states": {
          "flopping": {
            "animations": ["flop"],
            "transitions": [
              {"swimming": "query.is_in_water || query.is_levitating"}
            ]
          },
          "swimming": {
            "animations": ["swim"],
            "transitions": [
              {"flopping": "!query.is_in_water && !query.is_levitating"}
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.tropicalfish"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 44}
  },
  "turtle": {
    "identifier": "minecraft:turtle",
    "materials": {"default": "turtle"},
    "textures": {"default": "textures/entity/turtle/big_sea_turtle"},
    "geometry": {
      "default": {
        "texturewidth": 128,
        "textureheight": 64,
        "bones": [
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 5, -10],
            "cubes": [{"origin": [-3, 1, -13], "size": [6, 5, 6], "uv": [2, 0]}]
          },
          {
            "name": "eggbelly",
            "parent": "body",
            "pivot": [0, 13, -10],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {"origin": [-4.5, -8, -24], "size": [9, 18, 1], "uv": [69, 33]}
            ]
          },
          {
            "name": "body",
            "pivot": [0, 13, -10],
            "bind_pose_rotation": [90, 0, 0],
            "cubes": [
              {"origin": [-9.5, -10, -20], "size": [19, 20, 6], "uv": [6, 37]},
              {"origin": [-5.5, -8, -23], "size": [11, 18, 3], "uv": [30, 1]}
            ]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-3.5, 2, 11],
            "cubes": [
              {"origin": [-5.5, 1, 11], "size": [4, 1, 10], "uv": [0, 23]}
            ]
          },
          {
            "name": "leg1",
            "parent": "body",
            "pivot": [3.5, 2, 11],
            "cubes": [
              {"origin": [1.5, 1, 11], "size": [4, 1, 10], "uv": [0, 12]}
            ]
          },
          {
            "name": "leg2",
            "parent": "body",
            "pivot": [-5, 3, -4],
            "cubes": [
              {"origin": [-18, 2, -6], "size": [13, 1, 5], "uv": [26, 30]}
            ]
          },
          {
            "name": "leg3",
            "parent": "body",
            "pivot": [5, 3, -4],
            "cubes": [
              {"origin": [5, 2, -6], "size": [13, 1, 5], "uv": [26, 24]}
            ]
          }
        ]
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.timeMultiplier = query.has_rider ? 0.39972 : 1.0;",
        "variable.backLegMultiplier = query.has_rider ? 0.5 : 3.0;",
        "variable.frontLegMultiplier = query.has_rider ? 2.0 : 8.0;",
        "variable.legSpeedMultiplier = query.has_rider ? 2.0 : 5.0;"
      ],
      "scale": "1.2"
    },
    "animations": {
      "general": {
        "loop": true,
        "bones": {
          "body": {
            "position": [0, "query.is_pregnant ? 1.0 : 0.0", 0],
            "rotation": ["-this", 0, 0]
          },
          "eggbelly": {
            "position": [0, -0.08, 0],
            "rotation": ["-this", 0, 0],
            "scale": "query.is_pregnant ? 1.0 : 0.0"
          }
        }
      },
      "move": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": [
              "math.clamp(math.cos(query.anim_time * 22.92) * query.modified_move_speed * 28.65, -90.0, 90.0) - this",
              "-this",
              "-this"
            ]
          },
          "leg1": {
            "rotation": [
              "math.clamp(math.cos(query.anim_time * 22.92 + 180) * query.modified_move_speed * 28.65, -90.0, 90.0) - this",
              "-this",
              "-this"
            ]
          },
          "leg2": {
            "rotation": [
              "-this",
              "-this",
              "math.clamp(math.cos(query.anim_time * 22.92 + 180) * query.modified_move_speed * 28.65, -90.0, 90.0) - this"
            ]
          },
          "leg3": {
            "rotation": [
              "-this",
              "-this",
              "math.clamp(math.cos(query.anim_time * 22.92) * query.modified_move_speed * 28.65, -90.0, 90.0) - this"
            ]
          }
        }
      },
      "ground_move": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": [
              "-this",
              "math.clamp(math.cos(query.anim_time * variable.timemultiplier * variable.legspeedmultiplier * 57.3 + 180) * variable.backlegmultiplier * query.modified_move_speed * 57.3, -90.0, 90.0) - this",
              "-this"
            ]
          },
          "leg1": {
            "rotation": [
              "-this",
              "math.clamp(math.cos(query.anim_time * variable.timemultiplier * variable.legspeedmultiplier * 57.3) * variable.backlegmultiplier * query.modified_move_speed * 57.3, -90.0, 90.0) - this",
              "-this"
            ]
          },
          "leg2": {
            "rotation": [
              "-this",
              "query.is_laying_egg ? math.cos(query.life_time * 1146.0 + 180) * 90.0 : math.clamp(math.cos(query.anim_time * variable.timemultiplier * variable.legspeedmultiplier * 57.3 + 180) * variable.frontlegmultiplier * query.modified_move_speed * 57.3, -90.0, 90.0) - this",
              "-this"
            ]
          },
          "leg3": {
            "rotation": [
              "-this",
              "query.is_laying_egg ? math.cos(query.life_time * 1146.0) * 90.0 : math.clamp(math.cos(query.anim_time * variable.timemultiplier * variable.legspeedmultiplier * 57.3) * variable.frontlegmultiplier * query.modified_move_speed * 57.3, -90.0, 90.0) - this",
              "-this"
            ]
          }
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "general": {
        "initial_state": "default",
        "states": {"default": {"animations": ["general"]}}
      },
      "move": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["look_at_target"],
            "transitions": [
              {"swimming": "query.is_in_water && !query.is_on_ground"},
              {"walking": "!query.is_in_water && query.is_on_ground"}
            ]
          },
          "swimming": {
            "animations": ["move", "look_at_target"],
            "transitions": [
              {"walking": "!query.is_in_water && query.is_on_ground"}
            ]
          },
          "walking": {
            "animations": ["ground_move", "look_at_target"],
            "transitions": [
              {"swimming": "query.is_in_water && !query.is_on_ground"}
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.turtle"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 50}
  },
  "vex": {
    "identifier": "minecraft:vex",
    "min_engine_version": "1.8.0",
    "materials": {"default": "vex"},
    "textures": {
      "default": "textures/entity/illager/vex",
      "charging": "textures/entity/illager/vex_charging"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 1,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 64,
        "bones": [
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-4, 24, -4], "size": [8, 8, 8], "uv": [0, 0]}]
          },
          {
            "name": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 12, -2], "size": [8, 12, 4], "uv": [16, 16]}
            ]
          },
          {
            "name": "rightArm",
            "parent": "body",
            "pivot": [-5, 22, 0],
            "cubes": [
              {"origin": [-8, 12, -2], "size": [4, 12, 4], "uv": [40, 16]}
            ]
          },
          {
            "name": "rightItem",
            "pivot": [-6, 13, 0],
            "neverRender": true,
            "parent": "rightarm"
          },
          {
            "name": "leftArm",
            "parent": "body",
            "pivot": [5, 22, 0],
            "mirror": true,
            "cubes": [
              {"origin": [4, 12, -2], "size": [4, 12, 4], "uv": [40, 16]}
            ]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-1.9, 12, 0],
            "cubes": [
              {"origin": [-3.9, 0, -2], "size": [4, 12, 4], "uv": [0, 16]},
              {"origin": [-2.9, 3, -2], "size": [6, 10, 4], "uv": [32, 0]}
            ]
          },
          {
            "name": "leftwing",
            "parent": "body",
            "pivot": [0, 24, 0],
            "mirror": true,
            "cubes": [
              {"origin": [0, 12, 0], "size": [20, 12, 1], "uv": [0, 32]}
            ]
          },
          {
            "name": "rightwing",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-20, 12, 0], "size": [20, 12, 1], "uv": [0, 32]}
            ]
          }
        ]
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.tcos0 = (Math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed / variable.gliding_speed_value) * 57.3;"
      ],
      "scale": "0.4"
    },
    "animations": {
      "look_at_target_default": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_gliding": {
        "loop": true,
        "bones": {"head": {"rotation": [-45, "query.target_y_rotation", 0]}}
      },
      "look_at_target_swimming": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "math.lerp(query.target_x_rotation, -45.0, variable.swim_amount)",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "move": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["variable.tcos0", 0, 0]},
          "leftleg": {"rotation": ["variable.tcos0 * -1.4", 0, 0]},
          "rightarm": {"rotation": ["-variable.tcos0", 0, 0]},
          "rightleg": {"rotation": ["variable.tcos0 * 1.4", 0, 0]}
        }
      },
      "riding.arms": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": [-36, 0, 0]},
          "rightarm": {"rotation": [-36, 0, 0]}
        }
      },
      "riding.legs": {
        "loop": true,
        "bones": {
          "leftleg": {"rotation": ["-72.0 - this", "-18.0 - this", "-this"]},
          "rightleg": {"rotation": ["-72.0 - this", "18.0 - this", "-this"]}
        }
      },
      "holding": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "variable.is_holding_left ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "variable.is_holding_right ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          }
        }
      },
      "brandish_spear": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "this * -0.5 - 157.5 - 22.5 * variable.charge_amount",
              "-this",
              0
            ]
          }
        }
      },
      "charging": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": ["22.5 * variable.charge_amount - this", "-this", 0]
          }
        }
      },
      "attack.rotations": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              0,
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46 - this",
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.sin(1.0 - math.pow(1.0 - variable.attack_time, 3.0) * 180.0) * (variable.is_brandishing_spear ? -1.0 : 1.0 )",
              "variable.is_brandishing_spear ? 0.0 : (math.sin(math.sqrt(variable.attack_time) * 360) * 11.46) * 2.0",
              0
            ]
          }
        }
      },
      "sneaking": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["0.5 - this", 0, 0]},
          "head": {"position": [0, 1, 0]},
          "leftarm": {"rotation": [72, 0, 0]},
          "leftleg": {"position": [0, -3, 4]},
          "rightarm": {"rotation": [72, 0, 0]},
          "rightleg": {"position": [0, -3, 4]}
        }
      },
      "bob": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              0,
              0,
              "((math.cos(query.life_time * 103.2) * 2.865) + 2.865) *-1.0"
            ]
          },
          "rightarm": {
            "rotation": [
              0,
              0,
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "damage_nearby_mobs": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["-45.0-this", "-this", "-this"]},
          "leftleg": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightarm": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-45.0-this", "-this", "-this"]}
        }
      },
      "bow_and_arrow": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "query.target_x_rotation - 90.0 - math.sin(query.life_time * 76.8) * 2.865 - this",
              "query.target_y_rotation + 28.65",
              "-(math.cos(query.life_time * 103.2) * 2.865) - 2.865"
            ]
          },
          "rightarm": {
            "rotation": [
              "query.target_x_rotation - 90.0 + math.sin(query.life_time * 76.8) * 2.865 - this",
              "query.target_y_rotation - 5.73",
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "swimming": {
        "animation_length": 1.3,
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": {
              "0.7": {
                "post": [
                  "math.lerp(this, 11.25 * math.mod(query.modified_distance_moved, 26.0), variable.leftarmswim_amount)",
                  "math.lerp(this, 180.0, variable.leftarmswim_amount)",
                  "math.lerp(this, 72.77 + 13.4 * math.mod(query.modified_distance_moved, 26.0), variable.leftarmswim_amount)"
                ],
                "pre": [
                  "math.lerp(this, 0.0, variable.leftarmswim_amount)",
                  "math.lerp(this, 180.0, variable.leftarmswim_amount)",
                  "math.lerp(this, 180.0 - 0.01877 * (-65.0 * math.mod(query.modified_distance_moved, 26.0) + math.mod(query.modified_distance_moved, 26.0) * math.mod(query.modified_distance_moved, 26.0)), variable.leftarmswim_amount)"
                ]
              },
              "1.1": [
                "math.lerp(this, 11.25 * math.mod(query.modified_distance_moved, 26.0), variable.leftarmswim_amount)",
                "math.lerp(this, 180.0, variable.leftarmswim_amount)",
                "math.lerp(this, 72.77 + 13.4 * math.mod(query.modified_distance_moved, 26.0), variable.leftarmswim_amount)"
              ],
              "1.3": {
                "post": [
                  "math.lerp(this, 90.0 - (22.5 * math.mod(query.modified_distance_moved, 26.0)), variable.leftarmswim_amount)",
                  "math.lerp(this, 180.0, variable.leftarmswim_amount)",
                  "math.lerp(this, 180.0, variable.leftarmswim_amount)"
                ],
                "pre": [
                  "math.lerp(this, 11.25 * math.mod(query.modified_distance_moved, 26.0), variable.leftarmswim_amount)",
                  "math.lerp(this, 180.0, variable.leftarmswim_amount)",
                  "math.lerp(this, 72.77 + 13.4 * math.mod(query.modified_distance_moved, 26.0), variable.leftarmswim_amount)"
                ]
              }
            }
          },
          "leftleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.modified_distance_moved * 19.5 + 180.0) * 17.2, variable.leftarmswim_amount) - this",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": {
              "0.7": {
                "post": [
                  "math.lerp(this, 11.25 * math.mod(query.modified_distance_moved, 26.0), variable.rightarmswim_amount)",
                  "math.lerp(this, 180.0, variable.rightarmswim_amount)",
                  "math.lerp(this, 72.77 + 13.4 * math.mod(query.modified_distance_moved, 26.0), variable.rightarmswim_amount)"
                ],
                "pre": [
                  "math.lerp(this, 0.0, variable.rightarmswim_amount)",
                  "math.lerp(this, 180.0, variable.rightarmswim_amount)",
                  "math.lerp(this, -0.1019 * (-65.0 * math.mod(query.modified_distance_moved, 26.0) + math.mod(query.modified_distance_moved, 26.0) * math.mod(query.modified_distance_moved, 26.0)), variable.rightarmswim_amount)"
                ]
              },
              "1.1": [
                "math.lerp(this, 11.25 * math.mod(query.modified_distance_moved, 26.0), variable.rightarmswim_amount)",
                "math.lerp(this, 180.0, variable.rightarmswim_amount)",
                "math.lerp(this, 72.77 + 13.4 * math.mod(query.modified_distance_moved, 26.0), variable.rightarmswim_amount)"
              ],
              "1.3": {
                "post": [
                  "math.lerp(this, 90.0 - (22.5 * math.mod(query.modified_distance_moved, 26.0)), variable.rightarmswim_amount)",
                  "math.lerp(this, 180.0, variable.rightarmswim_amount)",
                  "math.lerp(this, 180.0, variable.rightarmswim_amount)"
                ],
                "pre": [
                  "math.lerp(this, 11.25 * math.mod(query.modified_distance_moved, 26.0), variable.rightarmswim_amount)",
                  "math.lerp(this, 180.0, variable.rightarmswim_amount)",
                  "math.lerp(this, 72.77 + 13.4 * math.mod(query.modified_distance_moved, 26.0), variable.rightarmswim_amount)"
                ]
              }
            }
          },
          "rightleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.modified_distance_moved * 19.5) * 17.2, variable.leftarmswim_amount) - this",
              0,
              0
            ]
          }
        }
      },
      "use_item_progress": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "variable.use_item_startup_progress * -60.0 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -22.5 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -5.625 + variable.use_item_interval_progress * 11.25"
            ]
          }
        }
      },
      "vex_move": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leftwing": {
            "position": [0, "-1.0 - this", "2.0 - this"],
            "rotation": [
              "27.0 - this",
              "-(27.0 + math.cos(query.life_time * 916.8) * 9.0) - this",
              "-27.0 - this"
            ]
          },
          "leg0": {"rotation": ["variable.tcos0 * 1.4 + 36.0", 0, 0]},
          "rightarm": {
            "rotation": ["query.is_charging ? (216.0 - this) : 0.0", 0, 0]
          },
          "rightwing": {
            "position": [0, "-1.0 - this", "2.0 - this"],
            "rotation": [
              "27.0 - this",
              "27.0 + math.cos(query.life_time * 916.8) * 9.0 - this",
              "27.0 - this"
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "look_at_target": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["look_at_target_default"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"swimming": "query.is_swimming"}
            ]
          },
          "gliding": {
            "animations": ["look_at_target_gliding"],
            "transitions": [
              {"swimming": "query.is_swimming"},
              {"default": "!query.is_gliding"}
            ]
          },
          "swimming": {
            "animations": ["look_at_target_swimming"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"default": "!query.is_swimming"}
            ]
          }
        }
      },
      "move": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move"]}}
      },
      "riding": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"riding": "query.is_riding"}]},
          "riding": {
            "animations": ["riding.arms", "riding.legs"],
            "transitions": [{"default": "!query.is_riding"}]
          }
        }
      },
      "holding": {
        "initial_state": "default",
        "states": {"default": {"animations": ["holding"]}}
      },
      "brandish_spear": {
        "initial_state": "default",
        "states": {
          "brandish_spear": {
            "animations": ["brandish_spear"],
            "transitions": [{"default": "!variable.is_brandishing_spear"}]
          },
          "default": {
            "transitions": [{"brandish_spear": "variable.is_brandishing_spear"}]
          }
        }
      },
      "charging": {
        "initial_state": "default",
        "states": {
          "charging": {
            "animations": ["charging"],
            "transitions": [{"default": "!query.is_charging"}]
          },
          "default": {"transitions": [{"charging": "query.is_charging"}]}
        }
      },
      "attack": {
        "initial_state": "default",
        "states": {
          "attacking": {
            "animations": ["attack.rotations"],
            "transitions": [{"default": "variable.attack_time < 0.0"}]
          },
          "default": {
            "transitions": [{"attacking": "variable.attack_time >= 0.0"}]
          }
        }
      },
      "sneaking": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"sneaking": "query.is_sneaking"}]},
          "sneaking": {
            "animations": ["sneaking"],
            "transitions": [{"default": "!query.is_sneaking"}]
          }
        }
      },
      "bob": {
        "initial_state": "default",
        "states": {"default": {"animations": ["bob"]}}
      },
      "damage_nearby_mobs": {
        "initial_state": "default",
        "states": {
          "damage_nearby_mobs": {
            "animations": ["damage_nearby_mobs"],
            "transitions": [{"default": "!variable.damage_nearby_mobs"}]
          },
          "default": {
            "transitions": [
              {"damage_nearby_mobs": "variable.damage_nearby_mobs"}
            ]
          }
        }
      },
      "bow_and_arrow": {
        "initial_state": "default",
        "states": {
          "bow_and_arrow": {
            "animations": ["bow_and_arrow"],
            "transitions": [{"default": "!query.has_target"}]
          },
          "default": {"transitions": [{"bow_and_arrow": "query.has_target"}]}
        }
      },
      "swimming": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [{"swimming": "variable.swim_amount > 0.0"}]
          },
          "swimming": {
            "animations": ["swimming"],
            "transitions": [{"default": "variable.swim_amount <= 0.0"}]
          }
        }
      },
      "use_item_progress": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [
              {
                "use_item_progress": "( variable.use_item_interval_progress > 0.0 ) || ( variable.use_item_startup_progress > 0.0 )"
              }
            ]
          },
          "use_item_progress": {
            "animations": ["use_item_progress"],
            "transitions": [
              {
                "default": "( variable.use_item_interval_progress <= 0.0 ) && ( variable.use_item_startup_progress <= 0.0 )"
              }
            ]
          }
        }
      },
      "vex_move": {
        "initial_state": "default",
        "states": {"default": {"animations": ["vex_move"]}}
      }
    },
    "render_controllers": ["controller.render.vex"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 41}
  },
  "villager": {
    "identifier": "minecraft:villager",
    "min_engine_version": "1.8.0",
    "materials": {"default": "villager"},
    "textures": {
      "farmer": "textures/entity/villager/profession/farmer",
      "librarian": "textures/entity/villager/profession/librarian",
      "priest": "textures/entity/villager/profession/cleric",
      "smith": "textures/entity/villager/profession/weaponsmith",
      "butcher": "textures/entity/villager/profession/butcher"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 2.5,
        "visible_bounds_offset": [0, 1.25, 0],
        "bones": [
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 24, -4], "size": [8, 10, 8], "uv": [0, 0]}
            ]
          },
          {
            "name": "nose",
            "parent": "head",
            "pivot": [0, 26, 0],
            "cubes": [
              {"origin": [-1, 23, -6], "size": [2, 4, 2], "uv": [24, 0]}
            ]
          },
          {
            "name": "body",
            "cubes": [
              {"origin": [-4, 12, -3], "size": [8, 12, 6], "uv": [16, 20]},
              {
                "origin": [-4, 6, -3],
                "size": [8, 18, 6],
                "uv": [0, 38],
                "inflate": 0.5
              }
            ]
          },
          {
            "name": "arms",
            "parent": "body",
            "pivot": [0, 22, 0],
            "cubes": [
              {"origin": [-4, 16, -2], "size": [8, 4, 4], "uv": [40, 38]},
              {"origin": [-8, 16, -2], "size": [4, 8, 4], "uv": [44, 22]},
              {"origin": [4, 16, -2], "size": [4, 8, 4], "uv": [44, 22]}
            ]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-2, 12, 0],
            "cubes": [
              {"origin": [-4, 0, -2], "size": [4, 12, 4], "uv": [0, 22]}
            ]
          },
          {
            "name": "leg1",
            "parent": "body",
            "pivot": [2, 12, 0],
            "cubes": [{"origin": [0, 0, -2], "size": [4, 12, 4], "uv": [0, 22]}]
          }
        ]
      }
    },
    "scripts": {"scale": "0.9375"},
    "animations": {
      "general": {
        "loop": true,
        "bones": {
          "arms": {"position": [0, -1, -1], "rotation": ["-42.97 - this", 0, 0]}
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "move": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": [
              "(math.cos(query.anim_time * 38.17) * 40.0) - this",
              "-this",
              0
            ]
          },
          "leg1": {
            "rotation": [
              "(math.cos(query.anim_time * 38.17 + 180) * 40.0) - this",
              "-this",
              0
            ]
          }
        }
      },
      "baby_transform": {"loop": true, "bones": {"head": {"scale": 1.5}}}
    },
    "animation_controllers": {
      "general": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["general", {"look_at_target": "!query.is_sleeping"}]
          }
        }
      },
      "move": {
        "initial_state": "default",
        "states": {
          "default": {"animations": [{"move": "query.modified_move_speed"}]}
        }
      },
      "baby": {
        "initial_state": "baby",
        "states": {
          "baby": {"animations": [{"baby_transform": "query.is_baby"}]}
        }
      }
    },
    "render_controllers": ["controller.render.villager"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 14}
  },
  "vindicator": {
    "identifier": "minecraft:vindicator",
    "min_engine_version": "1.8.0",
    "materials": {"default": "vindicator"},
    "textures": {"default": "textures/entity/illager/vindicator"},
    "geometry": {
      "default": {
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 2.5,
        "visible_bounds_offset": [0, 1.25, 0],
        "texturewidth": 64,
        "textureheight": 64,
        "bones": [
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 24, -4], "size": [8, 10, 8], "uv": [0, 0]}
            ]
          },
          {
            "name": "nose",
            "parent": "head",
            "pivot": [0, 26, 0],
            "cubes": [
              {"origin": [-1, 23, -6], "size": [2, 4, 2], "uv": [24, 0]}
            ]
          },
          {
            "name": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 12, -3], "size": [8, 12, 6], "uv": [16, 20]},
              {
                "origin": [-4, 6, -3],
                "size": [8, 18, 6],
                "uv": [0, 38],
                "inflate": 0.5
              }
            ]
          },
          {
            "name": "arms",
            "parent": "body",
            "pivot": [0, 22, 0],
            "cubes": [
              {"origin": [-8, 16, -2], "size": [4, 8, 4], "uv": [44, 22]},
              {"origin": [4, 16, -2], "size": [4, 8, 4], "uv": [44, 22]},
              {"origin": [-4, 16, -2], "size": [8, 4, 4], "uv": [40, 38]}
            ]
          },
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-2, 12, 0],
            "cubes": [
              {"origin": [-4, 0, -2], "size": [4, 12, 4], "uv": [0, 22]}
            ]
          },
          {
            "name": "leg1",
            "parent": "body",
            "pivot": [2, 12, 0],
            "mirror": true,
            "cubes": [{"origin": [0, 0, -2], "size": [4, 12, 4], "uv": [0, 22]}]
          },
          {
            "name": "rightArm",
            "parent": "body",
            "pivot": [-5, 22, 0],
            "cubes": [
              {"origin": [-8, 12, -2], "size": [4, 12, 4], "uv": [40, 46]}
            ]
          },
          {
            "name": "rightItem",
            "pivot": [-5.5, 16, 0.5],
            "neverRender": true,
            "parent": "rightArm"
          },
          {
            "name": "leftArm",
            "parent": "body",
            "pivot": [5, 22, 0],
            "mirror": true,
            "cubes": [
              {"origin": [4, 12, -2], "size": [4, 12, 4], "uv": [40, 46]}
            ]
          }
        ]
      }
    },
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 39},
    "scripts": {
      "scale": "0.9375",
      "animate": [
        "vindicator_base",
        "vindicator_walk",
        "controller_look_at_target",
        "controller_vindicator_base",
        "controller_riding"
      ]
    },
    "animations": {
      "look_at_target_default": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_gliding": {
        "loop": true,
        "bones": {"head": {"rotation": [-45, "query.target_y_rotation", 0]}}
      },
      "look_at_target_swimming": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "math.lerp(query.target_x_rotation, -45.0, variable.swim_amount)",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "riding.arms": {
        "loop": true,
        "bones": {"leftarm": {"rotation": [-36, 0, 0]}}
      },
      "riding.legs": {
        "loop": true,
        "bones": {
          "leg0": {"rotation": ["-72.0 - this", "18.0 - this", "-this"]},
          "leg1": {"rotation": ["-72.0 - this", "-18.0 - this", "-this"]}
        }
      },
      "vindicator_base": {
        "loop": true,
        "bones": {
          "arms": {
            "position": [0, "-3.0 - this", "-1.0 - this"],
            "rotation": ["-42.97 - this", 0, 0]
          },
          "leftarm": {
            "rotation": [
              "math.cos(query.life_time * 20.0 * 3.84) * 2.87",
              -9,
              "-1 * (math.cos(query.life_time * 20.0 * 5.16) * 2.87 + 2.87)"
            ]
          },
          "rightarm": {
            "rotation": [
              "math.cos(query.life_time * 20.0 * 3.84) * 2.87",
              9,
              "math.cos(query.life_time * 20.0 * 5.16) * 2.87 + 2.87"
            ]
          }
        }
      },
      "vindicator_attack": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "query.is_riding ? 0.0 : ((math.cos(query.life_time * 20.0 * 10.89) * 28.65) + (math.sin(variable.attack_time * 180.0) * 68.76 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0)) * 22.92))",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "(-108 + math.cos(query.life_time * 20.0 * 3.84) * 2.87) + (math.sin(variable.attack_time * 180.0) * 126.05 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0)) * 22.92)",
              0,
              0
            ]
          }
        }
      },
      "vindicator_hand_attack": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "(-108 + math.cos(query.life_time * 20.0 * 3.84) * 2.87) + (math.sin(variable.attack_time * 180.0) * 126.05 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0)) * 22.92)",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "(-108 + math.cos(query.life_time * 20.0 * 3.84) * 2.87) + (math.sin(variable.attack_time * 180.0) * 126.05 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0)) * 22.92)",
              0,
              0
            ]
          }
        }
      },
      "vindicator_walk": {
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": [
              "(math.cos(query.modified_distance_moved * 38.17) * 80.21) * query.modified_move_speed * 0.5",
              0,
              0
            ]
          },
          "leg1": {
            "rotation": [
              "(math.cos(query.modified_distance_moved * 38.17 + 180) * 80.21) * query.modified_move_speed * 0.5",
              0,
              0
            ]
          }
        }
      },
      "celebrating": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "(math.cos(query.life_time * 800.0) * 2.865)",
              180,
              -135
            ]
          },
          "rightarm": {
            "rotation": [
              "(math.cos(query.life_time * 800.0) * 2.865)",
              180,
              153
            ]
          }
        }
      }
    },
    "render_controllers": ["controller.render.vindicator"],
    "enable_attachables": true,
    "animation_controllers": {
      "controller_look_at_target": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["look_at_target_default"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"swimming": "query.is_swimming"}
            ]
          },
          "gliding": {
            "animations": ["look_at_target_gliding"],
            "transitions": [
              {"swimming": "query.is_swimming"},
              {"default": "!query.is_gliding"}
            ]
          },
          "swimming": {
            "animations": ["look_at_target_swimming"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"default": "!query.is_swimming"}
            ]
          }
        }
      },
      "controller_vindicator_base": {
        "initial_state": "default",
        "states": {
          "default": {
            "blend_transition": 0.2,
            "blend_via_shortest_path": true,
            "transitions": [
              {"celebrating": "query.is_celebrating"},
              {
                "hand_attack": "!query.is_item_equipped && variable.has_target && variable.attack_time >= 0.0"
              },
              {
                "melee_attack": "query.is_item_equipped && variable.has_target && variable.attack_time >= 0.0"
              }
            ]
          },
          "hand_attack": {
            "blend_transition": 0.2,
            "blend_via_shortest_path": true,
            "animations": ["vindicator_hand_attack"],
            "transitions": [
              {
                "default": "query.is_item_equipped || !variable.has_target || variable.attack_time < 0.0 || query.is_celebrating"
              }
            ]
          },
          "melee_attack": {
            "blend_transition": 0.2,
            "blend_via_shortest_path": true,
            "animations": ["vindicator_attack"],
            "transitions": [
              {
                "default": "!query.is_item_equipped || !variable.has_target || variable.attack_time < 0.0 || query.is_celebrating"
              }
            ]
          },
          "celebrating": {
            "animations": ["celebrating"],
            "blend_transition": 0.2,
            "blend_via_shortest_path": true,
            "transitions": [{"default": "!query.is_celebrating"}]
          }
        }
      },
      "controller_riding": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"riding": "query.is_riding"}]},
          "riding": {
            "animations": ["riding.arms", "riding.legs"],
            "transitions": [{"default": "!query.is_riding"}]
          }
        }
      }
    }
  },
  "wandering_trader": {
    "identifier": "minecraft:wandering_trader",
    "materials": {"default": "wandering_trader"},
    "textures": {"default": "textures/entity/wandering_trader"},
    "geometry": {
      "default": {
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 2.5,
        "visible_bounds_offset": [0, 1.25, 0],
        "bones": [
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 24, -4], "size": [8, 10, 8], "uv": [0, 0]}
            ]
          },
          {
            "name": "helmet",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-4, 24, -4],
                "size": [8, 10, 8],
                "uv": [32, 0],
                "inflate": 0.5
              }
            ]
          },
          {
            "name": "brim",
            "parent": "head",
            "pivot": [0, 24, 0],
            "bind_pose_rotation": [-90, 0, 0],
            "cubes": [
              {
                "origin": [-8, 16, -6],
                "size": [16, 16, 1],
                "uv": [30, 47],
                "inflate": 0.1
              }
            ]
          },
          {
            "name": "nose",
            "parent": "head",
            "pivot": [0, 26, 0],
            "cubes": [
              {"origin": [-1, 23, -6], "size": [2, 4, 2], "uv": [24, 0]}
            ]
          },
          {
            "name": "body",
            "locators": {"lead_hold": [0, 40, 0]},
            "cubes": [
              {"origin": [-4, 12, -3], "size": [8, 12, 6], "uv": [16, 20]},
              {
                "origin": [-4, 6, -3],
                "size": [8, 18, 6],
                "uv": [0, 38],
                "inflate": 0.5
              }
            ]
          },
          {
            "name": "arms",
            "parent": "body",
            "pivot": [0, 22, 0],
            "cubes": [
              {"origin": [-4, 16, -2], "size": [8, 4, 4], "uv": [40, 38]},
              {"origin": [-8, 16, -2], "size": [4, 8, 4], "uv": [44, 22]},
              {
                "origin": [4, 16, -2],
                "size": [4, 8, 4],
                "uv": [44, 22],
                "mirror": true
              }
            ]
          },
          {"name": "held_item", "parent": "arms", "pivot": [0, 0, 0]},
          {
            "name": "leg0",
            "parent": "body",
            "pivot": [-2, 12, 0],
            "cubes": [
              {"origin": [-4, 0, -2], "size": [4, 12, 4], "uv": [0, 22]}
            ]
          },
          {
            "name": "leg1",
            "parent": "body",
            "pivot": [2, 12, 0],
            "cubes": [
              {
                "origin": [0, 0, -2],
                "size": [4, 12, 4],
                "uv": [0, 22],
                "mirror": true
              }
            ]
          }
        ]
      }
    },
    "scripts": {"scale": "0.9375"},
    "animations": {
      "general": {
        "loop": true,
        "bones": {
          "arms": {"position": [0, -1, -1], "rotation": ["-42.97 - this", 0, 0]}
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "raise_arms": {
        "loop": true,
        "bones": {"arms": {"rotation": ["variable.raise_arms * -15.0", 0, 0]}}
      },
      "move": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": [
              "(math.cos(query.anim_time * 38.17) * 40.0) - this",
              "-this",
              0
            ]
          },
          "leg1": {
            "rotation": [
              "(math.cos(query.anim_time * 38.17 + 180) * 40.0) - this",
              "-this",
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "general": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["general", {"look_at_target": "!query.is_sleeping"}]
          }
        }
      },
      "raise_arms": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [{"raising": "variable.raise_arms > 0.0"}]
          },
          "raising": {
            "animations": ["raise_arms"],
            "transitions": [{"default": "variable.raise_arms == 0.0"}]
          }
        }
      },
      "move": {
        "initial_state": "default",
        "states": {
          "default": {"animations": [{"move": "query.modified_move_speed"}]}
        }
      }
    },
    "render_controllers": ["controller.render.wandering_trader"],
    "spawn_egg": {"texture": "spawn_egg_wandering_trader"}
  },
  "witch": {
    "identifier": "minecraft:witch",
    "min_engine_version": "1.8.0",
    "materials": {"default": "witch"},
    "textures": {"default": "textures/entity/witch"},
    "geometry": {
      "default": {
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 3,
        "visible_bounds_offset": [0, 1.5, 0],
        "texturewidth": 64,
        "textureheight": 128,
        "bones": [
          {
            "name": "nose",
            "parent": "head",
            "cubes": [
              {
                "origin": [0, 25, -6.75],
                "size": [1, 1, 1],
                "uv": [0, 0],
                "inflate": -0.25
              }
            ]
          },
          {
            "name": "hat",
            "parent": "head",
            "pivot": [-5, 32.03125, -5],
            "cubes": [
              {"origin": [-5, 32.05, -5], "size": [10, 2, 10], "uv": [0, 64]}
            ]
          },
          {
            "name": "hat2",
            "parent": "hat",
            "pivot": [1.75, 32, 2],
            "cubes": [
              {"origin": [-3.25, 33.5, -3], "size": [7, 4, 7], "uv": [0, 76]}
            ],
            "rotation": [-3, 0, 1.5]
          },
          {
            "name": "hat3",
            "parent": "hat2",
            "pivot": [1.75, 35, 2],
            "cubes": [
              {"origin": [-1.5, 36.5, -1], "size": [4, 4, 4], "uv": [0, 87]}
            ],
            "rotation": [-6, 0, 3]
          },
          {
            "name": "hat4",
            "parent": "hat3",
            "pivot": [1.75, 38, 2],
            "cubes": [
              {
                "origin": [0.25, 40, 1],
                "size": [1, 2, 1],
                "uv": [0, 95],
                "inflate": 0.25
              }
            ],
            "rotation": [-12, 0, 6]
          }
        ]
      }
    },
    "scripts": {"scale": "0.9375"},
    "animations": {
      "villager_general": {
        "loop": true,
        "bones": {
          "arms": {"position": [0, -1, -1], "rotation": ["-42.97 - this", 0, 0]}
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "move": {
        "anim_time_update": "query.modified_distance_moved",
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": [
              "(math.cos(query.anim_time * 38.17) * 40.0) - this",
              "-this",
              0
            ]
          },
          "leg1": {
            "rotation": [
              "(math.cos(query.anim_time * 38.17 + 180) * 40.0) - this",
              "-this",
              0
            ]
          }
        }
      },
      "general": {
        "loop": true,
        "bones": {
          "nose": {
            "rotation": [
              "(variable.isholdingitem ? -25.7831 : (math.sin(query.life_time * 57.296) * 4.5)) - this",
              0,
              "(math.cos(query.life_time * 57.296) * 2.5) - this"
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "general": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["villager_general", "look_at_target", "general"]
          }
        }
      },
      "move": {
        "initial_state": "default",
        "states": {
          "default": {"animations": [{"move": "query.modified_move_speed"}]}
        }
      }
    },
    "render_controllers": ["controller.render.witch"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 17}
  },
  "wither": {
    "identifier": "minecraft:wither",
    "min_engine_version": "1.8.0",
    "materials": {"default": "wither_boss", "armor": "wither_boss_armor"},
    "textures": {
      "default": "textures/entity/wither/wither",
      "armor_white": "textures/entity/wither/wither_armor",
      "armor_blue": "textures/entity/wither/wither_armor",
      "invulnerable": "textures/entity/wither/wither_invulnerable"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 3,
        "visible_bounds_height": 4,
        "visible_bounds_offset": [0, 2, 0],
        "texturewidth": 64,
        "textureheight": 64,
        "bones": [
          {
            "name": "upperBodyPart1",
            "cubes": [
              {"origin": [-10, 17.1, -0.5], "size": [20, 3, 3], "uv": [0, 16]}
            ]
          },
          {
            "name": "upperBodyPart2",
            "parent": "upperBodyPart1",
            "pivot": [-2, 17.1, -0.5],
            "cubes": [
              {"origin": [-2, 7.1, -0.5], "size": [3, 10, 3], "uv": [0, 22]},
              {"origin": [-6, 13.6, 0], "size": [11, 2, 2], "uv": [24, 22]},
              {"origin": [-6, 11.1, 0], "size": [11, 2, 2], "uv": [24, 22]},
              {"origin": [-6, 8.6, 0], "size": [11, 2, 2], "uv": [24, 22]}
            ]
          },
          {
            "name": "upperBodyPart3",
            "parent": "upperBodyPart2",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [0, 18, 0], "size": [3, 6, 3], "uv": [12, 22]}]
          },
          {
            "name": "head1",
            "parent": "upperBodyPart1",
            "pivot": [0, 20, 0],
            "cubes": [{"origin": [-4, 20, -4], "size": [8, 8, 8], "uv": [0, 0]}]
          },
          {
            "name": "head2",
            "parent": "upperBodyPart1",
            "pivot": [-9, 18, -1],
            "cubes": [
              {"origin": [-12, 18, -4], "size": [6, 6, 6], "uv": [32, 0]}
            ]
          },
          {
            "name": "head3",
            "parent": "upperBodyPart1",
            "pivot": [9, 18, -1],
            "cubes": [{"origin": [6, 18, -4], "size": [6, 6, 6], "uv": [32, 0]}]
          }
        ]
      },
      "armor": {
        "visible_bounds_width": 3,
        "visible_bounds_height": 4,
        "visible_bounds_offset": [0, 2, 0],
        "texturewidth": 64,
        "textureheight": 64,
        "bones": [
          {
            "name": "upperBodyPart1",
            "cubes": [
              {"origin": [-10, 17.1, -0.5], "size": [20, 3, 3], "uv": [0, 16]}
            ],
            "inflate": 2
          },
          {
            "name": "upperBodyPart2",
            "parent": "upperBodyPart1",
            "pivot": [-2, 17.1, -0.5],
            "cubes": [
              {"origin": [-2, 7.1, -0.5], "size": [3, 10, 3], "uv": [0, 22]},
              {"origin": [-6, 13.6, 0], "size": [11, 2, 2], "uv": [24, 22]},
              {"origin": [-6, 11.1, 0], "size": [11, 2, 2], "uv": [24, 22]},
              {"origin": [-6, 8.6, 0], "size": [11, 2, 2], "uv": [24, 22]}
            ],
            "inflate": 2
          },
          {
            "name": "upperBodyPart3",
            "parent": "upperBodyPart2",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [0, 18, 0], "size": [3, 6, 3], "uv": [12, 22]}
            ],
            "inflate": 2
          },
          {
            "name": "head1",
            "parent": "upperBodyPart1",
            "pivot": [0, 20, 0],
            "cubes": [
              {"origin": [-4, 20, -4], "size": [8, 8, 8], "uv": [0, 0]}
            ],
            "inflate": 2
          },
          {
            "name": "head2",
            "parent": "upperBodyPart1",
            "pivot": [-9, 18, -1],
            "cubes": [
              {"origin": [-12, 18, -4], "size": [6, 6, 6], "uv": [32, 0]}
            ],
            "inflate": 2
          },
          {
            "name": "head3",
            "parent": "upperBodyPart1",
            "pivot": [9, 18, -1],
            "cubes": [
              {"origin": [6, 18, -4], "size": [6, 6, 6], "uv": [32, 0]}
            ],
            "inflate": 2
          }
        ]
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.base_scale = 2;",
        "variable.swell_clamped = Math.clamp(query.swell_amount, 0.0, 1.0);",
        "variable.wobble = 1.0 + Math.sin(query.swell_amount * 5730) * query.swell_amount * 0.01;",
        "variable.swell_adjustment = Math.pow(variable.swell_clamped, 4);",
        "variable.scale_xz = (1.0 + variable.swell_adjustment * 0.4) * variable.wobble;",
        "variable.scale_y = (1.0 + variable.swell_adjustment * 0.1) / variable.wobble;",
        "variable.body_base_rotation = Math.cos(query.life_time * 114.6);",
        "variable.upper_body_rotation = (0.065 + 0.05 * variable.body_base_rotation) * 180 + query.target_x_rotation;",
        "variable.is_invulnerable = query.invulnerable_ticks > 0.0;",
        "variable.display_normal_skin = (query.invulnerable_ticks <= 0) || ((query.invulnerable_ticks <= 80) && (Math.mod(query.invulnerable_ticks / 5, 2) == 1));"
      ],
      "scalex": "variable.scale_xz * variable.base_scale",
      "scaley": "variable.scale_y * variable.base_scale",
      "scalez": "variable.scale_xz * variable.base_scale"
    },
    "animations": {
      "scale": {
        "loop": true,
        "bones": {"upperbodypart1": {"rotation": ["-this", "-this", "-this"]}}
      },
      "move": {
        "loop": true,
        "bones": {
          "upperbodypart2": {
            "rotation": [
              "variable.upper_body_rotation - this",
              "-this",
              "-this"
            ]
          },
          "upperbodypart3": {
            "position": [-2, -16.9, -0.5],
            "rotation": [
              "((0.2 + 0.1 * variable.body_base_rotation) * 180) - this",
              "-this",
              "-this"
            ]
          }
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head1": {
            "rotation": [
              "query.head_x_rotation(0) - this",
              "query.head_y_rotation(0) - this",
              "-this"
            ]
          },
          "head2": {
            "rotation": [
              "query.head_x_rotation(1) - this",
              "query.head_y_rotation(1) - this",
              "-this"
            ]
          },
          "head3": {
            "rotation": [
              "query.head_x_rotation(2) - this",
              "query.head_y_rotation(2) - this",
              "-this"
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "move": {
        "initial_state": "default",
        "states": {
          "default": {"animations": ["scale", "move", "look_at_target"]}
        }
      }
    },
    "render_controllers": [
      "controller.render.wither_boss",
      "controller.render.wither_boss_armor_white",
      "controller.render.wither_boss_armor_blue"
    ]
  },
  "wither_skeleton": {
    "identifier": "minecraft:wither_skeleton",
    "min_engine_version": "1.8.0",
    "materials": {"default": "skeleton"},
    "textures": {"default": "textures/entity/skeleton/wither_skeleton"},
    "geometry": {
      "default": {
        "texturewidth": 64,
        "textureheight": 32,
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 3,
        "visible_bounds_offset": [0, 1.5, 0],
        "bones": [
          {
            "name": "body",
            "parent": "waist",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 12, -2], "size": [8, 12, 4], "uv": [16, 16]}
            ]
          },
          {"name": "waist", "pivot": [0, 12, 0]},
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [{"origin": [-4, 24, -4], "size": [8, 8, 8], "uv": [0, 0]}]
          },
          {
            "name": "hat",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-4, 24, -4],
                "size": [8, 8, 8],
                "uv": [32, 0],
                "inflate": 0.5
              }
            ],
            "neverRender": true
          },
          {
            "name": "rightArm",
            "parent": "body",
            "pivot": [-5, 22, 0],
            "cubes": [
              {"origin": [-6, 12, -1], "size": [2, 12, 2], "uv": [40, 16]}
            ]
          },
          {
            "name": "rightItem",
            "parent": "rightArm",
            "pivot": [-5, 15, 1],
            "neverRender": true
          },
          {
            "name": "leftArm",
            "parent": "body",
            "pivot": [5, 22, 0],
            "cubes": [
              {"origin": [4, 12, -1], "size": [2, 12, 2], "uv": [40, 16]}
            ],
            "mirror": true
          },
          {
            "name": "leftItem",
            "parent": "leftArm",
            "pivot": [6, 15, 1],
            "neverRender": true
          },
          {
            "name": "rightLeg",
            "parent": "body",
            "pivot": [-2, 12, 0],
            "cubes": [
              {"origin": [-3, 0, -1], "size": [2, 12, 2], "uv": [0, 16]}
            ]
          },
          {
            "name": "leftLeg",
            "parent": "body",
            "pivot": [2, 12, 0],
            "cubes": [
              {"origin": [1, 0, -1], "size": [2, 12, 2], "uv": [0, 16]}
            ],
            "mirror": true
          }
        ]
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.tcos0 = (Math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed / variable.gliding_speed_value) * 57.3;"
      ]
    },
    "animations": {
      "look_at_target_default": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_gliding": {
        "loop": true,
        "bones": {"head": {"rotation": [-45, "query.target_y_rotation", 0]}}
      },
      "look_at_target_swimming": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "math.lerp(query.target_x_rotation, -45.0, variable.swim_amount)",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "move": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["variable.tcos0", 0, 0]},
          "leftleg": {"rotation": ["variable.tcos0 * -1.4", 0, 0]},
          "rightarm": {"rotation": ["-variable.tcos0", 0, 0]},
          "rightleg": {"rotation": ["variable.tcos0 * 1.4", 0, 0]}
        }
      },
      "riding.arms": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": [-36, 0, 0]},
          "rightarm": {"rotation": [-36, 0, 0]}
        }
      },
      "riding.legs": {
        "loop": true,
        "bones": {
          "leftleg": {"rotation": ["-72.0 - this", "-18.0 - this", "-this"]},
          "rightleg": {"rotation": ["-72.0 - this", "18.0 - this", "-this"]}
        }
      },
      "holding": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "variable.is_holding_left ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "variable.is_holding_right ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          }
        }
      },
      "brandish_spear": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "this * -0.5 - 157.5 - 22.5 * variable.charge_amount",
              "-this",
              0
            ]
          }
        }
      },
      "charging": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": ["22.5 * variable.charge_amount - this", "-this", 0]
          }
        }
      },
      "attack.rotations": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              0,
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46 - this",
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.sin(1.0 - math.pow(1.0 - variable.attack_time, 3.0) * 180.0) * (variable.is_brandishing_spear ? -1.0 : 1.0 )",
              "variable.is_brandishing_spear ? 0.0 : (math.sin(math.sqrt(variable.attack_time) * 360) * 11.46) * 2.0",
              0
            ]
          }
        }
      },
      "sneaking": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["0.5 - this", 0, 0]},
          "head": {"position": [0, 1, 0]},
          "leftarm": {"rotation": [72, 0, 0]},
          "leftleg": {"position": [0, -3, 4]},
          "rightarm": {"rotation": [72, 0, 0]},
          "rightleg": {"position": [0, -3, 4]}
        }
      },
      "bob": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              0,
              0,
              "((math.cos(query.life_time * 103.2) * 2.865) + 2.865) *-1.0"
            ]
          },
          "rightarm": {
            "rotation": [
              0,
              0,
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "damage_nearby_mobs": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["-45.0-this", "-this", "-this"]},
          "leftleg": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightarm": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-45.0-this", "-this", "-this"]}
        }
      },
      "bow_and_arrow": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "query.target_x_rotation - 90.0 - math.sin(query.life_time * 76.8) * 2.865 - this",
              "query.target_y_rotation + 28.65",
              "-(math.cos(query.life_time * 103.2) * 2.865) - 2.865"
            ]
          },
          "rightarm": {
            "rotation": [
              "query.target_x_rotation - 90.0 + math.sin(query.life_time * 76.8) * 2.865 - this",
              "query.target_y_rotation - 5.73",
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "use_item_progress": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "variable.use_item_startup_progress * -60.0 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -22.5 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -5.625 + variable.use_item_interval_progress * 11.25"
            ]
          }
        }
      },
      "wither_skeleton_attack": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "-90 - ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4) - (math.sin(query.life_time * 76.776372) * 2.865) - this",
              "5.73 - ((math.sin(variable.attack_time * 180.0) * 57.3) * 0.6) - this",
              "math.cos(query.life_time * 103.13244) * -2.865 - 2.865 - this"
            ]
          },
          "rightarm": {
            "rotation": [
              "-90 - ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4) + (math.sin(query.life_time * 76.776372) * 2.865) - this",
              "(math.sin(variable.attack_time * 180.0) * 57.3) * 0.6 - 5.73 - this",
              "math.cos(query.life_time * 103.13244) * 2.865 + 2.865 - this"
            ]
          }
        }
      },
      "swimming": {
        "loop": true,
        "bones": {
          "body": {
            "position": [
              0,
              "variable.swim_amount * -10.0 - this",
              "variable.swim_amount * 9.0 - this"
            ],
            "rotation": [
              "variable.swim_amount * (90.0 + query.target_x_rotation)",
              0,
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.lerp(this, -180.0, variable.swim_amount) - (variable.swim_amount * ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4)) - (variable.swim_amount * (math.sin(query.life_time * 76.776372) * 2.865)) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - (variable.swim_amount * (math.cos(query.life_time * 103.13244) * 2.865 + 2.865)) - this"
            ]
          },
          "leftleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.life_time * 390.0 + 180.0) * 0.3, variable.swim_amount)",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.lerp(this, -180.0, variable.swim_amount) - (variable.swim_amount * ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4)) + (variable.swim_amount * (math.sin(query.life_time * 76.776372) * 2.865)) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - this",
              "math.lerp(this, -14.325, variable.swim_amount) + (variable.swim_amount * (math.cos(query.life_time * 103.13244) * 2.865 + 2.865)) - this"
            ]
          },
          "rightleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.life_time * 390.0) * 0.3, variable.swim_amount)",
              0,
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "look_at_target": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["look_at_target_default"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"swimming": "query.is_swimming"}
            ]
          },
          "gliding": {
            "animations": ["look_at_target_gliding"],
            "transitions": [
              {"swimming": "query.is_swimming"},
              {"default": "!query.is_gliding"}
            ]
          },
          "swimming": {
            "animations": ["look_at_target_swimming"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"default": "!query.is_swimming"}
            ]
          }
        }
      },
      "move": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move"]}}
      },
      "riding": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"riding": "query.is_riding"}]},
          "riding": {
            "animations": ["riding.arms", "riding.legs"],
            "transitions": [{"default": "!query.is_riding"}]
          }
        }
      },
      "holding": {
        "initial_state": "default",
        "states": {"default": {"animations": ["holding"]}}
      },
      "brandish_spear": {
        "initial_state": "default",
        "states": {
          "brandish_spear": {
            "animations": ["brandish_spear"],
            "transitions": [{"default": "!variable.is_brandishing_spear"}]
          },
          "default": {
            "transitions": [{"brandish_spear": "variable.is_brandishing_spear"}]
          }
        }
      },
      "charging": {
        "initial_state": "default",
        "states": {
          "charging": {
            "animations": ["charging"],
            "transitions": [{"default": "!query.is_charging"}]
          },
          "default": {"transitions": [{"charging": "query.is_charging"}]}
        }
      },
      "attack": {
        "initial_state": "default",
        "states": {
          "attacking": {
            "animations": ["attack.rotations"],
            "transitions": [{"default": "variable.attack_time < 0.0"}]
          },
          "default": {
            "transitions": [{"attacking": "variable.attack_time >= 0.0"}]
          }
        }
      },
      "sneaking": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"sneaking": "query.is_sneaking"}]},
          "sneaking": {
            "animations": ["sneaking"],
            "transitions": [{"default": "!query.is_sneaking"}]
          }
        }
      },
      "damage_nearby_mobs": {
        "initial_state": "default",
        "states": {
          "damage_nearby_mobs": {
            "animations": ["damage_nearby_mobs"],
            "transitions": [{"default": "!variable.damage_nearby_mobs"}]
          },
          "default": {
            "transitions": [
              {"damage_nearby_mobs": "variable.damage_nearby_mobs"}
            ]
          }
        }
      },
      "bow_and_arrow": {
        "initial_state": "default",
        "states": {
          "bow_and_arrow": {
            "animations": ["bow_and_arrow"],
            "transitions": [{"default": "!query.has_target"}]
          },
          "default": {"transitions": [{"bow_and_arrow": "query.has_target"}]}
        }
      },
      "use_item_progress": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [
              {
                "use_item_progress": "( variable.use_item_interval_progress > 0.0 ) || ( variable.use_item_startup_progress > 0.0 )"
              }
            ]
          },
          "use_item_progress": {
            "animations": ["use_item_progress"],
            "transitions": [
              {
                "default": "( variable.use_item_interval_progress <= 0.0 ) && ( variable.use_item_startup_progress <= 0.0 )"
              }
            ]
          }
        }
      },
      "wither_skeleton_attack": {
        "initial_state": "default",
        "states": {
          "chase_target": {
            "animations": ["wither_skeleton_attack"],
            "transitions": [{"default": "!variable.has_target"}]
          },
          "default": {"transitions": [{"chase_target": "variable.has_target"}]}
        }
      },
      "swimming": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [{"is_swimming": "variable.swim_amount > 0.0"}]
          },
          "is_swimming": {
            "animations": ["swimming"],
            "transitions": [{"default": "variable.swim_amount <= 0.0"}]
          }
        }
      }
    },
    "render_controllers": ["controller.render.wither_skeleton"],
    "enable_attachables": true,
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 29}
  },
  "wither_skull": {
    "identifier": "minecraft:wither_skull",
    "materials": {"default": "wither_skull"},
    "textures": {"default": "textures/entity/wither/wither"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "head",
            "cubes": [{"origin": [-4, 0, -4], "size": [8, 8, 8], "uv": [0, 35]}]
          }
        ],
        "visible_bounds_width": 1,
        "visible_bounds_height": 1,
        "texturewidth": 64,
        "textureheight": 64
      }
    },
    "animations": {
      "move": {
        "loop": true,
        "bones": {"head": {"rotation": [0, "-query.target_y_rotation", 0]}}
      }
    },
    "scripts": {"animate": ["move"]},
    "render_controllers": ["controller.render.wither_skull"]
  },
  "wolf": {
    "identifier": "minecraft:wolf",
    "materials": {"default": "wolf"},
    "textures": {
      "default": "textures/entity/wolf/wolf",
      "angry": "textures/entity/wolf/wolf_angry",
      "tame": "textures/entity/wolf/wolf_tame"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 1,
        "visible_bounds_offset": [0, 0.5, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "head",
            "pivot": [-1, 10.5, -7],
            "locators": {"lead": [-1, 10.5, -7]},
            "cubes": [
              {"origin": [-4, 7.5, -9], "size": [6, 6, 4], "uv": [0, 0]},
              {"origin": [-4, 13.5, -7], "size": [2, 2, 1], "uv": [16, 14]},
              {"origin": [0, 13.5, -7], "size": [2, 2, 1], "uv": [16, 14]},
              {
                "origin": [-2.5, 7.515625, -12],
                "size": [3, 3, 4],
                "uv": [0, 10]
              }
            ]
          },
          {
            "name": "body",
            "pivot": [0, 10, 2],
            "cubes": [
              {"origin": [-4, 3, -1], "size": [6, 9, 6], "uv": [18, 14]}
            ]
          },
          {
            "name": "upperBody",
            "pivot": [-1, 10, 2],
            "cubes": [{"origin": [-5, 7, -1], "size": [8, 6, 7], "uv": [21, 0]}]
          },
          {
            "name": "leg0",
            "pivot": [-2.5, 8, 7],
            "cubes": [
              {"origin": [-3.5, 0, 6], "size": [2, 8, 2], "uv": [0, 18]}
            ]
          },
          {
            "name": "leg1",
            "pivot": [0.5, 8, 7],
            "cubes": [
              {"origin": [-0.5, 0, 6], "size": [2, 8, 2], "uv": [0, 18]}
            ]
          },
          {
            "name": "leg2",
            "pivot": [-2.5, 8, -4],
            "cubes": [
              {"origin": [-3.5, 0, -5], "size": [2, 8, 2], "uv": [0, 18]}
            ]
          },
          {
            "name": "leg3",
            "pivot": [0.5, 8, -4],
            "cubes": [
              {"origin": [-0.5, 0, -5], "size": [2, 8, 2], "uv": [0, 18]}
            ]
          },
          {
            "name": "tail",
            "pivot": [-1, 12, 8],
            "cubes": [{"origin": [-2, 4, 7], "size": [2, 8, 2], "uv": [9, 18]}]
          }
        ]
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.body_shake_angle = 0.05 * query.frame_alpha + query.shake_angle;",
        "variable.body_roll_progress = Math.clamp((variable.body_shake_angle - 0.16) / 1.8, 0, 1);",
        "variable.body_rot_z = Math.sin(variable.body_roll_progress * 180) * Math.sin(variable.body_roll_progress * 1980) * 27;",
        "variable.upper_body_roll_progress = Math.clamp((variable.body_shake_angle - 0.08) / 1.8, 0, 1);",
        "variable.upper_body_rot_z = (Math.sin(variable.upper_body_roll_progress * 180) * Math.sin(variable.upper_body_roll_progress * 1980) * 27) - variable.body_rot_z;",
        "variable.tail_roll_progress = Math.clamp((variable.body_shake_angle - 0.2) / 1.8, 0, 1);",
        "variable.tail_rot_z = (Math.sin(variable.tail_roll_progress * 180) * Math.sin(variable.tail_roll_progress * 1980) * 27) - variable.body_rot_z;",
        "variable.head_roll_progress = Math.clamp(variable.body_shake_angle / 1.8, 0, 1);",
        "variable.head_rot_z = (Math.sin(variable.head_roll_progress * 180) * Math.sin(variable.head_roll_progress * 1980) * 27) - variable.body_rot_z;"
      ]
    },
    "animations": {
      "wolf_setup": {
        "loop": true,
        "bones": {
          "body": {
            "position": ["-this", "-14 - this", "2.0 - this"],
            "rotation": ["90 - this", 0, 0]
          },
          "leg0": {"position": ["-2.5 - this", "-16 - this", "7 - this"]},
          "leg1": {"position": ["0.5 - this", "-16 - this", "7 - this"]},
          "leg2": {"position": ["-2.5 - this", "-16 - this", "-4 - this"]},
          "leg3": {"position": ["0.5 - this", "-16 - this", "-4 - this"]},
          "tail": {"position": ["-1.0 - this", "-12 - this", "8.0 - this"]},
          "upperbody": {
            "position": ["-1.0 - this", "-14 - this", "-3.0 - this"],
            "rotation": ["90 - this", 0, 0]
          }
        }
      },
      "wolf_baby_scaling": {
        "loop": true,
        "bones": {"head": {"position": [0, 1, -2], "scale": 1.6}}
      },
      "wolf_look_at": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation - this",
              "query.target_y_rotation - this",
              0
            ]
          }
        }
      },
      "wolf_head_rot_z": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              0,
              0,
              "(query.is_interested ? (query.head_roll_angle * 57.3) : 0) + (query.is_shaking_wetness ? variable.head_rot_z : 0) - this"
            ]
          }
        }
      },
      "wolf_tail_default": {
        "loop": true,
        "bones": {
          "tail": {
            "rotation": [
              "query.tail_angle * 57.3 - this",
              0,
              "variable.tail_rot_z - this"
            ]
          }
        }
      },
      "wolf_angry": {
        "loop": true,
        "bones": {
          "tail": {
            "rotation": [
              0,
              "query.is_angry ? -this : (math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed * 80.22 - this)",
              0
            ]
          }
        }
      },
      "wolf_sitting": {
        "loop": true,
        "bones": {
          "body": {
            "position": ["-this", "-18 - this", "-this"],
            "rotation": ["45.0 - this", 0, 0]
          },
          "leg0": {
            "position": ["-2.5 - this", "-22 - this", "2 - this"],
            "rotation": ["270 - this", 0, 0]
          },
          "leg1": {
            "position": ["0.5 - this", "-22 - this", "2 - this"],
            "rotation": ["270 - this", 0, 0]
          },
          "leg2": {
            "position": ["-2.49 - this", "-17 - this", "-4 - this"],
            "rotation": ["333 - this", 0, 0]
          },
          "leg3": {
            "position": ["0.51 - this", "-17 - this", "-4 - this"],
            "rotation": ["333 - this", 0, 0]
          },
          "tail": {"position": ["-1.0 - this", "-19 - this", "6.0 - this"]},
          "upperbody": {
            "position": ["-1.0 - this", "-16 - this", "-3.0 - this"],
            "rotation": ["72 - this", "-this", 0]
          }
        }
      },
      "wolf_shaking": {
        "loop": true,
        "bones": {
          "body": {"rotation": [0, 0, "variable.body_rot_z - this"]},
          "upperbody": {"rotation": [0, 0, "variable.upper_body_rot_z - this"]}
        }
      },
      "wolf_leg_default": {
        "loop": true,
        "bones": {
          "leg0": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17) * 80.22 * query.modified_move_speed - this",
              0,
              0
            ]
          },
          "leg1": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17 + 180) * 80.22 * query.modified_move_speed - this",
              0,
              0
            ]
          },
          "leg2": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17 + 180) * 80.22 * query.modified_move_speed - this",
              0,
              0
            ]
          },
          "leg3": {
            "rotation": [
              "math.cos(query.modified_distance_moved * 38.17) * 80.22 * query.modified_move_speed - this",
              0,
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "wolf_setup": {
        "initial_state": "default",
        "states": {"default": {"animations": ["wolf_setup"]}}
      },
      "wolf_look_at": {
        "initial_state": "default",
        "states": {"default": {"animations": ["wolf_look_at"]}}
      },
      "wolf_baby_scaling": {
        "initial_state": "default",
        "states": {
          "baby": {
            "animations": ["wolf_baby_scaling"],
            "transitions": [{"default": "!query.is_baby"}]
          },
          "default": {"transitions": [{"baby": "query.is_baby"}]}
        }
      },
      "wolf_head_rot_z": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [
              {"rot": "query.is_interested || query.is_shaking_wetness"}
            ]
          },
          "rot": {
            "animations": ["wolf_head_rot_z"],
            "transitions": [
              {"default": "!query.is_interested && !query.is_shaking_wetness"}
            ]
          }
        }
      },
      "wolf_tail_default": {
        "initial_state": "default",
        "states": {"default": {"animations": ["wolf_tail_default"]}}
      },
      "wolf_angry": {
        "initial_state": "default",
        "states": {"default": {"animations": ["wolf_angry"]}}
      },
      "wolf_sitting": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["wolf_leg_default"],
            "transitions": [{"sitting": "query.is_sitting"}]
          },
          "sitting": {
            "animations": ["wolf_sitting"],
            "transitions": [{"default": "!query.is_sitting"}]
          }
        }
      },
      "wolf_shaking": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"shaking": "query.is_shaking_wetness"}]},
          "shaking": {
            "animations": ["wolf_shaking"],
            "transitions": [{"default": "!query.is_shaking_wetness"}]
          }
        }
      }
    },
    "render_controllers": ["controller.render.wolf"],
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 4}
  },
  "zoglin": {
    "identifier": "minecraft:zoglin",
    "materials": {"default": "zoglin"},
    "textures": {"default": "textures/entity/hoglin/zoglin"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 19, -3],
            "cubes": [
              {
                "origin": [-8, 11, -7],
                "size": [16, 14, 26],
                "inflate": 0.02,
                "uv": [1, 1]
              },
              {
                "origin": [0, 22, -10],
                "size": [0, 10, 19],
                "inflate": 0.02,
                "uv": [90, 33]
              }
            ],
            "locators": {"lead": [0, 20, -5]}
          },
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 22, -5],
            "rotation": [50, 0, 0],
            "cubes": [
              {"origin": [-7, 21, -24], "size": [14, 6, 19], "uv": [61, 1]},
              {"origin": [-8, 22, -19], "size": [2, 11, 2], "uv": [1, 13]},
              {"origin": [6, 22, -19], "size": [2, 11, 2], "uv": [1, 13]}
            ]
          },
          {
            "name": "right_ear",
            "parent": "head",
            "pivot": [-7, 27, -7],
            "rotation": [0, 0, -50],
            "cubes": [
              {"origin": [-13, 26, -10], "size": [6, 1, 4], "uv": [1, 1]}
            ]
          },
          {
            "name": "left_ear",
            "parent": "head",
            "pivot": [7, 27, -7],
            "rotation": [0, 0, 50],
            "cubes": [{"origin": [7, 26, -10], "size": [6, 1, 4], "uv": [1, 6]}]
          },
          {
            "name": "leg_back_right",
            "pivot": [6, 8, 17],
            "cubes": [
              {"origin": [-8, 0, 13], "size": [5, 11, 5], "uv": [21, 45]}
            ]
          },
          {
            "name": "leg_back_left",
            "pivot": [-6, 8, 17],
            "cubes": [{"origin": [3, 0, 13], "size": [5, 11, 5], "uv": [0, 45]}]
          },
          {
            "name": "leg_front_right",
            "pivot": [-6, 12, -3],
            "cubes": [
              {"origin": [-8, 0, -6], "size": [6, 14, 6], "uv": [66, 42]}
            ]
          },
          {
            "name": "leg_front_left",
            "pivot": [6, 12, -3],
            "cubes": [
              {"origin": [2, 0, -6], "size": [6, 14, 6], "uv": [41, 42]}
            ]
          }
        ],
        "visible_bounds_width": 4,
        "visible_bounds_height": 3,
        "visible_bounds_offset": [0, 1.5, 0],
        "texturewidth": 128,
        "textureheight": 64
      }
    },
    "spawn_egg": {"base_color": "#c66e55", "overlay_color": "#e6e6e6"},
    "scripts": {
      "pre_animation": [
        "variable.tcos_right_side = (Math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed / variable.gliding_speed_value) * 57.3;",
        "variable.tcos_left_side = -variable.tcos_right_side;",
        "variable.attack_head_rot =  Math.sin(variable.attack_time * 180.0) * -37.3;"
      ],
      "animate": [
        "walk",
        "look_at_target",
        {"attack": "variable.has_target && variable.attack_time >= 0.0"},
        {"hoglin_baby_scaling": "query.is_baby"}
      ]
    },
    "animations": {
      "walk": {
        "loop": true,
        "bones": {
          "left_ear": {"rotation": [0, 0, "variable.tcos_left_side * 0.5"]},
          "right_ear": {"rotation": [0, 0, "variable.tcos_right_side * 0.5"]},
          "leg_back_right": {"rotation": ["variable.tcos_right_side", 0, 0]},
          "leg_back_left": {"rotation": ["variable.tcos_left_side", 0, 0]},
          "leg_front_right": {"rotation": ["-variable.tcos_right_side", 0, 0]},
          "leg_front_left": {"rotation": ["-variable.tcos_left_side", 0, 0]}
        }
      },
      "look_at_target": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [0, "query.target_y_rotation - this", 0]
          }
        }
      },
      "attack": {
        "loop": true,
        "bones": {"head": {"rotation": ["variable.attack_head_rot", 0, 0]}}
      },
      "hoglin_baby_scaling": {
        "loop": true,
        "bones": {"head": {"position": [0, 10, 4], "scale": 1.4}}
      }
    },
    "render_controllers": ["controller.render.zoglin"]
  },
  "zombie": {
    "identifier": "minecraft:zombie",
    "min_engine_version": "1.8.0",
    "materials": {"default": "zombie"},
    "textures": {"default": "textures/entity/zombie/zombie"},
    "geometry": {
      "default": {
        "visible_bounds_width": 2,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 1, 0],
        "texturewidth": 64,
        "textureheight": 32,
        "bones": [
          {
            "name": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 12, -2], "size": [8, 12, 4], "uv": [16, 16]}
            ],
            "parent": "waist"
          },
          {"name": "waist", "neverRender": true, "pivot": [0, 12, 0]},
          {
            "name": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 24, -4], "size": [8, 8, 8], "uv": [0, 0]}
            ],
            "parent": "body"
          },
          {
            "name": "hat",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-4, 24, -4],
                "size": [8, 8, 8],
                "uv": [32, 0],
                "inflate": 0.5
              }
            ],
            "neverRender": true,
            "parent": "head"
          },
          {
            "name": "rightArm",
            "pivot": [-5, 22, 0],
            "cubes": [
              {"origin": [-8, 12, -2], "size": [4, 12, 4], "uv": [40, 16]}
            ],
            "parent": "body"
          },
          {
            "name": "rightItem",
            "pivot": [-6, 15, 1],
            "neverRender": true,
            "parent": "rightArm"
          },
          {
            "name": "leftArm",
            "pivot": [5, 22, 0],
            "cubes": [
              {"origin": [4, 12, -2], "size": [4, 12, 4], "uv": [40, 16]}
            ],
            "mirror": true,
            "parent": "body"
          },
          {
            "name": "rightLeg",
            "pivot": [-1.9, 12, 0],
            "cubes": [
              {"origin": [-3.9, 0, -2], "size": [4, 12, 4], "uv": [0, 16]}
            ],
            "parent": "body"
          },
          {
            "name": "leftLeg",
            "pivot": [1.9, 12, 0],
            "cubes": [
              {"origin": [-0.1, 0, -2], "size": [4, 12, 4], "uv": [0, 16]}
            ],
            "mirror": true,
            "parent": "body"
          }
        ]
      }
    },
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 12},
    "scripts": {
      "pre_animation": [
        "variable.tcos0 = (Math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed / variable.gliding_speed_value) * 57.3;"
      ]
    },
    "animations": {
      "humanoid_big_head": {"loop": true, "bones": {"head": {"scale": 1.4}}},
      "look_at_target_default": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_gliding": {
        "loop": true,
        "bones": {"head": {"rotation": [-45, "query.target_y_rotation", 0]}}
      },
      "look_at_target_swimming": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "math.lerp(query.target_x_rotation, -45.0, variable.swim_amount)",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "move": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["variable.tcos0", 0, 0]},
          "leftleg": {"rotation": ["variable.tcos0 * -1.4", 0, 0]},
          "rightarm": {"rotation": ["-variable.tcos0", 0, 0]},
          "rightleg": {"rotation": ["variable.tcos0 * 1.4", 0, 0]}
        }
      },
      "riding.arms": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": [-36, 0, 0]},
          "rightarm": {"rotation": [-36, 0, 0]}
        }
      },
      "riding.legs": {
        "loop": true,
        "bones": {
          "leftleg": {"rotation": ["-72.0 - this", "-18.0 - this", "-this"]},
          "rightleg": {"rotation": ["-72.0 - this", "18.0 - this", "-this"]}
        }
      },
      "holding": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "variable.is_holding_left ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "variable.is_holding_right ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          }
        }
      },
      "brandish_spear": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "this * -0.5 - 157.5 - 22.5 * variable.charge_amount",
              "-this",
              0
            ]
          }
        }
      },
      "charging": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": ["22.5 * variable.charge_amount - this", "-this", 0]
          }
        }
      },
      "attack.rotations": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              0,
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46 - this",
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.sin(1.0 - math.pow(1.0 - variable.attack_time, 3.0) * 180.0) * (variable.is_brandishing_spear ? -1.0 : 1.0 )",
              "variable.is_brandishing_spear ? 0.0 : (math.sin(math.sqrt(variable.attack_time) * 360) * 11.46) * 2.0",
              0
            ]
          }
        }
      },
      "sneaking": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["0.5 - this", 0, 0]},
          "head": {"position": [0, 1, 0]},
          "leftarm": {"rotation": [72, 0, 0]},
          "leftleg": {"position": [0, -3, 4]},
          "rightarm": {"rotation": [72, 0, 0]},
          "rightleg": {"position": [0, -3, 4]}
        }
      },
      "bob": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              0,
              0,
              "((math.cos(query.life_time * 103.2) * 2.865) + 2.865) *-1.0"
            ]
          },
          "rightarm": {
            "rotation": [
              0,
              0,
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "damage_nearby_mobs": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["-45.0-this", "-this", "-this"]},
          "leftleg": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightarm": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-45.0-this", "-this", "-this"]}
        }
      },
      "bow_and_arrow": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "query.target_x_rotation - 90.0 - math.sin(query.life_time * 76.8) * 2.865 - this",
              "query.target_y_rotation + 28.65",
              "-(math.cos(query.life_time * 103.2) * 2.865) - 2.865"
            ]
          },
          "rightarm": {
            "rotation": [
              "query.target_x_rotation - 90.0 + math.sin(query.life_time * 76.8) * 2.865 - this",
              "query.target_y_rotation - 5.73",
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "use_item_progress": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "variable.use_item_startup_progress * -60.0 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -22.5 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -5.625 + variable.use_item_interval_progress * 11.25"
            ]
          }
        }
      },
      "zombie_attack_bare_hand": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "-90.0 - ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4) - (math.sin(query.life_time * 76.776372) * 2.865) - this",
              "5.73 - ((math.sin(variable.attack_time * 180.0) * 57.3) * 0.6) - this",
              "math.cos(query.life_time * 103.13244) * -2.865 - 2.865 - this"
            ]
          },
          "rightarm": {
            "rotation": [
              "90.0 * (variable.is_brandishing_spear - 1.0) - ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4) + (math.sin(query.life_time * 76.776372) * 2.865) - this",
              "(math.sin(variable.attack_time * 180.0) * 57.3) * 0.6 - 5.73 - this",
              "math.cos(query.life_time * 103.13244) * 2.865 + 2.865 - this"
            ]
          }
        }
      },
      "swimming": {
        "loop": true,
        "bones": {
          "body": {
            "position": [
              0,
              "variable.swim_amount * -10.0 - this",
              "variable.swim_amount * 9.0 - this"
            ],
            "rotation": [
              "variable.swim_amount * (90.0 + query.target_x_rotation)",
              0,
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.lerp(this, -180.0, variable.swim_amount) - (variable.swim_amount * ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4)) - (variable.swim_amount * (math.sin(query.life_time * 76.776372) * 2.865)) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - (variable.swim_amount * (math.cos(query.life_time * 103.13244) * 2.865 + 2.865)) - this"
            ]
          },
          "leftleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.life_time * 390.0 + 180.0) * 0.3, variable.swim_amount)",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.lerp(this, -180.0, variable.swim_amount) - (variable.swim_amount * ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4)) + (variable.swim_amount * (math.sin(query.life_time * 76.776372) * 2.865)) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - this",
              "math.lerp(this, -14.325, variable.swim_amount) + (variable.swim_amount * (math.cos(query.life_time * 103.13244) * 2.865 + 2.865)) - this"
            ]
          },
          "rightleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.life_time * 390.0) * 0.3, variable.swim_amount)",
              0,
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "humanoid_baby_big_head": {
        "initial_state": "default",
        "states": {
          "baby": {
            "animations": ["humanoid_big_head"],
            "transitions": [{"default": "!query.is_baby"}]
          },
          "default": {"transitions": [{"baby": "query.is_baby"}]}
        }
      },
      "look_at_target": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["look_at_target_default"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"swimming": "query.is_swimming"}
            ]
          },
          "gliding": {
            "animations": ["look_at_target_gliding"],
            "transitions": [
              {"swimming": "query.is_swimming"},
              {"default": "!query.is_gliding"}
            ]
          },
          "swimming": {
            "animations": ["look_at_target_swimming"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"default": "!query.is_swimming"}
            ]
          }
        }
      },
      "move": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move"]}}
      },
      "riding": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"riding": "query.is_riding"}]},
          "riding": {
            "animations": ["riding.arms", "riding.legs"],
            "transitions": [{"default": "!query.is_riding"}]
          }
        }
      },
      "holding": {
        "initial_state": "default",
        "states": {"default": {"animations": ["holding"]}}
      },
      "brandish_spear": {
        "initial_state": "default",
        "states": {
          "brandish_spear": {
            "animations": ["brandish_spear"],
            "transitions": [{"default": "!variable.is_brandishing_spear"}]
          },
          "default": {
            "transitions": [{"brandish_spear": "variable.is_brandishing_spear"}]
          }
        }
      },
      "charging": {
        "initial_state": "default",
        "states": {
          "charging": {
            "animations": ["charging"],
            "transitions": [{"default": "!query.is_charging"}]
          },
          "default": {"transitions": [{"charging": "query.is_charging"}]}
        }
      },
      "attack": {
        "initial_state": "default",
        "states": {
          "attacking": {
            "animations": ["attack.rotations"],
            "transitions": [{"default": "variable.attack_time < 0.0"}]
          },
          "default": {
            "transitions": [{"attacking": "variable.attack_time >= 0.0"}]
          }
        }
      },
      "sneaking": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"sneaking": "query.is_sneaking"}]},
          "sneaking": {
            "animations": ["sneaking"],
            "transitions": [{"default": "!query.is_sneaking"}]
          }
        }
      },
      "bob": {
        "initial_state": "default",
        "states": {"default": {"animations": ["bob"]}}
      },
      "damage_nearby_mobs": {
        "initial_state": "default",
        "states": {
          "damage_nearby_mobs": {
            "animations": ["damage_nearby_mobs"],
            "transitions": [{"default": "!variable.damage_nearby_mobs"}]
          },
          "default": {
            "transitions": [
              {"damage_nearby_mobs": "variable.damage_nearby_mobs"}
            ]
          }
        }
      },
      "bow_and_arrow": {
        "initial_state": "default",
        "states": {
          "bow_and_arrow": {
            "animations": ["bow_and_arrow"],
            "transitions": [{"default": "!query.has_target"}]
          },
          "default": {"transitions": [{"bow_and_arrow": "query.has_target"}]}
        }
      },
      "use_item_progress": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [
              {
                "use_item_progress": "( variable.use_item_interval_progress > 0.0 ) || ( variable.use_item_startup_progress > 0.0 )"
              }
            ]
          },
          "use_item_progress": {
            "animations": ["use_item_progress"],
            "transitions": [
              {
                "default": "( variable.use_item_interval_progress <= 0.0 ) && ( variable.use_item_startup_progress <= 0.0 )"
              }
            ]
          }
        }
      },
      "zombie_attack_bare_hand": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [{"is_bare_hand": "variable.is_holding_left != 1.0"}]
          },
          "is_bare_hand": {
            "animations": ["zombie_attack_bare_hand"],
            "transitions": [{"default": "variable.is_holding_left == 1.0"}]
          }
        }
      },
      "swimming": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [{"is_swimming": "variable.swim_amount > 0.0"}]
          },
          "is_swimming": {
            "animations": ["swimming"],
            "transitions": [{"default": "variable.swim_amount <= 0.0"}]
          }
        }
      }
    },
    "render_controllers": ["controller.render.zombie"],
    "enable_attachables": true
  },
  "zombie_horse": {
    "identifier": "minecraft:zombie_horse",
    "textures": {
      "base_brown": "textures/entity/horse/horse_brown",
      "base_white": "textures/entity/horse/horse_white",
      "base_chestnut": "textures/entity/horse/horse_chestnut",
      "base_creamy": "textures/entity/horse/horse_creamy",
      "base_black": "textures/entity/horse/horse_black",
      "base_gray": "textures/entity/horse/horse_gray",
      "base_darkbrown": "textures/entity/horse/horse_darkbrown",
      "markings_none": "textures/entity/horse/horse_markings_none",
      "markings_white": "textures/entity/horse/horse_markings_white",
      "markings_whitefield": "textures/entity/horse/horse_markings_whitefield",
      "markings_whitedots": "textures/entity/horse/horse_markings_whitedots",
      "markings_blackdots": "textures/entity/horse/horse_markings_blackdots",
      "mule": "textures/entity/horse/mule",
      "donkey": "textures/entity/horse/donkey",
      "skeleton": "textures/entity/horse/horse_skeleton",
      "zombie": "textures/entity/horse/horse_zombie",
      "armor_none": "textures/entity/horse/armor/horse_armor_none",
      "armor_leather": "textures/entity/horse/armor/horse_armor_leather",
      "armor_iron": "textures/entity/horse/armor/horse_armor_iron",
      "armor_gold": "textures/entity/horse/armor/horse_armor_gold",
      "armor_diamond": "textures/entity/horse/armor/horse_armor_diamond"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 2,
        "visible_bounds_height": 3,
        "visible_bounds_offset": [0, 1, 0],
        "texturewidth": 128,
        "textureheight": 128,
        "bones": [
          {
            "name": "Body",
            "pivot": [0, 13, 9],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5, 11, -10], "size": [10, 10, 24], "uv": [0, 34]}
            ]
          },
          {
            "name": "TailA",
            "pivot": [0, 21, 14],
            "rotation": [-65, 0, 0],
            "cubes": [
              {"origin": [-1, 20, 14], "size": [2, 2, 3], "uv": [44, 0]}
            ]
          },
          {
            "name": "TailB",
            "pivot": [0, 21, 14],
            "rotation": [-65, 0, 0],
            "cubes": [
              {"origin": [-1.5, 19, 17], "size": [3, 4, 7], "uv": [38, 7]}
            ]
          },
          {
            "name": "TailC",
            "pivot": [0, 21, 14],
            "rotation": [-80.34, 0, 0],
            "cubes": [
              {"origin": [-1.5, 21.5, 23], "size": [3, 4, 7], "uv": [24, 3]}
            ]
          },
          {
            "name": "Leg1A",
            "pivot": [4, 15, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.5, 8, 8.5], "size": [4, 9, 5], "uv": [78, 29]}
            ]
          },
          {
            "name": "Leg1B",
            "pivot": [4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2, 3, 9.5], "size": [3, 5, 3], "uv": [78, 43]}
            ]
          },
          {
            "name": "Leg1C",
            "pivot": [4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.5, -0.1, 9], "size": [4, 3, 4], "uv": [78, 51]}
            ]
          },
          {
            "name": "Leg2A",
            "pivot": [-4, 15, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, 8, 8.5], "size": [4, 9, 5], "uv": [96, 29]}
            ]
          },
          {
            "name": "Leg2B",
            "pivot": [-4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5, 3, 9.5], "size": [3, 5, 3], "uv": [96, 43]}
            ]
          },
          {
            "name": "Leg2C",
            "pivot": [-4, 8, 11],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, -0.1, 9], "size": [4, 3, 4], "uv": [96, 51]}
            ]
          },
          {
            "name": "Leg3A",
            "pivot": [4, 15, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2.1, 8, -10.1], "size": [3, 8, 4], "uv": [44, 29]}
            ]
          },
          {
            "name": "Leg3B",
            "pivot": [4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2.1, 3, -9.6], "size": [3, 5, 3], "uv": [44, 41]}
            ]
          },
          {
            "name": "Leg3C",
            "pivot": [4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [1.6, -0.1, -10.1], "size": [4, 3, 4], "uv": [44, 51]}
            ]
          },
          {
            "name": "Leg4A",
            "pivot": [-4, 15, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.1, 8, -10.1], "size": [3, 8, 4], "uv": [60, 29]}
            ]
          },
          {
            "name": "Leg4B",
            "pivot": [-4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.1, 3, -9.6], "size": [3, 5, 3], "uv": [60, 41]}
            ]
          },
          {
            "name": "Leg4C",
            "pivot": [-4, 8, -8],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.6, -0.1, -10.1], "size": [4, 3, 4], "uv": [60, 51]}
            ]
          },
          {
            "name": "Head",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.5, 25, -11.5], "size": [5, 5, 7], "uv": [0, 0]}
            ]
          },
          {
            "name": "UMouth",
            "pivot": [0, 20.05, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2, 27.05, -17], "size": [4, 3, 6], "uv": [24, 18]}
            ]
          },
          {
            "name": "LMouth",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2, 25, -16.5], "size": [4, 2, 5], "uv": [24, 27]}
            ]
          },
          {
            "name": "Ear1",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [0.45, 29, -6], "size": [2, 3, 1], "uv": [0, 0]}
            ]
          },
          {
            "name": "Ear2",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.45, 29, -6], "size": [2, 3, 1], "uv": [0, 0]}
            ]
          },
          {
            "name": "MuleEarL",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 15],
            "cubes": [
              {"origin": [-2, 29, -6], "size": [2, 7, 1], "uv": [0, 12]}
            ]
          },
          {
            "name": "MuleEarR",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, -15],
            "cubes": [{"origin": [0, 29, -6], "size": [2, 7, 1], "uv": [0, 12]}]
          },
          {
            "name": "Neck",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.05, 15.8, -12], "size": [4, 14, 8], "uv": [0, 12]}
            ]
          },
          {
            "name": "Bag1",
            "pivot": [-7.5, 21, 10],
            "rotation": [0, 90, 0],
            "cubes": [
              {"origin": [-10.5, 13, 10], "size": [8, 8, 3], "uv": [0, 34]}
            ]
          },
          {
            "name": "Bag2",
            "pivot": [4.5, 21, 10],
            "rotation": [0, 90, 0],
            "cubes": [
              {"origin": [1.5, 13, 10], "size": [8, 8, 3], "uv": [0, 47]}
            ]
          },
          {
            "name": "Saddle",
            "pivot": [0, 22, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5, 21, -1], "size": [10, 1, 8], "uv": [80, 0]}
            ]
          },
          {
            "name": "SaddleB",
            "pivot": [0, 22, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-1.5, 22, -1], "size": [3, 1, 2], "uv": [106, 9]}
            ]
          },
          {
            "name": "SaddleC",
            "pivot": [0, 22, 2],
            "rotation": [0, 0, 0],
            "cubes": [{"origin": [-4, 22, 5], "size": [8, 1, 2], "uv": [80, 9]}]
          },
          {
            "name": "SaddleL2",
            "pivot": [5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [4.5, 13, 1], "size": [1, 2, 2], "uv": [74, 0]}
            ]
          },
          {
            "name": "SaddleL",
            "pivot": [5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [4.5, 15, 1.5], "size": [1, 6, 1], "uv": [70, 0]}
            ]
          },
          {
            "name": "SaddleR2",
            "pivot": [-5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, 13, 1], "size": [1, 2, 2], "uv": [74, 4]}
            ]
          },
          {
            "name": "SaddleR",
            "pivot": [-5, 21, 2],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-5.5, 15, 1.5], "size": [1, 6, 1], "uv": [80, 0]}
            ]
          },
          {
            "name": "SaddleMouthL",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [1.5, 26, -14], "size": [1, 2, 2], "uv": [74, 13]}
            ]
          },
          {
            "name": "SaddleMouthR",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-2.5, 26, -14], "size": [1, 2, 2], "uv": [74, 13]}
            ]
          },
          {
            "name": "SaddleMouthLine",
            "pivot": [0, 20, -10],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [2.6, 23, -16], "size": [0, 3, 16], "uv": [44, 10]}
            ]
          },
          {
            "name": "SaddleMouthLineR",
            "pivot": [0, 20, -10],
            "rotation": [0, 0, 0],
            "cubes": [
              {"origin": [-2.6, 23, -16], "size": [0, 3, 16], "uv": [44, 5]}
            ]
          },
          {
            "name": "Mane",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {"origin": [-1, 15.5, -5], "size": [2, 16, 4], "uv": [58, 0]}
            ]
          },
          {
            "name": "HeadSaddle",
            "pivot": [0, 20, -10],
            "rotation": [30, 0, 0],
            "cubes": [
              {
                "origin": [-2.5, 25.1, -17],
                "size": [5, 5, 12],
                "uv": [80, 12],
                "inflate": 0.05
              }
            ]
          }
        ]
      }
    },
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 33}
  },
  "zombified_piglin": {
    "identifier": "minecraft:zombie_pigman",
    "min_engine_version": "1.8.0",
    "materials": {"default": "zombie"},
    "textures": {"default": "textures/entity/piglin/zombified_piglin"},
    "geometry": {
      "default": {
        "bones": [
          {
            "name": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 12, -2], "size": [8, 12, 4], "uv": [16, 16]},
              {
                "origin": [-4, 12, -2],
                "size": [8, 12, 4],
                "uv": [16, 32],
                "inflate": 0.25
              }
            ]
          },
          {
            "name": "head",
            "parent": "body",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-5, 24, -4],
                "size": [10, 8, 8],
                "uv": [0, 0],
                "inflate": -0.02
              },
              {"origin": [-2, 24, -5], "size": [4, 4, 1], "uv": [31, 1]},
              {"origin": [2, 24, -5], "size": [1, 2, 1], "uv": [2, 4]},
              {"origin": [-3, 24, -5], "size": [1, 2, 1], "uv": [2, 0]}
            ],
            "inflate": -0.02
          },
          {
            "name": "leftear",
            "parent": "head",
            "pivot": [5, 30, 0],
            "rotation": [0, 0, -30],
            "cubes": [{"origin": [4, 25, -2], "size": [1, 5, 4], "uv": [51, 6]}]
          },
          {
            "name": "rightear",
            "parent": "head",
            "pivot": [-5, 30, 0],
            "rotation": [0, 0, 30],
            "cubes": [
              {"origin": [-5, 25, -2], "size": [1, 5, 4], "uv": [39, 6]}
            ]
          },
          {"name": "hat", "parent": "head", "pivot": [0, 24, 0]},
          {
            "name": "rightarm",
            "parent": "body",
            "pivot": [-5, 22, 0],
            "cubes": [
              {"origin": [-8, 12, -2], "size": [4, 12, 4], "uv": [40, 16]},
              {
                "origin": [-8, 12, -2],
                "size": [4, 12, 4],
                "uv": [40, 32],
                "inflate": 0.25
              }
            ]
          },
          {"name": "rightItem", "parent": "rightarm", "pivot": [-6, 15, 1]},
          {
            "name": "leftarm",
            "parent": "body",
            "pivot": [5, 22, 0],
            "cubes": [
              {"origin": [4, 12, -2], "size": [4, 12, 4], "uv": [32, 48]},
              {
                "origin": [4, 12, -2],
                "size": [4, 12, 4],
                "uv": [48, 48],
                "inflate": 0.25
              }
            ]
          },
          {"name": "leftItem", "parent": "leftArm", "pivot": [6, 15, 1]},
          {
            "name": "rightleg",
            "parent": "body",
            "pivot": [-1.9, 12, 0],
            "cubes": [
              {"origin": [-4, 0, -2], "size": [4, 12, 4], "uv": [0, 16]},
              {
                "origin": [-4, 0, -2],
                "size": [4, 12, 4],
                "uv": [0, 32],
                "inflate": 0.25
              }
            ]
          },
          {
            "name": "leftleg",
            "parent": "body",
            "pivot": [1.9, 12, 0],
            "cubes": [
              {"origin": [0, 0, -2], "size": [4, 12, 4], "uv": [16, 48]},
              {
                "origin": [0, 0, -2],
                "size": [4, 12, 4],
                "uv": [0, 48],
                "inflate": 0.25
              }
            ]
          }
        ],
        "visible_bounds_width": 2,
        "visible_bounds_height": 2,
        "visible_bounds_offset": [0, 1, 0],
        "texturewidth": 64,
        "textureheight": 64
      }
    },
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 13},
    "scripts": {
      "pre_animation": [
        "variable.tcos0 = (Math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed / variable.gliding_speed_value) * 57.3;"
      ]
    },
    "animations": {
      "humanoid_big_head": {"loop": true, "bones": {"head": {"scale": 1.4}}},
      "humanoid_base_pose": {
        "loop": true,
        "bones": {"waist": {"rotation": [0, 0, 0]}}
      },
      "look_at_target_default": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_gliding": {
        "loop": true,
        "bones": {"head": {"rotation": [-45, "query.target_y_rotation", 0]}}
      },
      "look_at_target_swimming": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "math.lerp(query.target_x_rotation, -45.0, variable.swim_amount)",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "move": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["variable.tcos0", 0, 0]},
          "leftleg": {"rotation": ["variable.tcos0 * -1.4", 0, 0]},
          "rightarm": {"rotation": ["-variable.tcos0", 0, 0]},
          "rightleg": {"rotation": ["variable.tcos0 * 1.4", 0, 0]}
        }
      },
      "riding.arms": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": [-36, 0, 0]},
          "rightarm": {"rotation": [-36, 0, 0]}
        }
      },
      "riding.legs": {
        "loop": true,
        "bones": {
          "leftleg": {"rotation": ["-72.0 - this", "-18.0 - this", "-this"]},
          "rightleg": {"rotation": ["-72.0 - this", "18.0 - this", "-this"]}
        }
      },
      "holding": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "variable.is_holding_left ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "variable.is_holding_right ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          }
        }
      },
      "brandish_spear": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "this * -0.5 - 157.5 - 22.5 * variable.charge_amount",
              "-this",
              0
            ]
          }
        }
      },
      "charging": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": ["22.5 * variable.charge_amount - this", "-this", 0]
          }
        }
      },
      "attack.rotations": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              0,
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46 - this",
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.sin(1.0 - math.pow(1.0 - variable.attack_time, 3.0) * 180.0) * (variable.is_brandishing_spear ? -1.0 : 1.0 )",
              "variable.is_brandishing_spear ? 0.0 : (math.sin(math.sqrt(variable.attack_time) * 360) * 11.46) * 2.0",
              0
            ]
          }
        }
      },
      "sneaking": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["0.5 - this", 0, 0]},
          "head": {"position": [0, 1, 0]},
          "leftarm": {"rotation": [72, 0, 0]},
          "leftleg": {"position": [0, -3, 4]},
          "rightarm": {"rotation": [72, 0, 0]},
          "rightleg": {"position": [0, -3, 4]}
        }
      },
      "bob": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              0,
              0,
              "((math.cos(query.life_time * 103.2) * 2.865) + 2.865) *-1.0"
            ]
          },
          "rightarm": {
            "rotation": [
              0,
              0,
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "damage_nearby_mobs": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["-45.0-this", "-this", "-this"]},
          "leftleg": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightarm": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-45.0-this", "-this", "-this"]}
        }
      },
      "bow_and_arrow": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "query.target_x_rotation - 90.0 - math.sin(query.life_time * 76.8) * 2.865 - this",
              "query.target_y_rotation + 28.65",
              "-(math.cos(query.life_time * 103.2) * 2.865) - 2.865"
            ]
          },
          "rightarm": {
            "rotation": [
              "query.target_x_rotation - 90.0 + math.sin(query.life_time * 76.8) * 2.865 - this",
              "query.target_y_rotation - 5.73",
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "swimming": {
        "animation_length": 1.3,
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": {
              "0.7": {
                "post": [
                  "math.lerp(this, 11.25 * math.mod(query.modified_distance_moved, 26.0), variable.leftarmswim_amount)",
                  "math.lerp(this, 180.0, variable.leftarmswim_amount)",
                  "math.lerp(this, 72.77 + 13.4 * math.mod(query.modified_distance_moved, 26.0), variable.leftarmswim_amount)"
                ],
                "pre": [
                  "math.lerp(this, 0.0, variable.leftarmswim_amount)",
                  "math.lerp(this, 180.0, variable.leftarmswim_amount)",
                  "math.lerp(this, 180.0 - 0.01877 * (-65.0 * math.mod(query.modified_distance_moved, 26.0) + math.mod(query.modified_distance_moved, 26.0) * math.mod(query.modified_distance_moved, 26.0)), variable.leftarmswim_amount)"
                ]
              },
              "1.1": [
                "math.lerp(this, 11.25 * math.mod(query.modified_distance_moved, 26.0), variable.leftarmswim_amount)",
                "math.lerp(this, 180.0, variable.leftarmswim_amount)",
                "math.lerp(this, 72.77 + 13.4 * math.mod(query.modified_distance_moved, 26.0), variable.leftarmswim_amount)"
              ],
              "1.3": {
                "post": [
                  "math.lerp(this, 90.0 - (22.5 * math.mod(query.modified_distance_moved, 26.0)), variable.leftarmswim_amount)",
                  "math.lerp(this, 180.0, variable.leftarmswim_amount)",
                  "math.lerp(this, 180.0, variable.leftarmswim_amount)"
                ],
                "pre": [
                  "math.lerp(this, 11.25 * math.mod(query.modified_distance_moved, 26.0), variable.leftarmswim_amount)",
                  "math.lerp(this, 180.0, variable.leftarmswim_amount)",
                  "math.lerp(this, 72.77 + 13.4 * math.mod(query.modified_distance_moved, 26.0), variable.leftarmswim_amount)"
                ]
              }
            }
          },
          "leftleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.modified_distance_moved * 19.5 + 180.0) * 17.2, variable.leftarmswim_amount) - this",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": {
              "0.7": {
                "post": [
                  "math.lerp(this, 11.25 * math.mod(query.modified_distance_moved, 26.0), variable.rightarmswim_amount)",
                  "math.lerp(this, 180.0, variable.rightarmswim_amount)",
                  "math.lerp(this, 72.77 + 13.4 * math.mod(query.modified_distance_moved, 26.0), variable.rightarmswim_amount)"
                ],
                "pre": [
                  "math.lerp(this, 0.0, variable.rightarmswim_amount)",
                  "math.lerp(this, 180.0, variable.rightarmswim_amount)",
                  "math.lerp(this, -0.1019 * (-65.0 * math.mod(query.modified_distance_moved, 26.0) + math.mod(query.modified_distance_moved, 26.0) * math.mod(query.modified_distance_moved, 26.0)), variable.rightarmswim_amount)"
                ]
              },
              "1.1": [
                "math.lerp(this, 11.25 * math.mod(query.modified_distance_moved, 26.0), variable.rightarmswim_amount)",
                "math.lerp(this, 180.0, variable.rightarmswim_amount)",
                "math.lerp(this, 72.77 + 13.4 * math.mod(query.modified_distance_moved, 26.0), variable.rightarmswim_amount)"
              ],
              "1.3": {
                "post": [
                  "math.lerp(this, 90.0 - (22.5 * math.mod(query.modified_distance_moved, 26.0)), variable.rightarmswim_amount)",
                  "math.lerp(this, 180.0, variable.rightarmswim_amount)",
                  "math.lerp(this, 180.0, variable.rightarmswim_amount)"
                ],
                "pre": [
                  "math.lerp(this, 11.25 * math.mod(query.modified_distance_moved, 26.0), variable.rightarmswim_amount)",
                  "math.lerp(this, 180.0, variable.rightarmswim_amount)",
                  "math.lerp(this, 72.77 + 13.4 * math.mod(query.modified_distance_moved, 26.0), variable.rightarmswim_amount)"
                ]
              }
            }
          },
          "rightleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.modified_distance_moved * 19.5) * 17.2, variable.leftarmswim_amount) - this",
              0,
              0
            ]
          }
        }
      },
      "use_item_progress": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "variable.use_item_startup_progress * -60.0 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -22.5 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -5.625 + variable.use_item_interval_progress * 11.25"
            ]
          }
        }
      },
      "zombie_attack_bare_hand": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "-90.0 - ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4) - (math.sin(query.life_time * 76.776372) * 2.865) - this",
              "5.73 - ((math.sin(variable.attack_time * 180.0) * 57.3) * 0.6) - this",
              "math.cos(query.life_time * 103.13244) * -2.865 - 2.865 - this"
            ]
          },
          "rightarm": {
            "rotation": [
              "90.0 * (variable.is_brandishing_spear - 1.0) - ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4) + (math.sin(query.life_time * 76.776372) * 2.865) - this",
              "(math.sin(variable.attack_time * 180.0) * 57.3) * 0.6 - 5.73 - this",
              "math.cos(query.life_time * 103.13244) * 2.865 + 2.865 - this"
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "humanoid_baby_big_head": {
        "initial_state": "default",
        "states": {
          "baby": {
            "animations": ["humanoid_big_head"],
            "transitions": [{"default": "!query.is_baby"}]
          },
          "default": {"transitions": [{"baby": "query.is_baby"}]}
        }
      },
      "humanoid_base_pose": {
        "initial_state": "default",
        "states": {"default": {"animations": ["humanoid_base_pose"]}}
      },
      "look_at_target": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["look_at_target_default"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"swimming": "query.is_swimming"}
            ]
          },
          "gliding": {
            "animations": ["look_at_target_gliding"],
            "transitions": [
              {"swimming": "query.is_swimming"},
              {"default": "!query.is_gliding"}
            ]
          },
          "swimming": {
            "animations": ["look_at_target_swimming"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"default": "!query.is_swimming"}
            ]
          }
        }
      },
      "move": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move"]}}
      },
      "riding": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"riding": "query.is_riding"}]},
          "riding": {
            "animations": ["riding.arms", "riding.legs"],
            "transitions": [{"default": "!query.is_riding"}]
          }
        }
      },
      "holding": {
        "initial_state": "default",
        "states": {"default": {"animations": ["holding"]}}
      },
      "brandish_spear": {
        "initial_state": "default",
        "states": {
          "brandish_spear": {
            "animations": ["brandish_spear"],
            "transitions": [{"default": "!variable.is_brandishing_spear"}]
          },
          "default": {
            "transitions": [{"brandish_spear": "variable.is_brandishing_spear"}]
          }
        }
      },
      "charging": {
        "initial_state": "default",
        "states": {
          "charging": {
            "animations": ["charging"],
            "transitions": [{"default": "!query.is_charging"}]
          },
          "default": {"transitions": [{"charging": "query.is_charging"}]}
        }
      },
      "attack": {
        "initial_state": "default",
        "states": {
          "attacking": {
            "animations": ["attack.rotations"],
            "transitions": [{"default": "variable.attack_time < 0.0"}]
          },
          "default": {
            "transitions": [{"attacking": "variable.attack_time >= 0.0"}]
          }
        }
      },
      "sneaking": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"sneaking": "query.is_sneaking"}]},
          "sneaking": {
            "animations": ["sneaking"],
            "transitions": [{"default": "!query.is_sneaking"}]
          }
        }
      },
      "bob": {
        "initial_state": "default",
        "states": {"default": {"animations": ["bob"]}}
      },
      "damage_nearby_mobs": {
        "initial_state": "default",
        "states": {
          "damage_nearby_mobs": {
            "animations": ["damage_nearby_mobs"],
            "transitions": [{"default": "!variable.damage_nearby_mobs"}]
          },
          "default": {
            "transitions": [
              {"damage_nearby_mobs": "variable.damage_nearby_mobs"}
            ]
          }
        }
      },
      "bow_and_arrow": {
        "initial_state": "default",
        "states": {
          "bow_and_arrow": {
            "animations": ["bow_and_arrow"],
            "transitions": [{"default": "!query.has_target"}]
          },
          "default": {"transitions": [{"bow_and_arrow": "query.has_target"}]}
        }
      },
      "swimming": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [{"swimming": "variable.swim_amount > 0.0"}]
          },
          "swimming": {
            "animations": ["swimming"],
            "transitions": [{"default": "variable.swim_amount <= 0.0"}]
          }
        }
      },
      "use_item_progress": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [
              {
                "use_item_progress": "( variable.use_item_interval_progress > 0.0 ) || ( variable.use_item_startup_progress > 0.0 )"
              }
            ]
          },
          "use_item_progress": {
            "animations": ["use_item_progress"],
            "transitions": [
              {
                "default": "( variable.use_item_interval_progress <= 0.0 ) && ( variable.use_item_startup_progress <= 0.0 )"
              }
            ]
          }
        }
      },
      "zombie_attack_bare_hand": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [{"is_bare_hand": "variable.is_holding_left != 1.0"}]
          },
          "is_bare_hand": {
            "animations": ["zombie_attack_bare_hand"],
            "transitions": [{"default": "variable.is_holding_left == 1.0"}]
          }
        }
      }
    },
    "render_controllers": ["controller.render.zombie_pigman"],
    "enable_attachables": true
  },
  "zombie_villager": {
    "identifier": "minecraft:zombie_villager",
    "min_engine_version": "1.8.0",
    "materials": {"default": "zombie_villager"},
    "textures": {
      "smith": "textures/entity/zombie_villager/profession/weaponsmith",
      "butcher": "textures/entity/zombie_villager/profession/butcher",
      "librarian": "textures/entity/zombie_villager/profession/librarian",
      "priest": "textures/entity/zombie_villager/profession/cleric",
      "farmer": "textures/entity/zombie_villager/profession/farmer"
    },
    "geometry": {
      "default": {
        "visible_bounds_width": 1.5,
        "visible_bounds_height": 2.5,
        "visible_bounds_offset": [0, 1.25, 0],
        "texturewidth": 64,
        "textureheight": 64,
        "bones": [
          {
            "name": "hat",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-4, 24, -4],
                "size": [8, 8, 8],
                "uv": [32, 0],
                "inflate": 0.5
              }
            ],
            "neverRender": true
          },
          {
            "name": "head",
            "parent": "body",
            "reset": true,
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-4, 24, -4],
                "size": [8, 10, 8],
                "uv": [0, 0],
                "inflate": 0.25
              },
              {
                "origin": [-1, 23, -6],
                "size": [2, 4, 2],
                "uv": [24, 0],
                "inflate": 0.25
              }
            ]
          },
          {
            "name": "hat",
            "parent": "head",
            "pivot": [0, 24, 0],
            "cubes": [
              {
                "origin": [-4, 24, -4],
                "size": [8, 8, 8],
                "uv": [32, 0],
                "inflate": 0.5
              }
            ],
            "neverRender": true
          },
          {
            "name": "body",
            "parent": "waist",
            "reset": true,
            "pivot": [0, 24, 0],
            "cubes": [
              {"origin": [-4, 12, -3], "size": [8, 12, 6], "uv": [16, 20]},
              {
                "origin": [-4, 6, -3],
                "size": [8, 18, 6],
                "uv": [0, 38],
                "inflate": 0.5
              }
            ]
          },
          {"name": "waist", "neverRender": true, "pivot": [0, 12, 0]},
          {
            "name": "rightArm",
            "parent": "body",
            "reset": true,
            "pivot": [-5, 22, 0],
            "cubes": [
              {"origin": [-8, 12, -2], "size": [4, 12, 4], "uv": [44, 38]}
            ]
          },
          {
            "name": "rightItem",
            "pivot": [-6, 15, 1],
            "neverRender": true,
            "parent": "rightArm"
          },
          {
            "name": "leftArm",
            "parent": "body",
            "reset": true,
            "mirror": true,
            "pivot": [5, 22, 0],
            "cubes": [
              {"origin": [4, 12, -2], "size": [4, 12, 4], "uv": [44, 38]}
            ]
          },
          {
            "name": "rightLeg",
            "parent": "body",
            "reset": true,
            "pivot": [-2, 12, 0],
            "cubes": [
              {"origin": [-4, 0, -2], "size": [4, 12, 4], "uv": [0, 22]}
            ]
          },
          {
            "name": "leftLeg",
            "parent": "body",
            "reset": true,
            "mirror": true,
            "pivot": [2, 12, 0],
            "cubes": [{"origin": [0, 0, -2], "size": [4, 12, 4], "uv": [0, 22]}]
          }
        ]
      }
    },
    "scripts": {
      "pre_animation": [
        "variable.tcos0 = (Math.cos(query.modified_distance_moved * 38.17) * query.modified_move_speed / variable.gliding_speed_value) * 57.3;"
      ]
    },
    "animations": {
      "humanoid_big_head": {"loop": true, "bones": {"head": {"scale": 1.4}}},
      "humanoid_base_pose": {
        "loop": true,
        "bones": {"waist": {"rotation": [0, 0, 0]}}
      },
      "look_at_target_default": {
        "loop": true,
        "bones": {
          "head": {
            "relative_to": {"rotation": "entity"},
            "rotation": [
              "query.target_x_rotation",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "look_at_target_gliding": {
        "loop": true,
        "bones": {"head": {"rotation": [-45, "query.target_y_rotation", 0]}}
      },
      "look_at_target_swimming": {
        "loop": true,
        "bones": {
          "head": {
            "rotation": [
              "math.lerp(query.target_x_rotation, -45.0, variable.swim_amount)",
              "query.target_y_rotation",
              0
            ]
          }
        }
      },
      "move": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["variable.tcos0", 0, 0]},
          "leftleg": {"rotation": ["variable.tcos0 * -1.4", 0, 0]},
          "rightarm": {"rotation": ["-variable.tcos0", 0, 0]},
          "rightleg": {"rotation": ["variable.tcos0 * 1.4", 0, 0]}
        }
      },
      "riding.arms": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": [-36, 0, 0]},
          "rightarm": {"rotation": [-36, 0, 0]}
        }
      },
      "riding.legs": {
        "loop": true,
        "bones": {
          "leftleg": {"rotation": ["-72.0 - this", "-18.0 - this", "-this"]},
          "rightleg": {"rotation": ["-72.0 - this", "18.0 - this", "-this"]}
        }
      },
      "holding": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "variable.is_holding_left ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "variable.is_holding_right ? (-this * 0.5 - 18.0) : 0.0",
              0,
              0
            ]
          }
        }
      },
      "brandish_spear": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "this * -0.5 - 157.5 - 22.5 * variable.charge_amount",
              "-this",
              0
            ]
          }
        }
      },
      "charging": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": ["22.5 * variable.charge_amount - this", "-this", 0]
          }
        }
      },
      "attack.rotations": {
        "loop": true,
        "bones": {
          "body": {
            "rotation": [
              0,
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46 - this",
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.sin(math.sqrt(variable.attack_time) * 360) * 11.46",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.sin(1.0 - math.pow(1.0 - variable.attack_time, 3.0) * 180.0) * (variable.is_brandishing_spear ? -1.0 : 1.0 )",
              "variable.is_brandishing_spear ? 0.0 : (math.sin(math.sqrt(variable.attack_time) * 360) * 11.46) * 2.0",
              0
            ]
          }
        }
      },
      "sneaking": {
        "loop": true,
        "bones": {
          "body": {"rotation": ["0.5 - this", 0, 0]},
          "head": {"position": [0, 1, 0]},
          "leftarm": {"rotation": [72, 0, 0]},
          "leftleg": {"position": [0, -3, 4]},
          "rightarm": {"rotation": [72, 0, 0]},
          "rightleg": {"position": [0, -3, 4]}
        }
      },
      "bob": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              0,
              0,
              "((math.cos(query.life_time * 103.2) * 2.865) + 2.865) *-1.0"
            ]
          },
          "rightarm": {
            "rotation": [
              0,
              0,
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "damage_nearby_mobs": {
        "loop": true,
        "bones": {
          "leftarm": {"rotation": ["-45.0-this", "-this", "-this"]},
          "leftleg": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightarm": {"rotation": ["45.0-this", "-this", "-this"]},
          "rightleg": {"rotation": ["-45.0-this", "-this", "-this"]}
        }
      },
      "bow_and_arrow": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "query.target_x_rotation - 90.0 - math.sin(query.life_time * 76.8) * 2.865 - this",
              "query.target_y_rotation + 28.65",
              "-(math.cos(query.life_time * 103.2) * 2.865) - 2.865"
            ]
          },
          "rightarm": {
            "rotation": [
              "query.target_x_rotation - 90.0 + math.sin(query.life_time * 76.8) * 2.865 - this",
              "query.target_y_rotation - 5.73",
              "(math.cos(query.life_time * 103.2) * 2.865) + 2.865"
            ]
          }
        }
      },
      "use_item_progress": {
        "loop": true,
        "bones": {
          "rightarm": {
            "rotation": [
              "variable.use_item_startup_progress * -60.0 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -22.5 + variable.use_item_interval_progress * 11.25",
              "variable.use_item_startup_progress * -5.625 + variable.use_item_interval_progress * 11.25"
            ]
          }
        }
      },
      "zombie_attack_bare_hand": {
        "loop": true,
        "bones": {
          "leftarm": {
            "rotation": [
              "-90.0 - ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4) - (math.sin(query.life_time * 76.776372) * 2.865) - this",
              "5.73 - ((math.sin(variable.attack_time * 180.0) * 57.3) * 0.6) - this",
              "math.cos(query.life_time * 103.13244) * -2.865 - 2.865 - this"
            ]
          },
          "rightarm": {
            "rotation": [
              "90.0 * (variable.is_brandishing_spear - 1.0) - ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4) + (math.sin(query.life_time * 76.776372) * 2.865) - this",
              "(math.sin(variable.attack_time * 180.0) * 57.3) * 0.6 - 5.73 - this",
              "math.cos(query.life_time * 103.13244) * 2.865 + 2.865 - this"
            ]
          }
        }
      },
      "swimming": {
        "loop": true,
        "bones": {
          "body": {
            "position": [
              0,
              "variable.swim_amount * -10.0 - this",
              "variable.swim_amount * 9.0 - this"
            ],
            "rotation": [
              "variable.swim_amount * (90.0 + query.target_x_rotation)",
              0,
              0
            ]
          },
          "leftarm": {
            "rotation": [
              "math.lerp(this, -180.0, variable.swim_amount) - (variable.swim_amount * ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4)) - (variable.swim_amount * (math.sin(query.life_time * 76.776372) * 2.865)) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - (variable.swim_amount * (math.cos(query.life_time * 103.13244) * 2.865 + 2.865)) - this"
            ]
          },
          "leftleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.life_time * 390.0 + 180.0) * 0.3, variable.swim_amount)",
              0,
              0
            ]
          },
          "rightarm": {
            "rotation": [
              "math.lerp(this, -180.0, variable.swim_amount) - (variable.swim_amount * ((math.sin(variable.attack_time * 180.0) * 57.3) * 1.2 - (math.sin((1.0 - (1.0 - variable.attack_time) * (1.0 - variable.attack_time)) * 180.0) * 57.3) * 0.4)) + (variable.swim_amount * (math.sin(query.life_time * 76.776372) * 2.865)) - this",
              "math.lerp(this, 14.325, variable.swim_amount) - this",
              "math.lerp(this, -14.325, variable.swim_amount) + (variable.swim_amount * (math.cos(query.life_time * 103.13244) * 2.865 + 2.865)) - this"
            ]
          },
          "rightleg": {
            "rotation": [
              "math.lerp(this, math.cos(query.life_time * 390.0) * 0.3, variable.swim_amount)",
              0,
              0
            ]
          }
        }
      }
    },
    "animation_controllers": {
      "humanoid_baby_big_head": {
        "initial_state": "default",
        "states": {
          "baby": {
            "animations": ["humanoid_big_head"],
            "transitions": [{"default": "!query.is_baby"}]
          },
          "default": {"transitions": [{"baby": "query.is_baby"}]}
        }
      },
      "humanoid_base_pose": {
        "initial_state": "default",
        "states": {"default": {"animations": ["humanoid_base_pose"]}}
      },
      "look_at_target": {
        "initial_state": "default",
        "states": {
          "default": {
            "animations": ["look_at_target_default"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"swimming": "query.is_swimming"}
            ]
          },
          "gliding": {
            "animations": ["look_at_target_gliding"],
            "transitions": [
              {"swimming": "query.is_swimming"},
              {"default": "!query.is_gliding"}
            ]
          },
          "swimming": {
            "animations": ["look_at_target_swimming"],
            "transitions": [
              {"gliding": "query.is_gliding"},
              {"default": "!query.is_swimming"}
            ]
          }
        }
      },
      "move": {
        "initial_state": "default",
        "states": {"default": {"animations": ["move"]}}
      },
      "riding": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"riding": "query.is_riding"}]},
          "riding": {
            "animations": ["riding.arms", "riding.legs"],
            "transitions": [{"default": "!query.is_riding"}]
          }
        }
      },
      "holding": {
        "initial_state": "default",
        "states": {"default": {"animations": ["holding"]}}
      },
      "brandish_spear": {
        "initial_state": "default",
        "states": {
          "brandish_spear": {
            "animations": ["brandish_spear"],
            "transitions": [{"default": "!variable.is_brandishing_spear"}]
          },
          "default": {
            "transitions": [{"brandish_spear": "variable.is_brandishing_spear"}]
          }
        }
      },
      "charging": {
        "initial_state": "default",
        "states": {
          "charging": {
            "animations": ["charging"],
            "transitions": [{"default": "!query.is_charging"}]
          },
          "default": {"transitions": [{"charging": "query.is_charging"}]}
        }
      },
      "attack": {
        "initial_state": "default",
        "states": {
          "attacking": {
            "animations": ["attack.rotations"],
            "transitions": [{"default": "variable.attack_time < 0.0"}]
          },
          "default": {
            "transitions": [{"attacking": "variable.attack_time >= 0.0"}]
          }
        }
      },
      "sneaking": {
        "initial_state": "default",
        "states": {
          "default": {"transitions": [{"sneaking": "query.is_sneaking"}]},
          "sneaking": {
            "animations": ["sneaking"],
            "transitions": [{"default": "!query.is_sneaking"}]
          }
        }
      },
      "bob": {
        "initial_state": "default",
        "states": {"default": {"animations": ["bob"]}}
      },
      "damage_nearby_mobs": {
        "initial_state": "default",
        "states": {
          "damage_nearby_mobs": {
            "animations": ["damage_nearby_mobs"],
            "transitions": [{"default": "!variable.damage_nearby_mobs"}]
          },
          "default": {
            "transitions": [
              {"damage_nearby_mobs": "variable.damage_nearby_mobs"}
            ]
          }
        }
      },
      "bow_and_arrow": {
        "initial_state": "default",
        "states": {
          "bow_and_arrow": {
            "animations": ["bow_and_arrow"],
            "transitions": [{"default": "!query.has_target"}]
          },
          "default": {"transitions": [{"bow_and_arrow": "query.has_target"}]}
        }
      },
      "use_item_progress": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [
              {
                "use_item_progress": "( variable.use_item_interval_progress > 0.0 ) || ( variable.use_item_startup_progress > 0.0 )"
              }
            ]
          },
          "use_item_progress": {
            "animations": ["use_item_progress"],
            "transitions": [
              {
                "default": "( variable.use_item_interval_progress <= 0.0 ) && ( variable.use_item_startup_progress <= 0.0 )"
              }
            ]
          }
        }
      },
      "zombie_attack_bare_hand": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [{"is_bare_hand": "variable.is_holding_left != 1.0"}]
          },
          "is_bare_hand": {
            "animations": ["zombie_attack_bare_hand"],
            "transitions": [{"default": "variable.is_holding_left == 1.0"}]
          }
        }
      },
      "swimming": {
        "initial_state": "default",
        "states": {
          "default": {
            "transitions": [{"is_swimming": "variable.swim_amount > 0.0"}]
          },
          "is_swimming": {
            "animations": ["swimming"],
            "transitions": [{"default": "variable.swim_amount <= 0.0"}]
          }
        }
      }
    },
    "render_controllers": ["controller.render.zombie_villager"],
    "enable_attachables": true,
    "spawn_egg": {"texture": "spawn_egg", "texture_index": 42}
  }
}