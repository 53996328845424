# Made in Blockbench 4.9.4
mtllib materials.mtl

o body
v 0.125 0.625 -0.4375
v 0.125 0.2500000000000001 -0.4375
v 0.125 0.625 0.5625
v 0.125 0.2499999999999999 0.5625
v -0.125 0.2500000000000001 -0.4375
v -0.125 0.625 -0.4375
v -0.125 0.2499999999999999 0.5625
v -0.125 0.625 0.5625
vt 0.40625 0.8125
vt 0.46875 0.8125
vt 0.46875 0.3125
vt 0.40625 0.3125
vt 0.3125 0.8125
vt 0.40625 0.8125
vt 0.40625 0.3125
vt 0.3125 0.3125
vt 0.5625 0.8125
vt 0.625 0.8125
vt 0.625 0.3125
vt 0.5625 0.3125
vt 0.46875 0.8125
vt 0.5625 0.8125
vt 0.5625 0.3125
vt 0.46875 0.3125
vt 0.46875 0.8125
vt 0.40625 0.8125
vt 0.40625 1
vt 0.46875 1
vt 0.53125 1
vt 0.46875 1
vt 0.46875 0.8125
vt 0.53125 0.8125
vn 0 -1 -2.220446049250313e-16
vn 1 0 0
vn 0 1 2.220446049250313e-16
vn -1 0 0
vn 0 2.220446049250313e-16 -1
vn 0 -2.220446049250313e-16 1
usemtl m_2ee28436-932f-1f18-8613-04d9bd8d8ca4
f 4/4/1 7/3/1 5/2/1 2/1/1
f 3/8/2 4/7/2 2/6/2 1/5/2
f 8/12/3 3/11/3 1/10/3 6/9/3
f 7/16/4 8/15/4 6/14/4 5/13/4
f 6/20/5 1/19/5 2/18/5 5/17/5
f 7/24/6 4/23/6 3/22/6 8/21/6
o head
v 0.15625 0.6875 -0.4375
v 0.15625 0.6875 -0.75
v 0.15625 0.4375 -0.4375
v 0.15625 0.4375 -0.75
v -0.15625 0.6875 -0.75
v -0.15625 0.6875 -0.4375
v -0.15625 0.4375 -0.75
v -0.15625 0.4375 -0.4375
vt 0.078125 0.84375
vt 0.15625 0.84375
vt 0.15625 0.71875
vt 0.078125 0.71875
vt 0 0.84375
vt 0.078125 0.84375
vt 0.078125 0.71875
vt 0 0.71875
vt 0.234375 0.84375
vt 0.3125 0.84375
vt 0.3125 0.71875
vt 0.234375 0.71875
vt 0.15625 0.84375
vt 0.234375 0.84375
vt 0.234375 0.71875
vt 0.15625 0.71875
vt 0.15625 0.84375
vt 0.078125 0.84375
vt 0.078125 1
vt 0.15625 1
vt 0.234375 1
vt 0.15625 1
vt 0.15625 0.84375
vt 0.234375 0.84375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_2ee28436-932f-1f18-8613-04d9bd8d8ca4
f 12/28/7 15/27/7 13/26/7 10/25/7
f 11/32/8 12/31/8 10/30/8 9/29/8
f 16/36/9 11/35/9 9/34/9 14/33/9
f 15/40/10 16/39/10 14/38/10 13/37/10
f 14/44/11 9/43/11 10/42/11 13/41/11
f 15/48/12 12/47/12 11/46/12 16/45/12
o head
v 0.09375 0.5634762499999999 -0.6875
v 0.09375 0.5634762499999999 -0.8125
v 0.09375 0.43847625 -0.6875
v 0.09375 0.43847625 -0.8125
v -0.09375 0.5634762499999999 -0.8125
v -0.09375 0.5634762499999999 -0.6875
v -0.09375 0.43847625 -0.8125
v -0.09375 0.43847625 -0.6875
vt 0.03125 0.1875
vt 0.078125 0.1875
vt 0.078125 0.125
vt 0.03125 0.125
vt 0 0.1875
vt 0.03125 0.1875
vt 0.03125 0.125
vt 0 0.125
vt 0.109375 0.1875
vt 0.15625 0.1875
vt 0.15625 0.125
vt 0.109375 0.125
vt 0.078125 0.1875
vt 0.109375 0.1875
vt 0.109375 0.125
vt 0.078125 0.125
vt 0.078125 0.1875
vt 0.03125 0.1875
vt 0.03125 0.25
vt 0.078125 0.25
vt 0.125 0.25
vt 0.078125 0.25
vt 0.078125 0.1875
vt 0.125 0.1875
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_2ee28436-932f-1f18-8613-04d9bd8d8ca4
f 20/52/13 23/51/13 21/50/13 18/49/13
f 19/56/14 20/55/14 18/54/14 17/53/14
f 24/60/15 19/59/15 17/58/15 22/57/15
f 23/64/16 24/63/16 22/62/16 21/61/16
f 22/68/17 17/67/17 18/66/17 21/65/17
f 23/72/18 20/71/18 19/70/18 24/69/18
o head
v 0.125 0.75 -0.4375
v 0.125 0.75 -0.5625
v 0.125 0.6875 -0.4375
v 0.125 0.6875 -0.5625
v 0.0625 0.75 -0.5625
v 0.0625 0.75 -0.4375
v 0.0625 0.6875 -0.5625
v 0.0625 0.6875 -0.4375
vt 0.03125 0.625
vt 0.046875 0.625
vt 0.046875 0.59375
vt 0.03125 0.59375
vt 0 0.625
vt 0.03125 0.625
vt 0.03125 0.59375
vt 0 0.59375
vt 0.078125 0.625
vt 0.09375 0.625
vt 0.09375 0.59375
vt 0.078125 0.59375
vt 0.046875 0.625
vt 0.078125 0.625
vt 0.078125 0.59375
vt 0.046875 0.59375
vt 0.046875 0.625
vt 0.03125 0.625
vt 0.03125 0.6875
vt 0.046875 0.6875
vt 0.0625 0.6875
vt 0.046875 0.6875
vt 0.046875 0.625
vt 0.0625 0.625
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_2ee28436-932f-1f18-8613-04d9bd8d8ca4
f 28/76/19 31/75/19 29/74/19 26/73/19
f 27/80/20 28/79/20 26/78/20 25/77/20
f 32/84/21 27/83/21 25/82/21 30/81/21
f 31/88/22 32/87/22 30/86/22 29/85/22
f 30/92/23 25/91/23 26/90/23 29/89/23
f 31/96/24 28/95/24 27/94/24 32/93/24
o head
v -0.0625 0.75 -0.4375
v -0.0625 0.75 -0.5625
v -0.0625 0.6875 -0.4375
v -0.0625 0.6875 -0.5625
v -0.125 0.75 -0.5625
v -0.125 0.75 -0.4375
v -0.125 0.6875 -0.5625
v -0.125 0.6875 -0.4375
vt 0.125 0.625
vt 0.140625 0.625
vt 0.140625 0.59375
vt 0.125 0.59375
vt 0.09375 0.625
vt 0.125 0.625
vt 0.125 0.59375
vt 0.09375 0.59375
vt 0.171875 0.625
vt 0.1875 0.625
vt 0.1875 0.59375
vt 0.171875 0.59375
vt 0.140625 0.625
vt 0.171875 0.625
vt 0.171875 0.59375
vt 0.140625 0.59375
vt 0.140625 0.625
vt 0.125 0.625
vt 0.125 0.6875
vt 0.140625 0.6875
vt 0.15625 0.6875
vt 0.140625 0.6875
vt 0.140625 0.625
vt 0.15625 0.625
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_2ee28436-932f-1f18-8613-04d9bd8d8ca4
f 36/100/25 39/99/25 37/98/25 34/97/25
f 35/104/26 36/103/26 34/102/26 33/101/26
f 40/108/27 35/107/27 33/106/27 38/105/27
f 39/112/28 40/111/28 38/110/28 37/109/28
f 38/116/29 33/115/29 34/114/29 37/113/29
f 39/120/30 36/119/30 35/118/30 40/117/30
o tail1
v 0.03125 0.6066941738241591 0.5441941738241594
v 0.03125 0.5625 0.5
v 0.03125 0.2531407832308854 0.8977475644174331
v 0.03125 0.20894660940672616 0.853553390593274
v -0.03125 0.5625 0.5
v -0.03125 0.6066941738241591 0.5441941738241594
v -0.03125 0.20894660940672616 0.853553390593274
v -0.03125 0.2531407832308854 0.8977475644174331
vt 0.015625 0.5
vt 0.03125 0.5
vt 0.03125 0.25
vt 0.015625 0.25
vt 0 0.5
vt 0.015625 0.5
vt 0.015625 0.25
vt 0 0.25
vt 0.046875 0.5
vt 0.0625 0.5
vt 0.0625 0.25
vt 0.046875 0.25
vt 0.03125 0.5
vt 0.046875 0.5
vt 0.046875 0.25
vt 0.03125 0.25
vt 0.03125 0.5
vt 0.015625 0.5
vt 0.015625 0.53125
vt 0.03125 0.53125
vt 0.046875 0.53125
vt 0.03125 0.53125
vt 0.03125 0.5
vt 0.046875 0.5
vn 0 -0.7071067811865476 -0.7071067811865475
vn 1 0 0
vn 0 0.7071067811865476 0.7071067811865475
vn -1 0 0
vn 0 0.7071067811865475 -0.7071067811865476
vn 0 -0.7071067811865475 0.7071067811865476
usemtl m_2ee28436-932f-1f18-8613-04d9bd8d8ca4
f 44/124/31 47/123/31 45/122/31 42/121/31
f 43/128/32 44/127/32 42/126/32 41/125/32
f 48/132/33 43/131/33 41/130/33 46/129/33
f 47/136/34 48/135/34 46/134/34 45/133/34
f 46/140/35 41/139/35 42/138/35 45/137/35
f 47/144/36 44/143/36 43/142/36 48/141/36
o tail2
v 0.03125 0.27144660940672627 0.8535533905932737
v 0.03125 0.20894660940672627 0.8535533905932737
v 0.03125 0.2714466094067264 1.3535533905932737
v 0.03125 0.20894660940672638 1.3535533905932737
v -0.03125 0.20894660940672627 0.8535533905932737
v -0.03125 0.27144660940672627 0.8535533905932737
v -0.03125 0.20894660940672638 1.3535533905932737
v -0.03125 0.2714466094067264 1.3535533905932737
vt 0.078125 0.5
vt 0.09375 0.5
vt 0.09375 0.25
vt 0.078125 0.25
vt 0.0625 0.5
vt 0.078125 0.5
vt 0.078125 0.25
vt 0.0625 0.25
vt 0.109375 0.5
vt 0.125 0.5
vt 0.125 0.25
vt 0.109375 0.25
vt 0.09375 0.5
vt 0.109375 0.5
vt 0.109375 0.25
vt 0.09375 0.25
vt 0.09375 0.5
vt 0.078125 0.5
vt 0.078125 0.53125
vt 0.09375 0.53125
vt 0.109375 0.53125
vt 0.09375 0.53125
vt 0.09375 0.5
vt 0.109375 0.5
vn 0 -1 2.220446049250313e-16
vn 1 0 0
vn 0 1 -2.220446049250313e-16
vn -1 0 0
vn 0 -2.220446049250313e-16 -1
vn 0 2.220446049250313e-16 1
usemtl m_2ee28436-932f-1f18-8613-04d9bd8d8ca4
f 52/148/37 55/147/37 53/146/37 50/145/37
f 51/152/38 52/151/38 50/150/38 49/149/38
f 56/156/39 51/155/39 49/154/39 54/153/39
f 55/160/40 56/159/40 54/158/40 53/157/40
f 54/164/41 49/163/41 50/162/41 53/161/41
f 55/168/42 52/167/42 51/166/42 56/165/42
o backLegL
v -0.006249999999999978 0.375 0.5
v -0.006249999999999978 0.375 0.375
v -0.006249999999999978 0 0.5
v -0.006249999999999978 0 0.375
v -0.13124999999999998 0.375 0.375
v -0.13124999999999998 0.375 0.5
v -0.13124999999999998 0 0.375
v -0.13124999999999998 0 0.5
vt 0.15625 0.53125
vt 0.1875 0.53125
vt 0.1875 0.34375
vt 0.15625 0.34375
vt 0.125 0.53125
vt 0.15625 0.53125
vt 0.15625 0.34375
vt 0.125 0.34375
vt 0.21875 0.53125
vt 0.25 0.53125
vt 0.25 0.34375
vt 0.21875 0.34375
vt 0.1875 0.53125
vt 0.21875 0.53125
vt 0.21875 0.34375
vt 0.1875 0.34375
vt 0.1875 0.53125
vt 0.15625 0.53125
vt 0.15625 0.59375
vt 0.1875 0.59375
vt 0.21875 0.59375
vt 0.1875 0.59375
vt 0.1875 0.53125
vt 0.21875 0.53125
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_2ee28436-932f-1f18-8613-04d9bd8d8ca4
f 60/172/43 63/171/43 61/170/43 58/169/43
f 59/176/44 60/175/44 58/174/44 57/173/44
f 64/180/45 59/179/45 57/178/45 62/177/45
f 63/184/46 64/183/46 62/182/46 61/181/46
f 62/188/47 57/187/47 58/186/47 61/185/47
f 63/192/48 60/191/48 59/190/48 64/189/48
o backLegR
v 0.13124999999999998 0.375 0.5
v 0.13124999999999998 0.375 0.375
v 0.13124999999999998 0 0.5
v 0.13124999999999998 0 0.375
v 0.006249999999999978 0.375 0.375
v 0.006249999999999978 0.375 0.5
v 0.006249999999999978 0 0.375
v 0.006249999999999978 0 0.5
vt 0.15625 0.53125
vt 0.1875 0.53125
vt 0.1875 0.34375
vt 0.15625 0.34375
vt 0.125 0.53125
vt 0.15625 0.53125
vt 0.15625 0.34375
vt 0.125 0.34375
vt 0.21875 0.53125
vt 0.25 0.53125
vt 0.25 0.34375
vt 0.21875 0.34375
vt 0.1875 0.53125
vt 0.21875 0.53125
vt 0.21875 0.34375
vt 0.1875 0.34375
vt 0.1875 0.53125
vt 0.15625 0.53125
vt 0.15625 0.59375
vt 0.1875 0.59375
vt 0.21875 0.59375
vt 0.1875 0.59375
vt 0.1875 0.53125
vt 0.21875 0.53125
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_2ee28436-932f-1f18-8613-04d9bd8d8ca4
f 68/196/49 71/195/49 69/194/49 66/193/49
f 67/200/50 68/199/50 66/198/50 65/197/50
f 72/204/51 67/203/51 65/202/51 70/201/51
f 71/208/52 72/207/52 70/206/52 69/205/52
f 70/212/53 65/211/53 66/210/53 69/209/53
f 71/216/54 68/215/54 67/214/54 72/213/54
o frontLegL
v -0.012500000000000011 0.6375 -0.1875
v -0.012500000000000011 0.6375 -0.3125
v -0.012500000000000011 0.012499999999999956 -0.1875
v -0.012500000000000011 0.012499999999999956 -0.3125
v -0.1375 0.6375 -0.3125
v -0.1375 0.6375 -0.1875
v -0.1375 0.012499999999999956 -0.3125
v -0.1375 0.012499999999999956 -0.1875
vt 0.65625 0.9375
vt 0.6875 0.9375
vt 0.6875 0.625
vt 0.65625 0.625
vt 0.625 0.9375
vt 0.65625 0.9375
vt 0.65625 0.625
vt 0.625 0.625
vt 0.71875 0.9375
vt 0.75 0.9375
vt 0.75 0.625
vt 0.71875 0.625
vt 0.6875 0.9375
vt 0.71875 0.9375
vt 0.71875 0.625
vt 0.6875 0.625
vt 0.6875 0.9375
vt 0.65625 0.9375
vt 0.65625 1
vt 0.6875 1
vt 0.71875 1
vt 0.6875 1
vt 0.6875 0.9375
vt 0.71875 0.9375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_2ee28436-932f-1f18-8613-04d9bd8d8ca4
f 76/220/55 79/219/55 77/218/55 74/217/55
f 75/224/56 76/223/56 74/222/56 73/221/56
f 80/228/57 75/227/57 73/226/57 78/225/57
f 79/232/58 80/231/58 78/230/58 77/229/58
f 78/236/59 73/235/59 74/234/59 77/233/59
f 79/240/60 76/239/60 75/238/60 80/237/60
o frontLegR
v 0.13749999999999996 0.6375 -0.1875
v 0.13749999999999996 0.6375 -0.3125
v 0.13749999999999996 0.012499999999999956 -0.1875
v 0.13749999999999996 0.012499999999999956 -0.3125
v 0.012499999999999956 0.6375 -0.3125
v 0.012499999999999956 0.6375 -0.1875
v 0.012499999999999956 0.012499999999999956 -0.3125
v 0.012499999999999956 0.012499999999999956 -0.1875
vt 0.65625 0.9375
vt 0.6875 0.9375
vt 0.6875 0.625
vt 0.65625 0.625
vt 0.625 0.9375
vt 0.65625 0.9375
vt 0.65625 0.625
vt 0.625 0.625
vt 0.71875 0.9375
vt 0.75 0.9375
vt 0.75 0.625
vt 0.71875 0.625
vt 0.6875 0.9375
vt 0.71875 0.9375
vt 0.71875 0.625
vt 0.6875 0.625
vt 0.6875 0.9375
vt 0.65625 0.9375
vt 0.65625 1
vt 0.6875 1
vt 0.71875 1
vt 0.6875 1
vt 0.6875 0.9375
vt 0.71875 0.9375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_2ee28436-932f-1f18-8613-04d9bd8d8ca4
f 84/244/61 87/243/61 85/242/61 82/241/61
f 83/248/62 84/247/62 82/246/62 81/245/62
f 88/252/63 83/251/63 81/250/63 86/249/63
f 87/256/64 88/255/64 86/254/64 85/253/64
f 86/260/65 81/259/65 82/258/65 85/257/65
f 87/264/66 84/263/66 83/262/66 88/261/66